import { functionName } from '@carbonmaps/shared/utils/constants';
import { type QueryFunctionContext } from '@tanstack/react-query';

/**
 * saveBilanFootprintAction
 * @param data
 * @returns
 */
export const saveBilanFootprintAction = async (data: any) => {
	try {
		return Parse.Cloud.run(functionName.carbonFootprint.saveCarbonFootprint, data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getCurrentCarbonFootPrintAction = async (
	context: QueryFunctionContext<readonly ['getCurrentCarbonFootPrint', { companyId: string }]>,
) => {
	try {
		const {
			queryKey: {
				1: { companyId },
			},
		} = context;

		const data = await Parse.Cloud.run(functionName.carbonFootprint.getCurrentCarbonFootPrint, { companyId });
		if (data === 'ok') return {};

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * getCarbonFootprintByCategoryAction
 * @param context
 * @returns
 */
export const getCarbonFootprintByCategoryAction = async (
	context: QueryFunctionContext<readonly ['getCarbonFootprintByCategory']>,
) => {
	try {
		const data = await Parse.Cloud.run(functionName.carbonFootprint.getCarbonFootprintByCategory);

		if (data === 'ok') return;

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 *
 * @param context
 * @returns
 */
export const getCarbonFootprintByScopeAction = async (
	context: QueryFunctionContext<readonly ['getCarbonFootprintByScope']>,
) => {
	try {
		const data = await Parse.Cloud.run(functionName.carbonFootprint.getCarbonFootprintByScope);
		if (data === 'ok') return;

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * carbon footprint products
 * @param context
 * @returns
 */
export const getCarbonFootprintProductAction = async (
	context: QueryFunctionContext<readonly ['getCarbonFootprintProduct']>,
) => {
	try {
		const data = await Parse.Cloud.run(functionName.carbonFootprint.getCarbonFootprintProduct);
		if (data === 'ok') return;

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getDataSBTiFlagAction = async (
	context: QueryFunctionContext<readonly ['getDataSBTiFlag', { period: number; yearSelected?: number }]>,
) => {
	try {
		const {
			queryKey: {
				1: { period, yearSelected },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.carbonFootprint.getDataSBTIFlag, { period, yearSelected });
		if (result === 'ok') return;

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};
