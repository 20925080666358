import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';

export type GetPackagingParams = {
	size: number;
	page: number;
	productId?: string;
};

//--------------------------------------------------------------------------------------//
//                                                                                      //
//                                      List packaging for product                      //
//                                                                                      //
//--------------------------------------------------------------------------------------//

export async function searchPackagingAction(context: any) {
	const {
		queryKey: { 1: params },
	} = context;

	try {
		const result = await Parse.Cloud.run('findPackaging', params);
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
}

// ----------------------------------------------------------------- //
// ---------get the produit list to use in packaging sheet---------- //
// ----------------------------------------------------------------- //

export type GetParams = {
	size: number;
	page: number;
	direction?: number;
	id?: string;
	input?: string;
	facetFilters?: { values: string[]; path: string }[] | [];
	sortingBy?: {
		id: string;
		desc: boolean;
	} | null;
	objectId?: string;
	categoryFilters?: any;
	viewMode?: string;
};

export async function getPackagingProducts(params: GetParams) {
	const { size, page, input = '', sortingBy, objectId, ...rest } = params;

	try {
		const { data, count, maxValue } = (await Parse.Cloud.run('findProduct', {
			limit: size,
			skip: (page - 1) * size,
			input,
			sortingBy,
			packagingId: objectId,
			...rest,
		})) as {
			data: Parse.Object[];
			count: number;
			maxValue: number;
		};
		return {
			data,
			maxValue,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
}

export type useGetKpiParams = {
	carbonImpact?: number;
	waterImpact?: number;
	tonnage?: number;
	percentage?: number;
};

export const getImpactTotalPackagingAction = async (
	context: QueryFunctionContext<
		readonly [
			'getImpactTotalPackaging',
			{ facetFilters?: any; period?: string | number; viewMode?: string; inputSearch?: string },
		]
	>,
): Promise<useGetKpiParams> => {
	try {
		const {
			queryKey: [, { viewMode, period, facetFilters, inputSearch }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.kpi.getImpactTotalPackaging, {
			period,
			viewMode,
			facetFilters,
			inputSearch,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getIndicatorGlobalPackagingAction = async (
	context: QueryFunctionContext<
		readonly [
			'getIndicatorGlobalPackaging',
			{ facetFilters?: any; period?: string | number; viewMode?: string; inputSearch?: string },
		]
	>,
): Promise<useGetKpiParams> => {
	try {
		const {
			queryKey: [, { viewMode, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.kpi.getImpactTotalPackaging, {
			period,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type useGetRecycledPackaging = {
	carbonImpact?: number;
	waterImpact?: number;
	tonnage?: number;
};

export const getImpactRecycledPackagingAction = async (
	context: QueryFunctionContext<
		readonly ['getImpactRecycledPackaging', { period?: string | number; viewMode?: string }]
	>,
): Promise<useGetRecycledPackaging> => {
	try {
		const {
			queryKey: [, { viewMode, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.kpi.getImpactRecycledPackaging, {
			period,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getRecycledPercentageAction = async (
	context: QueryFunctionContext<
		readonly [
			'getRecycledPercentage',
			{
				period?: string | number;
				viewMode?: string;
				inputSearch?: string;
				facetFilters?: any;
			},
		]
	>,
): Promise<useGetKpiParams> => {
	try {
		const {
			queryKey: [, { viewMode, period, inputSearch, facetFilters }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.kpi.getRecycledPercentage, {
			period,
			viewMode,
			inputSearch,
			facetFilters,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getTonnagePackagingAction = async (
	context: QueryFunctionContext<
		readonly [
			'getTonnagePackaging',
			{
				inputSearch?: string;
				facetFilters?: any;
				period?: string | number;
				viewMode?: string;
			},
		]
	>,
): Promise<useGetKpiParams> => {
	try {
		const {
			queryKey: [, { viewMode, period, inputSearch, facetFilters }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.kpi.getTonnagePackaging, {
			period,
			viewMode,
			inputSearch,
			facetFilters,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type useGetPercentageComparaisonParams = {
	recycledTonnagePercent?: number;
	recycledImpactPercent?: number;
};

export const getRecycledPercentageComparaisonAction = async (
	context: QueryFunctionContext<
		readonly ['getRecycledPercentageComparaison', { period?: string | number; viewMode?: string }]
	>,
): Promise<useGetPercentageComparaisonParams> => {
	try {
		const {
			queryKey: [, { viewMode, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.graph.getRecycledPercentageComparaison, {
			period,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getRecycledEndOfLifePercentageComparaisonAction = async (
	context: QueryFunctionContext<
		readonly ['getRecycledPercentageComparaison', { period?: string | number; viewMode?: string }]
	>,
): Promise<useGetPercentageComparaisonParams> => {
	try {
		const {
			queryKey: [, { viewMode, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.endOfLife.getRecycledEndOfLifePercentageComparaison, {
			period,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getIsCmapsModelizationAction = async (
	context: QueryFunctionContext<readonly ['getIsCmapsModelization', { period?: string | number; viewMode?: string }]>,
): Promise<useGetPercentageComparaisonParams> => {
	try {
		const {
			queryKey: [, { viewMode, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.kpi.isCmapsModelization, {
			period,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getDataMaterialRecycledTableAction = async (
	context: QueryFunctionContext<
		readonly ['getDataMaterialRecycledTable', { period?: string | number; viewMode?: string }]
	>,
): Promise<useGetPercentageComparaisonParams> => {
	try {
		const {
			queryKey: [, { viewMode, period, ...rest }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.table.getDataMaterialRecycled, {
			period,
			viewMode,
			...rest,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getDataMaterialTableAction = async (
	context: QueryFunctionContext<readonly ['getDataMaterialTable', { period?: string | number; viewMode?: string }]>,
): Promise<useGetPercentageComparaisonParams> => {
	try {
		const {
			queryKey: [, { viewMode, period, ...rest }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.table.getDataMaterialTable, {
			period,
			viewMode,
			...rest,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getEndOfLifePercentAction = async (
	context: QueryFunctionContext<
		readonly ['getEndOfLifePercentAction', { period?: string | number; viewMode?: string }]
	>,
): Promise<useGetPercentageComparaisonParams> => {
	try {
		const {
			queryKey: [, { viewMode, period, ...rest }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.endOfLife.getEndOfLifePercent, {
			period,
			viewMode,
			...rest,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const getDataMaterialKpiAction = async (
	context: QueryFunctionContext<readonly ['getDataMaterialKpi', { period?: string | number; indicator?: string }]>,
): Promise<useGetPercentageComparaisonParams> => {
	try {
		const {
			queryKey: [, { indicator, period, ...rest }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.kpi.getDataMaterialKpi, {
			period,
			indicator,
			...rest,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getDataTonnageMaterialKpiAction = async (
	context: QueryFunctionContext<
		readonly ['getDataTonnageMaterialKpi', { period?: string | number; indicator?: string }]
	>,
): Promise<useGetPercentageComparaisonParams> => {
	try {
		const {
			queryKey: [, { indicator, period, ...rest }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.packaging.kpi.getDataTonnageMaterial, {
			period,
			indicator,
			...rest,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};
