import { useCallback, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler } from 'react-hook-form';

import { ClientFormInput } from '@carbonmaps/shared/validations/client.validations';
import { QuoteFormInput } from '@carbonmaps/shared/validations/quote.validations';
import { createQuoteAction } from '@carbonmaps/ui/actions/quote.actions';

import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';
import { createClientAction, getQuoteOptionsAutocompleteClientAction } from '../../../lib/react-query/features/client/client.actions';
import CreateDevisCarbone from '../../simulation/quote/CreateDevisCarbone';
import DevisModal from '../../simulation/quote/DevisModal';

const CreateButton = ({ setTableParams, paramsTable, initialSelectedOption }: any) => {
	const [openModal, setOpenModal] = useState(false);
	const [isNew, setIsNew] = useState(false);
	const [selectedOption, setSelectedOption] = useState(initialSelectedOption ?? {});
	const [message, setMessage] = useState('');
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const [search, setSearch] = useState<any>();

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	const {
		mutate: createQuote,
		isError,
		isSuccess,
		isLoading: isLoadingCreateQuote,
	} = useMutation({
		mutationKey: ['createQuote'],
		mutationFn: createQuoteAction,
		onSuccess: async (returnedData: any) => {
			setOpenModal(false);
			// wait a few second because job is running
			setTimeout(() => {
				console.log('quote created');
				queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
				queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
				queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
				queryClient.invalidateQueries({ queryKey: ['getClientSheetById'] });
				queryClient.invalidateQueries({ queryKey: ['findDevisClient'] });
				queryClient.invalidateQueries({ queryKey: ['findReportsClientTable'] });
				queryClient.invalidateQueries({ queryKey: ['findQuoteReportsClientTable'] });
			}, 500);
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	//----- action when submit quote form ----//
	const onSubmit: SubmitHandler<QuoteFormInput> = async (values) => {
		createQuote({
			...values,
			clientId: selectedOption?.objectId,
		} as any);
	};

	//----- mutation to create the client ----//

	//todo remove
	const {
		mutate: createClient,
		isError: isErrorClient,
		isSuccess: isSuccessClient,
		isLoading: isLoadingCreateClient,
	} = useMutation({
		mutationKey: ['createClient'],
		mutationFn: createClientAction,
		onSuccess: async (returnedData: any) => {
			setOpenModal(false);
			setTimeout(() => {
				console.log('client created');
				queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
				queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			}, 500);

			if (returnedData?.isUpdate) {
				setSelectedOption({
					label: returnedData?.client?.get('name'),
					...returnedData?.client?.toJSON(),
				});
			}
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	//----- action when submit client form  ----//
	const onSubmitClient: SubmitHandler<ClientFormInput> = async (values) => {
		createClient({
			...values,
		} as any);

		trackEvent('Simulate Carbon Quote Create Client', {
			ClientName: values?.name,
		});
	};

	//----- fetch data for autocomplete ----//
	const {
		data: dataOptions,
		isLoading: isLoadingOption,
		isSuccess: isSuccessOptions,
	} = useQuery({
		queryKey: [
			'getOptionsAutocompleteClient',
			{
				input: search?.input ?? '',
			},
		] as any,
		queryFn: getQuoteOptionsAutocompleteClientAction,
	});


	return (
		<>
			<CreateDevisCarbone
				setSearch={
					() => {
						/*  */
					}
				}
				isLoading={false}
				setOpenModal={setOpenModal}
				setSelectedOption={setSelectedOption}
				setIsNew={setIsNew}
				initialSelectedOption={initialSelectedOption}
			/>

			<DevisModal
				// eslint-disable-next-line quotes
				title={isNew === true ? t('form-create-quote-title') : t('form-update-quote-title')}
				open={openModal}
				onClose={() => {
					return setOpenModal(false);
				}}
				isLoading={isLoadingCreateQuote}
				onSubmit={onSubmit}
				onSubmitClient={onSubmitClient}
				options={dataOptions}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				message={message}
				isError={isError}
				setSearch={
					setSearch
				}
				isLoadingClient={isLoadingCreateClient}
				isNew={isNew}
				selectedSimulation={setSelectedOption}
				setSelectedSimulation={selectedOption}
				isLoadingOption={isLoadingOption}

			/>
			{/* {/* <LoadingDialog open={isLoadingCreateQuote} content={`${t('Création en cours')}...`} /> */}

		</>
	);
};

export default CreateButton;

