import { useEffect } from 'react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useTranslation } from '../../../hooks/useTranslation';

import GlossaryHeader from './GlossaryHeader';
import GlossaryTable from './GlossaryTable';

const Glossary = (/* props: Props */) => {
	const { setBreadcrumbs } = useApp();
	const { t } = useTranslation();

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('glossary') }]);
	}, [setBreadcrumbs, t]);

	return (
		<Container header={<GlossaryHeader />} seoText={`${t('glossary')} - Carbon Maps`}>
			<GlossaryTable />
		</Container>
	);
};

export default Glossary;
