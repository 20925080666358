
export const MOD_QUOTE = 'quote';
export const MOD_ECO_CONCEPTION = 'eco_conception';
export const MOD_DASHBOARD = 'dashboard';
export const MOD_PRODUCT = 'product';
export const MOD_INGREDIENT = 'ingredient';
export const MOD_PACKAGING = 'packaging';
export const MOD_SUPPLIER = 'supplier';
export const MOD_ANALYSE = 'analyse';
export const MOD_AUDIT = 'audit';
export const MOD_TRAIN = 'train'; // se former
export const MOD_CAP2ER = 'cap2er';
export const MOD_CAMPAIGN = 'campaign';
export const SUB_MOD_RELEASE_NOTE = 'release_note';
export const MOD_SALES = 'sales';

export const MODULE_CONFIG = new Map();


MODULE_CONFIG.set(MOD_DASHBOARD, {
	id: MOD_DASHBOARD,
	label: 'Tableau de bord',
	order: 1,
});

MODULE_CONFIG.set(MOD_PRODUCT, {
	id: MOD_PRODUCT,
	label: 'Produits',
	order: 2,
});

MODULE_CONFIG.set(MOD_INGREDIENT, {
	id: MOD_INGREDIENT,
	label: 'Ingrédients',
	order: 3,
});


MODULE_CONFIG.set(MOD_PACKAGING, {
	id: MOD_PACKAGING,
	label: 'Emballages',
	order: 4,
});



MODULE_CONFIG.set(MOD_SUPPLIER, {
	id: MOD_SUPPLIER,
	label: 'Fournisseurs',
	order: 5,
});

MODULE_CONFIG.set(MOD_ANALYSE, {
	id: MOD_ANALYSE,
	label: 'Analyser',
	order: 6,
});


MODULE_CONFIG.set(MOD_QUOTE, {
	id: MOD_QUOTE,
	label: 'Devis carbone',
	order: 7,
});

MODULE_CONFIG.set(MOD_ECO_CONCEPTION, {
	id: MOD_ECO_CONCEPTION,
	label: 'Eco-conception',
	order: 8,
});


MODULE_CONFIG.set(MOD_AUDIT, {
	id: MOD_AUDIT,
	label: 'Auditer',
	order: 9,
});

MODULE_CONFIG.set(MOD_TRAIN, {
	id: MOD_TRAIN,
	label: 'Se former',
	order: 10,
});

MODULE_CONFIG.set(MOD_CAP2ER, {
	id: MOD_CAP2ER,
	label: 'CAP2ER',
	order: 11,
});


MODULE_CONFIG.set(MOD_CAMPAIGN, {
	id: MOD_CAMPAIGN,
	label: 'Collecter',
	order: 12,
});


MODULE_CONFIG.set(MOD_SALES, {
	id: MOD_SALES,
	label: 'SALES',
	order: 13,
});
