import { useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { Factory, PackageOpen, RefreshCw, Store, Truck, UtensilsCrossed, Wheat } from 'lucide-react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import _ from 'lodash';
import LightTooltipGlossary from '../../../../../components/LightTooltipGlossary';
import ChartContainer from '../../../../../components/charts/ChartContainer';
import PercentBar from '../../../../../components/widget/PercentBar';
import { useGlossary } from '../../../../../hooks/useGlossary';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';
import { CARBON_INDICATOR } from '../../../../../utils/constants';
import { useGetLifeCycleData } from '../../../analyse.hooks';

type LifeCycleGraphProps = {
	isDisabled?: boolean;
};

const LifeCycleGraph = () => {
	const theme = useTheme();
	const { indicator } = useApp();
	const { t } = useTranslation();
	const { g } = useGlossary();

	const { data, isLoading } = useGetLifeCycleData({});

	const items = useMemo(() => {
		return [
			{
				carbonIcon: <Wheat width={24} height={24} color={siteColors.carbon500} />,
				waterIcon: <Wheat width={24} height={24} color={siteColors.water500} />,
				text: t('agriculture'),
				waterKey: 'waterUseAgricultureImpact',
				carbonKey: 'gesAgricultureImpact',
			},
			{
				carbonIcon: <Factory width={24} height={24} color={siteColors.carbon500} />,
				waterIcon: <Factory width={24} height={24} color={siteColors.water500} />,
				text: t('transformation'),
				waterKey: 'waterUseTransformationImpact',
				carbonKey: 'gesTransformationImpact',
			},
			{
				carbonIcon: <PackageOpen width={24} height={24} color={siteColors.carbon500} />,
				waterIcon: <PackageOpen width={24} height={24} color={siteColors.water500} />,
				text: t('emballage'),
				waterKey: 'waterUsePackagingImpact',
				carbonKey: 'gesPackagingImpact',
			},
			{
				carbonIcon: <Truck width={24} height={24} color={siteColors.carbon500} />,
				waterIcon: <Truck width={24} height={24} color={siteColors.water500} />,
				text: t('transport'),
				waterKey: 'waterUseTransportImpact',
				carbonKey: 'gesTransportImpact',
			},
			{
				carbonIcon: <Store width={24} height={24} color={siteColors.carbon500} />,
				waterIcon: <Store width={24} height={24} color={siteColors.water500} />,
				text: t('distribution'),
				waterKey: 'waterUseDistributionImpact',
				carbonKey: 'gesDistributionImpact',
			},
			{
				carbonIcon: <UtensilsCrossed width={24} height={24} color={siteColors.carbon500} />,
				waterIcon: <UtensilsCrossed width={24} height={24} color={siteColors.water500} />,
				text: t('consommation'),
				waterKey: 'gesConsumptionImpact',
				carbonKey: 'waterUseConsumptionImpact',
			},
		];
	}, [theme, t]);

	const getPercentValue = useCallback(
		(key: string) => {
			if (!data) return 0;

			const indicatorImpact = indicator === CARBON_INDICATOR ? 'ges' : 'waterUse';

			const keysImpact = [
				'AgricultureImpact',
				'ConsumptionImpact',
				'DistributionImpact',
				'PackagingImpact',
				'TransformationImpact',
				'TransportImpact',
			].map((key) => {
				return indicatorImpact + key;
			});

			const total =
				_.sumBy(keysImpact, (key) => {
					return parseFloat((data as any)?.[key] || 0);
				}) ?? 1;

			const value = (data as any)[key] ?? 0;
			const percent = (value * 100) / total;

			return percent;
		},
		[data, indicator],
	);

	return (
		<ChartContainer
			title={
				<div>
					<LightTooltipGlossary title={g('cycle-de-vie')}>
						<span className={'underline'}>{t('product-life-cycle-title')}</span>
					</LightTooltipGlossary>{' '}
					<Typography display="inline" color={theme.palette.grey[900]} fontWeight={500} ml="8px">
						{t('product-life-cycle-subtitle')}
					</Typography>
				</div>
			}
			showToggle={false}
			icon={<RefreshCw color={siteColors.grey700} size={20} />}
			sx={{ padding: '24px', ...(!data && { pointerEvents: 'none', opacity: 0.8 }) }}
			withBottom={false}
		>
			<table>
				{items.map((item: any, index: number) => {
					return (
						<tr>
							<td css={{ ...(index < items.length - 1 && { paddingBottom: '12px' }) }}>
								<Box className="flexRow nowrap alignCenter" sx={{ gap: '8px' }}>
									{item[`${indicator}Icon`]}
									<Typography sx={{ color: siteColors.text, fontSize: '12px' }}>{item.text}</Typography>
								</Box>
							</td>
							<td
								css={{ width: '100%', paddingLeft: '15px', ...(index < items.length - 1 && { paddingBottom: '12px' }) }}
							>
								<PercentBar
									value={getPercentValue(item[`${indicator}Key`])}
									color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
								/>
							</td>
						</tr>
					);
				})}
			</table>
		</ChartContainer>
	);
};

export default LifeCycleGraph;
