import { useQuery } from '@tanstack/react-query';

import {
	getDataMaterialKpiAction,
	getDataMaterialRecycledTableAction,
	getDataMaterialTableAction,
	getDataTonnageMaterialKpiAction,
	getEndOfLifePercentAction,
	getImpactRecycledPackagingAction,
	getImpactTotalPackagingAction,
	getIndicatorGlobalPackagingAction,
	getIsCmapsModelizationAction,
	getRecycledEndOfLifePercentageComparaisonAction,
	getRecycledPercentageAction,
	getRecycledPercentageComparaisonAction,
	getTonnagePackagingAction,
	useGetKpiParams,
	useGetPercentageComparaisonParams,
	useGetRecycledPackaging,
} from '@carbonmaps/ui/actions/packaging.actions';

import { useYearSelection } from '../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';

export const useGetImpact = ({
	facetFilters,
	inputSearch,
	viewMode,
	onError,
	onSuccess,
}: {
	inputSearch?: string;
	facetFilters?: any;
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: useGetKpiParams) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getImpactTotalPackaging',
			{
				viewMode,
				period: selectedYear,
				facetFilters,
				inputSearch,
			},
		] as any,
		queryFn: getImpactTotalPackagingAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useIndicatorGlobal = ({
	facetFilters,
	inputSearch,
	viewMode,
	onError,
	onSuccess,
}: {
	inputSearch?: string;
	facetFilters?: any;
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: useGetKpiParams) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getIndicatorGlobalPackaging',
			{
				viewMode,
				period: selectedYear,
			},
		] as any,
		queryFn: getIndicatorGlobalPackagingAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const usePackagingRecycled = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: useGetRecycledPackaging) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getImpactRecycledPackaging',
			{
				viewMode,
				period: selectedYear,
			},
		] as any,
		queryFn: getImpactRecycledPackagingAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useRecycledPercent = ({
	inputSearch,
	facetFilters,
	viewMode,
	onError,
	onSuccess,
}: {
	inputSearch?: any;
	facetFilters?: any;
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: useGetKpiParams) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getRecycledPercentage',
			{
				viewMode,
				period: selectedYear,
				inputSearch,
				facetFilters,
			},
		] as any,
		queryFn: getRecycledPercentageAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useTonnage = ({
	inputSearch,
	facetFilters,
	viewMode,
	onError,
	onSuccess,
}: {
	inputSearch?: any;
	facetFilters?: any;
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: useGetKpiParams) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getTonnagePackaging',
			{
				viewMode,
				period: selectedYear,
				inputSearch,
				facetFilters,
			},
		] as any,
		queryFn: getTonnagePackagingAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useRecyclePercentComparaison = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: useGetPercentageComparaisonParams) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getRecycledPercentageComparaison',
			{
				viewMode,
				period: selectedYear,
			},
		] as any,
		queryFn: getRecycledPercentageComparaisonAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

/**
 *  product table analyze details
 * @param param0
 * @returns
 */
export const useMaterialTable = ({
	viewMode,
	tableOptions,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	tableOptions?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getDataMaterialRecycledTable',
			{
				viewMode,
				period: selectedYear,
				...tableOptions,
			},
		] as any,
		queryFn: getDataMaterialRecycledTableAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

/**
 *  product table analyze details
 * @param param0
 * @returns
 */
export const useMaterial = ({
	viewMode,
	tableOptions,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	tableOptions?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getDataMaterialTable',
			{
				viewMode,
				period: selectedYear,
				...tableOptions,
			},
		] as any,
		queryFn: getDataMaterialTableAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useEndOfLifeMaterialPercent = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getEndOfLifePercentAction',
			{
				viewMode,
				period: selectedYear,
			},
		] as any,
		queryFn: getEndOfLifePercentAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useMaterialKpi = ({
	indicator,
	onError,
	onSuccess,
}: {
	indicator?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getDataMaterialKpi',
			{
				indicator,
				period: selectedYear,
			},
		] as any,
		queryFn: getDataMaterialKpiAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useTonnageMaterialKpi = ({
	indicator,
	onError,
	onSuccess,
}: {
	indicator?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getDataTonnageMaterialKpi',
			{
				indicator,
				period: selectedYear,
			},
		] as any,
		queryFn: getDataTonnageMaterialKpiAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useRecycledEndOfLifePercentComparaison = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: useGetPercentageComparaisonParams) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getRecycledEndOfLifePercentageComparaison',
			{
				viewMode,
				period: selectedYear,
			},
		] as any,
		queryFn: getRecycledEndOfLifePercentageComparaisonAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useCmapsModelization = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: [
			'getIsCmapsModelization',
			{
				viewMode,
				period: selectedYear,
			},
		] as any,
		queryFn: getIsCmapsModelizationAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};
