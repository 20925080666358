import { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import Skeleton from '../../../components/Skeleton';
import { useGlossary } from '../../../hooks/useGlossary';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetOneProductModelization } from '../../../lib/react-query/features/modelization/modelization.hooks';

const styles = {
	container: css({
		gap: '16px',
		border: cn('1px solid', siteColors.grey500),
		borderRadius: '16px',
		padding: '24px',
		color: siteColors.grey900,
		width: '100%',
		alignItems: 'center!important',
	}),
	skeleton: css({
		height: '50px !important',
		width: '100% !important',
	} as any),
	title: css({
		fontWeight: 600,
		color: siteColors.grey900,
		fontSize: '14px',
	} as any),
	label: css({
		fontWeight: 400,
		fontSize: '14px',
		color: siteColors.grey900,
	} as any),
	agb: css({
		color: siteColors.grey700,
		fontWeight: 400,
		fontSize: '14px',
		fontStyle: 'italic',
	} as any),
	content: css({
		gap: '5px',
	} as any),
};

const ReferenceBlock = () => {
	const { t } = useTranslation();
	const { g } = useGlossary();
	const params = useParams();
	const { indicator } = useApp();

	const { data, isLoading, isError, error } = useGetOneProductModelization({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		productId: params.modelizationId!,
		viewMode: indicator,
	});

	return (
		<>
			{isLoading ? (
				<Skeleton width="100%!important" className={styles.skeleton} />
			) : (
				<div className={cx('flexRow nowrap spaceBetween', styles.container)}>
					<div className={cx('flexRow nowrap spaceBetween', styles.content)}>
						<div className={styles.title}>{t('Source externe')}</div>
						<div className={styles.label}>{data?.labelCmaps || data?.labelCmapsAgbIngredient}</div>
						{(data?.bddRef || data?.bddRefPortfolio) && <div className={styles.agb}>({data?.bddRef || data?.bddRefPortfolio})</div>}
					</div>
				</div>
			)}
		</>
	);
};

export default ReferenceBlock;
