import { css, cx } from '@emotion/css';
import { useMemo } from 'react';
import Typography from '../../../../components/Typography';
import Card from '../../../../components/v3/Card';
import DonutChartLegend from '../../../../components/v3/DonutChartLegend';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import CarbonFootprintTooltip from '../../../dashboard/v3/carbon_footprint/CarbonFootprintTooltip';
import DonutChart from '../../components/DonutChart';
import { useTonnageMaterialKpi } from '../../packagings.hooks';

const styles = {
	title: css({
		textTransform: 'uppercase',
	}),
	container: css({
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
	}),
	legend: css({
		width: '230px !important',
		maxWidth: '100% !important',
	}),
};

const TonnageMaterialCard = () => {
	const { t } = useTranslation();

	const { data, isLoading } = useTonnageMaterialKpi({});

	const items = useMemo(() => {
		if (!data?.length) {
			return [
				{
					color: siteColors.grey3,
					value: 99.9,
					tonnage: 0,
					carbonImpact: 0,
					waterImpact: 0,
				},
			];
		}

		const firstElement = data.filter((item: any) => item?.label !== 'other');
		const otherElement = data.find((item: any) => item?.label === 'other');
		const items = firstElement.map((item: any, index: number) => {
			return {
				...item,
				value: item?.tonnagePercent,
				color: (siteColors.materialColors as any)[`color${index}`],
			};
		});
		items.push({
			...otherElement,
			value: otherElement?.tonnagePercent,
			color: siteColors.materialColors.other,
		});

		return items;
	}, [data]);

	return (
		<Card
			title={
				<Typography variant="h3" className="grey8 fontWeight600">
					{t('tonnage par matériaux')}
				</Typography>
			}
			containerClassName={'flex1'}
			loading={isLoading}
		>
			<DonutChart
				width={200}
				height={171}
				items={items}
				innerRadius={83}
				outerRadius={66}
				legend={<DonutChartLegend items={items} title={t('Tonnage (Tonnes)')} />}
				withTooltip
				renderTooltip={(item) => {
					return <CarbonFootprintTooltip label={t(item?.label)} value={item && item?.tonnage} />;
				}}
				data={items}
				className="width100 nowrap gap16"
			/>
		</Card>
	);
};

export default TonnageMaterialCard;
