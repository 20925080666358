import { css, cx } from '@emotion/css';

import CarbonFootprintCategory from './CarbonFootprintCategory';
import CarbonFootprintProduct from './CarbonFootprintProduct';
import CarbonFootprintScope from './CarbonFootprintScope';
import SbtiFlag from './SbtiFlag';

const styles = {
	container: css({
		maxWidth: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridColumnGap: 32,
		gridRowGap: 32,
		paddingBottom: 20,
	}),
};

const BilanFootprint = () => {
	return (
		<div className={cx(styles.container, 'width100')}>
			<CarbonFootprintCategory />
			<CarbonFootprintScope />
			<CarbonFootprintProduct />

			<SbtiFlag />
		</div>
	);
};

export default BilanFootprint;
