import Typography from '../../../../components/Typography';
import { useTranslation } from '../../../../hooks/useTranslation';

type HelpProps = {
	item?: {
		year: number;
		emission_source?: string;
		emission_method?: string;
		tooltip?: string;
	};
	tooltip?: React.ReactNode;
};

const Help = ({ item, tooltip }: HelpProps) => {
	const { t } = useTranslation();

	return tooltip ? (
		<Typography className="fontWeight700">{tooltip}</Typography>
	) : (
		<>
			{item?.emission_source ? (
				<div className="flexColumn ">
					<Typography className="fontWeight700">{t('Source du bilan carbone')}</Typography>
					<span>{item?.emission_source}</span>
					<Typography className="fontWeight700">{t('Méthodologie')}</Typography>
					<span>{item?.emission_method}</span>
				</div>
			) : (
				t('Source externe indéfinie')
			)}
		</>
	);
};

export default Help;
