import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { HelpCircle } from 'lucide-react';

import { siteColors } from '../../../../../apps/front/src/lib/colors';
import { CARBON_INDICATOR } from '../../../../../apps/front/src/utils/constants';
import { useApp } from '../../../hooks/useApp';
import { displayValue } from '../../../utils/numberFormat';
import BPopover from '../BPopover';

type Props = {
	value: any;
	row: any;
	column: any;
	// updateMyData: (...params: any[]) => void;
};

const styles = {
	width30: css({
		width: 30,
	}),
	popoverContent: css({
		padding: '16px',
	}),
	text: css({
		fontSize: '14px !important',
		fontWeight: '400 !important',
	}),
};

const ImpactPortion = ({ value, row, column: { id } }: Props) => {
	const theme = useTheme();
	const { indicator } = useApp();

	return (
		<span className={styles.width30}>
			{_.isNil(value) || _.isNaN(value) || value < 0 ? (
				<BPopover
					modeHover
					content={() => {
						return (
							<div className={styles.popoverContent}>
								<p>Poids net incorrect</p>
							</div>
						);
					}}
					transformOriginHorizontal={'left'}
					transformOriginVertical={'top'}
					anchorOriginHorizontal={'left'}
					anchorOriginVertical={'bottom'}
				>
					<HelpCircle color={theme.palette.grey[600]} />
				</BPopover>
			) : (
				<span
					style={{ color: CARBON_INDICATOR === indicator ? siteColors.carbon500 : siteColors.water500 }}
					className={cx(styles.text, 'number')}
				>
					{/* {formatNumber(value, '', 2)} */}
					{displayValue(value)}
				</span>
			)}
		</span>
	);
};

export default ImpactPortion;
