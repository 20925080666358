import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Database } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { getCompanySheetAction, getCompanySheetFacetAction } from '@carbonmaps/ui/actions/company.actions';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useQueryParams } from '../../../hooks/useSearchParams';

type VersionHeaderProps = {
	subtitle?: string;
};

const VersionHeader = ({ subtitle }: VersionHeaderProps) => {
	const theme = useTheme();
	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';
	// ---- company info ---- //
	const { data } = useQuery({
		queryKey: [
			'getCompanySheetFacet',
			{
				companyId,
			} as any,
		],
		queryFn: getCompanySheetFacetAction,
	});

	return (
		<ListHeader
			title="Data Version"
			subtitle={data?.name || ''}
			icon={
				<IconContainer
					element={<Database size={40} color={theme.palette.common.white} />}
					color={theme.palette.grey[900]}
				/>
			}
			// className="bgGrey500"
		/>
	);
};

export default VersionHeader;
