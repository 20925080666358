import { useQuery } from '@tanstack/react-query';
import { create } from 'zustand';

import { getListPeriodAction } from '@carbonmaps/ui/actions/product.actions';

export const useImpactYear = () => {
	const queryResult = useQuery({
		queryKey: ['getListPeriod', { view: 'top-bar-view-listing-year' }],
		queryFn: getListPeriodAction,
		retry: false,
	});

	return queryResult;
};

/**
 * use zustand
 * hooks to store option selected year
 */

export const useYearSelection = create((set: any) => {
	return {
		selectedYear: new Date().getFullYear(),
		setSelectedYear: (option: any) => {
			return set({ selectedYear: option });
		},
	};
});

/**
 * use period options
 */
export const usePeriodOptions = create((set: any) => {
	return {
		options: [],
		estimatedOptions: [],
		setPeriodOptions: (options: any) => {
			return set({
				options,
				estimatedOptions: options?.filter((item: any) => {
					return item?.data?.estimatedValue;
				}),
			});
		},

		addEstimatedImpactOption: (option: any) => {
			return set((state: any) => {
				return {
					options: state.options?.find((item: any) => {
						return option?.value === item.value;
					})
						? state.options
						: [...state.options, option],
					estimatedOptions: [...state.estimatedOptions, option],
				};
			});
		},
		removeEstimatedOption: (year: any) => {
			return set((state: any) => {
				return {
					options: state.options,
					estimatedOptions: state.estimatedOptions.filter((item: any) => {
						return item?.value !== year;
					}),
				};
			});
		},
	};
});
