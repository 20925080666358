import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { css, cx } from '@emotion/css';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SEO from '../../components/SEO';
import { useTranslation } from '../../hooks/useTranslation';

import GlobalIndicator from './GlobalIndicator';
import LifeCycleQuote from './LifeCycleQuote';
import QuoteMetaData from './QuoteMetaData';
import QuoteSheetContent from './QuoteSheetContent';
import QuoteSheetHeader from './QuoteSheetHeader';
import RepartitionGraph from './RepartitionGraph';

const styles = {
	grid: css({
		display: 'grid',

		gridTemplateColumns: '7fr 5fr',
		'@media screen and (min-width: 1380px)': {
			gridTemplateColumns: '8fr 4fr',
		},
	}),
	leftContainer: css({
		maxHeight: '84.5vh',
		overflowY: 'scroll',
	}),
	rightContainer: css({
		padding: '32px 26px',
		maxHeight: '84.5vh',
		overflowY: 'scroll',
	}),
};

const QuoteSheet = () => {
	const { setBreadcrumbs } = useApp();

	// translation
	const { t } = useTranslation();

	useEffect(() => {
		setBreadcrumbs([
			{
				text: t('Valoriser'),
				link: `${FRONT_PATH_NAMES.sales}`,
			},
			{ text: t('quote-sheet-breadcrumbs'), isBeta: false },
		]);
	}, [t]);

	return (
		<div>
			<SEO title={`${t('Devis carbone')} - CarbonMaps`} />
			<QuoteSheetHeader />
			<div className={cx(styles.grid)}>
				<div className={styles.leftContainer}>
					<QuoteSheetContent />
				</div>
				<div className={cx('bgGrey300', styles.rightContainer)}>
					<GlobalIndicator />
					<LifeCycleQuote />
					<RepartitionGraph />
					<QuoteMetaData />
				</div>
			</div>
		</div>
	);
};

export default QuoteSheet;
