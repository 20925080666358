import { css, cx } from '@emotion/css';
import { CircularProgress, Divider, Typography } from '@mui/material';
import { Pencil } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useTranslation } from '../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../../lib/react-query/features/img/img.hooks';
import QuoteModel from '../../../models/Quote.model';
import { useQuoteStore } from '../quote.hooks';

import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';
import { useState } from 'react';
import { saveAs } from '../../../../../../packages/ui/utils/FileSaverGo';
import { cn, siteColors } from '../../../lib/colors';
import { CARBON_INDICATOR } from '../../../utils/constants';
import { useGeneratePDF } from './pdf-document/worker/useRenderPDF';

const styles = {
	title: css({
		fontSize: '24px !important',
		fontWeight: '600 !important',
		color: siteColors.primary,
		margin: 0,
	}) as any,
	subTitle: css({
		marginTop: '0px',
		fontSize: '14px !important',
		fontWeight: '400 !important',
		width: '100% !important',
	}) as any,

	formContainer: css({
		maxWidth: 500,
		gap: '24px',
		'.input-container': {
			width: '100%',
		},
		marginBottom: '16px',
	}) as any,

	header: css({
		gap: '16px',
	}) as any,
	label1: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
		marginBottom: '8px',
	}) as any,
	label: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
	}) as any,
	searchClassName: css({
		width: '100%',
		'& .MuiOutlinedInput-root': {
			width: '100% !important',
			height: '42px!important',
			borderRadius: '11px !important',
		},
	}) as any,
	netWeightContainer: css({
		'& .MuiInputBase-root': {
			width: '120px !important',
		},
	}),

	chipStyles: css({
		textTransform: 'capitalize',
		background: 'var(--color-background)!important',
		uppercase: 'none',
		borderRadius: '8px !important',
		border: '1px solid rgba(0, 0, 0, 0.10)!important',
		span: {
			color: cn(siteColors.grey900, '!important'),
			fontSize: '1rem !important',
			padding: '8px 8px !important',
		},
	}),
	button: css({
		background: '#fff !important',
		minWidth: '36px !important',
		width: '36px !important',
		height: '36px !important',
		padding: '8px !important',
		border: cn('1px solid', siteColors.grey500, '!important'),
		borderRadius: '8px',
	}),
	actionContainer: css({
		padding: '0px 24px 16px 24px !important',
	}),
	confirmButton: css({
		fontSize: '16px !important',
		fontWeight: 600,
		textTransform: 'none !important',
		gap: 10,
		color: '#C74600 !important',
		background: '#FFE4CB !important',
		'& :hover': {
			background: '#FFCA99 !important',
		},
		'& .Mui-disabled': {
			color: '#C74600 !important',
			background: '#FFE4CB !important',
		},
		borderRadius: '8px !important',
	} as any),
	dialogTitle: css({
		textTransform: 'capitalize',
	}),

	blocForm: css({
		gap: '16px',
	}) as any,

	renderOptionRoot: css({
		padding: '8px',
		cursor: 'pointer',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
	renderOptionLabel: css({
		fontWeight: 'bold',
	}),

	editAutocomplete: css({
		cursor: 'pointer',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey700),
		padding: '10.5px 10px',
		background: siteColors.common.white,
	}),

	titleAutocomplete: css({
		fontSize: '14px',
		color: siteColors.grey900,
		fontWeight: 600,
		border: 'none',
	}),
};

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
	onCloseDialog?: any;
};

// eslint-disable-next-line no-empty-pattern
const QuoteForm = ({ onCloseDialog }: Props) => {
	const { quote } = useQuoteStore() as { quote: QuoteModel | null };
	const { indicator } = useApp();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// console.log('❗❗❗❗❗❗❗❗❗❗', authData);

	const preferredFacet = authData?.session?.company?.category;
	const facetsConfig = authData?.session?.company?.facets;
	const companyCode = authData?.session?.company?.code;

	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);

	const form = useForm({
		defaultValues: async () => {
			return {
				name: quote?.quote?.name || '',
				customMessage: quote?.quote?.customMessage || '',

				interlocutorUserName: quote?.quote?.interlocutorUserName || '',
				interlocutorUserEmail: quote?.quote?.interlocutorUserEmail || '',
				interlocutorUserPhone: '',

				clientEnterpriseName: quote?.getClient()?.name || '',
				clientInterlocutorFirstName: quote?.getClient()?.interlocutorFirstName || '',
				clientInterlocutorName: quote?.getClient()?.interlocutorName || '',
				clientInterlocutorEmail: quote?.getClient()?.interlocutorEmail || '',
			};
		},
		mode: 'onChange',
	});

	form.watch(); // ! this is very important!!!

	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: authData?.session.company?.logo || '' },
	});

	const [showClientForm, toggleClientForm] = useToggle(false);

	// const [isPDFLoading, setIsLoading] = useState(false);
	const { mutateAsync: generate, isLoading: isPDFLoading } = useGeneratePDF();

	return (
		<div css={{ marginBottom: 24 }}>
			<FormContainer
				t={t}
				buttonTitle={t('save-button')}
				withCancel
				onCancel={() => {
					onCloseDialog?.();
				}}
				addStyles={{
					maxWidth: 528,
					margin: 'auto',
				}}
				onSubmit={function (e?: any): void {}}
				loading={isPDFLoading}
				confirmButton={
					<BButton
						label={isPDFLoading ? <CircularProgress sx={{ color: '#FFF' }} size={22} /> : t('export-quote-button')}
						variant="primary"
						onClick={async () => {
							const fileName = `${authData?.session.company?.name || ''}_${quote?.getClient().name || ''}_${
								quote?.quote?.name || ''
							}_${indicator === CARBON_INDICATOR ? t('carbon_file_name') : t('water_file_name')}.pdf`;

							const rs = await generate({
								quote: quote as never,
								form,
								companyLogo: logoUrl,
								indicator: indicator,
								companyCode,
								facetsConfig,
								preferredFacet,
								t,
								languageCode,
								translations: authData?.translations || [],
							});
							saveAs(rs, fileName);
							onCloseDialog?.();
						}}
						addStyles={{ marginTop: 20 }}
					/>
				}
			>
				<div className={cx('flexColumn width100', styles.formContainer)}>
					<div className={cx('flexColumn width100', styles.blocForm)}>
						<Typography variant="h2">{t('quote')}</Typography>

						<Divider
							style={{
								margin: '0 auto', // Centrer le divider si nécessaire
								width: '100%',
							}}
						/>
						<BInput
							required
							id="name"
							label={<div className={cx(styles.label)}>{t('quote-name-label')}</div>}
							fullWidth
							margin="dense"
							{...form.register('name')}
							name="name"
							// error={errors.name ? true : false}
							// errorMessage={errors?.name?.message}
							autoComplete="off"
						/>
						<BInput
							required
							id="customMessage"
							label={<div className={cx(styles.label)}>{t('custom-message-quote')}</div>}
							fullWidth
							margin="dense"
							{...form.register('customMessage')}
							name="customMessage"
							// error={errors.customMessage ? true : false}
							// errorMessage={errors?.customMessage?.message}
							autoComplete="off"
							isTextarea={true}
						/>
					</div>

					<div className={cx('flexColumn width100', styles.blocForm)}>
						<Typography variant="h2">{t('Interlocuteur')}</Typography>

						<Divider
							style={{
								margin: '0 auto', // Centrer le divider si nécessaire
								width: '100%',
							}}
						/>
						<BInput
							required
							id="interlocutorUserName"
							label={<div className={cx(styles.label)}>{t('client-name')}</div>}
							fullWidth
							margin="dense"
							{...form.register('interlocutorUserName')}
							autoComplete="off"
						/>
						<BInput
							required
							id="interlocutorUserEmail"
							label={<div className={cx(styles.label)}>{t('client-email')}</div>}
							fullWidth
							margin="dense"
							{...form.register('interlocutorUserEmail')}
							autoComplete="off"
						/>

						<BInput
							required
							id="interlocutorUserTel"
							label={<div className={cx(styles.label)}>{t('phoneNumber')}</div>}
							fullWidth
							margin="dense"
							{...form.register('interlocutorUserPhone')}
							// error={errors.interlocutorUserTel ? true : false}
							// errorMessage={errors?.interlocutorUserTel?.message}
							autoComplete="off"
						/>
					</div>
					<div className={cx('flexColumn width100', styles.blocForm)}>
						<Typography variant="h2">{t('client')}</Typography>
						<Divider
							style={{
								margin: '0 auto', // Centrer le divider si nécessaire
								width: '100%',
							}}
						/>

						{!showClientForm ? (
							<div
								onClick={() => {
									toggleClientForm();
								}}
								className={cx('flexRow width100 justifyBetween alignCenter  ', styles.editAutocomplete)}
							>
								<div>
									<div className={cx(styles.titleAutocomplete)}>{(quote as any)?.getClient()?.name}</div>
									{(quote as any)?.getClient()?.siretNumber}
									<br />
									{(quote as any)?.getClient()?.address}
									<br />
									{(quote as any)?.getClient()?.postalCode} {(quote as any)?.getClient()?.city}
									<br />
								</div>
								<Pencil color={siteColors.grey700} size={20} />
							</div>
						) : (
							<>
								<BInput
									required
									id="clientEnterpriseName"
									label={<div className={cx(styles.label)}>Raison sociale</div>}
									fullWidth
									margin="dense"
									// name="clientEnterpriseName"
									{...form.register('clientEnterpriseName')}
									error={form.formState.errors.clientEnterpriseName ? true : false}
									errorMessage={form.formState.errors?.clientEnterpriseName?.message}
									autoComplete="off"
								/>
								<BInput
									required
									id="clientInterlocutorFirstName"
									label={<div className={cx(styles.label)}>Prénom du client</div>}
									fullWidth
									margin="dense"
									{...form.register('clientInterlocutorFirstName')}
									error={form.formState.errors.clientInterlocutorFirstName ? true : false}
									errorMessage={form.formState.errors?.clientInterlocutorFirstName?.message}
									autoComplete="off"
								/>
								<BInput
									required
									id="clientInterlocutorName"
									label={<div className={cx(styles.label)}>Nom du client</div>}
									fullWidth
									margin="dense"
									{...form.register('clientInterlocutorName')}
									error={form.formState.errors.clientInterlocutorName ? true : false}
									errorMessage={form.formState.errors?.clientInterlocutorName?.message}
									autoComplete="off"
								/>
								<BInput
									required
									id="clientInterlocutorEmail"
									label={<div className={cx(styles.label)}>Email</div>}
									fullWidth
									margin="dense"
									{...form.register('clientInterlocutorEmail')}
									error={form.formState.errors.clientInterlocutorEmail ? true : false}
									errorMessage={form.formState.errors?.clientInterlocutorEmail?.message}
									autoComplete="off"
								/>
							</>
						)}
					</div>
				</div>
			</FormContainer>
		</div>
	);
};

export default QuoteForm;
