import { useTheme } from '@mui/material';
import { Database } from 'lucide-react';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../hooks/useTranslation';

type HeaderProps = {
	subtitle?: string;
};

const QuestionFormsHeader = ({ subtitle }: HeaderProps) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<ListHeader
			title={t('Questionnaire')}
			subtitle={subtitle}
			icon={
				<IconContainer
					element={<Database size={40} color={theme.palette.common.white} />}
					color={theme.palette.grey[900]}
				/>
			}
		/>
	);
};

export default QuestionFormsHeader;
