import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getDataImpactByCategoryAction } from '@carbonmaps/ui/actions/dashboard.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '../../../../../components/Typography';
import Button from '../../../../../components/v3/Button';
import Card from '../../../../../components/v3/Card';
import CategoryTable from '../../../../../components/v3/CategoryTable';
import { useYearSelection } from '../../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';

const styles = {
	title: css({
		fontSize: '16px !important',
		lineHeight: '1.2 !important',
	}),
	subtitle: css({
		lineHeight: '1.2 !important',
		color: `${siteColors.grey7} !important`,
	}),
	button: css({
		width: 'auto !important',
		minWidth: '50px !important',
		color: `${siteColors.blackButton} !important`,
		padding: '8px 16px !important',
		fontWeight: '500 !important',
	}),
};

type CategoryCardProps = {
	category: string;
	label: string;
};

const CategoryCard = ({ category, label }: CategoryCardProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { selectedYear } = useYearSelection();
	const { indicator } = useApp();

	// ---- fetch segmentation data ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getImpactProductByCategory',
			{
				period: selectedYear,
				category,
				viewMode: indicator,
				limit: 6,
			},
		],
		queryFn: getDataImpactByCategoryAction,
	});

	const goToAnalyzeList = useCallback(() => {
		window.scrollTo(0, 0);
		navigate(`${FRONT_PATH_NAMES.analyse}/${category}`);
	}, []);

	return (
		<Card
			title={
				<div className="flexColumn gap6">
					<Typography className={cx('fontWeight600', styles.title)}>
						{t('analyze-tab-title', { category: label })}
					</Typography>
					<Typography className={styles.subtitle}>{t('Par impact total')}</Typography>
				</div>
			}
			footer={
				<BButton
					variant="secondary"
					label={t('analyze-category-button', { category: label })}
					onClick={goToAnalyzeList}
					className={cx(styles.button)}
				/>
			}
			loading={isLoading}
		>
			<CategoryTable data={data} />
		</Card>
	);
};

export default CategoryCard;
