import { css } from '@emotion/css';
import Typography from '../../../../components/Typography';
import { useTranslation } from '../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../lib/colors';

const styles = {
	text: css({
		color: cn(siteColors.grey700, '!important'),
		textAlign: 'center',
		width: '100%',
		height: '136px',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
	}),
};
type EmptyCarbonFootprintProps = {
	text?: string;
};

const EmptyCarbonFootprint = ({ text }: EmptyCarbonFootprintProps) => {
	const { t } = useTranslation();

	return (
		<Typography className={styles.text}>
			{text || t('Contactez votre CSM pour importer votre bilan carbone')}
		</Typography>
	);
};

export default EmptyCarbonFootprint;
