import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Factory, PackageOpen, Store, Truck, UtensilsCrossed, Wheat } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { functionName } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import VsMarket from '@carbonmaps/ui/components/saas/Table/VsMarket';
import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import Difference from '../../../components/sales/Difference';
import Skeleton from '../../../components/Skeleton';
import Unit from '../../../components/units/Unit';
import { useGlossary } from '../../../hooks/useGlossary';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';

const styles = {
	container: css({
		width: '100%',
	}),
	title: css({
		fontSize: '16px',
		fontWeight: 600,
		alignItems: 'stretch!important',
	}),
	flex1: css({
		flex: 1,
		border: cn('1px solid', siteColors.grey500),
		borderRadius: '16px',
	}),

	stretch: css({
		alignItems: 'stretch',
	}),

	hidden: css({
		display: 'hidden',
	}),
	skeleton: css({
		height: '175px !important',
		width: '100% !important',
	} as any),

	leftBarWater: css({
		background: siteColors.water100,
		borderTop: cn('solid 1px', siteColors.water500),
	}),
	leftBarCarbon: css({
		background: siteColors.carbon100,
		borderTop: cn('solid 1px', siteColors.carbon500),
	}),

	rightBarPositive: css({
		background: '#ffe4cb',
		borderTop: 'solid 1px #C74600',
	}),
	rightBarNegative: css({
		background: '#eaf0dc',
		borderTop: 'solid 1px #009F36',
	}),

	popover: css({
		// display: 'none',
		// display: 'flex',
		padding: '24px',
		// flexDirection: 'column',
		// alignItems: 'flex-start',
		// gap: '16px',
		borderRadius: 'var(--num-8, 8px)',
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.40), 0px 1px 2px 0px rgba(0, 0, 0, 0.45)',
		// width: 'fit-content',
	}),

	w: css({
		flexWrap: 'nowrap !important',
		'& *': {
			whiteSpace: 'nowrap',
		},
	} as any),

	displayNone: css({
		display: 'none',
	}),
};

const icons = {
	agriculture: <Wheat size={20} color={siteColors.grey700} />,
	transformation: <Factory size={20} color={siteColors.grey700} />,
	packaging: <PackageOpen size={20} color={siteColors.grey700} />,
	transport: <Truck size={20} color={siteColors.grey700} />,
	distribution: <Store size={20} color={siteColors.grey700} />,
	consumption: <UtensilsCrossed size={20} color={siteColors.grey700} />,
};

const LifeCycleBlock = () => {
	const { indicator } = useApp();
	const params = useParams();
	const productId = params.modelizationId;

	const { data: lifeCycleData, isLoading } = useQuery({
		queryKey: [functionName.temporality.findModelizationLifecycleBlock, { viewMode: indicator, productId }] as const,
		queryFn: async (ctx) => {
			try {
				const { viewMode, productId } = ctx.queryKey[1];
				return Parse.Cloud.run(functionName.temporality.findModelizationLifecycleBlock, { viewMode, productId });
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});

	// const theme = useTheme();
	const { t } = useTranslation();
	//glossary
	const { g } = useGlossary();

	return (
		<div className={cx('flexColumn gap16', styles.container)}>
			{isLoading ? (
				<Skeleton width="100%!important" className={styles.skeleton} />
			) : (
				<>
					<div>
						{' '}
						<div className={cx(styles.title)}>{t('modelization-title-life-cycle')}</div>
						{lifeCycleData?.display === 'agribalyse_vs_custom' ? (
							<>
								<LightTooltipGlossary title={g('modelization-external')}>
									<span className="underline">{`${t('Modélisation externe')}`}</span>
								</LightTooltipGlossary>{' '}
								vs.{' '}
								<LightTooltipGlossary title={g('modelization-custom')}>
									<span className="underline">{`${t('Modélisation personnalisée')}`}</span>
								</LightTooltipGlossary>
							</>
						) : null}
						{lifeCycleData?.display === 'agribalyse_only' ? (
							<LightTooltipGlossary title={g('modelization-external')}>
								<span className="underline">{`${t('Modélisation externe')}`} </span>
							</LightTooltipGlossary>
						) : null}
					</div>
					<div
						className={cx('flexRow width100')}
						css={{
							gap: 16,
							alignItems: 'stretch',
							padding: '24px',
							borderRadius: '16px',
							border: cn('1px solid', siteColors.grey500),
							// marginBottom: 100,
						}}
					>

						<div
							className={cx('flexRow')}
							css={{
								// /* background: 'red', */
								flexGrow: 1,
								// /* , height: '100%' */
								alignItems: 'stretch',
								gap: '16px',
								height: 250,
							}}
						>
							{(lifeCycleData?.data || []).map((item: any, index: number, array: any) => {
								const diffPosition = 100 - _.max([item.graphPercent1, item.graphPercent2]);

								return (
									<div
										key={index}
										style={{
											width: `calc(${100 / array.length}% - 16px)`,
											// background: 'red',
											paddingTop: '20px',
										}}
									>
										<div
											className={cx('flexRow')}
											css={{
												height: `calc(100% - ${12 + 20}px)`,
												alignItems: 'flex-end',
												gap: '4px',
												position: 'relative',
											}}
										>
											<div
												css={{
													position: 'absolute',
													// left: '50%',
													left: '50%',
													transform: 'translate(-50%, 0)',
													// top: '70%',
												}}
												style={{
													top: `calc(${diffPosition}% - 40px)`,
												}}
											>
												{/* <Difference
													value={lifeCycleData?.display === 'agribalyse_only' ? 0 : item.diffPercent}
													withEquals={false}
													showZero={true}
													diffMainClass={cx('', styles.w)}
													withPercent={true}
												/> */}

												<VsMarket
													column={{
														props: {
															t, page: 'view-modelization', showPopover: false,
														}
													}}
													value={lifeCycleData?.display === 'agribalyse_only' ? 0 : item.diffPercent} />

											</div>
											<div
												className={cx('', indicator === WATER_INDICATOR ? styles.leftBarWater : styles.leftBarCarbon)}
												css={{
													position: 'relative',
													'&:hover': {
														'& .popover-x': {
															display: 'block',
															top: 'var(--num-top, 0px)',
														},
													},
													// zIndex: '9!important',
												}}
												style={
													{
														height: `${item.graphPercent1 || 0}%`,
														width: (() => {
															if (lifeCycleData?.display === 'agribalyse_only') {
																return '100%';
															}

															// if (item.diffPercent === 0) {
															// 	return '100%';
															// }

															return '50%';
															// '100%' : '50%'
														})(),
														'--num-top': `calc(${item.graphPercent1 / 3}%)`,
													} as any
												}
											>
												<div
													css={{
														//
														position: 'absolute',
														display: 'none',
														zIndex: '99!important',
													}}
													className={cx('popover-x', styles.popover)}
												>
													<div css={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{t('Modélisation Externe')}</div>
													<div>{t(item.labelTranslationKey)}</div>
													<div>
														{displayValue(item.agribalyseValue)}{' '}
														<Unit measure={indicator === WATER_INDICATOR ? 'waterIntensity' : 'carbonIntensityKg'} />
													</div>
												</div>
											</div>
											<div
												className={cx(
													'',
													// item.diffPercent > 0 ? styles.rightBarPositive : styles.rightBarNegative,
													(() => {
														if (item.diffPercent > 0) {
															return styles.rightBarPositive;
														}

														if (item.diffPercent < 0) {
															return styles.rightBarNegative;
														}

														return indicator === WATER_INDICATOR ? styles.leftBarWater : styles.leftBarCarbon;
													})(),
													// item.diffPercent === 0 ? styles.displayNone : '',
												)}
												css={{
													width: 'calc(50% - 4px)',
													position: 'relative',
													'&:hover': {
														'& .popover-x': {
															display: 'block',
															top: 'var(--num-top, 0px)',
														},
													},
													// zIndex: '9 !important',
												}}
												style={
													{
														height: `${item.graphPercent2}%`,
														display: lifeCycleData?.display === 'agribalyse_only' ? 'none' : 'auto',
														'--num-top': `calc(${item.graphPercent2 / 3}%)`,
													} as any
												}
											>
												<div
													css={{
														position: 'absolute',
														display: 'none',
														//
														zIndex: '99 !important',
													}}
													className={cx('popover-x', styles.popover)}
												>
													<div css={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
														{t('Modélisation personnalisée')}
													</div>
													<div>{t(item.labelTranslationKey)}</div>
													<div>
														{displayValue(item.customValue)}{' '}
														<Unit measure={indicator === WATER_INDICATOR ? 'waterIntensity' : 'carbonIntensityKg'} />
													</div>
												</div>
											</div>
										</div>
										<div
											className={cx('flexRow alignCenter justifyCenter')}
											css={{ height: '20px', marginTop: '12px' }}
										>
											{(icons as any)[item.key]}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default LifeCycleBlock;
