import { css, cx } from '@emotion/css';

import { useGlossary } from '../../hooks/useGlossary';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import LightTooltipGlossary from '../LightTooltipGlossary';

type TitleListProps = {
	color?: string;
	backgroundColor?: string;
	borderColor?: string;
	value?: string;
	keyGlossary?: string;
	title: string;
	dateSent: string;
	count?: number;
};

const styles = {
	label: css({
		color: siteColors.grey900,
	}),
};

const TitleList = ({ count = 0, title, dateSent }: TitleListProps) => {
	const { t } = useTranslation();

	return (
		<div className="flexRow gap8">
			<div className="fontWeight600 fontSize16">
				{title}
			</div>
			<div className={cx('fontWeight400 fontSize14', styles.label)}>
				{dateSent}
			</div>
			<div className={cx('fontWeight400 fontSize14', styles.label)}>
				({count} {t('supplier')}{count > 1 ? 's' : ''})
			</div>

		</div>
	);
};

export default TitleList;
