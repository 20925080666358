
import { functionName } from '@carbonmaps/shared/utils/constants';


export type ISupplerContact = {
	supplier: {
		objectId: string;
		uid: string;
		name: string;
	},
	contact: {
		// objectId: string;
		firstName: string;
		lastName: string;
		email: string;
		language: string;
	},
	language: string;
}

export async function createUsersForCampaignAction(params: {
	// emails: string[],
	contacts: ISupplerContact[],
	languageCode: string;
	questionFormId: string;
	customEmailContent?: any;
}) {
	try {
		const { contacts: supplierContacts, ...other } = params;
		return await Parse.Cloud.run(functionName.createUsersForCampaign, { supplierContacts, ...other });
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function saveResponseQuestionnaireAction(params: {
	languageCode: string
}) {
	try {
		await Parse.Cloud.run(functionName.saveResponseQuestionnaire, { ...params });
	} catch (error) {
		return Promise.reject(error);
	}
}


export async function getDataFormQuestionnaireAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		const result = await Parse.Cloud.run(functionName.getDataFormQuestionnaire);
		return result;
	} catch (error) {
		console.log('--------- getDataFormQuestionnaireAction error: ', error);
		return Promise.reject(error);
	}
}






