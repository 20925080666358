import { useCallback, useEffect } from 'react';

import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../components/layout/list/Container';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useTranslation } from '../../hooks/useTranslation';
import { CARBON_INDICATOR, MAX_NUMBER_OF_INGREDIENTS_TO_DISPLAY } from '../../utils/constants';

import { getIngredientCount } from '@carbonmaps/ui/actions/ingredient.actions';
import { useQuery } from '@tanstack/react-query';
import { useYearSelection } from '../../hooks/useImpactYear';
import IngredientFilter from './IngredientFilter';
import IngredientHeader from './IngredientHeader';
import IngredientTable from './IngredientTable';
import IngredientTradeOff from './IngredientTradeoff';

export interface IngredientItem {
	label: string;
	countUse: number;
	carbonIntensity?: number;
	waterIntensity?: number;
	carbonImpact?: number;
	percentage?: number;
	labelOrigine?: string;
	uid?: string;
}

const styles = {
	container: css({ gap: '32px' }),
	tradeoffContainer: css({
		borderRadius: 16,
	}),
};

const Ingredients = () => {
	const { indicator, setIndicator, setBreadcrumbs } = useApp();

	const { selectedYear } = useYearSelection();

	const [searchQueryParams] = useSearchQueryParams();

	const { t } = useTranslation();
	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBON_INDICATOR);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackEvent]);

	useEffect(() => {
		setBreadcrumbs([
			//
			// { text: t('reference_ingredients', { ingredients: t('ingredients') }) },
			{ text: t('ingredients') },
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	// ---- useEffect track even on land page ---- //
	useEffect(() => {
		trackEvent('Explore Land Ingredients');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackEvent]);

	// use this count will appear in graph legend
	const { data: ingredientCount, isLoading } = useQuery({
		queryKey: ['getIngredientCount', { period: selectedYear }],
		queryFn: getIngredientCount,
	});

	return (
		<Container
			header={<IngredientHeader />}
			graph={
				!isLoading && ingredientCount < MAX_NUMBER_OF_INGREDIENTS_TO_DISPLAY ? (
					<IngredientTradeOff filterOptions={searchQueryParams} />
				) : null
			}
			actionFilter={<IngredientFilter />}
			seoText={t('ingredients-seo-title', { ingredients: t('ingredients') })}
		>
			<IngredientTable filterOptions={searchQueryParams} />
		</Container>
	);
};

export default Ingredients;
