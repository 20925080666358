import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { getDataModuleAction, updateModuleAction, updateModuleActionShow } from '@carbonmaps/ui/actions/module.actions';
import SwitchCell from '@carbonmaps/ui/components/saas/Table/SwitchCell';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';

import _ from 'lodash';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';

const styles = {
	container: css({
		paddingTop: 16,
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
			padding: '5px!important',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

const ModuleTable = () => {
	// ---- theme ---- //
	const theme = useTheme();
	// ---- translation ---- //
	const { t } = useTranslation();
	// ---- styles ---- //
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);
	// ---- fetch data ref ---- //
	const fetchIdRef = useRef(0);
	// ---- company selected ---- //
	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';
	const navigate = useNavigate();

	// query client
	const queryClient = useQueryClient();
	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState({
		input: '',
		page: 1,
		size: 100,
		companyId,
	});

	// ---- apply version ---- //
	const { mutate: updateModule, isLoading: isLoadingUpdate } = useMutation({
		mutationKey: ['updateModule'],
		mutationFn: updateModuleAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getModuleData'] });
		},
		onError: (error: any) => {
			console.log('error', error);
		},
	});

	// ---- apply version ---- //
	const { mutate: updateModuleShow, isLoading: isLoadingUpdateShow } = useMutation({
		mutationKey: ['updateModuleShow'],
		mutationFn: updateModuleActionShow,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getModuleData'] });
		},
		onError: (error: any) => {
			console.log('error', error);
		},
	});

	// ---- action when switch is visible in front  ---- //
	const handleSwitchChange = async (row: any, status: boolean) => {
		await updateModule({ updateId: row?.original?.objectId, status, companyId: row?.original?.companyId });
	};

	// ---- action when switch is visible in front  ---- //
	const handleSwitchChangeShow = async (row: any, status: boolean) => {
		await updateModuleShow({ updateId: row?.original?.objectId, status, companyId: row?.original?.companyId });
	};

	// ---- columns ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('module-name')} />;
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('active-label')} />;
				},
				accessor: 'isActive',
				editable: true,
				//sortDescFirst: true,
				disableSortBy: true,
				component: 'SimpleCell',
				type: 'booleanStatus',

				Cell: ({ row }: any) => {
					return (
						<SwitchCell
							row={row}
							value={row?.original?.isActive}
							onDataUpdate={handleSwitchChange}
							page="boModule"
							confirmMessage={t('confirm-message-cap2er')}
						/>
					);
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('show-module-label')} />;
				},
				accessor: 'show',
				editable: true,
				//sortDescFirst: true,
				disableSortBy: true,
				component: 'SimpleCell',
				type: 'booleanStatus',

				Cell: ({ row }: any) => {
					return (
						<SwitchCell
							row={row}
							value={row?.original?.show}
							onDataUpdate={handleSwitchChangeShow}
							page="boModule"
							dependance={true}
						/>
					);
				},
			},
		];
	}, [t]);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- fetch data version ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getModuleData',
			{
				...paramsTable,
			},
		],
		queryFn: getDataModuleAction,
	} as any);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
			});
		},
		[updateTableParams],
	);

	return (
		<div className={cx('flexColumn', styles.container)}>
			<TableComponent
				tableName={t('module-title')}
				skeleton={isLoading}
				loading={false}
				fetchData={updateTableParams}
				pageCount={(data as any)?.meta?.last_page || 0}
				noDataMessage={t('empty-data-table-module')}
				pageSize={paramsTable.size}
				columns={tableColumns}
				data={_.get(data, 'data', [])}
				addStyles={stylesTable}
				// manualSortBy
				onSortingColumn={handleSortingTable}
				isSelectable={false}
			/>
			<LoadingDialog open={isLoadingUpdate} content={`${t('Modification en cours')}...`} />
		</div>
	);
};

export default ModuleTable;
