import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';

import { getProductAcvDataAction } from '@carbonmaps/ui/actions/acv.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import TableHeaderCell from '../../../../components/table/TableHeaderCell';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { CARBON_INDICATOR } from '../../../../utils/constants';

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered tr th:nth-of-type(1), .bordered tr td:nth-of-type(1)': {
			width: 10,
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:nth-of-type(3), .bordered tr th:nth-of-type(4)': {
			width: 200,
		},
		'.bordered tr th:nth-of-type(5)': {
			width: 120,
		},
		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) > div ':
			{
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
			},
	};
};

const ProductAcvTable = () => {
	const [searchQueryParams] = useSearchQueryParams();
	const { indicator } = useApp();
	const theme = useTheme();

	// translation
	const { t } = useTranslation();

	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// ---- define columns table ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				// Header: 'PRODUIT FINI',
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('products')} />;
				},
				accessor: 'label',
				editable: true,
				component: 'AcvLabel',
				sortDescFirst: true,
				type: 'string',
				props: {
					// page: 'productAcv'
					enableSelection: false,
				},
			},

			{
				// Header: 'PRODUIT FINI',
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={
								<>
									{t('acv-generic-vs-specific')}
									{/* {t('per_kilo')} */}
								</>
							}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'ges' : 'water',
				editable: true,
				component: 'AcvDelta',
				sortDescFirst: true,
				type: 'string',
				props: {
					page: 'productAcv',
					trans: t,
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('difference')} />;
				},
				accessor: indicator === CARBON_INDICATOR ? 'gesDiffPercent' : 'waterDiffPercent',
				editable: true,
				component: 'AcvDiff',
				sortDescFirst: true,
				type: 'number',
				props: {
					page: 'productAcv',
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label="" />;
				},
				accessor: 'infoBulle',
				editable: true,
				component: 'AcvPopoverField',
				disableSortBy: true,
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label="" sortable={false} />;
				},

				accessor: 'link',
				editable: true,
				type: 'string',
				component: 'LinkField',
				disableSortBy: true,
			},
		];
	}, [indicator, t]);

	// --- ref for fetch data table ---- //
	const fetchIdRef = useRef(0);

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);

	// ---- fetch data ---- //

	const { data, isLoading } = useQuery({
		queryKey: [
			'getProductAcvDataAction',
			{
				...paramsTable,
				viewMode: indicator,
			},
		],
		queryFn: getProductAcvDataAction,
	} as any);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					...(pageIndex && { page: pageIndex + 1 }),
					...(pageSize && { size: pageSize }),
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				sortingBy: value,
			});
		},
		[updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...searchQueryParams };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQueryParams]);

	return (
		<TableComponent
			skeleton={isLoading}
			fetchData={updateTableParams}
			pageCount={(data as any)?.meta?.last_page || 0}
			noDataMessage={t('product-acv-table-empty-data', {
				product: toLowerCase(t('product')),
				of_product: t('of_product'),
				products: toLowerCase(t('products')),
				ingredient: toLowerCase(t('ingredient')),
				ingredients: toLowerCase(t('ingredients')),
				an_ingredient: t('an_ingredient'),
			})}
			pageSize={paramsTable.size}
			columns={tableColumns}
			resetPage={resetPage}
			data={(data as any)?.data || []}
			onSortingColumn={handleSortingTable}
			manualSortBy
			isSelectable={false}
			transFunc={t}
			isExpanded
			addStyles={stylesTable}
		/>
	);
};

export default ProductAcvTable;
