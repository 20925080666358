import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Typography, useTheme } from '@mui/material';
import MUILink from '@mui/material/Link';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { PieChart } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';

import { CARBON_INDICATOR, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getProductTableDataActionPackagingUses } from '@carbonmaps/ui/actions/product.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import BlueCheck from '../../../components/BlueCheck';
import Skeleton from '../../../components/Skeleton';
import DonutChart from '../../../components/charts/DonutChart';
import Unit from '../../../components/units/Unit';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetPackagingSheetTimePeriod } from '../../../lib/react-query/features/packaging/packaging.hooks';
import PackagingModel from '../../../models/Packaging.model';
import { orderBy } from '../../../utils/array.utils';
import { GLOBAL_INDICATOR_CONFIG } from '../../../utils/graph';
import * as packagingUtils from '../../../utils/packaging.utils';
import IndicatorTag from '../../scenario/IndicatorTag';

const styles = {
	block: css({
		background: 'none',
		padding: '0!important',
	}),
	donutContainer: css({
		width: '30%',
		// height: '268px',
	}),

	mainClassName: css({
		marginTop: '-2.5px',
	}),
	textClassName: css({
		fontSize: '90% !important',
	}),

	diffMainClass: css({
		fontSize: '10px!important',
		padding: '1px!important',
		'& svg': {
			width: '10px',
		},
	}),

	skeleton: css({
		height: '300px !important',
		width: '100% !important',
	} as any),

	icon: css({
		width: 24,
		'& svg': {
			width: '100% !important',
		},
	}),

	graphContainer: css({
		// gap: '44px',
		// padding: '0!important',
		gap: '32px',
		padding: '32px 24px 24px 32px',
	}),
	gap8: css({
		gap: 8,
	}),

	gap16: css({
		gap: 16,
	}),
	miniFont: css({
		fontSize: 12,
	}),

	circle: css({
		width: 8,
		height: 8,
		borderRadius: '50%',
		background: 'var(--backgroundColor)',
		marginTop: 4,
	}),

	label: css({
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		maxWidth: 300,
		//textWrap: 'nowrap',
		lineHeight: '1 !important',
		color: cn(siteColors.grey900, '!important'),
	}),
	value: css({
		color: cn(siteColors.grey800, '!important'),
		lineHeight: '1 !important',
	}),
	noUnderline: css({
		textDecoration: 'none !important',
	}),
};
type LegendProps = {
	color: string;
	label: string;
	value?: number;
	objectId: string;
};

const Legend = ({ color, label, value = 0, objectId }: LegendProps) => {
	return (
		<MUILink
			component={Link}
			// target='_blank'
			key={objectId}
			className={styles.noUnderline}
			to={`${FRONT_PATH_NAMES.products.details}/${objectId}/synthesis`}
		>
			<div className="flexRow gap8 nowrap">
				<div className={styles.circle} style={{ ['--backgroundColor' as any]: color }} />
				<div className="flexColumn flex1 gap8">
					<Typography className={styles.label}>{label}</Typography>
					<Typography variant="caption" className={cx(styles.value, 'number')}>
						{formatNumber(value, undefined, 1)} %
					</Typography>
				</div>
			</div>
		</MUILink>
	);
};

type ListItemProps = {
	part: any;
};

const Legends = ({ part }: ListItemProps) => {
	return (
		<div className={cx('flexColumn  nowrap stretch', styles.gap16)}>
			{part.map((item: any, index: number) => {
				return (
					<Legend key={index} color={item.color} label={item?.name} value={item?.value} objectId={item?.objectId} />
				);
			})}
		</div>
	);
};

const PackagingRepartitionGraph = () => {
	const { indicator } = useApp();
	const params = useParams();
	const [packagingObject, setPackagingObject] = useState<PackagingModel>();
	const { selectedYear } = useYearSelection();
	const theme = useTheme();
	const packagingId = params.packagingId;
	const { t } = useTranslation();

	const {
		data: products,
		isLoading,
		isSuccess: isSearchSuccess,
	} = useQuery({
		queryKey: [
			'getProductTableDataPackagingUses',
			{
				input: '',
				page: 1,
				size: 10_000,
				facetFilters: [],
				supplierIds: [],
				viewMode: indicator,
				selectedYear,
				packagingId,
			},
		],
		queryFn: getProductTableDataActionPackagingUses,
	});

	const { data } = useGetPackagingSheetTimePeriod({
		objectId: _.toString(params.packagingId),
	});
	useEffect(() => {
		if (!data) return;

		setPackagingObject(new PackagingModel(data));
	}, [data]);

	const dataPieChart = useMemo(() => {
		const dataPieChart = packagingUtils.getDataPieChart((products as any)?.data, indicator);
		return dataPieChart;
	}, [indicator, products]);

	const content = useMemo(() => {
		const color = indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500;

		const sortingData = (orderBy(dataPieChart || [], 'value') as any)?.filter((item: any) => {
			return item.value > 0.09;
		});

		const firstData = sortingData.slice(0, 20);
		const othersData = sortingData?.length > 20 ? sortingData.slice(20 + 1, sortingData?.length) : [];

		const formattedData =
			firstData.map((p: any, index: number) => {
				return {
					...p,
				};
			}) || [];

		if (othersData?.length) {
			formattedData.push({
				...othersData[0],
				value: _.sumBy(othersData, 'value'),
				color: formattedData[formattedData.length - 2].color,
				name: t('Autres'),
				objectId: 'others',
				y: _.sumBy(othersData, 'value'),
			});
		}

		if (formattedData.length === 0) {
			return (
				<div className={cx('flexRow nowrap stretch ', styles.graphContainer)}>
					<div className={cx(styles.donutContainer)}>Aucun produit associé</div>
				</div>
			);
		}

		const part1 = formattedData.slice(0, 5);
		const part2 = formattedData.slice(5, 10);
		const impact = (packagingObject as any)?.getImpactTotal(indicator);

		const impactIndicator = packagingObject?.getImpactIndicatorSynthesis(indicator, true);

		return (
			<div className={cx('flexRow nowrap stretch ', styles.graphContainer)}>
				<div className={cx(styles.donutContainer)}>
					<DonutChart
						width={150}
						innerRadius={32}
						data={formattedData}
						indicator={'undefined'}
						color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500}
						onClick={function (item: any): void {
							// throw new Error('Function not implemented.');
						}}
						renderTooltip={(item: any) => {
							if (!item) return null;
							return <ToolTip item={item} />;
						}}
						topPosition={-55}
						content={
							<>
								<IndicatorTag
									icon={<div className={styles.icon}>{GLOBAL_INDICATOR_CONFIG.get(indicator)?.impactIcon}</div>}
									mainClassName={styles.mainClassName}
									textClassName={styles.textClassName}
									precision={impact < 1 && impact > 0.01 ? 5 : 0}
									abbreviatedNumbers={true}
									showValue={impact < 0 ? '-' : impactIndicator}
								/>
								<Unit
									measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
									css={{ fontSize: '10px!important', marginTop: '-20px' }}
								/>
							</>
						}
					/>
				</div>
				{/* Legend */}
				<div className={cx('flexRow  nowrap stretch', styles.gap16)}>
					<Legends part={part1} />
					<Legends part={part2} />
				</div>
			</div>
		);
	}, [dataPieChart, indicator, packagingObject, t]);

	return (
		<>
			<BlockTitle className={cx('flexRow alignCenter')}>{t('repartition-product-title')}</BlockTitle>
			<BlockCard className={cx(styles.block)}>
				{isLoading ? (
					<Skeleton width="100%!important" className={styles.skeleton}>
						{content}
					</Skeleton>
				) : (
					content
				)}
			</BlockCard>
		</>
	);
};

const tooltipStyles = {
	container: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
		padding: '16px',
		gap: 4,
		minWidth: '200px',
	}),
};

export const ToolTip = ({ item }: any) => {
	return (
		<div className={cx('flexRow alignCenter nowrap', tooltipStyles.container)}>
			<BlueCheck fontSize={12} title={''} iconSize={14} withIcon={item?.isBlueCheck} />
			<MUILink
				component={Link}
				// target='_blank'
				key={item?.objectId}
				className={styles.noUnderline}
				to={`${FRONT_PATH_NAMES.products.details}/${item?.objectId}/synthesis`}
			>
				<Typography className="flex1">
					<span className="fontWeight600">{formatNumber(item?.value, undefined, 1)}</span> % {item?.name}
				</Typography>
			</MUILink>
		</div>
	);
};

export default PackagingRepartitionGraph;
