import { useRecycledMaterialsProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import { useParams } from 'react-router-dom';
import CircularProgress from '../../../../components/card/CircularProgress';
import Typography from '../../../../components/Typography';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/v3/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';

import { css } from '@emotion/css';
import CircularContent from './CircularContent';
import TooltipPackaging from './TooltipPackaging';

const styles = {
	container: css({
		minHeight: 215,
	}),
};

const RecycledMaterialCard = () => {
	const { t } = useTranslation();
	const params = useParams();

	// ---- fetch data ---- //
	const { data, isLoading } = useRecycledMaterialsProduct({
		productId: params.productId!,
	});

	return (
		<Card
			topTitle={<Typography className="fontWeight500 lineHeight1">{t('Matériaux recyclés')}</Typography>}
			className="justifyCenter alignCenter"
			containerClassName={styles.container}
			loading={isLoading}
		>
			<CircularProgress
				width={162}
				percent={data?.percent || 0.01}
				otherColor={siteColors.green10}
				fillColor={siteColors.green500}
				containerWidth={'162px'}
				innerRadius={41}
				content={
					<CircularContent
						title={`${displayValue(data?.percent, undefined, 1)}%`}
						content={t('Recyclé')}
						color={siteColors.grey8}
					/>
				}
				withBorder
				renderTooltip={(item, indicator) => {
					return <TooltipPackaging item={item}></TooltipPackaging>;
				}}
				data={[
					{
						label: t('par-kilo-packaging'),
						value: data?.tonnage,
						unit: <Unit measure="massTon" />,
					},
					{
						label: t('par-kilo-product'),
						value: data?.tonnageProduct,
						unit: <Unit measure="massTon" />,
					},
				]}
			/>
		</Card>
	);
};

export default RecycledMaterialCard;
