import { cx } from '@emotion/css';

import ProductAcvFilter from '../../../../audit/acv/product/ProductAcvFilter';
import ProductAcvTable from '../../../../audit/acv/product/ProductAcvTable';

const AuditProductLCAs = () => {
	// const { setBreadcrumbs } = useApp();

	// translation
	// const { t } = useTranslation();
	// // ---- breadcrumbs ---- //
	// useEffect(() => {
	// 	setBreadcrumbs([{ text: t('audit-title') }, { text: t('acv-breadcrumbs') }]);
	// }, [t]);

	return (
		<div className={cx('flexColumn')}>
			<ProductAcvFilter />
			<ProductAcvTable />
		</div>
	);
};

export default AuditProductLCAs;
