import Box from '@mui/material/Box';

import CarbonImpactBlock from './CarbonImpactBlock';
import ProductPackagingList from './ProductPackagingList';
import TopOverView from './ProductPackagingTopOverView';
import KpiPackaging from './kpi/KpiPackaging';

export interface PackagingItem {
	weightedCarbonIntensity: string;
	name: number;
	composition: string;
	waterWeighted: string;
}

export interface ProductSingle {
	gesPackaging: number | undefined;
	gesTotal: number | undefined;
}

const ProductPackaging = () => {
	return (
		<>
			<KpiPackaging />
			<Box sx={{ display: 'flex', marginTop: '32px' }}>
				<ProductPackagingList />
			</Box>
		</>
	);
};

export default ProductPackaging;
