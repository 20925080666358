import { type QueryFunctionContext } from '@tanstack/react-query';

import { ICompany } from '@carbonmaps/shared/types/company.types';
import { classNames } from '@carbonmaps/shared/utils/constants';

import { uploadCompanyLogo } from './user.actions';

//--------------------------------------------------------------------------------------//
//                                                                                      //
//                                       Queries                                        //
//                                                                                      //
//--------------------------------------------------------------------------------------//

export const getCompaniesAction = async (
	context: QueryFunctionContext<readonly ['getCompanies']>,
): Promise<ICompany[]> => {
	try {
		return await Parse.Cloud.run('getAllCompany', {});
	} catch (error) {
		console.log(' ------ getCompaniesAction error: ', error);
		return Promise.reject(error);
	}
};

export type GetCompanySheetActionData = {
	companyId: any;
};

export const getCompanySheetAction = async (
	context: QueryFunctionContext<readonly ['getCompanySheet', { companyId: string }]>,
): Promise<any> => {
	try {
		const {
			queryKey: [, { companyId }],
		} = context;

		// Everything must be already JSONs from the server.
		if (!companyId || companyId === '') {
			return Promise.reject('CompanyId misssing');
		}

		const companySheetData = await new Parse.Query(classNames.COMPANY).get(companyId);
		return companySheetData.toJSON()?.[0];
	} catch (error) {
		console.log(' ------ getCompanySheetAction error: ', error);
		return Promise.reject(error);
	}
};

export const getCompanySheetFacetAction = async (
	context: QueryFunctionContext<readonly ['getCompanySheetFacet', { companyId: string; indexId?: string }]>,
): Promise<any> => {
	try {
		const {
			queryKey: [, { companyId, indexId }],
		} = context;

		const data = (await Parse.Cloud.run('getCompanySheet', {
			companyId,
			indexId,
		})) as any;

		return data || [];
	} catch (error) {
		console.log(' ------ GetProductSheetNewActionData error: ', error);
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                                      Mutations                                       //
//--------------------------------------------------------------------------------------//
//create company
type createCompanyInput = {
	code: string;
	name: string;
	maxUsers?: number;
	hasSupplierIng?: any;
	hasSupplierProduct?: any;
	objectId?: string;
	fileLogo?: any;
	deleted?: boolean;
};

export const createUpdateCompanyAction = async ({
	deleted = false,
	fileLogo,
	code,
	name,
	objectId,
	maxUsers,
	hasSupplierIng,
	hasSupplierProduct,
}: createCompanyInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run('createUpdateCompany', {
			deleted,
			code,
			name,
			objectId,
			maxUsers,
			hasSupplierIng,
			hasSupplierProduct,
		});

		// if has logo
		if (res && fileLogo) {
			const params = {
				objectId: res.id,
				file: fileLogo,
			};
			await uploadCompanyLogo(params);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

// delete company
type deleteCompanyInput = {
	companyId: string;
	deleted: boolean | null;
};

export const deleteCompanyAction = async ({ companyId, deleted }: deleteCompanyInput): Promise<void> => {
	try {
		if (deleted && deleted === true) {
			await Parse.Cloud.run('destroyCompany', { companyId });
		} else {
			await Parse.Cloud.run('deleteCompany', { companyId });
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                          CREATE FACET mutations                                      //
//--------------------------------------------------------------------------------------//

type createFacetInput = {
	collectionName: string;
	companyId: string;
	indexName: string;
};

export const createFacetAction = async ({ indexName, collectionName, companyId }: createFacetInput): Promise<void> => {
	try {
		// const res =
		await Parse.Cloud.run('createUpdateFacetCompany', { collectionName, companyId });

		// if (res) {
		// 	await Parse.Cloud.run('createUpdateFacetCompany', { collectionName, indexName: 'search', companyId });
		// }
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                               DELETE FACET mutations                                //
//--------------------------------------------------------------------------------------//

type deleteFacetInput = {
	indexId: string;
	companyId: string;
};

export const deleteFacetAction = async ({ indexId, companyId }: deleteFacetInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run('deleteFacetCompany', { indexId, companyId });

		// if success delete also facet search
		if (res.success && res.facetSearch && res.facetSearch.indexID) {
			await Parse.Cloud.run('deleteFacetCompany', { indexId: res.facetSearch.indexID, companyId });
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                               DELETE FACET FIELD mutations                           //
//--------------------------------------------------------------------------------------//

type deleteFacetFieldInput = {
	indexId: string;
	companyId: string;
	field: string;
};

export const deleteFacetFieldAction = async ({ field, indexId, companyId }: deleteFacetFieldInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run('deleteFacetFieldCompany', { field, indexId, companyId });

		// if success delete also field in search index
		if (res.success && res.facetSearch && res.facetSearch.indexID) {
			await Parse.Cloud.run('deleteFacetFieldCompany', { indexId: res.facetSearch.indexID, field, companyId });
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                                 FIELD FACET mutations                                //
//--------------------------------------------------------------------------------------//

type createFacetFieldInput = {
	collectionName?: string;
	label?: string;
	type?: string;
	field?: any;
	global?: any;
	companyId: string;
	indexId: string;
	isNew: number;
	isVisibleInFront: boolean;
};

export const createUpdateFacetFieldAction = async ({
	collectionName,
	isVisibleInFront = false,
	isNew = 1,
	indexId,
	field,
	label,
	global = false,
	companyId,
	type = 'String',
}: createFacetFieldInput): Promise<void> => {
	try {
		await Parse.Cloud.run('createUpdateFacetFieldCompany', {
			collectionName,
			isVisibleInFront,
			//indexId,
			isNew,
			field,
			label,
			global,
			companyId,
			type,
		});

	} catch (error) {
		return Promise.reject(error);
	}
};

export const saveConfigFacetAction = async ({
	category,
	companyId,
}: {
	category: string;
	companyId: string;
}): Promise<void> => {
	try {
		await Parse.Cloud.run('saveConfigFacet', {
			category,
			companyId,
		});
	} catch (error) {
		return Promise.reject(error);
	}
};


export const updateFacetFieldDbAction = async ({
	collection,
	isVisibleInFront = false,
	indexId,
	field,
	label,
	companyId,
	type,
	global = false,
}: {
	collection: string,
	label: string,
	isVisibleInFront: boolean,
	indexId: string,
	field: string,
	companyId: string,
	type: string,
	global: boolean,
}): Promise<void> => {
	try {

		await Parse.Cloud.run('updateFacetFieldDbCompany', {
			collection,
			isVisibleInFront,
			field,
			label,
			companyId,
			type,
			global
		});

	} catch (error) {
		console.log(error)
		return Promise.reject(error);
	}
};
