import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Component } from 'lucide-react';

import { getCompanySheetFacetAction } from '@carbonmaps/ui/actions/company.actions';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';

type ModuleHeaderProps = {
	subtitle?: string;
};

const ModuleHeader = ({ subtitle }: ModuleHeaderProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';
	// ---- company info ---- //
	const { data } = useQuery({
		queryKey: [
			'getCompanySheetFacet',
			{
				companyId,
			} as any,
		],
		queryFn: getCompanySheetFacetAction,
	});

	return (
		<ListHeader
			title={t('module-title')}
			subtitle={data?.name || ''}
			icon={
				<IconContainer
					element={<Component size={40} color={theme.palette.common.white} />}
					color={theme.palette.grey[900]}
				/>
			}
			// className="bgGrey500"
		/>
	);
};

export default ModuleHeader;
