import { useEffect, useState } from 'react';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useTranslation } from '../../../hooks/useTranslation';

import _ from 'lodash';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { DEFAULT_CUSTOM_MESSAGE_OPTIONS } from '../../../utils/constants';
import CustomMessageForm from './CustomMessageForm';
import PrepareQuestionFormSummary from './PrepareQuestionFormSummary';
import QuestionFormSuppliers from './QuestionFormSuppliers';
import { useQuestionFormStore } from './hooks';

const PrepareQuestionForm = () => {
	const { t } = useTranslation();
	const [screen, setScreen] = useState(0);

	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		setBreadcrumbs([
			{
				text: t('Collecter'),
				link: `${FRONT_PATH_NAMES.questionForms}/general`,
			},
			{
				text: t('Préparer un envoi'),
			},
		]);
	}, [t, setBreadcrumbs]);
	const { setCustomMessages } = useQuestionFormStore();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const customEmailContent = authData?.session.company?.customEmailContent;

	useEffect(() => {
		if (!customEmailContent) return;
		const languageCodes = _.keys(DEFAULT_CUSTOM_MESSAGE_OPTIONS);
		const messages = languageCodes.reduce((acc, lang) => {
			acc[lang] = (customEmailContent as never)?.[lang] ?? DEFAULT_CUSTOM_MESSAGE_OPTIONS[lang]?.value ?? '';
			return acc;
		}, {} as any);
		setCustomMessages(messages as never);
	}, [authData]);

	if (screen === 1) return <QuestionFormSuppliers setScreen={setScreen} />;
	if (screen === 2) return <CustomMessageForm setScreen={setScreen} />;
	return <PrepareQuestionFormSummary setScreen={setScreen} />;
};

export default PrepareQuestionForm;
