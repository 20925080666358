import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';
// import _ from 'lodash';

export type GetInfoGeneralActionData = {
	product: number;
	ingredient?: number;
	indicator?: GetIndicatorFunctionResult;
	supplier?: number;
	dataSegment?: any;
};

export type GetIndicatorFunctionResult = {
	sumGes: number;
	sumWater: number;
	sumGesIngredients: number;
	sumGesAgricultures: number;
	sumGesTransformation: number;
	sumGesEmballage: number;
	sumGesTransport: number;
	sumGesDistribution: number;
	sumGesConsommation: number;
	sumWaterUseIngredients: number;
	sumWaterUseAgricultures: number;
	sumWaterUseTransformation: number;
	sumWaterUseEmballage: number;
	sumWaterUseTransport: number;
	sumWaterUseDistribution: number;
	sumWaterUseConsommation: number;
	sumGesTotal: number;
	ges: number;
	water: number;
	[x: string]: number;
	// objectId: null
};

export const getInfoGeneralAction = async (
	context: QueryFunctionContext<readonly ['getInfoGeneral', Record<string, any>]>,
): Promise<GetInfoGeneralActionData> => {
	try {
		const sessionToken = Parse.User.current()?.getSessionToken();

		// use promise all to get all dashboard info
		const productCountPromise = Parse.Cloud.run('getCountProduct', null, { sessionToken });

		const [productCount]: [number] = await Promise.all([productCountPromise]);

		return {
			product: productCount,
		};
	} catch (error) {
		return Promise.reject(error);
	}
};

type getProductCountByPeriodParams = {
	facetFilters?: any;
	period: any;
	category?: boolean;
};

/**
 * count product by period
 * @returns
 */
export const getProductCountByPeriod = async (
	context: QueryFunctionContext<readonly ['getProductCountByPeriod', getProductCountByPeriodParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { facetFilters, period },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.temporality.countProductByPeriod, {
			facetFilters,
			period,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * getTotalImpactByPeriod
 * @returns
 */
export const getTotalImpactByPeriod = async (
	context: QueryFunctionContext<readonly ['getTotalImpactByPeriod', getProductCountByPeriodParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { facetFilters, period, category },
			},
		} = context;
		if (category) {
			const result = await Parse.Cloud.run(functionName.carbonFootprint.getTotalImpactByCategory);
			return result;
		}
		const result = await Parse.Cloud.run(functionName.temporality.getTotalImpactByPeriod, { facetFilters, period });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

// ------------------------------------------------------------------ //
// ----------------- segmentation data ------------------------------ //
// ------------------------------------------------------------------ //

type GetSegmentationParams = {
	facetFilters?: any;
	period?: number;
	selectedYear?: number;
	viewMode?: string;
	currentSegment?: string;
	category?: string;
	limit?: number;
};

export const getSegmentationDataAction = async (
	context: QueryFunctionContext<readonly ['getSegmentationData', GetSegmentationParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { facetFilters, period },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.dashboard.getProductCategories, { facetFilters, period });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * getDataImpactByCategoryAction
 * @param context
 * @returns
 */
export const getDataImpactByCategoryAction = async (
	context: QueryFunctionContext<readonly ['getImpactProductByCategory', GetSegmentationParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { viewMode, category, period, limit },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.dashboard.getImpactByCategory, {
			viewMode,
			category,
			period,
			limit,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getCurrentCategoryAction = async (context: QueryFunctionContext<readonly ['getCurrentCategory']>) => {
	try {
		const result = await Parse.Cloud.run(functionName.dashboard.getCurrentCategory);
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

type GetSuppliersByTemporalityParams = {
	facetFilters?: any;
	year?: number;
};

export const getSuppliersByTemporality = async (
	context: QueryFunctionContext<readonly ['getSuppliersByTemporality', GetSuppliersByTemporalityParams]>,
) => {
	const {
		queryKey: {
			1: { facetFilters, year },
		},
	} = context;

	try {
		console.log('test');
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getSuppliersData = async (
	context: QueryFunctionContext<readonly ['getSuppliersData', GetSegmentationParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { facetFilters, selectedYear },
			},
		} = context;

		if (!selectedYear) {
			await Parse.Cloud.run('findSupplier', { isGraph: true });
		}

		if (!selectedYear) {
			const result = await Parse.Cloud.run('findSupplier', { isGraph: true });
			return result;
		}

		const result = await Parse.Cloud.run(functionName.temporality.findSupplier, {
			view: 'general-suppliers-tradeoff',
			facetFilters,
			selectedYear,
		});

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

// ------------------------------------------------------------------ //
// ----------------- global impact data ------------------------------ //
// ------------------------------------------------------------------ //

type GetGlobalImpactParams = {
	period?: any;
	facetFilters?: any;
};

export const getTemporalityLifeChartAction = async (
	context: QueryFunctionContext<readonly ['getTemporalityLifeChart', GetGlobalImpactParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { facetFilters, period },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.dashboard.getTemporalityLifeChart, { facetFilters, period });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getDataSBTiFlagAction = async (
	context: QueryFunctionContext<readonly ['getDataSBTiFlag', GetGlobalImpactParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { facetFilters, period },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.dashboard.getTemporalityLifeChart, { facetFilters, period });

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getTotalImpactPerPeriod = async (
	context: QueryFunctionContext<readonly ['getTotalImpactPerPeriod', GetGlobalImpactParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { facetFilters, period },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.temporality.getTemporalityData, { facetFilters, period });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * saveEstimatedImpact
 * @param data
 * @returns
 */
export const saveEstimatedImpact = async (data: any): Promise<void> => {
	try {
		const res = await Parse.Cloud.run(functionName.temporality.saveImpactEstimated, data);
		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

// -------------------------------------------------------------------- //
// ------------- data products display in dashboard ------------------- //
// -------------------------------------------------------------------- //

type GetProductsParams = {
	viewMode?: string;
	indicatorField?: string;
	sorting?: string;
	facetFilters?: any;
	period?: any;
};

export const getProductResumeAction = async (
	context: QueryFunctionContext<readonly ['getProductResume', GetProductsParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { viewMode, indicatorField, sorting, facetFilters },
			},
		} = context;
		const result = await Parse.Cloud.run('getProductSection', { viewMode, indicatorField, sorting, facetFilters });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getEcoDesignProducts = async (
	context: QueryFunctionContext<readonly ['getEcoDesignProducts', GetProductsParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { viewMode, indicatorField, sorting, facetFilters, period },
			},
		} = context;
		const result = await Parse.Cloud.run('getEcoDesignProducts', {
			viewMode,
			period,
			indicatorField,
			sorting,
			facetFilters,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getDataAcvPercent = async (
	context: QueryFunctionContext<readonly ['getDataAcvPercent', GetProductsParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { facetFilters },
			},
		} = context;
		const result = await Parse.Cloud.run('getDataAcvPercent', { facetFilters });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVolumeErrors = async (
	context: QueryFunctionContext<readonly ['getVolumeErrors', GetProductsParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { facetFilters, period },
			},
		} = context;
		const result = await Parse.Cloud.run('getVolumeErrors', { facetFilters, period });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getScenarioData = async (
	context: QueryFunctionContext<readonly ['getScenarioData', GetProductsParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { viewMode, indicatorField, sorting, facetFilters },
			},
		} = context;
		const result = await Parse.Cloud.run('getScenarioData', { viewMode, indicatorField, sorting, facetFilters });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getEcoDesignIndicator = async (
	context: QueryFunctionContext<readonly ['getEcoDesignIndicator', GetProductsParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { viewMode, indicatorField, period, sorting, facetFilters },
			},
		} = context;
		const result = await Parse.Cloud.run('getEcoDesignIndicator', {
			viewMode,
			period,
			indicatorField,
			sorting,
			facetFilters,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

// ----------------------------------------------------------------- //
// ------------------- data ingredients ---------------------------- //
// ----------------------------------------------------------------- //
export const getIngredientsAction = async (
	context: QueryFunctionContext<readonly ['getDataIngredientsSection', GetProductsParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { viewMode, indicatorField, sorting, facetFilters },
			},
		} = context;
		const result = await Parse.Cloud.run('getIngredientSection', { viewMode, indicatorField, sorting, facetFilters });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

// --------------------------------------------------------- //
// --------------------- packaging ------------------------- //
// --------------------------------------------------------- //

type GetPackagingParams = {
	tab?: string;
	viewMode?: string;
	indicatorField?: string;
	sorting?: string;
	unit?: number;
	facetFilters?: any;
};

export const getPackagingSectionAction = async (
	context: QueryFunctionContext<readonly ['getPackagingSection', GetPackagingParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { viewMode, indicatorField, sorting, tab, unit, facetFilters },
			},
		} = context;
		const result = await Parse.Cloud.run('getPackagingSection', {
			viewMode,
			indicatorField,
			sorting,
			tab,
			unit,
			facetFilters,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

type GetSuppliersParams = {
	viewMode: string;
	sortingBy?: any;
	limit?: number;
	searchByIngredient?: string;
	facetFilters?: any;
};

export const getSuppliersAction = async (
	context: QueryFunctionContext<readonly ['getSuppliers', GetSuppliersParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { viewMode, sortingBy, limit, searchByIngredient, facetFilters },
			},
		} = context;
		const result = await Parse.Cloud.run('findSupplier', {
			groupField: '',
			limit,
			skip: 0,
			withMedian: true,
			viewMode,
			facetFilters,
			sortingBy,
			searchByIngredient,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

type GetScoringDataSuppliersParams = {
	filters?: any;
};

/**
 *  function to retrieve suppliers group by scoring suppliers
 * @param context
 * @returns
 */
export const getScoringDataSuppliersAction = async (
	context: QueryFunctionContext<readonly ['getScoringDataSuppliers', GetScoringDataSuppliersParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { filters },
			},
		} = context;
		const result = await Parse.Cloud.run('getScoringDataSupplier', {});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getTopSupplier = async (
	context: QueryFunctionContext<readonly ['getTopSupplier', GetSegmentationParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { facetFilters, period, viewMode, currentSegment },
			},
		} = context;

		if (!period) {
			const result = await Parse.Cloud.run('findSupplier', { isGraph: true });

			return result.slice(0, 6);
		}

		const result = await Parse.Cloud.run(functionName.dashboard.getTopSupplier, {
			facetFilters,
			period,
			viewMode,
			currentSegment,
		});

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};
