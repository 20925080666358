import { css, cx } from '@emotion/css';
import { fontSize } from '@mui/system';
import Typography from '../../../../components/Typography';

const styles = {
	title: css({
		fontSize: '24px !important',
		color: 'var(--color) !important',
	}),
};
type CircularContentProps = {
	title: React.ReactNode;
	content: React.ReactNode;
	color: string;
};

const CircularContent = ({ title, content, color }: CircularContentProps) => {
	return (
		<div className="flexColumn alignCenter gap4">
			<Typography className={cx('lineHeight1', styles.title)} style={{ ['--color' as any]: color }}>
				{title}
			</Typography>
			<Typography variant="caption" className="grey8">
				{content}
			</Typography>
		</div>
	);
};

export default CircularContent;
