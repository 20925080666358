import _ from 'lodash';

import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';

import PackagingKPIs from './PackagingKPIs';
import PackagingsFilter from './PackagingsFilter';
import PackagingTable from './PackagingTable';

const PackagingsDefault = () => {
	return (
		<div
			css={{
				'.filterContainer-wrapper': {
					// paddingTop: 0,
					paddingLeft: 0,
					paddingRight: 0,
					top: `${_.toString(58 * 2 + 2)}px`,
				},
			}}
		>
			<PackagingsFilter withMore={false} />
			<div className="flexColumn width100 gap24">
				<PackagingTable manualSortBy />
			</div>
		</div>
	);
};

export default PackagingsDefault;
