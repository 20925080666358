import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { css, cx } from '@emotion/css';
import { Typography, useTheme } from '@mui/material';
import _ from 'lodash';
import { Atom, PackageOpen } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import TableHeaderCell from '../../../../components/table/TableHeaderCell';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/widget/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { useMaterialTable } from '../../packagings.hooks';

const styles = {
	headerImpact: css({
		flexDirection: 'row-reverse !important',
		gap: '4px',
	} as any),
	impactContainer: css({
		flexDirection: 'row-reverse !important',
		justifyContent: 'flex-end',
	} as any),
	popoverContent: css({
		padding: '16px',
		minWidth: '120px !important',
	}),
	unit: css({
		marginLeft: 12,
	}),
	fontBold: css({
		fontWeight: 'bold',
	}),
};

const MaterialTable = () => {
	// ---- define hooks ---- //
	const { t } = useTranslation();
	const { indicator } = useApp();
	// --- ref for fetch data table
	const fetchIdRef = useRef(0);
	const theme = useTheme();
	const [paramsTable, setTableParams] = useState(
		/* <GetProductTableQueryParams> */ {
			input: '',
			page: 1,
			size: 100,
			facetFilters: [],
			supplierIds: [], // Suppliers filter
			direction: 0,
		},
	);

	// ---- define columns table ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				// Header: 'PRODUIT FINI',
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('Matériaux recyclés')} />;
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				disableSortBy: true,
				icon: <PackageOpen size={20} color={siteColors.grey700} />,
				props: {
					noLink: true,
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell column={props.column} label={t('Tonnage recyclé')} variant="measure" measure={'percent'} />
					);
				},
				accessor: 'tonnageRecycledPercentage',
				editable: true,
				sortDescFirst: true,
				component: 'RecycledPercentField',
				type: 'percent',
				props: {
					color: siteColors.grey7,
					className: 'justifyEnd',
					anchorOriginHorizontal: 'center',
					popoverContent: (row: any) => {
						const value = _.get(row, 'original.tonnage');

						return (
							<div className={cx('flexColumn  nowrap gap8', styles.popoverContent)}>
								<div className={styles.fontBold}>{t('recycled-tonnage')}</div>
								<Typography className={cx(/* styles.value, */ 'number')}>
									{_.isString(value) ? value : `${displayValue(value, undefined, 2)}`}
									<Unit
										// className={styles.unit}
										sx={{ ml: '5px' }}
										measure={'massTon'}
									/>
								</Typography>
							</div>
						);
					},
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('Impact recyclé')}
							variant="measure"
							measure={'percent'}
							className={styles.headerImpact}
							isLeft
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonImpactRecycledPercentage' : 'waterImpactRecycledPercentage',
				editable: true,
				sortDescFirst: true,
				component: 'RecycledPercentField',
				type: 'string',
				props: {
					color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
					className: styles.impactContainer,
					contentAlignment: 'flex-start',
					anchorOriginHorizontal: 'left',
					popoverContent: (row: any) => {
						const value = _.get(row, `original.${indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpact'}`);

						return (
							<div className={cx('flexColumn  nowrap gap8', styles.popoverContent)}>
								<div className={styles.fontBold}>{t('recycled-impact')}</div>
								<Typography className={cx(/* styles.value, */ 'number')}>
									{_.isString(value) ? value : `${displayValue(value, undefined, 2)}`}
									<Unit
										// className={styles.unit}
										sx={{ ml: '5px' }}
										measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpactTon'}
									/>
								</Typography>
							</div>
						);
					},
				},
			},
		];
	}, [indicator, t]);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	const { data, isLoading } = useMaterialTable({
		viewMode: indicator,
		tableOptions: paramsTable,
	});

	return (
		<Card
			title={t('Par matériaux')}
			icon={<Atom color={siteColors.grey7} size={20} />}
			sx={{ padding: 0, flexWrap: 'nowrap', width: '100%' }}
			withBorder={false}
			center={false}
		>
			<TableComponent
				skeleton={isLoading}
				loading={false}
				fetchData={updateTableParams}
				pageCount={1}
				noDataMessage={t('material_empty_table')}
				pageSize={paramsTable.size}
				columns={tableColumns}
				data={data || []}
				onSortingColumn={handleSortingTable}
				stickyHeader
				transFunc={t}
				isSelectable={false}
				addStyles={{
					' .bordered th': {
						borderLeft: 'none!important',
						background: `${theme.palette.common.white} !important`,
					},
					'.bordered td': {
						borderLeft: 'none!important',
						background: `${theme.palette.common.white} !important`,
						// borderBottom: `1px solid ${theme.palette.grey[400]}`,
					},
					// '.bordered tr th:not(:nth-of-type(1)) > div,.bordered tr td:not(:nth-of-type(1)) div ,.bordered tr td:not(:nth-of-type(1)) button':
					// 	{
					// 		justifyContent: 'flex-end!important',
					// 		paddingRight: '0!important',
					// 	},
				}}
				pagination={false}
			/>
		</Card>
	);
};

export default MaterialTable;
