import { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { eachOfLimit } from 'async';

import { getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getSupplierFacets, getSupplierTradeOff } from '@carbonmaps/ui/actions/supplier.actions';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';

import { EXPORT_TYPE } from '@carbonmaps/shared/utils/constants';
import { generateUniqUID } from '@carbonmaps/ui/utils/utils';
import { IFilterFolder } from '../../../../components/Filter';
import { Option } from '../../../../components/form/fields/MultiSelectCheckboxField';
import FilterContainer from '../../../../components/layout/list/FilterContainer';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { exportSupplierAction } from '../../../../lib/react-query/features/export/supplier.actions';
import { getLabelNodeFacet, SUPPLIER_LABEL_FOLDER } from '../../../../utils/utils';

const suppliersFolderLabel = SUPPLIER_LABEL_FOLDER;

const SuppliersFilter = ({ withMore = true }: { withMore?: boolean }) => {
	// translation
	const { t } = useTranslation();

	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const searchParams = searchQueryParams?.facetFilters ?? [];
	const [isExportLoading, setIsExportLoading] = useState(false); // custom facets suppliers
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);
	const [exportKey, setExportKey] = useState<string | undefined>(undefined);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// filters values
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);

	// ---- select rows selected in store ---- //
	const { selectedRows } = useRowTableSelection();

	// -------------------------------------------------------------------------------------- //
	// -------------------------------- Use query ------------------------------------------- //
	// -------------------------------------------------------------------------------------- //

	// ---- fetch facets ---- //
	const { data: facets, isLoading: isFacetsLoading } = useQuery({
		queryKey: [
			'getSupplierFacets',
			{
				input: searchQueryParams?.input || '',
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: async ({ queryKey }) => {
			const { 1: parameters } = queryKey;

			return await getSupplierFacets({ ...parameters /* , category: categorySelected */ });
		},
	}) as any;

	// ---- fetch data graph to count in export---- //
	const { data } = useQuery({
		queryKey: [
			'getSupplierTradeOff',
			{
				facetFilters: searchQueryParams.facetFilters,
				input: searchQueryParams.input,
			},
		],
		queryFn: getSupplierTradeOff,
	});

	useEffect(() => {
		//	if there are filters in the url
		if (searchParams && searchParams.length) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			searchParams.forEach((item: any) => {
				const arrayValues = item.values.map((v: any) => {
					const value = {
						facetPath: item.path,
						value: v,
						folderLabel: item.folderLabel ?? item.path,
						label: getLabelValue(item.path, v),
						isFacetBucket: true,
						type: typeof v,
						global: item.global === true ? true : false,
						itemType: item.itemType,
					};

					currentSelectedValues.push(value);
					return value;
				});

				currentAppliedValuesFolders.push({
					isFacet: true,
					label: item.folderLabel ?? item.path,
					options: arrayValues,
				});
			});

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchParams]);

	useEffect(() => {
		const asyncWrapper = async () => {
			// don't change current filter
			if (!facets) return;

			const facetFilterFolders: IFilterFolder[] = [];

			for await (const entry of Object.entries(facets.facet)) {
				const [key, value] = entry;
				const { buckets } = value as any;
				const { label, type, global, itemType } = getFacetConfig(key, authData?.session.company) ?? {};
				const facetLabel = label || getFacetLabel(key, authData?.session.company);

				const currentFacetFolder: IFilterFolder = {
					label: facetLabel,
					options: [],
					isFacet: true,
					path: key,
					showCount: false,
				};

				await eachOfLimit(buckets as any[], 100, async (bucket, k) => {
					if (!bucket.count) {
						return;
					}

					currentFacetFolder.options.push({
						value: bucket._id,
						label: getLabelValue(key, bucket._id),
						labelNode: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
							? getLabelNodeFacet(key, bucket._id, t)
							: undefined,
						count: bucket.count,
						folderLabel: facetLabel,
						isFacetBucket: true,
						facetPath: key,
						type: type ?? 'hardcoded-undefined-facet-type',
						global,
						itemType,
					});

					return;
				});

				facetFilterFolders.push(currentFacetFolder);
			}

			setFilterFolders((folders) => {
				const notFacetFolders = folders.filter((e) => {
					return !e.isFacet;
				});

				return [...notFacetFolders, ...facetFilterFolders];
			});
		};

		asyncWrapper();
	}, [facets, authData?.session.company]);

	const { selectedYear } = useYearSelection();

	// ---- handle export  data ---- //
	// ---- handle export  data ---- //
	const handleExport = useCallback(
		async ({ exportFormat }: { exportFormat: string }) => {
			try {
				const exportKey = generateUniqUID();
				setExportKey(exportKey);
				setIsExportLoading(true);
				await exportSupplierAction(
					{
						ids: selectedRows.map((e: any) => {
							return e.original.objectId;
						}),
						facetFilters: searchQueryParams.facetFilters,
						supplierIds: searchQueryParams?.supplierIds ?? [],
						input: searchQueryParams.input ?? '',
						format: exportFormat,
						selectedYear,
						companyName: authData?.session?.company?.name || '___',
						exportKey,
					},
					t,
				);
				setIsExportLoading(false);
			} catch (error) {
				setIsExportLoading(false);
				console.error(error);
			}
		},
		[
			searchQueryParams.facetFilters,
			searchQueryParams.input,
			searchQueryParams.supplierIds,
			selectedRows,
			selectedYear,
		],
	);

	return (
		<FilterContainer
			skeleton={isFacetsLoading}
			isLoading={false}
			appliedValuesByFolders={appliedValuesByFolders}
			setAppliedValuesByFolders={setAppliedValuesByFolders}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			folders={filterFolders}
			setSearchQueryParams={setSearchQueryParams}
			suppliersFolderLabel={suppliersFolderLabel}
			selectedRow={selectedRows}
			isTableLoading={false}
			isExportLoading={isExportLoading}
			showCount={false}
			handleExport={handleExport}
			searchPlaceholder={t('search-supplier-placeholder')}
			tag={'fournisseur'}
			count={data?.length || null}
			enableActionDelete={false}
			searchTrackName="Explore Suppliers Search"
			filterTrackName="Explore Suppliers Filter"
			trackCategory="Explore"
			exportTrackName="Explore Suppliers Export"
			withMore={withMore}
			exportKey={exportKey}
			type={EXPORT_TYPE.supplier}
		/>
	);
};

export default SuppliersFilter;
