import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const styles = {
	text: css({
		lineHeight: '1 !important',
	}),
};

const measure = {
	//Carbone au kilo
	nbrReference: <>Nbr de references</>,
	carbonIntensityKg: (
		<>
			kgCO<sub>2</sub>e / kg
		</>
	),
	carbonIntensityTon: (
		<>
			tCO<sub>2</sub>e / kg
		</>
	),
	//	Impact carbone en kilo
	carbonImpact: (
		<>
			kgCO<sub>2</sub>
		</>
	),

	carbonImpactTon: (
		<>
			tCO<sub>2</sub>e
		</>
	),
	// Eau au kilo
	waterIntensity: (
		<>
			m<sup>3</sup> / kg
		</>
	),
	// Impact eau
	waterImpact: (
		<>
			m<sup>3</sup>
		</>
	),
	massTon: <>T</>,
	percent: <>%</>,
	//Carbone à l’unité
	carbonImpactPortion: (
		<>
			kgCO<sub>2</sub> / unité
		</>
	),
	// Eau à l’unité
	waterImpactPortion: (
		<>
			m<sup>3</sup> / unité
		</>
	),
	//
	// En gramme
	weight: <>en grammes</>,
	uvc: <>UVC</>,
	unity: <>unité</>,
	grammes: <>g</>,
	gesApbo: (
		<>
			kgCO<sub>2</sub>e / L
		</>
	),
	gesFPCM: <>kgCO₂e / kg FPCM</>,
	soldUnity: <>units sold </>,
	tonne: <>Tonnes</>,
};

const getTranslateMesure = (t: any) => {
	const measure = {
		//Carbone au kilo
		carbonIntensityKg: (
			<>
				kgCO<sub>2</sub>e / kg
			</>
		),
		carbonIntensityTon: (
			<>
				tCO<sub>2</sub>e / kg
			</>
		),

		// Impact carbone en kilo
		carbonImpact: (
			<>
				kgCO<sub>2</sub>e
			</>
		),

		//Impact carbone en tonne
		carbonImpactTon: (
			<>
				tCO<sub>2</sub>e
			</>
		),
		// Eau au kilo
		waterIntensity: (
			<>
				m<sup>3</sup> / kg
			</>
		),
		// Impact eau
		waterImpact: (
			<>
				m<sup>3</sup>
			</>
		),
		massTon: <>T</>,
		percent: <>%</>,
		// Carbone à l’unité
		carbonImpactPortion: (
			<>
				kgCO<sub>2</sub>e / {t('unit')}
			</>
		),
		// Eau à l’unité
		waterImpactPortion: (
			<>
				m<sup>3</sup> / {t('unit')}
			</>
		),
		weight: <>{t('en grammes')}</>,

		uvc: <>UVC</>,
		unity: <>{t('units')}</>,
		grammes: <>{t('g')}</>,
		gesApbo: (
			<>
				kgCO<sub>2</sub> / L
			</>
		),

		gesFPCM: <>kg CO₂ / kg FPCM</>,
		nbrReference: <>Nbr de references</>,
		soldUnity: <>{t('units-sold')}</>,
		tonne: <>Tonnes</>,
	};
	return measure;
};

export type Measure = keyof typeof measure;

type Props = {
	measure: Measure;
	component?: React.ElementType;
} & Omit<TypographyProps, 'children'>;

const Unit = ({ measure: _measure /* = "carbonImpact" */, className, ...props }: Props) => {
	const { t } = useTranslation();
	const translatedMesure = useMemo(() => {
		return getTranslateMesure(t);
	}, [t]);
	return (
		<Typography /* component="p" */ variant="caption" className={cx(styles.text, className)} {...props}>
			{translatedMesure[_measure]}
		</Typography>
	);
};

export default Unit;
