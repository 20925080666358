import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { HelpCircle } from 'lucide-react';

import Popover from '../../../../../apps/front/src/components/Popover';
import { useTranslation } from '../../../../../apps/front/src/hooks/useTranslation';
import { siteColors } from '../../../../../apps/front/src/lib/colors';
import { formatNumber } from '../../../utils/numberFormat';
import BPopover from '../BPopover';

const styles = {
	text: css({
		fontSize: '14px !important',

		fontWeight: '400 !important',
	}),
};

const PortionField = ({ value, row, column }: any) => {
	const columnName = column?.props?.columnName;
	const { t } = useTranslation();

	const runGetValue = (iRow: any) => {
		const precision = value > 100 || value === 100 ? 0 : column?.props?.precision ?? 2;
		return formatNumber(value, '', precision);
	};

	const renderValue = column?.props?.renderValue || runGetValue;

	if (columnName === 'quoteSheet-per-quote') {
		if (_.isNil(_.get(row.original, 'netWeight'))) {
			return (
				<BPopover
					modeHover
					content={() => {
						return (
							<div
								css={{
									padding: 16,
								}}
							>
								{t('pas de poids net renseigné')}
							</div>
						);
					}}
				>
					<HelpCircle
						size={20}
						css={{
							path: 'currentColor',
						}}
					/>
				</BPopover>
			);
		}
	}

	return (
		<div className={cx(styles.text, 'number')} style={{ color: column?.props?.color || siteColors.primary }}>
			{renderValue(row?.original)}
		</div>
	);
};

export default PortionField;
