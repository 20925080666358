import { SyntheticEvent, useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, ITEM_TYPE_INGREDIENT } from '@carbonmaps/shared/utils/constants';
import { getOneSupplierRSERadarChartDataAction, getSupplierSheetTimePeriodAction } from '@carbonmaps/ui/actions/supplier.actions';

import { useYearSelection } from '../../hooks/useImpactYear';
import SupplierModel from '../../models/Supplier.model';

export const useSupplierSetup = () => {
	const params = useParams();
	const navigate = useNavigate();

	const handleTabChange = useCallback(
		(_: SyntheticEvent, value: string) => {
			navigate(`${FRONT_PATH_NAMES.suppliers.details}/${params.supplierId}/${value}`);
		}
		, [navigate, params.supplierId]);

	const { data, isLoading } = useGetSupplier(params.supplierId || '');

	const {
		supplierObject,
		dataCategories,
		productsSupplier,
		ingredientsSupplier,
	} = useMemo(() => {
		if (!data) {
			return {
				dataCategories: {},
				productsSupplier: [],
				ingredientsSupplier: [],
			};
		}

		const { supplier, segmentationData, products, ingredients } = data;

		return {
			supplierObject: new SupplierModel(supplier),
			dataCategories: segmentationData ?? {},
			productsSupplier: formatData(products, supplier),
			ingredientsSupplier: formatData(ingredients, supplier, true),
		};
	}, [data]);

	return {
		supplierObject,
		dataCategories,
		productsSupplier,
		ingredientsSupplier,
		handleTabChange,
		isLoading
	};
};

export const useGetSupplier = (supplierId: string) => {
	const { selectedYear } = useYearSelection();

	return useQuery({
		queryKey: ['getSupplierSheetTimePeriod', {
			objectId: supplierId,
			period: selectedYear,

		}],
		queryFn: getSupplierSheetTimePeriodAction,
		retry: false,
	});
};

const formatData = (res: any, supplier: any, forIngredients = false) => {
	const data = res.map((item: any) => {

		const result = {
			...item,
			carbonImpactPercent: (item.carbonImpact / supplier[!forIngredients ? 'carbonImpact' : 'carboneImpactIngredients']) * 100,
			waterImpactPercent: (item.waterImpact / supplier[!forIngredients ? 'waterImpact' : 'waterImpactIngredients']) * 100,
			carbonIntensityPercent: (100 * item.carbonIntensity) / (supplier[!forIngredients ? 'carboneIntensityMaxValue' : 'carboneIntensityMaxValueIngredients'] || 1),
			waterIntensityPercent: (100 * item.waterIntensity) / (supplier[!forIngredients ? 'waterIntensityMaxValue' : 'waterIntensityMaxValueIngredients'] || 1),
			origin: item?.uid,
			link: `${item?.itemType === ITEM_TYPE_INGREDIENT ? FRONT_PATH_NAMES.ingredients : FRONT_PATH_NAMES.products.details}/${item?.objectId || item._id
				}/synthesis`,
		};

		return result;
	});

	return data;
};



export const useFindOneSupplierRSERadarChartData = ({ supplierId, questionFormId }: { supplierId: string, questionFormId: string }) => {
	const { selectedYear } = useYearSelection();

	return useQuery({
		queryKey: ['getOneSupplierRSERadarChartData', {
			objectId: supplierId,
			period: selectedYear,
			questionFormId: questionFormId,
		}],
		queryFn: getOneSupplierRSERadarChartDataAction,
		enabled: !!supplierId && !!questionFormId, // La requête ne s'exécutera que si tous les paramètres sont présents
		retry: false,
	});
};
