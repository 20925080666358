import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import _ from 'lodash';
import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SEO from '../../components/SEO';
import TabsBar, { TabItem } from '../../components/TabsBar';
import { ITab } from '../../components/layout/page/SheetPageLayout';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import { TOP_BAR_HEIGHT } from '../../utils/constants';
import PackagingsHeader from './_/PackagingsHeader';
import { useCmapsModelization } from './packagings.hooks';
import Analyze from './tabs/analyze/Analyze';
import PackagingsDefault from './tabs/default/PackagingsDefault';
import PackagingsEndOfLife from './tabs/end-of-life/PackagingsEndOfLife';
import Materials from './tabs/materials/Materials';
import PackagingsRecycled from './tabs/recycled/PackagingsRecycled';

const CONTENT_WIDTH = 'calc(100% - 4rem)';
const PackagingsGeneral = () => {
	const { t } = useTranslation();
	const { setBreadcrumbs } = useApp();

	// ---- fetch when company is cmaps modelization --- //
	const { data } = useCmapsModelization({});

	const tabs: Record<string, ITab> = useMemo(() => {
		return {
			analyze: {
				title: t('analyze'),
				element: <Analyze />,
				tabName: t('analyze'),
				isCmapsModelization: true,
				eventName: 'Explore dashboard packaging',
			},
			packagings: {
				title: t('Emballages'),
				element: <PackagingsDefault />,
				tabName: t('Emballages'),
				isCmapsModelization: true,
				eventName: 'Explore Land Packagings',
			},

			materials: {
				title: t('Materials'),
				element: <Materials />,
				tabName: t('Materials'),
				disabled: !data,
				eventName: 'Explore Land Materials',
			},
			recycled: {
				title: t('recycled'),
				element: <PackagingsRecycled />,
				tabName: t('recycled'),
				disabled: !data,
				eventName: 'Explore Land recycled',
			},
			'end-of-life': {
				title: t('end-of-life'),
				element: <PackagingsEndOfLife />,
				tabName: t('end-of-life'),
				disabled: !data,
				eventName: 'Explore Land end of life',
			},
		};
	}, [t, data]);

	const params = useParams();
	const currentTab = params.tab || _.keys(tabs)[0];

	// ---- breadCrumbs ---- //
	useEffect(() => {
		const title2 = _.get(tabs[currentTab], 'tabName');
		setBreadcrumbs([{ text: t('Emballages') }, ...(title2 ? [{ text: title2 }] : [])]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, params.tab]);

	const navigate = useNavigate();

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Packaging Switch Tab', options, {
				context: { category: 'Analyse > packaging' },
			});
		},
		[analytics],
	);

	const trackEvent2 = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Analyse > packaging' },
			});
		},
		[analytics],
	);

	// ---- useEffect for track event land page packagings ---- //
	useEffect(() => {
		trackEvent2('Explore dashboard packaging');
	}, [trackEvent]);

	const handleTabChange = (_e: SyntheticEvent, value: string) => {
		trackEvent({
			SuppliersGeneralViewTabTarget: _.get(tabs[value], 'tabName'),
		});

		trackEvent2(_.get(tabs[value], 'eventName') || 'Explore dashboard packaging');
		navigate(`${FRONT_PATH_NAMES.packagings.general}/${value}`);
	};

	const renderTabs = (
		<TabsBar
			value={currentTab}
			onChange={handleTabChange}
			sticky
			sx={{
				mb: pxToRem(50),
				width: CONTENT_WIDTH,
				mx: 'auto',
				top: TOP_BAR_HEIGHT,
				'&.sticky': { width: '100%', borderRadius: 0 },
				marginTop: 3,
				marginBottom: '24px',
			}}
		>
			{_.entries(tabs).map(([tabKey, { title, disabled }]) => {
				return <TabItem key={tabKey} value={tabKey} label={title} disabled={disabled} />;
			})}
		</TabsBar>
	);

	const renderTabContent = (
		<div
			style={{
				width: CONTENT_WIDTH,
				margin: 'auto',
			}}
		>
			{tabs[currentTab]?.element}
		</div>
	);

	return (
		<>
			<SEO title={t('Emballages') + ' - CarbonMaps'} />
			<PackagingsHeader />
			{renderTabs}
			{renderTabContent}
		</>
	);
};

export default PackagingsGeneral;
