import { css, cx } from '@emotion/css';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { siteColors } from '../../lib/colors';
import Typography from '../Typography';
const styles = {
	container: css({
		background: '#fff',
		border: `1px solid var(--colorType)}`,
		boxShadow: `inset 4px 0px 0px var(--colorType)`,
		borderRadius: '0px',
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		padding: '8px 8px 8px 16px',
		fontWeight: 500,
		fontSize: 14,
		justifyContent: 'space-between',
		maxHeight: '500px', // Hauteur maximale
		overflowY: 'auto' // Ajoute une barre de défilement verticale si nécessaire
	}),
};

type FlashMessageProps = {
	open: boolean;
	onClose: (e?: any) => void;
	message: string | React.ReactNode;
	icon?: React.ReactNode;
	type?: 'error' | 'success' | 'info'
	isCustomMessage?: boolean;
};
const FlashMessage = ({ open, onClose, icon, message, type = 'info', isCustomMessage }: FlashMessageProps) => {
	if (isCustomMessage) {
		return (
			<div
				className={cx('flexRow alignCenter MuiPaper-root MuiAlert-root spaceBetween', styles.container)}
				style={{ ['--colorType' as any]: siteColors.status.success }}
				role="alert"
			>

				{/* Snackbar avec une durée automatique */}
				<Snackbar
					open={open}
					autoHideDuration={3000} // 5 secondes
					onClose={onClose}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert icon={false} onClose={onClose} severity="info"
						className={styles.container}
						sx={{
							width: '100%',
							backgroundColor: '#fff',

						}}>
						{message}
					</Alert>
				</Snackbar>
			</div>)
	}
	return (
		<Snackbar
			open={open}
			autoHideDuration={3000}
			onClose={onClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<div
				className={cx('flexRow alignCenter MuiPaper-root MuiAlert-root spaceBetween', styles.container)}
				style={{ ['--colorType' as any]: siteColors.status[type] }}
				role="alert"
			>
				<div className="flexRow alignCenter nowrap gap20">
					{icon}
					<Typography>{message}</Typography>
				</div>
				<IconButton
					onClick={onClose}
					color="inherit"
					aria-label="visible"
					css={{
						background: 'transparent',
						':hover': {
							background: 'transparent',
						},
						'& path': {
							fill: 'black',
						},
					}}
				>
					<CloseIcon />
				</IconButton>
			</div>
		</Snackbar>
	);
};

export default FlashMessage;
