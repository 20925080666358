import { PackageOpen } from 'lucide-react';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';
import { useGetPackagingCount } from '../../../../../lib/react-query/features/packaging/packaging.hooks';
import KPICard from '../../../components/KPICard';
import KpiValue from '../../../components/KpiValue';

const PackagingCountCard = () => {
	// // ---- translate ---- //
	const { t } = useTranslation();

	// ---- fetch count packaging ---- //
	const {
		result: { data: packagingCount, isLoading },
	} = useGetPackagingCount();

	return (
		<KPICard
			className="flex1"
			label={t('Emballages')}
			value={<KpiValue value={packagingCount} unit={''} />}
			icon={<PackageOpen size={30} color={siteColors.grey7} />}
			isLoading={isLoading}
		/>
	);
};

export default PackagingCountCard;
