import { useQuery } from '@tanstack/react-query';

import { IProduct } from '@carbonmaps/shared/types/product.types';

import { useYearSelection } from '../../../apps/front/src/hooks/useImpactYear';
import {
	getACVPackagingProduct,
	getDataPopoverProductAction,
	getImpactProductDataType,
	getIngredientsProductAction,
	getLifeChartProduct,
	getModelizationProductAction,
	getPackagingProductAction,
	getProductImpactAction,
	getProductInfoAction,
	GetProductSheetNewActionData,
	getProductSheetTimePeriodAction,
	getRecycledMaterialsProduct,
	LifeChartProductData,
	ModelizationProduct,
	PopoverProductData,
} from '../actions/product.actions';
// import { IProductFinal } from '@carbonmaps/shared/types/product.types';

// export const useGetProductSheetNew = ({
// 	objectId,
// 	fromPath,
// 	onError,
// 	onSuccess,
// }: {
// 	objectId: string;
// 	sortingBy?: any;
// 	fromPath?: string;
// 	onError?: (err: unknown) => void;
// 	onSuccess?: (data: GetProductSheetNewActionData) => void;
// }) => {
// 	const queryResult = useQuery({
// 		queryKey: ['getProductSheetNew', { objectId, fromPath }],
// 		queryFn: getProductSheetNewAction,
// 		onError,
// 		onSuccess,
// 		retry: false,
// 	});

// 	return queryResult;
// };

export const useGetProductSheetTimePeriod = ({
	objectId,
	// period,
	fromPath,
	onError,
	onSuccess,
	viewMode,
	sortingBy,
}: {
	objectId: string;
	viewMode?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: GetProductSheetNewActionData) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getProductSheetTimePeriod', { objectId, fromPath, period: selectedYear }],
		queryFn: getProductSheetTimePeriodAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useProductInfo = ({
	objectId,
	// period,
	fromPath,
	onError,
	onSuccess,
	viewMode,
	sortingBy,
}: {
	objectId: string;
	viewMode?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: IProduct) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getProductInfo', { objectId, fromPath, period: selectedYear }],
		queryFn: getProductInfoAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useProductImpact = ({
	objectId,
	uid,
	onError,
	onSuccess,
	viewMode,
}: {
	objectId: string;
	viewMode?: string;
	uid?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: getImpactProductDataType) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getProductImpact', { objectId, viewMode, uid, period: selectedYear }],
		queryFn: getProductImpactAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useProductLifeChart = ({
	productId,
	uid,
	onError,
	onSuccess,
	viewMode,
}: {
	productId: string;
	viewMode?: string;
	uid?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: LifeChartProductData) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getProductLifeChart', { productId, uid, viewMode, period: selectedYear }],
		queryFn: getLifeChartProduct,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useModelizationProduct = ({
	productId,
	uid,
	onError,
	onSuccess,
}: {
	productId: string;
	viewMode?: string;
	uid?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: ModelizationProduct) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getModelizationProduct', { productId, uid, period: selectedYear }],
		queryFn: getModelizationProductAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useRecycledMaterialsProduct = ({
	productId,
	uid,
	onError,
	onSuccess,
}: {
	productId: string;
	viewMode?: string;
	uid?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: { percent: number; tonnage: number; tonnageProduct: number }) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getRecycledMaterialsProduct', { productId, uid, period: selectedYear }],
		queryFn: getRecycledMaterialsProduct,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useACVPackagingProduct = ({
	productId,
	uid,
	viewMode,
	onError,
	onSuccess,
}: {
	productId: string;
	viewMode?: string;
	uid?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: {
		percent: number;
		tonnage: number;
		tonnageProduct: number;
		impactPackaging: number;
		impactProduct: number;
	}) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getACVPackagingProduct', { productId, uid, period: selectedYear, viewMode }],
		queryFn: getACVPackagingProduct,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useIngredientsProduct = ({
	productId,
	uid,
	onError,
	onSuccess,
}: {
	productId: string;
	viewMode?: string;
	uid?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: ModelizationProduct) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getIngredientsProduct', { productId, uid, period: selectedYear }],
		queryFn: getIngredientsProductAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const usePackagingProduct = ({
	productId,
	uid,
	onError,
	onSuccess,
}: {
	productId: string;
	viewMode?: string;
	uid?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: ModelizationProduct) => void;
}) => {
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: ['getPackagingProduct', { productId, uid, period: selectedYear }],
		queryFn: getPackagingProductAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const usePopoverIndicator = ({
	productId,

	onError,
	onSuccess,
}: {
	productId?: string;

	// period?: string | number;

	onError?: (err: unknown) => void;
	onSuccess?: (data: PopoverProductData) => void;
}) => {
	const queryResult = useQuery({
		queryKey: ['getDataPopoverProduct', { productId }],
		queryFn: getDataPopoverProductAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};
