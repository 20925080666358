import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';
import { HelpCircle } from 'lucide-react';

import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { createTheme } from '@mui/material/styles';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import PopoverItem from '../table/popover/PopoverItem';

type ScoringCardProps = {
	value?: 'A+' | 'A' | 'B' | 'C' | 'D' | 'E' | 'default';
	sx?: SxProps;
	fontSize?: number;
	withLabel?: boolean;
	width?: number;
	height?: number;
	borderRadius?: number;
	withBorder?: boolean;
};

type ScoringContainerProps = {
	children: React.ReactNode;
};

const styles = {
	a: css({
		padding: '18px',
	}),
};

const _theme = createTheme();
const {
	palette: { augmentColor },
} = _theme;

const suppliersColors = {
	'A+': augmentColor({ color: { main: '#2FBA90', light: '#2FBA90' } }),
	A: augmentColor({ color: { main: '#49B654', light: '#49B654' } }),
	B: augmentColor({ color: { main: '#95BF3C', light: '#95BF3C' } }),
	C: augmentColor({ color: { main: '#F0BF41', light: '#F0BF41' } }),
	D: augmentColor({ color: { main: '#ED9D38', light: '#ED9D38' } }),
	E: augmentColor({ color: { main: '#EA602C', light: '#EA602C' } }),
	default: augmentColor({ color: { main: siteColors.grey400, light: siteColors.grey400 } }),
};

const ScoringCard = ({
	value = 'default',
	sx = {},
	withLabel = false,
	fontSize = 15,
	width = 48,
	height = 32,
	borderRadius = 24,
	withBorder = true,
}: ScoringCardProps) => {
	const theme = useTheme();
	const Container = withBorder ? (withLabel ? ScoringContainer : Box) : ScoringContainerLight;
	return (
		<Container>
			<Box
				className="flexCenter"
				sx={{
					width: pxToRem(width),
					height: pxToRem(height),
					background: suppliersColors[value].main,
					borderRadius: pxToRem(borderRadius),
					...sx,
				}}
			>
				{value !== 'default' ? (
					<Typography fontWeight={600} fontSize={pxToRem(fontSize)} color={theme.palette.common.white}>
						{value}
					</Typography>
				) : (
					<BPopover
						modeHover
						content={() => {
							return (
								<div className={cx(styles.a)}>
									<PopoverItem label="Score fournisseur" value="Non renseigné" />
								</div>
							);
						}}
					>
						<HelpCircle size={20} color={theme.palette.grey[700]} />
					</BPopover>
				)}
			</Box>
		</Container>
	);
};

export const ScoringContainer = ({ children }: ScoringContainerProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Box
			className="flexCenter"
			sx={{
				borderRadius: '16px',
				border: `1px solid ${theme.palette.grey[500]}`,
				padding: 'padding: 14px 21px 15px 19px',
				minWidth: '160px',
				gap: '10px',
			}}
		>
			{children}
			<Typography color={theme.palette.common.black}>{t('Score Fournisseur')}</Typography>
		</Box>
	);
};

export const ScoringContainerLight = ({ children }: ScoringContainerProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Box
			className="flexCenter"
			sx={{
				padding: 'padding: 14px 21px 15px 19px',
				minWidth: '120px',
				gap: '10px',
			}}
		>
			{children}
			<Typography color={theme.palette.common.black}>{t('Score Fournisseur')}</Typography>
		</Box>
	);
};

export default ScoringCard;
