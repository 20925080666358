import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { siteColors } from '../../../../lib/colors';

type Props = {
	title?: React.ReactNode;
	subtitle?: React.ReactNode;
	rightContent?: ReactNode;
	icon?: ReactNode;
	loading?: boolean;
};

const HeaderCategoryPage = ({ title, subtitle, rightContent, icon, loading }: Props) => {
	const theme = useTheme();

	if (loading) {
		return <Skeleton variant="rectangular" height="120px" sx={{ mb: '16px', borderRadius: '16px' }} />;
	}

	return (
		<Box
			sx={{
				// background:
				// 	'linear-gradient(90deg, #F6EDFD 0%, #F8E7FE 100%), linear-gradient(90deg, #E3EDFB 19.89%, #D3ECFA 74.64%)',
				background: siteColors.purple10,
				borderRadius: '16px',
				mb: '16px',
			}}
		>
			<Box className="flexRow alignCenter spaceBetween" height="150px" paddingRight="24px" flexWrap="nowrap">
				<Box className="flexRow alignCenter" height="120px" flexWrap="nowrap">
					{/* Box Icon */}
					<Box
						className="flexRow alignCenter justifyCenter"
						border={`0.475px solid ${theme.palette.grey[700]}`}
						borderRadius="8px"
						bgcolor="#FFF"
						width="72px"
						height="72px"
						marginLeft="-8px"
					>
						{icon}
					</Box>

					<Box ml="24px">
						<Box className="flexRow alignCenter" mb={pxToRem(8)}>
							<Typography variant="h2">{title}</Typography>
						</Box>

						<Box className="flexRow alignCenter">{subtitle}</Box>
					</Box>
				</Box>
				{rightContent && <Box>{rightContent}</Box>}
			</Box>
		</Box>
	);
};

export default HeaderCategoryPage;
