import useTheme from '@mui/material/styles/useTheme';
import { PackageOpen } from 'lucide-react';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

const PackagingsHeader = () => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<ListHeader
			title={t('Emballages')}
			icon={
				<IconContainer
					color={siteColors.brown500}
					element={<PackageOpen color={theme.palette.common.white} size={32} />}
				/>
			}
		/>
	);
};

export default PackagingsHeader;
