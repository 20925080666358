import { css } from '@emotion/css';
import { useTheme } from '@mui/material';
import { Building } from 'lucide-react';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../hooks/useTranslation';

type AuditHeaderProps = {
	subtitle?: string;
};

const CompanyHeader = ({ subtitle }: AuditHeaderProps) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<ListHeader
			title={t('organisation')}
			subtitle={subtitle}
			icon={
				<IconContainer
					element={<Building size={40} color={theme.palette.common.white} />}
					color={theme.palette.grey[900]}
				/>
			}
			// className="bgGrey500"
		/>
	);
};

export default CompanyHeader;
