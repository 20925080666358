import { formatCompactNumber, formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { css, cx } from '@emotion/css';
import Typography from '../../../../components/Typography';
import { useTranslation } from '../../../../hooks/useTranslation';

const styles = {
	circle: css({
		width: 8,
		height: 8,
		background: 'var(--background)',
		borderRadius: '50%',
	}),
	label: css({
		maxWidth: '200px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	}),
};
type CarbonFootprintLegendProps = {
	items: Array<{
		label: string;
		value: number;
		valueNotPercent?: number;
		color: string;
		tonnage?: number;
	}>;
};

const CarbonFootprintLegend = ({ items }: CarbonFootprintLegendProps) => {
	const { t } = useTranslation();
	return (
		<div className="flexColumn flex1 gap6 ">
			{items.map((item) => {
				return (
					<div className="flex1 flexRow spaceBetween gap16 alignCenter width100 nowrap">
						<div className="flexRow alignCenter gap8 nowrap">
							<div className={cx('', styles.circle)} style={{ ['--background' as any]: item.color }} />
							<Typography className={cx('grey900', styles.label)}>{t(item.label)}</Typography>
						</div>
						{item?.valueNotPercent &&
							<Typography className="number grey800 " variant="caption">
								{item.valueNotPercent < 1 ? '< 1' : formatCompactNumber(item.valueNotPercent)}
							</Typography>
						}
						<Typography className="number grey800 " variant="caption">
							{item.value < 1 ? '< 1' : formatNumber(item.value, undefined, 0)}%
						</Typography>
					</div>
				);
			})}
		</div>
	);
};

export default CarbonFootprintLegend;
