import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import DeleteConfirm from '@carbonmaps/ui/components/saas/DeleteConfirm';
import { generateRandomString } from '@carbonmaps/ui/utils/utils';
import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { X } from 'lucide-react';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import z from 'zod';
import { cn, siteColors } from '../../../../lib/colors';
import { useDeleteQuestionSection, useFindQuestionSettings, useSaveQuestionSection } from '../hooks';

const styles = {
	fontBold: css({
		fontWeight: 'bold',
	}),
	gap3: css({
		gap: 18,
	}),
};

const SectionsTab = () => {
	const params = useParams();
	const { data: settingsData } = useFindQuestionSettings({ questionFormId: _.toString(params.questionFormId) });

	return (
		<div>
			<div className={cx('mt-3')} css={{ marginBottom: 24 }}>
				<AddFormDialog />
			</div>

			<div>
				{(settingsData?.questionSections || [])?.map((item: Record<string, any>) => {
					return (
						<div
							key={nanoid()}
							className={cx('flexRow alignCenter', 'justifyBetween')}
							css={{
								marginBottom: 24,
								border: cn('1px solid', siteColors.grey700),
								borderRadius: 8,
								padding: 12,
							}}
						>
							<div className={cx('')}>
								<Typography variant="body1">
									<span className={cx(styles.fontBold)}>code:</span> {_.get(item, 'code', '?????????')}
								</Typography>
								<Typography variant="body1">
									<span className={cx(styles.fontBold)}>labelFr:</span> {_.get(item, 'labelFr', '?????????')}
								</Typography>
								<Typography variant="body1">
									<span className={cx(styles.fontBold)}>labelEn:</span> {_.get(item, 'labelEn', '?????????')}
								</Typography>
								<Typography variant="body1">
									<span className={cx(styles.fontBold)}>labelIt:</span> {_.get(item, 'labelIt', '?????????')}
								</Typography>
								<Typography variant="body1">
									<span className={cx(styles.fontBold)}>labelEs:</span> {_.get(item, 'labelEs', '?????????')}
								</Typography>
								<Typography variant="body1">
									<span className={cx(styles.fontBold)}>labelDe:</span> {_.get(item, 'labelDe', '?????????')}
								</Typography>
							</div>
							<div className={cx('flexRow', styles.gap3)}>
								<UpdateFormDialog item={item} />
								<DeleteSectionButton item={item} />
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SectionsTab;

const DeleteSectionButton = ({ item }: { item: Record<string, any> }) => {
	const params = useParams();
	const { mutate: deleteSection } = useDeleteQuestionSection();

	return (
		<DeleteConfirm
			title="Supprimer ?"
			onClick={(close?: VoidFunction): void => {
				deleteSection({ questionFormId: _.toString(params.questionFormId), sectionCode: _.get(item, 'code', '') });
			}}
		>
			<div>
				<BButton variant="secondary" label="Supprimer" addStyles={{ marginLeft: 12 }} />
			</div>
		</DeleteConfirm>
	);
};

const sectionSchema = z.object({
	labelFr: z.string().min(1),
	labelEn: z.string().min(1),
	labelIt: z.string().min(1),
	labelEs: z.string().min(1),
	labelDe: z.string().min(1),
});

type SectionInput = z.infer<typeof sectionSchema>;

const AddFormDialog = () => {
	const params = useParams();
	const [open, setOpen] = useState(false);

	const sectionForm = useForm<SectionInput>({
		resolver: zodResolver(sectionSchema),
	});

	const { mutate: saveQuestionSection, isLoading: isSaveQuestionLoading } = useSaveQuestionSection({
		onSuccess: () => {
			setOpen(false);
		},
	});

	const onSubmit = sectionForm.handleSubmit(
		(data) => {
			saveQuestionSection({
				questionFormId: _.toString(params.questionFormId),
				section: { ...data, code: generateRandomString() },
			});
		},
		(errors) => {
			console.log('------', errors);
		},
	);

	return (
		<div>
			<BButton
				variant="primary"
				label="Ajouter une section"
				onClick={() => {
					setOpen(true);
				}}
			/>

			<Dialog
				fullWidth
				maxWidth="xl"
				open={open}
				PaperProps={{
					sx: {
						height: '80vh',
					},
				}}
			>
				<DialogTitle
					component="div"
					sx={{
						position: 'relative',
					}}
				>
					<IconButton
						onClick={() => {
							setOpen(false);
							// localIdRef.current = nanoid();
						}}
						sx={{ position: 'absolute', right: 5, top: 5 }}
					>
						<X size={20} />
					</IconButton>

					<Typography variant="h6">Ajouter une section</Typography>
				</DialogTitle>

				<DialogContent>
					<BInput
						label="Label Fr"
						{...sectionForm.register('labelFr')}
						error={!!sectionForm.formState.errors.labelFr?.message}
						helperText={sectionForm.formState.errors.labelFr?.message}
					/>
					<BInput
						label="Label En"
						{...sectionForm.register('labelEn')}
						error={!!sectionForm.formState.errors.labelEn?.message}
						helperText={sectionForm.formState.errors.labelEn?.message}
					/>
					<BInput
						label="Label It"
						{...sectionForm.register('labelIt')}
						error={!!sectionForm.formState.errors.labelIt?.message}
						helperText={sectionForm.formState.errors.labelIt?.message}
					/>
					<BInput
						label="Label Es"
						{...sectionForm.register('labelEs')}
						error={!!sectionForm.formState.errors.labelEs?.message}
						helperText={sectionForm.formState.errors.labelEs?.message}
					/>
					<BInput
						label="Label De"
						{...sectionForm.register('labelDe')}
						error={!!sectionForm.formState.errors.labelDe?.message}
						helperText={sectionForm.formState.errors.labelDe?.message}
					/>

					<BButton
						variant="primary"
						// label="Sauvegarder"
						label={
							isSaveQuestionLoading ? (
								<CircularProgress
									size={20}
									sx={{
										color: 'white',
									}}
								/>
							) : (
								'Sauvegarder'
							)
						}
						onClick={async () => {
							onSubmit();
							// sectionForm.reset();
						}}
						className={cx('mt-3')}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

const UpdateFormDialog = ({ item }: { item: Record<string, any> }) => {
	const [open, setOpen] = useState(false);

	return (
		<div className={cx('flexRow')}>
			{/* <BButton variant="primary" label="Modifier" /> */}
			{/* <BButton variant="secondary" label="Modifier"  /> */}
			<BButton
				variant="secondary"
				label="Modifier"
				onClick={() => {
					setOpen(true);
				}}
			/>

			<FormDialog item={item} open={open} setOpen={setOpen} title="Modifier une section" />
		</div>
	);
};

const FormDialog = ({
	item,
	open,
	setOpen,
	title,
}: {
	item?: Record<string, any>;
	open: boolean;
	setOpen: Function;
	title: string;
}) => {
	const params = useParams();
	// const [open, setOpen] = useState(false);

	const sectionForm = useForm<SectionInput>({
		resolver: zodResolver(sectionSchema),
		defaultValues: {
			labelFr: _.get(item, 'labelFr', ''),
			labelEn: _.get(item, 'labelEn', ''),
			labelIt: _.get(item, 'labelIt', ''),
			labelEs: _.get(item, 'labelEs', ''),
			labelDe: _.get(item, 'labelDe', ''),
		},
	});

	const { mutate: saveQuestionSection, isLoading: isSaveQuestionLoading } = useSaveQuestionSection();

	const onSubmit = sectionForm.handleSubmit(
		(data) => {
			console.log('++++++', data);
			saveQuestionSection({
				questionFormId: _.toString(params.questionFormId),
				section: { ...data, code: _.get(item, 'code', '') },
			});
		},
		(errors) => {
			console.log('------', errors);
		},
	);

	return (
		<div>
			{/* <BButton
				variant="primary"
				label="Ajouter une section"
				onClick={() => {
					setOpen(true);
				}}
			/> */}

			<Dialog
				fullWidth
				maxWidth="xl"
				open={open}
				PaperProps={{
					sx: {
						height: '80vh',
					},
				}}
			>
				<DialogTitle
					component="div"
					sx={{
						position: 'relative',
					}}
				>
					<IconButton
						onClick={() => {
							setOpen(false);
							// localIdRef.current = nanoid();
						}}
						sx={{ position: 'absolute', right: 5, top: 5 }}
					>
						<X size={20} />
					</IconButton>

					<Typography variant="h6">{title}</Typography>
				</DialogTitle>

				<DialogContent>
					<BInput label="Code" value={_.get(item, 'code', '')} disabled={!!item} />
					<BInput
						label="Label Fr"
						{...sectionForm.register('labelFr')}
						error={!!sectionForm.formState.errors.labelFr?.message}
						helperText={sectionForm.formState.errors.labelFr?.message}
					/>
					<BInput
						label="Label En"
						{...sectionForm.register('labelEn')}
						error={!!sectionForm.formState.errors.labelEn?.message}
						helperText={sectionForm.formState.errors.labelEn?.message}
					/>
					<BInput
						label="Label It"
						{...sectionForm.register('labelIt')}
						error={!!sectionForm.formState.errors.labelIt?.message}
						helperText={sectionForm.formState.errors.labelIt?.message}
					/>
					<BInput
						label="Label Es"
						{...sectionForm.register('labelEs')}
						error={!!sectionForm.formState.errors.labelEs?.message}
						helperText={sectionForm.formState.errors.labelEs?.message}
					/>
					<BInput
						label="Label De"
						{...sectionForm.register('labelDe')}
						error={!!sectionForm.formState.errors.labelDe?.message}
						helperText={sectionForm.formState.errors.labelDe?.message}
					/>

					<BButton
						variant="primary"
						label={
							isSaveQuestionLoading ? (
								<CircularProgress
									size={20}
									sx={{
										color: 'white',
									}}
								/>
							) : (
								'Sauvegarder'
							)
						}
						onClick={() => {
							onSubmit();
						}}
						className={cx('mt-3')}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};
