import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { ReactNode } from 'react';
import { cn, siteColors } from '../../../../lib/colors';

type TooltipPackagingProps = {
	item: {
		label?: string;
		value?: any;
		unit?: string;
	};
};
const styles = {
	textNoWrap: css({
		whiteSpace: 'nowrap',
	}),
	fontBold: css({
		fontWeight: 'bold',
	}),
};

const TooltipPackaging = ({ item }: TooltipPackagingProps) => {
	return (
		<div
			className="flexColumn"
			css={{
				borderRadius: '8px',
				border: cn('1px solid', siteColors.grey500),
				background: siteColors.common.white,
				boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
				padding: '24px',
				gap: '24px',
				minWidth: '150px',
			}}
		>
			<div>
				<div className={cx('', styles.textNoWrap, styles.fontBold)}>{_.get(item, 'label', '-')}</div>
				<div className={cx('', styles.textNoWrap)}>
					{displayValue(_.get(item, 'value', '-'))} {_.get(item, 'unit', '-')}
				</div>
			</div>
		</div>
	);
};

export default TooltipPackaging;
