import { css, cx } from '@emotion/css';

import { TM_COMPO_AGRIBALYSE, TM_COMPO_INGREDIENT } from '@carbonmaps/shared/utils/constants';

import PointListLabel from '../../../components/modelization/PointListLabel';
import { useGlossary } from '../../../hooks/useGlossary';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';

const styles = {
	title: css({
		padding: '16px',
		fontSize: '14px',
		fontWeight: 400,
		color: siteColors.grey900,
		borderBottom: cn('1px solid', siteColors.grey500),
		gap: '5px',
		background: siteColors.grey200,
		borderRadius: '16px 16px 0  0',
	}),
	title1: css({
		// textDecorationLine: 'underline',
		color: siteColors.grey900,
		background: siteColors.grey200,
		borderRadius: '16px 16px 0  0',
		textAlign: 'center',
		alignItems: 'center!important',
	}),
	title2: css({
		color: siteColors.grey800,
		textDecorationLine: 'none!important',
	}),

	list: css({
		fontSize: '14px',
		fontWeight: '400',
		color: siteColors.grey800,
	}),

	skeleton: css({
		height: '175px !important',
		width: '100% !important',
	} as any),

	containerList: css({
		padding: '16px',
	}),
};

const AgribalyseTable = ({ data, noValue }: { data: any; noValue?: string }) => {
	// translation
	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();

	return (
		<>
			<div className="flex1">
				<div className={cx('flexRow', styles.title)}>
					<div className={cx('flewRow', styles.title1)}>
						<PointListLabel
							value={t('tm_external')}
							color={TM_COMPO_INGREDIENT.get(TM_COMPO_AGRIBALYSE)?.color}
							backgroundColor={TM_COMPO_INGREDIENT.get(TM_COMPO_AGRIBALYSE)?.backgroundColor}
							borderColor={TM_COMPO_INGREDIENT.get(TM_COMPO_AGRIBALYSE)?.borderColor}
							keyGlossary={TM_COMPO_INGREDIENT.get(TM_COMPO_AGRIBALYSE)?.keyGlossary}
						/>
					</div>
				</div>

				<div className={cx(styles.containerList)}>
					{data?.length > 0 ? (
						data?.map((row: any, i: number) => {
							return <div className={cx(styles.list)}>{row.label}</div>;
						})
					) : (
						<div className={cx(styles.list)}>{noValue ? noValue : t('Détails non spécifiés')}</div>
					)}
				</div>
			</div>
		</>
	);
};

export default AgribalyseTable;
