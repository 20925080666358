import { useCallback, useMemo, useRef, useState } from 'react';

import { useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GalleryHorizontalEnd, Trash2 } from 'lucide-react';
import { useToggle } from 'react-use';

import {
	deleteFacetFieldAction,
	getCompanySheetFacetAction,
	updateFacetFieldDbAction,
} from '@carbonmaps/ui/actions/company.actions';
import SwitchCell from '@carbonmaps/ui/components/saas/Table/SwitchCell';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';

import Dialog from '../../../../components/dialogs/Dialog';
import TableHeaderCell from '../../../../components/table/TableHeaderCell';
import { useQueryParams } from '../../../../hooks/useSearchParams';
import { useSkeleton } from '../../../../hooks/useSkeleton';
import { useTranslation } from '../../../../hooks/useTranslation';

import FieldForm from './FieldForm';

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

type TableProps = {
	filterOptions?: any;
	manualSortBy?: boolean;
	resetPage?: number;
	onSelectedRow?: any;
	isOpenRowAdd?: boolean;
};

const FieldsTable = ({ filterOptions, manualSortBy, onSelectedRow }: TableProps) => {
	const queryParams = useQueryParams();
	// state to toggle to edit field
	const [isDialogOpen, toggleDialog] = useToggle(false);
	// state to edit an field
	const [fieldToEdit, setFieldToEdit] = useState({});
	const [message, setMessage] = useState('');
	// query params
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';
	const indexId = queryParams?.indexId ? queryParams?.indexId[0] : '';

	// translation
	const { t } = useTranslation();

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState({
		input: '',
		page: 1,
		size: 100,
		indexId,
		...filterOptions,
	});

	const queryClient = useQueryClient();

	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	// ---- fetch data --- //
	const { data: tableResultData, isLoading: isTableLoading } = useQuery({
		queryKey: [
			'getCompanySheetFacet',
			{
				companyId,
				indexId,
			} as any,
		],
		queryFn: getCompanySheetFacetAction,
		refetchInterval: 4000,
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);
	// ---- action when click edit's column ---- //

	const handleClickEdit = useCallback((value: any) => {
		setFieldToEdit(value);
		toggleDialog();
	}, []);

	// mutate action confirm to delete fields
	const confirmDelete = async (obj: any) => {
		if (obj.field) {
			await deleteSelectedRow({ indexId, companyId, field: obj.field } as any);
		}
	};

	const {
		mutate: deleteSelectedRow,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteFacetFieldSelectedRow'],
		mutationFn: deleteFacetFieldAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getCompanySheetFacet'] });
			setMessage('');
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const { mutate: updateFacetFieldDb } = useMutation({
		mutationKey: ['updateFacetFieldDb'],
		mutationFn: updateFacetFieldDbAction,
		onSuccess: async () => {
			setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: ['getCompanySheetFacet'] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	// ---- action when switch is visible in front  ---- //
	const handleSwitchChange = async (row: any, newValue: any) => {
		await updateFacetFieldDb({
			...row.original,
			isVisibleInFront: newValue,
			indexId,
			companyId,
		});
	};

	// ---- skeleton ---- //
	const { skeleton } = useSkeleton({ condition: isTableLoading });

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('field-name')} />;
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
				icon: <GalleryHorizontalEnd color={theme.palette.grey[700]} />,
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('field-type')} column={props.column} />;
				},
				accessor: 'type',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('field-code')} column={props.column} />;
				},
				accessor: 'field',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('is-show-field')} />;
				},
				accessor: 'isVisibleInFront',
				editable: true,
				//sortDescFirst: true,
				component: 'SimpleCell',
				type: 'booleanStatus',
				disableSortBy: true,
				Cell: ({ row }: any) => {
					return <SwitchCell row={row} value={row?.original?.isVisibleInFront} onDataUpdate={handleSwitchChange} />;
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('actions-column')} />;
				},
				accessor: 'objectId',
				editable: true,
				component: 'Action',
				type: 'action',
				disableSortBy: true,
				icon: <Trash2 color={theme.palette.grey[700]} />,
				withConfirm: true,
				messageConfirm: t('field-delete-row-title'),
				callbackEdit: (value: any) => {
					handleClickEdit(value);
				},
				callback: (value: any) => {
					confirmDelete(value);
				},
			},
		];
	}, [theme, tableResultData, t]);

	return (
		<>
			<Dialog
				closeButton={true}
				isLoading={isTableLoading}
				title={''}
				description={''}
				open={isDialogOpen}
				toggle={toggleDialog}
				withCancelButton={false}
			>
				<FieldForm toggle={toggleDialog} companyId={companyId} indexId={indexId} currentField={fieldToEdit} />
			</Dialog>

			<TableComponent
				tableName={'user'}
				skeleton={skeleton}
				loading={false}
				fetchData={updateTableParams}
				pageCount={1}
				noDataMessage={t('empty-data-fields')}
				pageSize={paramsTable.size}
				columns={tableColumns}
				resetPage={resetPage}
				data={tableResultData?.facets?.fields || []}
				setSelectedRow={onSelectedRow}
				onSortingColumn={() => {
					return 1;
				}}
				addStyles={stylesTable}
				manualSortBy={false}
				pagination={false}
			/>
		</>
	);
};

export default FieldsTable;
