import { useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import _, { isNull } from 'lodash';
import { Minus, Undo2 } from 'lucide-react';

import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
// import { actionControl, resetActionControl } from '../../../actions/quote.actions';
import { useDebounce } from '../../../hooks/useDebounce';
import { formatNumber } from '../../../utils/numberFormat';
import BButton from '../BButton';

const styles = {
	outerContainer: css({
		paddingTop: 8,
		paddingBottom: 8,
	}),

	input: css({} as any),
	inputGreenText: css({
		input: {
			color: cn(siteColors.teal500, '!important'),
		},
	} as any),

	container: css({
		maxWidth: 150,

		'.MuiInputBase-root': {
			height: 42,
			borderRadius: '8px !important',
			background: 'var(--background)',
			// minWidth: 148,
			minWidth: '120px !important',
		},
		'.MuiOutlinedInput-notchedOutline': {
			border: cn('1px solid', siteColors.grey500),
		},

		input: {
			textAlign: 'right !important',
			color: cn(siteColors.grey800, '!important'),
			fontWeight: '400 !important',
		},
	} as any),

	endAdornment: css({
		p: {
			color: cn(siteColors.grey700, '!important'),
			fontSize: '14px !important',
			fontWeight: 500,
		},
	}),

	endButton: css({
		color: cn(siteColors.grey700, '!important'),
		padding: '0px !important',
		height: 'auto !important',
		minWidth: 'auto !important',

		background: 'none !important',
		border: 'none',
	}),
	text: css({
		fontSize: '14px !important',

		fontWeight: '400 !important',
	}),
};

const isNumberKeyPress = (event: any) => {
	const { keyCode } = event;
	return (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
};

// const _onChange = _.debounce((val: any, id: any, index: any, updateMyData: any, setValue: any, setIsFocused: any) => {
// 	// remove the input like 004 to  4
// 	if (val.match(/\b0[0-9]\w*\b/g)) {
// 		val = val.substring(1);
// 	}

// 	const valueDb = val === '' ? 0 : parseFloat(_.toString(val).replace(',', '.'));
// 	updateMyData(index, id, valueDb);

// 	setValue(val);
// 	setIsFocused(false);
// }, 1500);

// const onChange = (e: any, value: any, id: any, index: any, updateMyData: any, setValue: any, setIsFocused: any) => {
// 	console.log('lol');
// 	// eslint-disable-next-line no-useless-escape
// 	let val = e.target.value?.replace(/[^0-9\,\.]/g, '').replace(',', '.');
// 	if (_.isEqual(val, value)) {
// 		return;
// 	}
// 	// _onChange(val);
// 	// remove the input like 004 to  4
// 	if (val.match(/\b0[0-9]\w*\b/g)) {
// 		val = val.substring(1);
// 	}

// 	const valueDb = val === '' ? 0 : parseFloat(_.toString(val).replace(',', '.'));
// 	updateMyData(index, id, valueDb);

// 	setValue(val);
// 	setIsFocused(false);
// };

// const xFunc = _.debounce(onChange, 500);

const InputFieldQuote = (props: any) => {
	const {
		value: initialValue,
		row: { index, ...restRow },
		column: { id, props: propsColumn },
		updateMyData: _up,
	} = props;
	const { column, row } = props;
	// const updateMyData = _.debounce(_up, 1500);
	const updateMyData = _up;

	if (!initialValue && propsColumn.page === 'eco-packaging') {
		return (
			<div className={cx(styles.text, 'number')} style={{ color: column?.props?.color || siteColors.primary }}>
				-
			</div>
		);
	}

	// get container actions row
	const elem = document.getElementsByClassName(`row-action-${index}`).item(0);

	const prevData = propsColumn?.cloneData;
	const isNoRevertButton = propsColumn?.isNoRevertButton;

	const prevValue = prevData?.get(restRow?.original?.objectKey);

	const [value, setValue] = useState(initialValue);
	// const [isChange, setIsChange] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	// const debounceValue = useDebounce(value, 1500);

	// const _onChange = useCallback(
	// 	_.debounce((val: any) => {
	// 		// remove the input like 004 to  4
	// 		if (val.match(/\b0[0-9]\w*\b/g)) {
	// 			val = val.substring(1);
	// 		}

	// 		const valueDb = val === '' ? 0 : parseFloat(_.toString(val).replace(',', '.'));
	// 		updateMyData(index, id, valueDb);

	// 		setValue(val);
	// 		setIsFocused(false);
	// 	}, 300),
	// 	[index, id],
	// );

	const onChange = (e: any) => {
		let val = e.target.value?.replace(/[^0-9\,\.]/g, '').replace(',', '.');

		// console.log(val, value);
		if (_.isEqual(val, value)) {
			return;
		}

		// actionControl?.controller.abort();
		// resetActionControl();

		// remove the input like 004 to  4
		if (val.match(/\b0[0-9]\w*\b/g)) {
			val = val.substring(1);
		}

		const valueDb = val === '' ? 0 : parseFloat(_.toString(val).replace(',', '.'));

		setInputValue(valueDb);

		setValue(val);
		setIsFocused(false);
	};

	const [inputValue, setInputValue] = useState<number>();

	// Debounced function that gets called with the final value after the user stops typing
	const debouncedSave = _.debounce((value) => {
		if (_.isNil(value)) {
			return;
		}
		// console.log('Debounced final value:', value);
		updateMyData(index, id, _.isNaN(value) ? 0 : value);
		// Do something with the final value (e.g., send it to an API)
	}, 3000); // 1000ms debounce time

	// Use useEffect to trigger debounced function whenever inputValue changes
	useEffect(() => {
		// Call the debounced function when inputValue changes
		debouncedSave(inputValue);

		// Cleanup the debounced function to avoid memory leaks
		return () => {
			debouncedSave.cancel();
		};
	}, [inputValue]); // Runs this effect only when inputValue changes

	// const xFunc = _.throttle(onChange, 10000);

	// useEffect(() => {
	// 	return () => {
	// 		xFunc.cancel();
	// 	};
	// }, [xFunc]);

	// We'll only update the external data when the input is blurred
	const onBlur = () => {
		elem?.setAttributeNS(null, 'style', 'display:none');
		setIsFocused(false);
	};

	const onFocus = () => {
		elem?.setAttributeNS(null, 'style', 'display:none');
	};

	const handleKeyPress = (evt: any) => {
		if (!isNumberKeyPress(evt)) return;
		// force only input for number
		// eslint-disable-next-line no-useless-escape
		let val = evt.target.value?.replace(/[^0-9\,\.]/g, '').replace('.', ',');

		setIsFocused(isNumberKeyPress(evt));

		// remove the input like 004 to  4
		if (val.match(/\b0[0-9]\w*\b/g)) {
			val = val.substring(1);
		}

		setValue(val);
		// setIsChange(true);
	};

	// const isNotEqual = prevValue ? parseFloat(prevValue) !== parseFloat(value) : false;

	const isNotEqual = useMemo(() => {
		// remove revert value
		if (isNoRevertButton) return false;

		const isPreviousValue = prevValue ? parseFloat(prevValue) !== parseFloat(value) : isFocused;
		return isPreviousValue;
	}, [prevValue, value, isFocused, isNoRevertButton]);

	const inputBgColor = useMemo(() => {
		let value = props.row.original.isOriginal ? 'transparent' : '#E3F5F7';

		if (isNotEqual) {
			value = '#E3F5F7';
		}

		return value;
	}, [isNotEqual, props.row.original.isOriginal]);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	const isDisabledInput =
		(column.props.page === 'simulation-scenario' && row.original.isOriginal && !row.original.isPresent) ||
		column.props.disabled === true;

	const getInputValue = (value: any, isDisabledInput: boolean) => {
		if (column.props.type === 'string') {
			return value;
		}
		if (isDisabledInput) {
			return isNaN(value) && (!/,/.test(value) || !/./.test(value)) ? 0 : formatNumber(value, '', 2).replace('.', ',');
		}

		return isNaN(value) && (!/,/.test(value) || !/./.test(value)) ? 0 : _.toString(value).replace('.', ',');
	};

	return (
		<div className={styles.outerContainer}>
			<FormControl
				variant="outlined"
				className={cx(
					styles.container,
					styles.input,
					isNotEqual ? styles.inputGreenText : '',
					!row.original.isOriginal ? styles.inputGreenText : '',
					propsColumn?.className,
				)}
				style={{ ['--background' as any]: inputBgColor }}
			>
				<OutlinedInput
					disabled={isDisabledInput}
					aria-describedby="outlined-weight-helper-text"
					value={getInputValue(value, isDisabledInput)}
					// onChange={(e) => {
					// 	console.log('hi');
					// 	xFunc(e, value, id, index, updateMyData, setValue, setIsFocused);
					// }}
					onChange={onChange}
					onBlur={onBlur}
					onFocus={onFocus}
					onKeyUp={handleKeyPress}
				/>
			</FormControl>
		</div>
	);
};

export default InputFieldQuote;
