import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { Typography, useTheme } from '@mui/material';
import { PieChart } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockCard from '../../components/BlockCard';
import BlockTitle from '../../components/BlockTitle';
import BlueCheck from '../../components/BlueCheck';
import Skeleton from '../../components/Skeleton';
import DonutChart from '../../components/charts/DonutChart';
import Unit from '../../components/units/Unit';
import { useTranslation } from '../../hooks/useTranslation';
import { orderBy } from '../../utils/array.utils';
import { GLOBAL_INDICATOR_CONFIG } from '../../utils/graph';
import * as simulationUtils from '../../utils/simulation.utils';

import { cn, siteColors } from '../../lib/colors';
import Difference from './Difference';
import IndicatorTag from './IndicatorTag';
import { useScenario } from './scenario.hooks';
import { useDataSimulation } from './useDataSimulation.hooks';

const styles = {
	block: css({
		background: 'white',
		border: 'none!important',
	}),
	// flexSpacing: css({
	// 	gap: '64px',
	// }),
	donutContainer: css({
		width: '250px', //Set the size of the pie chart to 250px (instead of 80%)
		//width: '80%',
		marginTop: '16px',
	}),
	// donutContent: css({
	// 	// width: '10px',
	// 	// background: 'red',
	// 	'& .indicator-tag-text': {
	// 		fontSize: '16px!important',
	// 	},
	// }),

	mainClassName: css({
		// background: 'red',
		// width: '30px',
		// marginBottom: '2px',
		// fontSize: '16px!important',
		// height: 'fit-content',
		marginTop: '-2.5px',
	}),
	textClassName: css({
		fontSize: '100% !important',
	}),
	// iconContainClass: css({
	// 	// width: '32px',
	// }),

	diffMainClass: css({
		// width: '40px',
		fontSize: '10px!important',
		// padding: '0 1px 0 1px',
		// height: '20px',
		padding: '1px!important',
		// marginBottom: '-5px',
		// paddingBottom: '1px',
		'& svg': {
			width: '10px',
		},
	}),

	skeleton: css({
		height: '300px !important',
		width: '100% !important',
	} as any),

	icon: css({
		width: 24,
		'& svg': {
			width: '100% !important',
		},
	}),
};

const RepartitionBlock = () => {
	const params = useParams();

	const { isLoading } = useScenario({
		simulationId: params.simulationId || '',
	});

	const { indicator } = useApp();

	// translation
	const { t } = useTranslation();

	const { simulation } = useDataSimulation();

	// const [dataPieChart, setDataPieChart] = useState<any>();

	const theme = useTheme();

	const dataPieChart = useMemo(() => {
		if (!simulation) return;
		const dataPieChart = simulationUtils.getDataPieChart(simulation, indicator, true, true);
		return dataPieChart;
	}, [indicator, simulation]);

	const content = useMemo(() => {
		if (!dataPieChart) return null;

		return (
			<>
				<div className={cx(styles.donutContainer)}>
					<DonutChart
						width={150}
						innerRadius={32}
						data={(orderBy(dataPieChart?.dataGraph || [], 'value') as any)?.filter((item: any) => {
							return item.value > 0.09;
						})}
						indicator={'undefined'}
						onClick={function (item: any): void {
							// throw new Error('Function not implemented.');
						}}
						renderTooltip={(item: any) => {
							if (!item) return null;
							return <ToolTip item={item} />;
						}}
						topPosition={-55}
						content={
							<>
								<IndicatorTag
									value={dataPieChart?.gesIngredient}
									icon={<div className={styles.icon}>{GLOBAL_INDICATOR_CONFIG.get(indicator)?.intensityIcon}</div>}
									mainClassName={styles.mainClassName}
									textClassName={styles.textClassName}
								/>
								<Unit
									measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
									css={{ fontSize: '10px!important', marginTop: '-20px' }}
								/>
								<Difference
									diffMainClass={styles.diffMainClass}
									decimal={2}
									value={dataPieChart?.diff}
									withEquals={false}
								/>
							</>
						}
					/>
				</div>
			</>
		);
	}, [dataPieChart, indicator]);

	return (
		<>
			<BlockTitle
				icon={<PieChart color={siteColors.grey700} width={20} height={20} />}
				className={cx('flexRow alignCenter')}
			>
				{t('breakdown_by_ingredient_graph_title', { ingredient: toLowerCase(t('ingredient')) })}
			</BlockTitle>
			<BlockCard className={cx(styles.block, 'flexRow justifyCenter')}>
				{isLoading ? (
					<Skeleton width="100%!important" className={styles.skeleton}>
						{content}
					</Skeleton>
				) : (
					content
				)}
			</BlockCard>
		</>
	);
};

const tooltipStyles = {
	container: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
		padding: '16px',
		gap: 4,
		minWidth: '200px',
	}),
};

export const ToolTip = ({ item }: any) => {
	return (
		<div className={cx('flexRow alignCenter nowrap', tooltipStyles.container)}>
			<BlueCheck fontSize={12} title={''} iconSize={14} withIcon={item?.isBlueCheck} />

			<Typography className="flex1">
				<span className="fontWeight600 number">{formatNumber(item?.value, undefined, 2)}</span> % {item?.name}
			</Typography>
		</div>
	);
};

export default RepartitionBlock;
