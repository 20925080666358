import { siteColors } from "../../../apps/front/src/lib/colors";

export const COLOR_SCORING = {
	'A+': '#2fba90',
	'A': '#49b654',
	'B': '#95bf3c',
	'C': '#f0bf41',
	'D': '#ed9d38',
	'E': '#ea602c',
};

export const getObjScoring = (value: string) => {
	return (COLOR_SCORING as any)[value] ?? siteColors.grey400;
	// switch (true) {
	// 	case value >= 90:
	// 		return {
	// 			label: 'A+',
	// 			color: COLOR_SCORING['A+'],
	// 		};

	// 	case value >= 70 && value < 90:
	// 		return {
	// 			label: 'A',
	// 			color: COLOR_SCORING['A'],
	// 		};

	// 	case value >= 50 && value < 70:
	// 		return {
	// 			label: 'B',
	// 			color: COLOR_SCORING['B'],
	// 		};

	// 	case value >= 30 && value < 50:
	// 		return {
	// 			label: 'C',
	// 			color: COLOR_SCORING['C'],
	// 		};

	// 	case value >= 10 && value < 30:
	// 		return {
	// 			label: 'D',
	// 			color: COLOR_SCORING['D'],
	// 		};

	// 	case value >= 0 && value < 10:
	// 		return {
	// 			label: 'E',
	// 			color: COLOR_SCORING['E'],
	// 		};

	// 	default:
	// 		return {
	// 			label: '-',
	// 			color: siteColors.grey400,
	// 		};
	// }
};
