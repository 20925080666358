import { css } from '@emotion/css';

import Dialog from '../../../components/dialogs/Dialog';
import { useTranslation } from '../../../hooks/useTranslation';

import { cn, siteColors } from '../../../lib/colors';
import ClientForm from './ClientForm';
import DevisForm from './DevisForm';
import { useQuoteFormStep } from './useDevisFormStore';

type CreateScenarioModalProps = {
	title: any;
	open: boolean;
	isLoading: boolean;
	onSubmit: (a?: any) => void;
	onClose: () => void;
	options?: any;
	message?: string;
	isSuccess?: any;
	isError?: any;
	selectedOption?: any;
	setSelectedOption?: any;
	setSearch?: any;
	isLoadingOption?: boolean;
	onSubmitClient?: (a?: any) => void;
	isLoadingClient: any;
	selectedSimulation: any;
	setSelectedSimulation: any;
	isNew?: boolean;
	forceStep?: number;
};

const styles = {
	actionsContainer: css({
		padding: '0px 24px',
	}),

	button: css({
		gap: 8,
	}),

	infoContent: css({
		paddingTop: '16px',
		fontSize: '16px !important',
	}),
	checkbox: css({
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		paddingLeft: 16,
	}),
	disabledButton: css({
		pointerEvents: 'none',
		opacity: '0.8',
	}),
};

const DevisModal = ({
	title,
	open,
	isLoading,
	isLoadingClient,
	onSubmit,
	onSubmitClient,
	onClose,
	options = [],
	selectedOption,
	setSelectedOption,
	message,
	isError,
	isSuccess,
	setSearch,
	isLoadingOption,
	selectedSimulation,
	setSelectedSimulation,
	isNew,
	forceStep,
}: CreateScenarioModalProps) => {
	// translation
	const { t } = useTranslation();
	const { step, setStep } = useQuoteFormStep();

	const handleClose = () => {
		onClose();
		setStep(1);
	};

	const _step = forceStep ?? step;

	return (
		<Dialog
			title={''}
			open={open}
			toggle={handleClose}
			isLoading={isLoading}
			paperProps={{
				width: '500px',
			}}
			withCancelButton={false}
		>
			{_step === 1 && (
				<DevisForm
					options={options}
					//title={selectedOption ? 'Dupliquer le scénario' : 'Créer un scénario de recette'}
					title={title}
					subTitle={t('form-create-quote-subtitle')}
					toggle={() => {
						onClose();
					}}
					onSubmit={onSubmit}
					message={message}
					isError={isError}
					isSuccess={isSuccess}
					isLoading={isLoading}
					setSelectedOption={setSelectedOption}
					selectedOption={selectedOption}
					setSearch={setSearch}
					isLoadingOption={isLoadingOption}
					selectedSimulation={selectedSimulation}
					isNew={isNew}
				/>
			)}

			{_step === 2 && (
				<ClientForm
					options={options}
					title={t('form-client-quote-button')}
					toggle={handleClose}
					onSubmit={onSubmitClient}
					message={message}
					isError={isError}
					isSuccess={isSuccess}
					isLoading={isLoadingClient}
					setSelectedOption={setSelectedOption}
					selectedOption={selectedOption}
					setSearch={setSearch}
					isLoadingOption={isLoadingOption}
					toggleOnCancel
					buttonTitle={t('save-button')}
				/>
			)}
		</Dialog>
	);
};

export default DevisModal;
