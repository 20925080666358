import { useState } from 'react';
import { read, utils } from 'xlsx';

import { css, cx } from '@emotion/css';
import { IconButton, Typography } from '@mui/material';
import { ArrowLeft, CheckCircle2, Circle, FileDown } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import Dialog from '../../../components/dialogs/Dialog';
import { useTranslation } from '../../../hooks/useTranslation';

import { isWhitespace } from '@carbonmaps/shared/utils/utils';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import ImportDialog from '../../../components/dialogs/ImportDialog';
import { cn, siteColors } from '../../../lib/colors';
import { fetchSuppliersByName, useQuestionFormStore } from './hooks';
import { flattenContacts } from './PrepareQuestionFormSummary';
import QuestionFormSuppliersTable, {
	getContactStringValue,
	SupplierContactRowData,
} from './QuestionFormSuppliersTable';
import QuestionFormSuppliersTableSelect from './QuestionFormSuppliersTableSelect';

export const stylesPrepareQuestion = {
	main: css({
		padding: '24px 32px',
		gap: 32,
	}),
	hidden: css({
		visibility: 'hidden',
	}),
	link: css({
		fontSize: '14px !important',
		color: cn(siteColors.primary, '!important'),
	}),
	fileInput: css({
		input: {
			opacity: 0,
			position: 'absolute',
			width: '402px',
			height: '200px',
			cursor: 'pointer',
		},
		fontSize: '14px !important',
		color: cn(siteColors.primary, '!important'),
		width: '402px',
		height: '200px',
		justifyContent: 'center !important',
		alignItems: 'center !important',
		textAlign: 'center',
		padding: '8px 16px',
		border: cn('1px dashed', siteColors.grey500),
		borderRadius: '8px',
		transition: 'all .2s',
		'&:hover': {
			border: cn('1px dashed', siteColors.primary),
			background: siteColors.primary10,
		},
	}),
};

type Props = {
	setScreen: (screen: number) => void;
};

const QuestionFormSuppliers = ({ setScreen }: Props) => {
	return (
		<div className={cx('')}>
			<TopBar setScreen={setScreen} />
			<div className={cx('flexColumn', stylesPrepareQuestion.main)}>
				<Note />
				<QuestionFormSuppliersTable />
			</div>
		</div>
	);
};

export default QuestionFormSuppliers;

export const topStyles = {
	main: css({
		height: 80,
		borderBottom: cn('1px solid', siteColors.grey500),
		paddingLeft: '24px',
		paddingRight: '24px',
	}),
	text1: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',
		marginBottom: 4,
	}),
	text2: css({
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '140%',
	}),
	leftGroup: css({
		flexGrow: 1,
	}),
	text3: css({
		marginLeft: 12,
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '150%',
	}),
	text4: css({
		fontWeight: 'bold',
	}),
};

const TopBar = ({ setScreen }: { setScreen: (screen: number) => void }) => {
	const { t } = useTranslation();
	const { supplierContacts } = useQuestionFormStore();

	const contacts = supplierContacts
		.map((c) => {
			return c.subRows;
		})
		.flat();

	const filledContacts = contacts.filter((e) => {
		return !isWhitespace(getContactStringValue(e));
	});

	const allComplete = filledContacts.length === contacts.length;

	return (
		<div className={cx('flexRow alignCenter', topStyles.main)}>
			<div className={cx('flexRow alignCenter', topStyles.leftGroup)}>
				<div
					css={{
						marginRight: 21,
					}}
				>
					<IconButton
						disableRipple
						onClick={() => {
							setScreen(0);
						}}
					>
						<ArrowLeft color={siteColors.grey700} size={20} />
					</IconButton>
				</div>

				{supplierContacts.length <= 0 ? (
					<div className={cx('', topStyles.text1)}>{t('Fournisseurs à contacter')}</div>
				) : (
					<div className={cx('')}>
						<div className={cx('', topStyles.text1)}>{t('Fournisseurs à contacter')}</div>
						<div
							className={cx('flexRow alignCenter', supplierContacts.length <= 0 ? stylesPrepareQuestion.hidden : '')}
						>
							{allComplete ? (
								<CheckCircle2 color={siteColors.green500} size={20} />
							) : (
								<Circle size={20} color="#F0BF41" className={cx('')} />
							)}

							<div className={cx('', topStyles.text3)}>
								<span className={cx('', topStyles.text4)}>
									{filledContacts.length}/{contacts.length}
								</span>{' '}
								{t('contact renseignés')}
							</div>
						</div>
					</div>
				)}
			</div>

			<div className={cx('flexRow alignCenter')}>
				<ToggleModal />
			</div>
		</div>
	);
};

const noteStyles = {
	main: css({
		background: siteColors.grey200,
		padding: 24,
		border: cn('1px solid', siteColors.grey500),
		borderRadius: 16,
	}),
};

const Note = () => {
	const { t } = useTranslation();

	const { supplierContacts } = useQuestionFormStore();

	return (
		<div
			className={cx('flexRow alignCenter width100', noteStyles.main)}
			dangerouslySetInnerHTML={{
				__html:
					supplierContacts.length > 0
						? t('question-forms.complete-suppliers-info')
						: t('question-forms.start-to-add-suppliers'),
			}}
		></div>
	);
};

interface User {
	supplierName: string;
	contactFirstName: string;
	contactName: string;
	contactEmail: string;
	contactLanguage: string;
}

const ToggleModal = () => {
	const [open, setOpen] = useState(false);
	const [openImport, setOpenImport] = useState(false);
	const { t, i18n } = useTranslation();
	const [data, setData] = useState<User[]>([]);
	const [error, setError] = useState<string | null>(null);
	const { setSuppliersContacts } = useQuestionFormStore();
	const languageCode = getIsoCountryCode(i18n.language);
	const [loading, setLoading] = useState(false);
	const [fileName, setFileName] = useState<string | null>(null);

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (!file) return;
		// Récupérer le nom du fichier
		const fileName = file.name;
		setFileName(fileName);
		// Vérifier si le fichier est un CSV
		if (file.type !== 'text/csv') {
			setError(t('import-contact-error-not-csv'));
			setData([]);
			return;
		}

		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target?.result) {
					const textDecoder = new TextDecoder('utf-8');
					const decodedText = textDecoder.decode(new Uint8Array(e.target.result as ArrayBuffer));
					const workbook = read(decodedText, { type: 'string' });
					const sheetName = workbook.SheetNames[0];
					const sheet = workbook.Sheets[sheetName];
					const parsedData = utils.sheet_to_json<User>(sheet, { header: 1 });

					// Vérifier le nombre de lignes sans compter la première ligne
					if (parsedData.length - 1 > 300) {
						setError(t('import-error-too-many-rows', { varMaxRow: 300 }));
						setData([]);
						return;
					}

					const formattedData = (parsedData.slice(1) as any).map((row: any) => ({
						supplierName: row[0],
						contactFirstName: row[1],
						contactName: row[2],
						contactEmail: row[3],
						contactLanguage: row[4],
					}));
					setData(formattedData);
				}
			};
			reader.readAsArrayBuffer(file);
		}
	};

	const handleSaveData = async () => {
		if (error) return;
		setLoading(true);
		const nameSuppliers = data?.map((s) => s.supplierName);

		if (!nameSuppliers) return;
		const results = await fetchSuppliersByName({ nameSuppliers });

		if (!results) return;
		const mapSuppliers = new Map();
		results.forEach((result: any) => {
			mapSuppliers.set(result.name, result);
		});

		const _newData: Record<string, any>[] = [];

		data.forEach(async (s) => {
			//todo test if supplier exist in contact supplier
			const itemMap = mapSuppliers.get(s.supplierName);

			if (!itemMap) {
				return;
			}

			const contactItem = {
				id: itemMap.id,
				supplierName: s.supplierName || '---',
				supplierUid: itemMap.uid || '---',
				contactEmail: s.contactEmail,
				contactFirstName: s.contactFirstName,
				contactLastName: s.contactName,
				contactLanguage: s.contactLanguage ?? languageCode,
			};

			_newData.push(contactItem);
		});

		const fData = formatData(_newData);
		setSuppliersContacts(fData);

		setOpenImport(false);
		setLoading(false);
		setError(null);
	};

	return (
		<>
			<div className="flexRow gap16">
				<BButton
					label={t('Importer des contacts')}
					variant="primary"
					onClick={() => {
						setOpenImport(true);
					}}
				/>

				<BButton
					label={t('Ajouter des fournisseurs')}
					variant="primary"
					onClick={() => {
						setOpen(true);
					}}
				/>
			</div>
			<Dialog
				open={open}
				withCancelButton={false}
				closeButton
				toggle={() => {
					setOpen(!open);
				}}
				paperProps={{
					maxWidth: 1200,
					width: 1200,
					minHeight: 600,
				}}
			>
				<QuestionFormSuppliersTableSelect />
			</Dialog>
			<ImportDialog
				title={t('import-contact-title')}
				error={error}
				isLoading={loading}
				open={openImport}
				withCancelButton={false}
				closeButton
				toggle={() => {
					setOpenImport(!openImport);
				}}
				paperProps={{
					maxWidth: 1200,
					width: 482,
					minHeight: 250,
				}}
				description={
					<div>
						<Typography variant="body1" textAlign="left">
							{t('import-contact-description')}
						</Typography>
						<div>
							<a href="/template_contact.csv" download="template_contact.csv" className={stylesPrepareQuestion.link}>
								{t('import-contact-download-model')}
							</a>
						</div>
					</div>
				}
				primaryButtonText={t('import-contact-to-validate')}
				handlePrimaryButtonAction={handleSaveData}
			>
				<div className={cx('flexColumn gap16', stylesPrepareQuestion.fileInput)}>
					<input type="file" accept=".csv" onChange={handleFileUpload} />
					<FileDown color={siteColors.grey700} />
					<Typography color={siteColors.grey700}>{t('import-contact-choose-file')}</Typography>
					{fileName && <div>{fileName}</div>}
				</div>
			</ImportDialog>
		</>
	);
};

const formatData = (data: Record<string, any>[]) => {
	const res = _.groupBy(data, (e) => {
		return e.id;
	});

	const _data: SupplierContactRowData[] = [];

	_.entries(res).forEach((entry) => {
		const [id, contacts] = entry;

		const first = contacts[0];

		const supplierName = _.get(first, 'supplierName');
		const supplierUid = _.get(first, 'supplierUid');

		const item = {
			id,
			supplierName,
			supplierUid,
			subRows: contacts.map((c) => {
				return {
					...c,
					id: nanoid(10),
				};
			}) as never,
		};

		_data.push(item);
	});

	return _data;
};
