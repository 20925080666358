import { useQuery } from '@tanstack/react-query';

import { useYearSelection } from '../../../../hooks/useImpactYear';

import { findQuoteReportsClientGraphAction, findQuoteReportsClientTableAction, findReportsClientTableAction, getCountReportsClientAction } from './sales.actions';



// ---- 2 --------------------------------------------------------------------------------
type useFindReportsClientTableProps = {
	params: any
}

export const useFindReportsClientTable = ({
	params,
}: useFindReportsClientTableProps) => {
	const { selectedYear } = useYearSelection();

	const key = ['findReportsClientTable', { selectedYear, ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findReportsClientTableAction,
	});

	return { key, result };
};



export const useGetCountReportsClient = () => {
	const { selectedYear } = useYearSelection();

	const key = ['getCountReportsClient', { selectedYear }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: getCountReportsClientAction,
	});

	return { key, result };
};




export const useFindQuoteByReportsClientTable = ({
	params,
}: useFindReportsClientTableProps) => {
	const { selectedYear } = useYearSelection();

	const key = ['findQuoteReportsClientTable', { selectedYear, ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findQuoteReportsClientTableAction,
	});

	return { key, result };
};


export const useFindQuoteByReportsClientGraph = ({
	params,
}: useFindReportsClientTableProps) => {
	const { selectedYear } = useYearSelection();

	const key = ['findQuoteReportsClientGraph', { selectedYear, ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findQuoteReportsClientGraphAction,
	});

	return { key, result };
};
