import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { css, cx } from '@emotion/css';
import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import Typography from '../Typography';

const styles = {
	circle: css({
		width: 8,
		height: 8,
		background: 'var(--background)',
		borderRadius: '50%',
	}),
	label: css({
		maxWidth: '175px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		textWrapMode: 'nowrap',
	}),
};
type DonutChartLegendProps = {
	title?: React.ReactNode;
	items: Array<{
		label: string;
		value: number;
		color: string;
		tonnage?: number;
	}>;
	className?: string;
};

const DonutChartLegend = ({ items, title, className = 'flex1 width100' }: DonutChartLegendProps) => {
	const { t } = useTranslation();
	return (
		<div className={cx('flexColumn   gap16 flexEnd', className)}>
			{title ? (
				<Typography variant="caption" className="grey7">
					{title}
				</Typography>
			) : null}
			<div className={cx('flexColumn flex1 width100 gap6 ')}>
				{items.map((item) => {
					return (
						<div className={cx('flex1 flexRow spaceBetween gap16 alignCenter width100 nowrap')}>
							<div className="flexRow alignCenter gap8 nowrap wrapperContent">
								<div className={cx('', styles.circle)} style={{ ['--background' as any]: item.color }} />
								<Typography className={cx('grey900', styles.label)}>{t(item.label)}</Typography>
							</div>
							<Typography className="number grey9">
								{item.value < 1 ? '< 1' : formatNumber(item.value, undefined, 0)}%
							</Typography>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default DonutChartLegend;
