import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useMemo } from 'react';
import Card from '../../../../components/v3/Card';

import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import DonutChart from '../../../packagings/components/DonutChart';
import CarbonFootprintLegend from './CarbonFootprintLegend';
import CarbonFootprintTitle from './CarbonFootprintTitle';
import CarbonFootprintTooltip from './CarbonFootprintTooltip';

import { getCarbonFootprintByCategoryAction } from '@carbonmaps/ui/actions/carbonFootprint.actions';
import TotalImpact from '../../../../components/dashboard/temporality/TotalImpact';
import EmptyCarbonFootprint from './EmptyCarbonFootprint';

const colors_config = {
	0: siteColors.fontPrint.color0,
	1: siteColors.fontPrint.color1,
	2: siteColors.fontPrint.color2,
	3: siteColors.fontPrint.color3,
	4: siteColors.fontPrint.color4,
	5: siteColors.fontPrint.color5,
	6: siteColors.fontPrint.color6,
	7: siteColors.carbon100,
	8: siteColors.carbon10,
	9: siteColors.carbon100,
};

const CarbonFootprintCategory = () => {
	const { t } = useTranslation();

	// ---- fetch data ---- //
	const { data, isLoading } = useQuery({
		queryKey: ['getCarbonFootprintByCategory'],
		queryFn: getCarbonFootprintByCategoryAction,
	});

	const itemsOrdered = useMemo(() => {
		if (!data?.items?.length) {
			return [
				{
					color: siteColors.grey3,
					value: 99.9,
					tonnage: 0,
				},
			];
		}

		// ---- remove no value ---- //

		const result = data?.items.filter((item: { label: string; value: number; tonnage?: number }) => item.value);
		if (!result?.length) {
			return [
				{
					color: siteColors.grey3,
					value: 99.9,
					tonnage: 0,
				},
			];
		}

		const sortingArray = _.orderBy(result, 'value', 'desc');

		return sortingArray.map((item: { label: string; value: number }, index) => {
			return {
				...item,
				color: (colors_config as any)[index] || (siteColors.grey3 as string),
			};
		});
	}, [data]);

	const legendItems = useMemo(() => {
		if (!data?.items?.length) return [];

		const items = data?.items;

		const sortingArray = _.orderBy(items, 'value', 'desc');

		return sortingArray.map((item: { label: string; value: number }, index) => {
			return {
				...item,
				color: (colors_config as any)[index] || (siteColors.grey3 as string),
			};
		});
	}, [data]);

	return (
		<Card
			title={
				<div className="width100">
					<CarbonFootprintTitle
						title={t('Par poste', { period: '' })}
						item={{ ...data, emission_method: t('bilan_carbon_tooltip') }}
					/>
					<TotalImpact withUnit category />
				</div>
			}
			loading={isLoading}
		>
			{!data && !isLoading ? (
				<EmptyCarbonFootprint />
			) : (
				<DonutChart
					width={200}
					height={200}
					items={itemsOrdered}
					innerRadius={85}
					outerRadius={70}
					legend={<CarbonFootprintLegend items={legendItems as any} />}
					withTooltip
					renderTooltip={(item, indicator) => {
						return <CarbonFootprintTooltip label={item?.label} value={item?.tonnage} />;
					}}
					data={itemsOrdered}
					className="width100 nowrap"
				/>
			)}
		</Card>
	);
};

export default CarbonFootprintCategory;
