import { useCallback, useEffect } from 'react';

import { css, cx } from '@emotion/css';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetInfoGeneral } from '@carbonmaps/ui/hooks/useGetInfoGeneral';

import SEO from '../../components/SEO';
import { useTranslation } from '../../hooks/useTranslation';

import LoadingData from './LoadingData';

import DashboardTab from './v2/DashboardTabs';

import { useJuneTrack } from '../../hooks/useJuneTrack';
import Welcome from './v2/Welcome';

const styles = {
	container: css({
		backgroundPositionX: 'center',
		backgroundPositionY: '-5px',
		backgroundImage: 'url(/dashboard-bg.svg)',
		minHeight: '100vh',
		width: 'calc(100% - 4rem)',
		maxWidth: '1009px',
		backgroundRepeat: 'no-repeat',
		margin: '0 auto',
		paddingTop: 24,
		paddingBottom: 62,
	}),
};

const Dashboard = () => {
	const { data, isLoading } = useGetInfoGeneral();
	const { t } = useTranslation();

	const { setBreadcrumbs } = useApp();

	// ---- event june ---- //
	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Dashboard' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		setBreadcrumbs([{ text: t('dashboard-title') }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	useEffect(() => {
		trackEvent('Dashboard landing');
	}, [trackEvent]);

	return (
		<>
			<SEO title={`${t('dashboard-title')} - Carbon Maps`} />
			{!isLoading && !data?.product ? (
				<LoadingData />
			) : (
				<div className={cx('flexColumn gap32', styles.container)}>
					<Welcome />
					<DashboardTab />
				</div>
			)}
		</>
	);
};

export default Dashboard;
