import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { PackageOpen } from 'lucide-react';
import { Navigate, createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import SEO from '../../components/SEO';
import SheetPageLayout from '../../components/layout/page/SheetPageLayout';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetPackagingSheetTimePeriod } from '../../lib/react-query/features/packaging/packaging.hooks';

import { siteColors } from '../../lib/colors';
import PackagingSynthesis from './synthesis/PackagingSynthesis';
import PackagingUses from './uses/PackagingUses';

type ITab = {
	title: string;
	element: ReactNode;
	disabled?: boolean;
	tabName?: string;
};

const tabs: Record<string, ITab> = {
	synthesis: {
		title: 'synthesis-tab',
		element: <PackagingSynthesis />,
		tabName: 'Summary',
	},
	usage: {
		title: 'Utilisations dans les produits',
		element: <PackagingUses />,
		tabName: 'Uses in products',
	},
};

const PackagingSheet = () => {
	const { setBreadcrumbs } = useApp();
	const params = useParams(); // * { packagingId: string; tab: string }
	const [notFound, setNotFound] = useState<boolean>(false);
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo({ behavior: 'smooth', top: 0 });
	}, []);

	const { data, isLoading, isError, error } = useGetPackagingSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.packagingId!,
	});

	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		if (isError && error instanceof Error) {
			if (error.message && error.message === 'Object not found.') {
				setNotFound(true);
			}
		}
	}, [error, isError]);

	useEffect(() => {
		if (!data) {
			setBreadcrumbs([{ text: 'Emballages' }]);
			return;
		}

		if (!isLoading && !data) {
			setNotFound(true);
		}

		if ((data as any).notFound) {
			setNotFound(true);
		}

		setBreadcrumbs([
			{ text: t('Emballages'), link: `${FRONT_PATH_NAMES.packagings.general}?${searchParams}` },
			{ text: _.capitalize(data?.label || '') },
		]);

		trackEvent('Explore Land Packaging', { PackagingLabel: data?.label });
	}, [data, setBreadcrumbs, searchParams, isLoading, t]);

	const handleTabChange = (_: SyntheticEvent, value: string) => {
		navigate(`${FRONT_PATH_NAMES.packagings.details}/${params.packagingId}/${value}?${searchParams.toString()}`);
		trackEvent('Explore Packaging Switch Tab', { PackagingTabTarget: tabs[value]?.tabName });
	};

	if (!params.tab) {
		return (
			<Navigate
				replace
				state={{ from: location }}
				to={`${FRONT_PATH_NAMES.packagings.details}/${params.packagingId}/${Object.keys(tabs)[0]}`}
			/>
		);
	}

	if (notFound) {
		// TODO: Create a notFound Component
		return <Typography variant="h1">l'emballage avec l'identifiant: {params.packagingId} n'existe pas.</Typography>;
	}

	return (
		<Box>
			<SEO title={data?.label ? `${_.capitalize(data.label)} - CarbonMaps` : 'CarbonMaps'} />
			<SheetPageLayout
				loading={isLoading}
				currentTab={params.tab}
				headerProps={{
					variant: 'fixed',
					icon: <PackageOpen width={40} height={40} color={theme.palette.common.black} />,
					isBlueCheck: (data as any)?.tagAdvanced,
					title: data?.label || '',
					overTitle: t('Emballage') || '',
					sx: {
						background: siteColors.brown10,
					},
					subtitle: _.get(data, 'uid', '-'),
				}}
				onTabChange={handleTabChange}
				tabs={tabs}
			/>
		</Box>
	);
};

export default PackagingSheet;
