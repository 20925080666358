import AcvCard from './AcvCard';
import RecycledMaterialCard from './RecycledMaterialCard';

const KpiPackaging = () => {
	return (
		<div className="flexRow alignCenter gap16 stretch nowrap">
			<AcvCard />
			<RecycledMaterialCard />
		</div>
	);
};

export default KpiPackaging;
