import Grid from '@mui/material/Grid';

import PackagingCalculDataBlock from './PackagingCalculDataBlock';
import PackagingDataBlock from './PackagingDataBlock';
import PackagingEndOfLife from './PackagingEndOfLife';
import PackagingIndicatorsBlock from './PackagingIndicatorsBlock';
import PackagingLifeCycle from './PackagingLifecycle';
import PackagingMetaDataBlock from './PackagingMetaDataBlock';
import PackagingOtherIndicatorsBlock from './PackagingOtherIndicatorsBlock';
import PackagingRecycledMaterials from './PackagingRecycledMaterials';
import PackagingRepartitionGraph from './PackagingRepartitionGraph';

const PackagingSynthesis = () => {
	return (
		<Grid container gap="40px" sx={{ flexWrap: 'nowrap' }}>
			<Grid item xs={8.2}>
				<PackagingIndicatorsBlock />
				<PackagingLifeCycle />
				<PackagingRecycledMaterials />
				<PackagingEndOfLife />
				<PackagingRepartitionGraph />
				<PackagingOtherIndicatorsBlock />
			</Grid>

			<Grid item flexGrow={1}>
				<PackagingDataBlock />
				<PackagingCalculDataBlock />
				<PackagingMetaDataBlock />
			</Grid>
		</Grid>
	);
};

export default PackagingSynthesis;
