import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { ChevronDown, PackageOpen, Plus } from 'lucide-react';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { searchPackagingAction } from '@carbonmaps/ui/actions/packaging.actions';
import {
	TableComponent,
	getStickyHeaderStyle,
	getStickyRowStyle,
} from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import TableHeaderCell from '../../../../../components/table/TableHeaderCell';
import { useSearchQueryParams } from '../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../lib/colors';
import { useFindPackagingsTable } from '../../../../../lib/react-query/features/packaging/packaging.hooks';
import { getRecycledArray, manageEndOfLife } from '../../../../../utils/packaging';

const styles = {
	container: css({
		gap: 24,
	}),
	autoComplete: css({
		width: '100% !important',
		'& .MuiInputBase-root': {
			width: '100% !important',
		},
	}),

	initButton: css({
		border: cn('1px solid', siteColors.grey600, '!important'),
		background: '#fff !important',
	}),
	input: css({
		maxWidth: 150,

		'.MuiInputBase-root': {
			minWidth: 100,
		},
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},
		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered tr th:nth-of-type(1), .bordered tr td:nth-of-type(1)': {
			width: 400,
		},
		'.bordered tr th:nth-of-type(2), .bordered tr td:nth-of-type(2)': {
			width: 350,
		},
		'.bordered tr td:not(:nth-of-type(1)) div,.bordered tr th:not(:nth-of-type(1)),.bordered tr th:not(:nth-of-type(1))  div':
			{
				justifyContent: 'flex-end !important',
				textAlign: 'right !important',
			},
		'.bordered tr th:not(:nth-of-type(1))  div': {
			justifyContent: 'flex-end!important',
			paddingRight: '0!important',
			alignItems: 'right',
		},

		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

const addSeparatorStyle = () => {
	return {
		'.bordered tr:nth-of-type(2) td': {
			borderTop: cn('11px solid', siteColors.grey200),
		},
	};
};

const formatData = (res: any, indicator: string, currentPackagingId: string) => {
	if (!res?.length) return [];
	const maxCarboneIntensity = _.maxBy(res, 'ges') as any;
	const maxWaterIntensity = _.maxBy(res, 'waterUse') as any;
	const stickyRowStyle = getStickyRowStyle(720, 59);

	const result = res.map((item: any) => {
		console.log('🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀', item?.endOfLife);

		return {
			...item,
			isSticky: item.objectId === currentPackagingId ? true : false,
			stickyRowStyle: item.objectId === currentPackagingId ? stickyRowStyle : '',
			ref: item?.uid,
			reference: item.uid,
			percentage:
				(indicator === CARBON_INDICATOR
					? (item.ges * 100) / maxCarboneIntensity?.ges || 1
					: (item.waterUse * 100) / maxWaterIntensity?.waterUse) || 1,

			recycledValues: item?.recycled ? getRecycledArray(item?.recycled) : [],
			endOfLife: manageEndOfLife(item?.endOfLife || []),
		};
	});
	return result;
};

type CreationPackagingTableProps = {
	packaging?: any;
	emballages?: any;
	onSavePackaging: (currentId: any, replaceId: string) => void;
	loading?: boolean;
};

const CreationPackagingTable = ({ packaging, emballages, onSavePackaging }: CreationPackagingTableProps) => {
	const theme = useTheme();
	// translation
	const { t } = useTranslation();

	const { indicator } = useApp();

	// custom style tab
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	const currentPackagingId = packaging?.id;

	// ---- columns ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} valueType="string" label={t('Emballages')} />;
				},
				accessor: 'label',
				component: 'TagLabel',
				editable: true,
				sortDescFirst: true,
				type: 'string',
				icon: <PackageOpen size={20} color={siteColors.grey700} />,
				props: {
					gap: 8,
					style: {
						color: theme.palette.common.black,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
						textTransform: 'uppercase !important',
					},
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('Intensité par kilo d’emballage')}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'ges' : 'waterUse',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					...(indicator !== CARBON_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('Recyclé')} variant="measure" measure={'percent'} />;
				},
				accessor: 'recycledValues',
				component: 'BarField',
				type: 'percent',
				sortDescFirst: true,
				editable: true,
				// sortDescFirst: true,
				// editable: true,
				// component: 'PortionField',
				// type: 'number',
				// props: {
				// 	minWidth: 100,
				// 	className: styles?.input,
				// 	disabled: true,
				// 	page: 'eco-packaging',
				// 	color: siteColors.grey700,
				// },
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('Fin de vie')} />;
				},
				component: 'BarField',
				accessor: 'endOfLife',
				type: 'percent',
				sortDescFirst: true,
				editable: true,
				// accessor: 'endOfLife',
				// sortDescFirst: true,
				// editable: true,
				// component: 'SimpleCell',
				// type: 'string',
				// props: {
				// 	minWidth: 100,
				// 	className: styles?.input,
				// 	disabled: true,
				// 	suffix: <ChevronDown size={20} />,
				// 	type: 'string',
				// 	page: 'eco-packaging',
				// },
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} valueType="string" label="" />;
				},
				accessor: 'objectID',
				disableSortBy: true,
				editable: true,
				component: 'Action',
				callBack: (row: any) => {
					onSavePackaging(row?.objectId, packaging?.id);
				},
				labelButton: packaging ? t('Remplacer') : t('Ajouter'),
				props: {
					...(!packaging && { icon: <Plus color={theme.palette.grey[700]} /> }),
					initLabel: t('reset-filter-button'),
					currentUid: packaging?.get('uid'),
					className: styles.initButton,
				},
			},
		];
	}, [theme.palette.common.black, theme.palette.grey, indicator, packaging, onSavePackaging]);

	const [searchQueryParams] = useSearchQueryParams();
	// const { input, facetFilters, supplierIds } = searchQueryParams;

	// ---- table params ( pagination, sort , size ) ---- //
	// TODO: infinite loading
	const [paramsTable, setTableParams] = useState(
		/* <GetProductTableQueryParams> */ {
			input: '',
			page: 1,
			size: 100,
			facetFilters: [],
			supplierIds: [], // Suppliers filter
			direction: 0,
			...(searchQueryParams as any),
		},
	);

	// ---- fetch data ---- //
	const {
		// key,
		result: { data: tableResultData, isLoading: isTableLoading },
	} = useFindPackagingsTable({
		params: {
			...paramsTable,
			indicator,
			packagingId: null, // to avoid the search params object id // TODO: ?? yes
		},
		allYears: true,
	});

	// const {} = useFindPackagingsTable({ params: { }})
	// const { data, isLoading } = useQuery({
	// 	queryKey: [
	// 		'searchPackaging',
	// 		{
	// 			currentPackaging: packaging?.id,
	// 			notInIds: emballages
	// 				.filter((i: any) => {
	// 					return i?.packaging?.id !== packaging?.id;
	// 				})
	// 				.map((item: any) => {
	// 					return item?.packaging?.id;
	// 				}),
	// 			limit: 100,
	// 			input,
	// 			facetFilters,
	// 			supplierIds,
	// 		},
	// 	],
	// 	queryFn: searchPackagingAction,
	// } as any);

	const stickyHeaderStyle = getStickyHeaderStyle(1391, 0);

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...searchQueryParams };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQueryParams]);

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	return (
		<div className={cx('flexColumn width100', styles.container)}>
			<div className="width100">
				<TableComponent
					skeleton={isTableLoading}
					loading={false}
					fetchData={(row: any) => {
						return console.log('row');
					}}
					pageCount={0}
					noDataMessage={t('packaging-table-empty-data')}
					pageSize={1000}
					columns={tableColumns}
					data={formatData(_.get(tableResultData, 'data'), indicator, currentPackagingId)}
					manualSortBy={true}
					pagination={false}
					isSelectable={false}
					addStyles={{ ...stylesTable, ...(packaging && addSeparatorStyle()) }}
					transFunc={t}
					stickyHeader
					stickyHeaderStyles={stickyHeaderStyle}
					onSortingColumn={handleSortingTable}
				/>
			</div>
		</div>
	);
};

export default CreationPackagingTable;
