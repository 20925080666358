import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useACVPackagingProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import _ from 'lodash';

import { useParams } from 'react-router-dom';
import CircularProgress from '../../../../components/card/CircularProgress';
import Typography from '../../../../components/Typography';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/v3/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { css } from '@emotion/css';
import CircularContent from './CircularContent';
import TooltipPackaging from './TooltipPackaging';

const styles = {
	container: css({
		minHeight: 215,
	}),
};

const AcvCard = () => {
	const { t } = useTranslation();
	const params = useParams();

	const { indicator } = useApp();

	// ---- fetch data ---- //
	const { data, isLoading } = useACVPackagingProduct({
		productId: params.productId!,
		viewMode: indicator,
	});

	return (
		<Card
			className="justifyCenter alignCenter"
			containerClassName={styles.container}
			topTitle={<Typography className="fontWeight500 lineHeight1">{t('packaging-life-cycle-bloc')}</Typography>}
			loading={isLoading}
		>
			<CircularProgress
				width={162}
				percent={data?.percent || 0.01}
				otherColor={siteColors.blue100}
				fillColor={siteColors.primary}
				containerWidth={'162px'}
				innerRadius={41}
				content={
					<CircularContent
						title={`${displayValue(data?.percent, undefined, 1)}%`}
						content={t('acv-card-content')}
						color={siteColors.grey8}
					/>
				}
				withBorder
				renderTooltip={(item, indicator) => {
					return <TooltipPackaging item={item}></TooltipPackaging>;
				}}
				data={[
					{
						label: t('par-kilo-packaging'),
						value: data?.impactPackaging,
						unit: <Unit measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'} />,
					},
					{
						label: t('par-kilo-product'),
						value: data?.impactProduct,
						unit: <Unit measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'} />,
					},
				]}
			/>
		</Card>
	);
};

export default AcvCard;
