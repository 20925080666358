import { useCallback, useEffect } from 'react';

import { getFacetLabel } from '@carbonmaps/shared/utils/utils';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../components/layout/list/Container';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import AnalyseFilter from './AnalyseFilter';
import AnalyseHeader from './AnalyseHeader';
import AnalyseTable from './AnalyseTable';
import AnalyseTradeoff from './AnalyseTradeoff';
import { useAnalyseSetup, useCountAnalyseSegment } from './analyse.hooks';

const Analyse = () => {
	const { setBreadcrumbs } = useApp();
	const { categorySelected } = useAnalyseSetup();
	const { t } = useTranslation();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const { indicator } = useApp();

	// ---- user info to specify facets ---- //
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string, options: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Analyse' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		// setBreadcrumbs([{ text: `${t('analyze')} ${getFacetLabel(categorySelected, authData?.session.company)}` }]);

		setBreadcrumbs([
			{ text: t('product') },
			{ text: t('analyze'), link: `${FRONT_PATH_NAMES.products.general}` },
			{ text: getFacetLabel(categorySelected, authData?.session.company) },
		]);
	}, [authData?.session.company, categorySelected, setBreadcrumbs, t /* , trackEvent */]);

	useEffect(() => {
		trackEvent('Analyse Land', {
			Segment: categorySelected,
		});
	}, [
		categorySelected,
		//  trackEvent
	]);

	const {
		result: { data: count, isLoading: isSearchLoading },
	} = useCountAnalyseSegment({
		categorySelected,
	});

	return (
		<Container
			header={<AnalyseHeader totalCount={count ?? 0} />}
			graph={<AnalyseTradeoff totalCount={count ?? 0} />}
			actionFilter={<AnalyseFilter />}
			seoText={`${t('analyze')} - Carbon Maps`}
		>
			<AnalyseTable />
		</Container>
	);
};

export default Analyse;
