import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { proxy, wrap } from '../../../../../../../../packages/ui/utils/comlink';
import QuoteModel from '../../../../../models/Quote.model';
import { getPDFArgs } from '../useQuote';
import type { WorkerType } from './pdfWorker';
import Worker from './pdfWorker?worker';

export const pdfWorker = wrap<WorkerType>(new Worker());

pdfWorker.onProgress(proxy((info: any) => console.log(info)));

export type PDFDocumentProps = {
	quote: QuoteModel | undefined;
	companyLogo: string | undefined;
	form: any | undefined;
	indicator?: string;
	t: any;
	companyCode: any;
	facetsConfig: any;
	preferredFacet: any;

	translations: any[];
	languageCode: string;
};

export const useGeneratePDF = () => {
	const result = useMutation({
		mutationKey: ['GeneratePDFInWorker'],
		mutationFn: async ({
			companyCode,
			companyLogo,
			facetsConfig,
			form,
			preferredFacet,
			quote,
			t,
			indicator,

			translations,
			languageCode,
		}: PDFDocumentProps) => {
			const returnValue = getPDFArgs({
				companyCode,
				facetsConfig,
				form,
				preferredFacet,
				quote,
				indicator,
				t,
				translations,
				languageCode,
			});
			return pdfWorker.renderPDFInWorker(returnValue);
			// return pdfWorker.renderPDFInWorker({
			// 	companyCode,
			// 	companyLogo,
			// 	facetsConfig,
			// 	form,
			// 	preferredFacet,
			// 	quote,
			// 	t,
			// 	indicator,
			// });
		},
	});

	// const { data: url } = result;

	// useEffect(() => (url ? () => URL.revokeObjectURL(url) : undefined), [url]);

	return result;
};
