import { z } from 'zod';

export const CarbonFootprintFormValidationSchema = z.object({
	year: z.coerce.number().optional(),
	emission_source: z.coerce.string().optional(),
	emission_method: z.coerce.string().optional(),

	scope_1: z.coerce.number().optional(),
	scope_2: z.coerce.number().optional(),
	scope_3: z.coerce.number().optional(),
	intrants: z.coerce.number().optional(),
	packaging: z.coerce.number().optional(),
	fret: z.coerce.number().optional(),
	immobilisations: z.coerce.number().optional(),
	energy: z.coerce.number().optional(),
	transport: z.coerce.number().optional(),
	direct_waste_emissions: z.coerce.number().optional(),
	product_lifecycle: z.coerce.number().optional(),
});

export type CarbonFootprintFormInput = z.infer<typeof CarbonFootprintFormValidationSchema>;
