import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useTheme from '@mui/material/styles/useTheme';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, Pencil, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import {
	deleteSimulationAction,
	getProductSimulationDataAction,
	updateStatusSimulationAction,
} from '@carbonmaps/ui/actions/simulation.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useRowTableExpandedOpen } from '@carbonmaps/ui/hooks/useRowTableSelection';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { CARBON_INDICATOR } from '../../../utils/constants';

type TableProps = {
	setOpenModal: any;
	setSelectedOption: any;
	setIsNew: any;
	setSelectedSimulation: any;
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered tr th:nth-of-type(1), .bordered tr td:nth-of-type(1)': {
			width: 10,
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:nth-of-type(3), .bordered tr th:nth-of-type(4)': {
			width: 200,
		},
		'.bordered tr th:nth-of-type(5)': {
			width: 120,
		},

		'& table tbody > tr > td:nth-of-type(2)': {
			paddingLeft: '0px !important',
		},

		'.bordered tr th:nth-of-type(3) > div, .bordered tr td:nth-of-type(3) div,.bordered tr th:nth-of-type(4) div, .bordered tr td:nth-of-type(4) div':
			{
				textAlign: 'right',
				// paddingLeft: '0px !important',
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
			},
	};
};

const EcoConceptionTable = ({ setOpenModal, setSelectedOption, setIsNew, setSelectedSimulation }: TableProps) => {
	const [searchQueryParams] = useSearchQueryParams();
	const { indicator } = useApp();
	const theme = useTheme();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);
	// --- ref for fetch data table ---- //
	const fetchIdRef = useRef(0);

	// translation
	const { t } = useTranslation();

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);

	// ---- fetch data ---- //

	const { data, isLoading, isSuccess } = useQuery({
		queryKey: [
			'getProductSimulationDataAction',
			{
				...paramsTable,
				viewMode: indicator,
			},
		],
		queryFn: getProductSimulationDataAction,
	} as any);

	// ---- action when click edit ---- //
	const handleClickEdit = useCallback((value: any) => {
		navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
	}, []);

	// ---- action when click add ---- //
	const handleClickAdd = useCallback(
		(value: any, level: number) => {
			if (level === 1) {
				setSelectedOption(value);
			} else {
				setSelectedOption(value?.productSource);
				setSelectedSimulation(value);
			}

			setOpenModal(true);
			setIsNew(false);
		},
		[data, setSelectedOption],
	);

	// ---- action when click copy ---- //
	const handleClickCopy = useCallback(
		(value: any) => {
			console.log(value);
			setSelectedOption(value?.productSource);
			setSelectedSimulation(value);
			setOpenModal(true);
			setIsNew(false);
		},
		[data, setSelectedOption],
	);

	// ---- action when delete row ---- //
	const handleClickDelete = useCallback(async (value: any) => {
		await deleteSelectedRow({ objectId: value } as any);
	}, []);

	// ---- action when update row ---- //
	const handleClickUpdate = useCallback(async (value: any) => {
		await updateStatusSelectedRow({ objectId: value.objectId, status: value.status } as any);
	}, []);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					...(pageIndex && { page: pageIndex + 1 }),
					...(pageSize && { size: pageSize }),
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				//...paramsTable,
				sortingBy: value,
				// pageSize: paramsTable.size,
				// pageIndex: paramsTable.size - 1,
			});
		},
		[updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...searchQueryParams };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQueryParams]);

	// mutate action confirm to delete simulation
	const {
		mutate: updateStatusSelectedRow,
		isLoading: isLoadingUpdateStatus,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['updateStatusSelectedRow'],
		mutationFn: updateStatusSimulationAction,
		onSuccess: () => {
			//	queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			//setMessage('');
		},
		onError: (error: any) => {
			// if (error.message) {
			// 	setMessage(error.message);
			// }
		},
	});

	// mutate action confirm to delete simulation
	const {
		mutate: deleteSelectedRow,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteFacetFieldSelectedRow'],
		mutationFn: deleteSimulationAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			//setMessage('');
		},
		onError: (error: any) => {
			// if (error.message) {
			// 	setMessage(error.message);
			// }
		},
	});

	// ---- select expanded rows selected in store ---- //
	const { expandedRows } = useRowTableExpandedOpen();

	const defaultExpanded = {};
	(data as any)?.data?.map((element: any, index: number) => {
		if (expandedRows.includes(element?.objectId)) {
			(defaultExpanded as any)[index] = true;
		}

		return element;
	});

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	// ---- define columns table ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							valueType="string"
							column={props.column}
							label={t('finished_product_with_eco_design', { products: t('products') })}
						/>
					);
				},
				accessor: 'label',
				editable: true,
				component: 'AcvLabel',
				sortDescFirst: true,
				type: 'string',
				icon: <Box color={theme.palette.grey[700]} />,
				props: {
					subIcons: false,
					page: 'simulation',
					trans: t,
					onClick: (row: any) => {
						trackEvent('Simulate Eco Design Click Scenario', {
							ScenarioName: row?.label,
						});
					},
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('simulation-status')} />;
				},
				accessor: 'status',
				component: 'SimpleCell',
				editable: true,
				disableSortBy: true,
				type: 'status',
				callbackChangeStatus: (objectId: any, status: string) => {
					handleClickUpdate({ objectId, status });
				},
				props: {
					isLoading: isLoadingUpdateStatus,
					page: 'simulation',
					trans: t,
				},
				isLoading: isLoadingUpdateStatus,
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					page: 'simulation',
					...(indicator !== CARBON_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
								},
						  }
						: {
								color: {
									primary: theme.palette.primary.main,
								},
						  }),
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label="" sortable={false} />;
				},
				accessor: indicator === CARBON_INDICATOR ? 'gesDiffPercent' : 'waterDiffPercent',
				editable: true,
				disableSortBy: true,
				component: 'AcvDiff',
				type: 'number',
				props: {
					page: 'simulation',
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label="" />;
				},
				accessor: 'objectId',
				editable: true,
				disableSortBy: true,
				component: 'Action',
				type: 'action',
				icon: <Trash2 size={18} color={theme.palette.grey[700]} />,
				withConfirm: true,
				callback: (value: any) => {
					handleClickDelete(value);
				},
				editIcon: <Pencil size={18} color={theme.palette.grey[700]} />,
				callbackEdit: (value: any) => {
					handleClickEdit(value);
				},
				callbackAdd: (value: any) => {
					handleClickAdd(value, 1);
				},
				callbackCopy: (value: any) => {
					// handleClickCopy(value, 2);
					handleClickCopy(value);
				},
				props: {
					page: 'simulation',
				},
				// eslint-disable-next-line quotes
				messageConfirm: 'Supprimer le scénario ?',
				subMessageConfirm: 'Cette action ne peut pas être annulée.',
			},
		];
	}, [
		theme.palette.grey,
		siteColors.water500,
		theme.palette.primary.main,
		isLoadingUpdateStatus,
		indicator,
		handleClickUpdate,
		handleClickDelete,
		handleClickEdit,
		handleClickAdd,
		// data,
		t,
	]);

	return (
		<>
			<TableComponent
				skeleton={isLoading}
				fetchData={updateTableParams}
				pageCount={(data as any)?.meta?.last_page || 0}
				noDataMessage={
					paramsTable?.input || paramsTable?.facetFilters?.length
						? t('simulation-table-empty-data')
						: t('simulation-table-empty-data-message')
				}
				pageSize={paramsTable.size}
				columns={tableColumns}
				resetPage={resetPage}
				data={
					(data as any)?.data?.map((p: any) => {
						return {
							...p,
							typeModelizationIngredient: t(toLowerCase(p?.typeModelizationIngredient), {
								recipe: t('recipe'),
							}),
						};
					}) || []
				}
				onSortingColumn={handleSortingTable}
				manualSortBy
				isSelectable={true}
				isExpanded
				addStyles={stylesTable}
				setSelectedRow={() => {
					return 1;
				}}
				nbColumns={7}
				defaultExpanded={defaultExpanded}
				transFunc={t}
				tableId={'simulation-table'}
			/>
			<LoadingDialog open={isLoadingDelete} content={`${t('Suppression en cours')}...`} />
		</>
	);
};

export default EcoConceptionTable;
