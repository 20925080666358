import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
	CircularProgress,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
	useTheme,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Building, Moon, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { ICompany } from '@carbonmaps/shared/types/company.types';
import { IUser } from '@carbonmaps/shared/types/user.types';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import {
	createUpdateCompanyAction,
	deleteCompanyAction,
	getCompaniesAction,
} from '@carbonmaps/ui/actions/company.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import SwitchCell from '@carbonmaps/ui/components/saas/Table/SwitchCell';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';

import FlashMessage from '../../../components/bo/FlashMessage';
import Dialog from '../../../components/dialogs/Dialog';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useLoginAsMutation } from '../../../lib/react-query/features/auth/auth.hooks';
import { useFindUsers } from '../../../lib/react-query/features/user/user.hooks';

import CompanyForm from './CompanyForm';

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

const CompanyTable = () => {
	// -------------------------------------------------------------------------------------- //
	// --------------------------------- State definition ----------------------------------- //
	// -------------------------------------------------------------------------------------- //
	// const { syncUserState } = useAuth();
	const [chosenCompany, setChosenCompany] = useState<ICompany | undefined>();
	const [chosenUser, setChosenUser] = useState<IUser | undefined>();
	const [message, setMessage] = useState('');
	const [paramsTable, setTableParams] = useState<any>({
		page: 1,
		size: 100,
	});
	const queryClient = useQueryClient();
	// state to force to first page
	const [resetPage, setResetPage] = useState(0);
	// state to toggle to edit company
	const [isDialogOpen, toggleDialog] = useToggle(false);
	// state to toggle for chosen user
	const [isDialogUserOpen, toggleUserDialog] = useToggle(false);

	const [openAlert, setOpenAlert] = useToggle(false);

	const analytics = useJuneTrack();

	// state to edit an company
	const [companyToEdit, setCompanyToEdit] = useState({});

	const navigate = useNavigate();
	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// ---- ref for fetch data table ----//
	const fetchIdRef = useRef(0);

	// ---- translation ---- //
	const { t } = useTranslation();

	// ---- action when click users column ---- //
	const handleClickUser = useCallback((id: string) => {
		navigate(`${FRONT_PATH_NAMES.superAdminSettings}/users?companyId=${id}`);
	}, []);

	// ---- action when click facets column ---- //
	const handleClickFacet = useCallback((value: any) => {
		navigate(`${FRONT_PATH_NAMES.superAdminSettings}/facets?companyId=${value.objectId}`);
	}, []);

	// ---- action when click edit's column ---- //

	const handleClickEdit = useCallback((value: any) => {
		setCompanyToEdit(value);
		toggleDialog();
	}, []);

	// mutate action confirm to delete company
	const confirmDelete = async (companyId: any, deleted: any) => {
		if (companyId) {
			await deleteSelectedRow({ companyId, deleted } as any);
		}
	};

	// ---- action when switch deleted  ---- //
	const handleSwitchChange = async (row: any, newValue: any) => {
		await createUpdateCompany({
			...row.original,
			deleted: newValue,
		});
	};

	// ---- triggers on click db version ---- //
	const handleClickDbVersion = useCallback((value: any) => {
		navigate(`${FRONT_PATH_NAMES.superAdminSettings}/version?companyId=${value.objectId}`);
	}, []);

	const handleClickTranslation = useCallback((value: any) => {
		navigate(`${FRONT_PATH_NAMES.superAdminSettings}/translation?companyId=${value.objectId}`);
	}, []);

	// ---- triggers on click module ---- //
	const handleClickDbModule = useCallback((value: any) => {
		navigate(`${FRONT_PATH_NAMES.superAdminSettings}/module?companyId=${value.objectId}`);
	}, []);

	// ---- update company ---- //
	const {
		mutate: createUpdateCompany,
		isError,
		isLoading: isLoadingUpdate,
	} = useMutation({
		mutationKey: ['createUpdateCompany'],
		mutationFn: createUpdateCompanyAction,
		onSuccess: async () => {
			//	setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: ['getCompanies'] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});
	// ---- delete company ---- //
	const {
		mutate: deleteSelectedRow,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteCompanyRow'],
		mutationFn: deleteCompanyAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getCompanies'] });
			setMessage('');
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('organisation')} />;
				},
				accessor: 'name',
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				icon: <Building color={theme.palette.grey[700]} />,
				callbackLoginAs: (value: any) => {
					if (value?.deleted) {
						setOpenAlert();
						return;
					}

					toggleUserDialog();
					setChosenCompany(value);
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('company-code')} column={props.column} />;
				},
				accessor: 'code',
				editable: true,
				//	sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('company-max-user-column')} column={props.column} />;
				},
				accessor: 'maxUsers',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'number',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('archived')} column={props.column} />;
				},
				accessor: 'deleted',
				editable: true,
				sortDescFirst: true,
				disableSortBy: true,
				type: 'booleanStatus',
				Cell: ({ row }: any) => {
					return <SwitchCell row={row} value={row?.original?.deleted} onDataUpdate={handleSwitchChange} />;
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('actions-column')} />;
				},
				accessor: 'objectId',
				editable: true,
				sortDescFirst: true,
				disableSortBy: true,
				component: 'Action',
				type: 'action',
				icon: <Trash2 color={theme.palette.grey[700]} />,
				withArchive: true,
				callback: (value: any) => {
					confirmDelete(value?.objectId, value?.deleted);
				},
				callbackUser: (value: any) => {
					handleClickUser(value);
				},
				callbackEdit: (value: any) => {
					handleClickEdit(value);
				},
				callbackSetting: (value: any) => {
					handleClickFacet(value);
				},
				callBackDbVersion: (value: any) => {
					handleClickDbVersion(value);
				},
				callBackDbModule: (value: any) => {
					handleClickDbModule(value);
				},
				callBackTranslation: (value: any) => {
					handleClickTranslation(value);
				},
				callbackQuestionnaire: (value: any) => {
					navigate(`${FRONT_PATH_NAMES.superAdminSettings}/preferred-survey?companyId=${value.objectId}`);
				},
				// eslint-disable-next-line quotes
				messageConfirm: t('archived-confirm-text'),
				// eslint-disable-next-line quotes
				messageConfirmDelete: t('deleted-company-confirm-text'),
			},
		];
	}, [theme, t]);

	// ---- fetch data company--- //
	const { data, isLoading, isSuccess } = useQuery({
		queryKey: ['getCompanies'],
		queryFn: getCompaniesAction,
	});

	// ---- fetch data user--- //
	const {
		result: {
			data: usersData,
			isLoading: isUsersLoading,
			// isFetching: isUsersFetching,
			refetch: findUsers,
		},
	} = useFindUsers({
		params: { companyId: chosenCompany?.objectId || '' },
		options: {
			enabled: false,
		},
	});

	useEffect(() => {
		if (chosenCompany) {
			findUsers();
		}
	}, [chosenCompany, findUsers]);

	const {
		result: { mutate: logInAs, isLoading: isLogInAsLoading },
	} = useLoginAsMutation({
		options: {
			async onSuccess(data, variables, context) {
				navigate(FRONT_PATH_NAMES.home);
			},
		},
	});

	// ---- skeleton ---- //
	const { skeleton } = useSkeleton({ condition: isLoading });

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);
	return (
		<>
			<div>
				<FlashMessage open={openAlert} onClose={setOpenAlert} icon={<Moon />} message={t('disabled-message-company')} />
			</div>
			<Dialog
				closeButton={true}
				isLoading={isLoading}
				title={''}
				description={''}
				open={isDialogOpen}
				toggle={toggleDialog}
				withCancelButton={false}
			>
				<CompanyForm toggle={toggleDialog} currentCompany={companyToEdit} />
			</Dialog>

			<Dialog
				closeButton={true}
				isLoading={isUsersLoading}
				title={''}
				description={''}
				open={isDialogUserOpen}
				toggle={toggleUserDialog}
				withCancelButton={false}
			>
				<Typography variant="h3" mt="16px" mb="16px">
					{t('choose-user-company-modal-title')}
				</Typography>
				<List
					sx={{
						margin: '0 auto',
						width: '100%',
						maxWidth: 360,
						height: '300px',
						mb: '16px',
						overflow: 'auto',
						/* scroll transparent */
						'::-webkit-scrollbar': {
							width: '4px',
							background: 'rgba(189, 189, 189, .05)',
							borderRadius: '999px',
						},
						'::-webkit-scrollbar-thumb': {
							backgroundColor: 'rgba(189, 189, 189, .5)',
							borderRadius: '999px',
						},
						'::-webkit-scrollbar-track': {
							background: 'transparent',
							borderRadius: '999px',
						},
					}}
				>
					{chosenCompany &&
						(isUsersLoading ? (
							<ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
								<CircularProgress size={32} />
							</ListItem>
						) : (
							<>
								{(usersData?.users ?? []).map((user: IUser) => {
									return (
										<Fragment key={user.code}>
											<ListItemButton
												onClick={async () => {
													setChosenUser(user);
												}}
											>
												<ListItemText
													primary={`${user.firstName || ''} ${user.lastName || ''}` || user.username}
													secondary={`@${user.username}`}
													// sx={{ mt: '12px' }}
												/>
											</ListItemButton>
											<Divider />
										</Fragment>
									);
								})}
							</>
						))}
				</List>
				<BButton
					isDisabled={!chosenUser}
					variant={chosenUser ? 'primary' : 'secondary'}
					label={
						isLogInAsLoading ? (
							<CircularProgress size={20} css={{ color: siteColors.common.white }} />
						) : (
							`${t('login-as-text')} ${chosenUser ? `@${chosenUser.username}` : '...'}`
						)
					}
					addStyles={{
						minHeight: '52px',
						margin: '0 auto',
						width: '350px',
						display: 'block',
						marginBottom: '14px',
					}}
					onClick={() => {
						logInAs({ userId: chosenUser?.objectId || '', companyId: chosenCompany?.objectId });
					}}
				/>
			</Dialog>
			{isSuccess && (
				<TableComponent
					skeleton={skeleton}
					loading={false}
					fetchData={updateTableParams}
					pageCount={1 || 0}
					noDataMessage={t('empty-data-company-table')}
					pageSize={paramsTable.size}
					columns={tableColumns}
					resetPage={resetPage}
					data={data || []}
					onSortingColumn={handleSortingTable}
					addStyles={stylesTable}
					manualSortBy={false}
					setSelectedRow={() => {
						return 1;
					}}
					transFunc={t}
				/>
			)}

			<LoadingDialog open={isLoadingUpdate} content={`${t('Modification en cours')}...`} />
			<LoadingDialog open={isLoadingDelete} content={`${t('Suppression en cours')}...`} />
		</>
	);
};

export default CompanyTable;
