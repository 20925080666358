import { css } from '@emotion/css';
import { ChevronDown } from 'lucide-react';

import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import Typography from '../Typography';
import SelectField from '../form/fields/SelectField';

const styles = {
	container: css({
		background: 'none !important',
		padding: '0px !important',
		lineHeight: '1',
		minWidth: 65,
		'.MuiInputBase-input': {
			minHeight: '0 !important',
			padding: '0px !important',
			paddingRight: '0px !important',
			minWidth: '0',
			'.MuiSelect-select': {
				paddingRight: '0px !important',
			},
		},
		'.MuiSelect-select': {
			paddingRight: '0px !important',
		},
		svg: {
			width: '12px !important',
			color: cn(siteColors.grey7, '!important'),
		},
	}),
};

type DashboardSelectProps = {
	options?: { value: string; label: string; [x: string]: string | number }[];
	value: string | number;
	onChange: (v: any) => void;
	className?: string;
	ExpandMoreRoundedIcon?: any;
	renderValue?: (item: any) => React.ReactNode;
};

const DashboardSelect = ({
	options = [],
	value,
	onChange,
	className,
	ExpandMoreRoundedIcon,
	renderValue,
}: DashboardSelectProps) => {
	const { t } = useTranslation();
	return (
		<SelectField
			value={value}
			items={options?.map((item: any) => {
				return {
					...item,
					label: t(item?.label),
				};
			})}
			renderValue={(item: any) => {
				if (renderValue) return renderValue(item);
				return (
					<Typography variant="caption" className={className}>
						{t(item?.label)}
					</Typography>
				);
			}}
			onChange={onChange}
			className={styles.container}
			paddingRight={24}
			ExpandMoreRoundedIcon={ExpandMoreRoundedIcon || ChevronDown}
		/>
	);
};

export default DashboardSelect;
