import { css, cx } from '@emotion/css';
import ProductCountKPI from './ProductCountKPI';
import ProductImpactKPI from './ProductImpactKPI';

const styles = {
	gridCols: css({
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
	}),
};

const Kpi = () => {
	return (
		<div className={cx(/* "flexRow width100 stretch gap16" */ ' gap24', styles.gridCols)}>
			<ProductCountKPI />
			<ProductImpactKPI />
		</div>
	);
};

export default Kpi;
