import { useQuery } from '@tanstack/react-query';
import { create } from 'zustand';

import { classNames, functionName } from '@carbonmaps/shared/utils/constants';

import _ from "lodash";
import { nanoid } from "nanoid";
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { CustomMessageRowData, type SupplierContactRowData, type UpdateContactData } from './QuestionFormSuppliersTable';
import { type AddSupplierWithContacts, type RemoveSupplierContactById } from './QuestionFormSuppliersTableSelect';

export type AddContactForSupplier = (options: { supplierId: string; isEdited?: boolean }) => void
export type RemoveSupplierById = (options: { supplierId: string }) => void;

type QuestionFormState = {
	supplierContacts: SupplierContactRowData[];
	setSuppliersContacts: (value: SupplierContactRowData[]) => void;
	// updateSupplierContactDataById: UpdateSupplierDataById;
	updateContactData: UpdateContactData;
	addContactForSupplier: AddContactForSupplier;
	addSupplierWithContacts: AddSupplierWithContacts;
	removeSupplierContactById: RemoveSupplierContactById;
	removeSupplierById: RemoveSupplierById;

	supplierErrorImportContacts: SupplierContactRowData[];
	addErrorSupplierImport: (value: SupplierContactRowData) => void;

	customMessages: CustomMessageRowData[];
	setCustomMessages: (value: CustomMessageRowData[]) => void;

};

export const useQuestionFormStore = create<QuestionFormState>((set) => {

	return {
		supplierContacts: [],
		supplierErrorImportContacts: [],
		customMessages: [],
		updateContactData: ({ supplierId, contactId, values }) => {
			return set((state) => {
				const supplierContacts = state.supplierContacts;
				const supplierIndex = supplierContacts.findIndex((iData) => {
					return iData.id === supplierId;
				});

				if (supplierIndex !== -1) {
					const supplier = supplierContacts[supplierIndex];
					const subRows = supplier.subRows;

					const contactIndex = subRows.findIndex((iData) => {
						return iData.id === contactId;
					});

					if (contactIndex !== -1) {
						subRows[contactIndex] = {
							...subRows[contactIndex],
							...values,
						}
					}
				}

				return {
					supplierContacts: [...supplierContacts],
				};
			})
		},
		addContactForSupplier: ({ supplierId, isEdited = false }) => {
			return set((state) => {
				const supplierContacts = state.supplierContacts;
				const supplierIndex = supplierContacts.findIndex((iData) => {
					return iData.id === supplierId;
				});

				if (supplierIndex !== -1) {
					const supplier = supplierContacts[supplierIndex];
					const subRows = supplier.subRows;

					supplierContacts[supplierIndex].subRows = [
						{
							id: nanoid(7),
							..._.pick(supplier, ['supplierName', 'supplierUid']),
							isEdited,
						},
						...subRows
					]
				}

				return {
					supplierContacts: [...supplierContacts],
				};
			})
		},
		setSuppliersContacts: (value: SupplierContactRowData[]) => {
			return set({ supplierContacts: value });
		},
		addSupplierWithContacts: (value: SupplierContactRowData) => {
			return set((state) => {
				return {
					supplierContacts: [...state.supplierContacts, value],
				};
			});
		},
		removeSupplierContactById: ({ supplierId, contactId }) => {
			return set((state) => {
				const supplierContacts = state.supplierContacts;
				const supplierIndex = supplierContacts.findIndex((iData) => {
					return iData.id === supplierId;
				});

				if (supplierIndex !== -1) {
					const supplier = supplierContacts[supplierIndex];
					const subRows = supplier.subRows;

					const contactIndex = subRows.findIndex((iData) => {
						return iData.id === contactId;
					});

					if (contactIndex !== -1) {
						const left = _.slice(subRows, 0, contactIndex);
						const right = _.slice(subRows, contactIndex + 1, subRows.length);

						supplier.subRows = [...left, ...right];
						// subRows[contactIndex] = {
						// 	...subRows[contactIndex],
						// }
					}
				}

				return {
					supplierContacts: [...supplierContacts],
				};
			})
		},
		addErrorSupplierImport: (value: SupplierContactRowData) => {
			return set((state) => {
				return {
					supplierErrorImportContacts: [...state.supplierErrorImportContacts, value],
				};
			});
		},
		setCustomMessages: (value: CustomMessageRowData[]) => {
			return set({ customMessages: value });
		},
		removeSupplierById: ({ supplierId }) => {
			return set((state) => {
				let supplierContacts = state.supplierContacts;
				const supplierIndex = supplierContacts.findIndex((iData) => {
					return iData.id === supplierId;
				});

				if (supplierIndex !== -1) {
					const supplier = supplierContacts[supplierIndex];
					// const subRows = supplier.subRows;

					const left = _.slice(supplierContacts, 0, supplierIndex);
					const right = _.slice(supplierContacts, supplierIndex + 1, supplierContacts.length);

					supplierContacts = [...left, ...right];

					// if (contactIndex !== -1) {
					// 	const left = _.slice(subRows, 0, contactIndex);
					// 	const right = _.slice(subRows, contactIndex + 1, subRows.length);

					// 	supplier.subRows = [...left, ...right];
					// 	// subRows[contactIndex] = {
					// 	// 	...subRows[contactIndex],
					// 	// }
					// }
				}

				return {
					supplierContacts: [...supplierContacts],
				};
			})
		}
	};
});

export const useGetQuestionFormById = ({ id }: { id: string }) => {
	return useQuery({
		queryKey: ['getQuestionFormById', { id }] as const,
		queryFn: async (context) => {
			try {
				const { id } = context.queryKey[1];
				//
				const questionForm = await new Parse.Query(classNames.QUESTION_FORM).get(id);
				return questionForm.toJSON();
			} catch (error) {
				console.log('---- getQuestionFormById action error ---------------', error);
				return Promise.reject(error);
			}
		},
	});
};

export const useGetSuppliersContacts = ({ supplierUids, enabled }: { supplierUids: string[], enabled: boolean }) => {
	return useQuery({
		queryKey: ['getSuppliersContacts', { supplierUids }] as const,
		queryFn: async (ctx) => {
			try {
				const { supplierUids } = ctx.queryKey[1];
				const results: Record<string, any> = await Parse.Cloud.run(functionName.getSuppliersContacts, { supplierUids });
				return results;
			} catch (error) {
				console.log('---- getSuppliersContacts action error ---------------', error);
				return Promise.reject(error);
			}
		},
		enabled,
	});
};

export const fetchSuppliersContacts = async ({ supplierUids }: { supplierUids: string[] }) => {
	try {
		// const { supplierUids } = ctx.queryKey[1];
		const results: Record<string, any>[] = await Parse.Cloud.run(functionName.getSuppliersContacts, { supplierUids });
		return results;
	} catch (error) {
		console.log('---- getSuppliersContacts action error ---------------', error);
		// return Promise.reject(error);
	}
};



export const fetchSuppliersByName = async ({ nameSuppliers }: { nameSuppliers: string[] }) => {
	try {
		const results: Record<string, any> = await Parse.Cloud.run(functionName.suppliers.getSuppliersByName, { nameSuppliers });
		return results;
	} catch (error) {
		console.log('---- nameSuppliers action error ---------------', error);
		// return Promise.reject(error);
	}
};


