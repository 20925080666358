import { useCallback, useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { Plus } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { CARBON_INDICATOR, FRONT_PATH_NAMES, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import AccordionContainer from '../../components/AccordionContainer';
import TableHeaderCell from '../../components/table/TableHeaderCell';
import { useTranslation } from '../../hooks/useTranslation';
import QuoteModel from '../../models/Quote.model';

import LoadingDialog from '../../components/dialogs/LoadingDialog';
import { siteColors } from '../../lib/colors';
import QuoteGroupHeader from './QuoteGroupHeader';
import TotalRow from './TotalRow';
import ProductsModalSelect from './_/ProductsModalSelect';
import ProductsTableSelect from './_/ProductsTableSelect';
import { useRemoveProductDevis } from './hooks';
import { useAddGroupQuote, useQuoteStore } from './quote.hooks';

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered tr th:nth-of-type(1), .bordered tr td:nth-of-type(1)': {
			width: 370,
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr td:nth-of-type(2)': {
			textAlign: 'right !important',
		},

		'.bordered tr th:nth-of-type(3), .bordered tr th:nth-of-type(4)': {
			width: 150,
		},
	};
};

const classNames = {
	addButton: css({
		padding: '16px !important',
		minHeight: '42px !important',
		height: 'auto !important',
	}),
	input: css({
		maxWidth: 150,

		'.MuiInputBase-root': {
			minWidth: 90,
		},
	}),
	label: css({
		maxWidth: '250px !important',
		'@media (max-width: 1560px)': {
			maxWidth: '200px !important',
		},
	}),
	headerTable: css({
		justifyContent: 'flex-end !important',
	}),
};

type QuoteGroupProps = {
	group: any;
};

const QuoteGroup = ({ group }: QuoteGroupProps) => {
	// ---- theme and style ---- //
	const theme = useTheme();
	const styles = useMemo(() => {
		return classes(theme);
	}, [theme]);

	const { indicator } = useApp();

	const { mutateAsync: saveQuoteGroup } = useAddGroupQuote();

	const { t } = useTranslation();

	// const [error, setError] = useState<any>();

	// ---- ingredient selected when clic replace button ---- //
	const [sortField, setSortField] = useQueryParam('sortField', StringParam);
	const [sortOrder, setSortOrder] = useQueryParam('sortOrder', StringParam);
	const [openModal, setOpenModal] = useState(false);

	const initDataTable = useCallback(
		(sortField: any, sortOrder: any) => {
			const products = (group?.products || []).map((item: any) => {
				return {
					...item,
					percentage:
						indicator === CARBON_INDICATOR ? item?.gesImpactPortionPercent : item?.waterUseImpactPortionPercent,
					// remove popover
					typeModelizationIngredient: null,
					link: `${FRONT_PATH_NAMES.audit}/modelization/${item.objectId}`,
				};
			});

			const dataSorted = (_.orderBy(products, [sortField], [sortOrder as any]) as any) || [];
			return dataSorted;
		},
		[group],
	);

	const [dataTable, setDataTable] = useState(initDataTable(sortField, sortOrder));

	// ---- define table columns ---- //
	const columns = useMemo(() => {
		return [
			{
				// Header: 'Produit fini',
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('products')} />;
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'FinalProduct',
				type: 'string',
				props: {
					gap: 8,
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
					},
					from: 'quote',
					onClick: (row: any) => {
						// handleEditRow(row);
					},
					className: classNames?.label,
					page: 'quote',
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('quantity')} />;
				},
				accessor: 'quantity',
				editable: true,
				sortDescFirst: true,
				component: 'InputFieldQuote',
				type: 'percent',
				props: {
					suffix: ' ',
					isNoRevertButton: true,
					className: classNames?.input,
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('per_unit')}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonImpactPortion' : 'waterImpactPortion'}
							className={classNames?.headerTable}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'gesImpactPortion' : 'waterUseImpactPortion',
				editable: true,
				sortDescFirst: true,
				component: 'PortionField',
				type: 'number',

				props: {
					...(indicator !== CARBON_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
					headerClassName: classNames?.headerTable,
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('Par devis')}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonImpact' : 'waterImpact'}
							className={classNames?.headerTable}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'gesTotalImpact' : 'waterUseTotalImpact',
				editable: true,
				sortDescFirst: true,
				component: 'PortionField',
				type: 'number',
				props: {
					color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
					precision: 2,
					headerClassName: classNames?.headerTable,
					columnName: 'quoteSheet-per-quote',
				},
			},
		];
	}, [indicator, t]);

	const params = useParams();
	const { quote } = useQuoteStore();

	const updateMyData = (rowIndex: number, columnId: string, value: any) => {
		// console.log('🚀🚀🚀', value);
		// return null;
		setDataTable((old: any) => {
			return old.map((row: any, index: number) => {
				if (index === rowIndex) {
					const productId = old[rowIndex]?.objectId;

					//set 2 digits after decimal point otherwise it is not consistent in the table
					const roundedGesImpactPortion: any = parseFloat(old[rowIndex].gesImpactPortion)?.toFixed(2);
					const roundedWaterUseImpactPortion: any = parseFloat(old[rowIndex].waterUseImpactPortion)?.toFixed(2);

					const gesTotalImpact = roundedGesImpactPortion * parseFloat(value);
					const waterUseTotalImpact = roundedWaterUseImpactPortion * parseFloat(value);

					// update ges and water quote
					const newProduct = {
						groupId: group?.objectId,
						productId,
						quantity: parseFloat(value),
						gesTotalImpact,
						waterUseTotalImpact,
					};

					saveQuoteGroup({
						quoteId: params?.quoteId,
						group: {
							objectId: group?.objectId,
							name: group?.name,
							product: {
								id: productId,
								quantity: parseFloat(value),
							},
						},
						estimatedImpactCarbon: (quote as QuoteModel | null)?.getEstimatedImpact(CARBON_INDICATOR, newProduct),
						estimatedImpactWater: (quote as QuoteModel | null)?.getEstimatedImpact(WATER_INDICATOR, newProduct),
						averageCarbonIntensity: (quote as QuoteModel | null)?.getAverageIntensity(CARBON_INDICATOR),
						averageWaterIntensity: (quote as QuoteModel | null)?.getAverageIntensity(WATER_INDICATOR),
					});

					return {
						...old[rowIndex],
						[columnId]: value,
						gesTotalImpact,
						waterUseTotalImpact,
					};
				}

				return row;
			});
		});
	};

	// ----------------------------------------------------------------- //
	// ------------------------ delete product row---------------------- //
	// ----------------------------------------------------------------- //

	const { mutate: deleteProduct, isLoading: isLoadingDelete } = useRemoveProductDevis();

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback((value: any) => {
		setSortField(value.id);
		setSortOrder(value.desc === true ? 'desc' : 'asc');
	}, []);

	const handleDeleteRow = (row: any) => {
		// console.log(row);
		deleteProduct({ quoteId: params.quoteId, groupId: group?.objectId, productId: row?.product?.objectId });
	};

	const groupProductIds = useMemo(() => {
		return (
			group?.products?.map((item: any) => {
				return item?.objectId;
			}) || []
		);
	}, [group?.products]);

	useEffect(() => {
		const data = initDataTable(sortField, sortOrder);

		setDataTable(data);
	}, [group?.products, indicator, sortField, sortOrder]);
	return (
		<div className="width100">
			<AccordionContainer
				title={<QuoteGroupHeader group={group} />}
				showHeader={false}
				content={
					<TableComponent
						skeleton={false}
						pageCount={1}
						pageSize={1000}
						columns={columns}
						data={dataTable}
						fetchData={(q: any) => {}}
						isSelectable={false}
						pagination={false}
						loading={false}
						manualPagination={false}
						addStyles={styles}
						updateMyData={updateMyData}
						withEmptyData={false}
						headerRow={<TotalRow data={dataTable} />}
						onSortingColumn={handleSortingTable}
						manualSortBy={true}
						withActionToggle
						onDeleteRow={handleDeleteRow}
						showRowEditButton={false}
						transFunc={t}
					/>
				}
			/>
			<div>
				<BButton
					variant="tertiary"
					label={t('add-product-quote', { product: toLowerCase(t('product')), of_product: t('of_product') })}
					iconLeft={<Plus />}
					className={classNames.addButton}
					onClick={() => {
						setOpenModal(true);
					}}
				/>
			</div>
			<LoadingDialog open={isLoadingDelete} content={`${t('Suppression en cours')}...`} />
			<ProductsModalSelect
				open={openModal}
				onClose={() => {
					return setOpenModal(false);
				}}
			>
				<ProductsTableSelect quoteId={params.quoteId} groupId={group?.objectId} groupProductIds={groupProductIds} />
			</ProductsModalSelect>
		</div>
	);
};

export default QuoteGroup;
