import { css, cx } from '@emotion/css';
import CategoryCardGroup from './CategoryCardGroup';
import Kpi from './components/Kpi';

const styles = {
	container: css({
		paddingTop: 24,
		paddingBottom: 40,
		maxWidth: 1200,
		margin: 'auto',
	}),
};

const AnalyzeTab = () => {
	return (
		<div className={cx('flexColumn gap32', styles.container)}>
			<Kpi />
			<CategoryCardGroup />
		</div>
	);
};

export default AnalyzeTab;
