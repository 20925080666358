import { useCallback, useEffect, useMemo, useState } from 'react';

import { css /* , cx */ } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useMutation, /* useQuery, */ useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { Carrot, Plus } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import {
	deleteIngredientAction,
	updateIngredientAction,
	updateSimulationDbAction,
} from '@carbonmaps/ui/actions/simulation.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { SMALL_DESKTOP_DOWN, XL_BREAKPOINT, XL_DESKTOP_DOWN } from '@carbonmaps/ui/utils/Responsive';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import AccordionContainer from '../../../../components/AccordionContainer';
// import ScenarioModal from '../../../../components/ScenarioModal';
import LoadingDialog from '../../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../../components/table/TableHeaderCell';
import { useJuneTrack } from '../../../../hooks/useJuneTrack';
import { useTranslation } from '../../../../hooks/useTranslation';
import { checkErrorIngredient, getGlobalIndicator } from '../../../../utils/simulation.utils';
import { useScenario, useShowDeleted } from '../../scenario.hooks';
import { useDataSimulation } from '../../useDataSimulation.hooks';
import ErrorData from '../ErrorData';

import { cn, siteColors } from '../../../../lib/colors';
import IngredientsModalSelect from './add/IngredientsModalSelect';
import IngredientCreationTable from './creation/IngredientCreationTable';

const classes = (theme: any, showDeleted: boolean) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered tr th:nth-of-type(1), .bordered tr td:nth-of-type(1)': {
			width: 370,
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr td:nth-of-type(2)': {
			textAlign: 'right !important',
		},
		'.bordered tr th:nth-of-type(3), .bordered tr th:nth-of-type(4)': {
			width: 150,
		},

		// removed original ingredients
		'& tr:has([data-original-disabled-ingredient="true"]) td': {
			background: cn(siteColors.grey100, '!important'),
		},

		// hide or show deleted original
		'& tr:has([data-original-disabled-ingredient="true"])': {
			display: showDeleted ? 'table-row' : 'none!important',
		},
	};
};

const classNames = {
	addButton: css({
		padding: '16px !important',
		minHeight: '42px !important',
		height: 'auto !important',
	}),
	input: css({
		maxWidth: 150,

		'.MuiInputBase-root': {
			minWidth: 100,
		},
	}),

	label: css({
		maxWidth: '250px !important',
		[XL_DESKTOP_DOWN]: {
			maxWidth: '125px !important',
		},
		[SMALL_DESKTOP_DOWN]: {
			maxWidth: '90px !important',
		},
	}),
};

const Ingredient = () => {
	// ---- theme and style ---- //
	const theme = useTheme();

	const { showDeleted } = useShowDeleted();

	const styles = useMemo(() => {
		return classes(theme, showDeleted);
	}, [showDeleted, theme]);
	const [sortField, setSortField] = useQueryParam('sortField', withDefault(StringParam, 'gesProductPerPortion'));
	const [sortOrder, setSortOrder] = useQueryParam('sortOrder', withDefault(StringParam, 'desc'));
	const params = useParams();

	const [dataTable, setDataTable] = useState([]);
	const queryClient = useQueryClient();

	// translation
	const { t } = useTranslation();

	const { cloneSimulation, simulation, onIngredientData, onRowChange } = useDataSimulation();

	const { indicator } = useApp();

	const [error, setError] = useState<any>();

	// ---- ingredient selected when clic replace button ---- //
	const [ingredientSelected, setIngredientSelected] = useState<any>(null);
	const [openModal, setOpenModal] = useState(false);

	// simulationId

	// ---- fetch data ---- //

	const { data, isLoading } = useScenario({
		simulationId: params.simulationId || '',
	});

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	const updateMyData = (rowIndex: number, columnId: string, value: any) => {
		// // We also turn on the flag to not reset the page
		setDataTable((old: any) => {
			return old.map((row: any, index: number) => {
				if (index === rowIndex) {
					onRowChange({ objectId: old[rowIndex]?.objectKey, value: value });
					trackEvent('Simulate Eco Design Edit Presence Product', {
						ScenarioName: (simulation as any)?.name,
						Type: 'Ingredient',
						ItemLabel: old[rowIndex]?.label,
					});
					return {
						...old[rowIndex],
						[columnId]: value,
						gesProductPerPortion: (old[rowIndex].gesIngredient * parseFloat(value)) / 100,
						waterProductPerPortion: (old[rowIndex].waterIngredient * parseFloat(value)) / 100,
					};
				}

				return row;
			});
		});
	};

	// ---- handle edit ingredient ---- //
	const handleEditRow = useCallback((row: any) => {
		setIngredientSelected(row?.ingredient);

		trackEvent('Simulate Eco Design Replace Item', {
			ScenarioName: (simulation as any)?.name,
			Type: 'Ingredient',
			ItemLabel: row?.ingredient?.label,
		});

		// open modal
		setOpenModal(true);
	}, []);

	// ---- handle add ingredient ---- //
	const handleAddIngredient = useCallback(() => {
		// remove ingredient selected when add  item
		setIngredientSelected(null);
		// open modal
		setOpenModal(true);
	}, []);

	const handleRestoreRow = useCallback((row: any) => {
		updateIngredient({
			simulationId: params.simulationId,
			currentIngredientId: row?.ingredient?.id,
			isRestore: true,
			ingredientToRestoreData: { ..._.omit(row, ['isOriginal', 'isPresent', 'ingredient']), isRestored: true },
		});
	}, []);

	// ---- handle close modale ---- //
	const handleCloseModal = useCallback(() => {
		setOpenModal(false);
	}, []);

	// ---- define table columns ---- //
	const columns = useMemo(() => {
		return [
			{
				// Header: 'Produit fini',
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('ingredients')}
							error={
								(error?.isErrorData || error?.isErrorComposition) &&
								!isLoading && (
									<ErrorData
										text={
											error?.isErrorData
												? t('simulation-no-data-ingredient', {
														ingredients: toLowerCase(t('ingredients')),
														ingredient: toLowerCase(t('ingredient')),
														an_ingredient: t('an_ingredient'),
												  })
												: t('simulation-error-ingredient')
										}
										withIcon
									/>
								)
							}
						/>
					);
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				icon: <Carrot size={16} color={siteColors.grey700} />,
				props: {
					gap: 8,
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
					},
					onClick: (row: any) => {
						handleEditRow(row);
					},
					page: 'simulation-scenario',
					className: classNames.label,
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('presence')}
							error={
								<ErrorData
									color={error?.isErrorComposition ? '#C74600' : '#009F36'}
									text={`${formatNumber(error?.composition, undefined, 0)} %`}
								/>
							}
						/>
					);
				},
				accessor: 'composition',
				editable: true,
				sortDescFirst: true,
				component: 'InputField',
				type: 'percent',
				props: {
					cloneData: (cloneSimulation as any)?.ingredient,
					page: 'simulation-scenario',
					minWidth: 100,
					className: classNames?.input,
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('par kilo d’ingredient')}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'gesIngredient' : 'waterIngredient',
				editable: true,
				sortDescFirst: true,
				component: 'PortionField',
				type: 'number',
				props: {
					color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('per_kilo_of_product', {
								per_kilo: t('per_kilo'),
								product: toLowerCase(t('product')),
								of_product: t('of_product'),
							})}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'gesProductPerPortion' : 'waterProductPerPortion',
				editable: true,
				sortDescFirst: true,
				component: 'PortionField',
				type: 'number',
				props: {
					color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
				},
			},
		];
	}, [
		dataTable,
		cloneSimulation,
		error?.composition,
		error?.isErrorComposition,
		error?.isErrorData,
		handleEditRow,
		indicator,
		isLoading,
		t,
	]);

	// ---- update ingredient ---- //
	const { mutate: updateIngredient, isLoading: isLoadingUpdate } = useMutation({
		mutationKey: ['updateIngredient'],
		mutationFn: updateIngredientAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			handleCloseModal();
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
		},
	});

	// ---- update ingredient ---- //
	const { mutate: updateSimulationDb } = useMutation({
		mutationKey: ['updateSimulationDb'],
		mutationFn: updateSimulationDbAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			// queryClient.invalidateQueries({ queryKey: ['getScenario'] });
			// handleCloseModal();
		},
		onError: (error: any) => {
			// queryClient.invalidateQueries({ queryKey: ['getScenario'] });
		},
	});

	// ---- delete Ingredient ---- //
	const { mutate: deleteIngredient, isLoading: isLoadingDelete } = useMutation({
		mutationKey: ['deleteIngredient'],
		mutationFn: deleteIngredientAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
			handleCloseModal();
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
		},
	});

	const handleDeleteRow = (row: any) => {
		deleteIngredient({ simulationId: params.simulationId, ingredientId: row?.ingredient?.id });

		// track delete event
		trackEvent('Simulate Eco Design Remove Item', {
			ScenarioName: (simulation as any)?.name,
			Type: 'Ingredient',
			ItemLabel: row?.ingredient?.label,
		});
	};

	// ---- update or add ingredient ---- //
	const saveIngredient = useCallback(async (currentIngredientId: string, prevIngredientId: string) => {
		updateIngredient({ simulationId: params.simulationId, currentIngredientId, prevIngredientId });
	}, []);

	useEffect(() => {
		if (!data) return;

		const dataSorted = (_.orderBy(data?.ingredients, [sortField], [sortOrder as any]) as any) || [];

		setDataTable(dataSorted || []);
	}, [data, sortField, sortOrder]);

	// ---- updata ingredients store ---- //
	useEffect(() => {
		onIngredientData(dataTable);
		const dataError = checkErrorIngredient(dataTable);
		setError(dataError);
	}, [dataTable, onIngredientData]);

	useEffect(() => {
		const globalIndicatorGes = getGlobalIndicator(simulation, CARBON_INDICATOR);
		const globalIndicatorWater = getGlobalIndicator(simulation, WATER_INDICATOR);

		updateSimulationDb({
			simulationId: params.simulationId,
			ingredients: dataTable.map((item: any) => {
				return {
					composition: item?.composition,
					ingredientId: item?.ingredient?.id,
				};
			}),
			emballages: ((simulation as any)?.emballages || []).map((p: any) => {
				return {
					packagingId: p?.packaging?.id,
					weight: p?.weight,
				};
			}),
			carbonIntensity: globalIndicatorGes?.gesPerKilo,
			waterIntensity: globalIndicatorWater?.gesPerKilo,

			carbonIntensityDiffPercent: globalIndicatorGes?.gesPerKiloDiff,
			waterIntensityDiffPercent: globalIndicatorWater?.gesPerKiloDiff,
		});
	}, [simulation]);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback((value: any) => {
		queryClient.invalidateQueries({ queryKey: ['getScenario'] });
		setSortField(value.id);
		setSortOrder(value.desc === true ? 'desc' : 'asc');
	}, []);

	return (
		<div className="width100">
			<AccordionContainer
				showHeader={false}
				title={t('simulation-ingredient-card-title')}
				content={
					<TableComponent
						skeleton={isLoading}
						pageCount={1}
						pageSize={1000}
						columns={columns}
						data={dataTable}
						fetchData={(q: any) => {
							//console.log(q);
						}}
						isSelectable={false}
						pagination={false}
						loading={false}
						manualPagination={false}
						addStyles={styles}
						updateMyData={updateMyData}
						onEditRow={handleEditRow}
						onDeleteRow={handleDeleteRow}
						onRestoreRow={handleRestoreRow}
						withEmptyData={false}
						withActionToggle
						onSortingColumn={handleSortingTable}
						manualSortBy={true}
						dialogTitle={t('simulation-ingredient-delete-modal-text', {
							ingredient: toLowerCase(t('ingredient')),
							an_ingredient: t('an_ingredient'),
						})}
						transFunc={t}
					/>
				}
			/>
			<div>
				<BButton
					variant="tertiary"
					label={t('add-custom-button', {
						ingredient: toLowerCase(t('ingredient')),
						an_ingredient: t('an_ingredient'),
					})}
					iconLeft={<Plus />}
					className={classNames.addButton}
					onClick={handleAddIngredient}
				/>
			</div>
			<IngredientsModalSelect
				open={openModal}
				onClose={handleCloseModal}
				title={
					ingredientSelected
						? t('simulation-ingredient-modal-replace-title', {
								ingredient: toLowerCase(t('ingredient')),
								an_ingredient: t('an_ingredient'),
						  })
						: t('simulation-ingredient-modal-add-title', {
								ingredient: toLowerCase(t('ingredient')),
								an_ingredient: t('an_ingredient'),
						  })
				}
				subTitle={
					ingredientSelected
						? `${t('simulation-ingredient-modal-replace-subtitle', {
								ingredients: toLowerCase(t('ingredients')),
						  })} ${ingredientSelected?.get('label')?.toUpperCase()} ${t('in_recipe', {
								recipe: toLowerCase(t('recipe')),
						  })}`
						: t('simulation-ingredient-modal-add-subtile', {
								ingredient: toLowerCase(t('ingredient')),
								an_ingredient: t('an_ingredient'),
						  })
				}
			>
				<IngredientCreationTable
					ingredient={ingredientSelected}
					ingredients={dataTable}
					onSaveIngredient={saveIngredient}
					// loading={isLoadingUpdate}
				/>
			</IngredientsModalSelect>
			{/* <ScenarioModal
				open={openModal}
				title={
					ingredientSelected
						? t('simulation-ingredient-modal-replace-title', {
							ingredient: toLowerCase(t('ingredient')),
							an_ingredient: t('an_ingredient'),
						})
						: t('simulation-ingredient-modal-add-title', {
							ingredient: toLowerCase(t('ingredient')),
							an_ingredient: t('an_ingredient'),
						})
				}
				subtitle={
					ingredientSelected
						? `${t('simulation-ingredient-modal-replace-subtitle', {
							ingredients: toLowerCase(t('ingredients')),
						})} ${ingredientSelected?.get('label')?.toUpperCase()} ${t('in_recipe', {
							recipe: toLowerCase(t('recipe')),
						})}`
						: t('simulation-ingredient-modal-add-subtile', {
							ingredient: toLowerCase(t('ingredient')),
							an_ingredient: t('an_ingredient'),
						})
				}
				width={965}
				onClose={handleCloseModal}
			>
				<IngredientCreationTable
					ingredient={ingredientSelected}
					ingredients={dataTable}
					onSaveIngredient={saveIngredient}
				// loading={isLoadingUpdate}
				/>
			</ScenarioModal> */}
			<LoadingDialog open={isLoadingDelete} content={`${t('Suppression en cours')}...`} />
			<LoadingDialog open={isLoadingUpdate} content={`${t('Modification en cours')}...`} />
		</div>
	);
};

export default Ingredient;
