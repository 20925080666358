import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { CircleDashed, Eye } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import Dialog from '../../../../components/dialogs/Dialog';
import GenericEmptyBlock from '../../../../components/GenericEmptyBlock';
import QuestionPreview from '../../../../components/question/QuestionPreview';
import { useTranslation } from '../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { useFindQuestionForm } from '../../../superAdmin/questionForms/hooks';

const QuestionFormsSynthesis = () => {
	const {
		result: { data: authData, isLoading },
	} = useGetClientAuthQuery();

	const { data, isLoading: isLoadingFindQuestionForm } = useFindQuestionForm({
		fromBo: false,
		companyCode: authData?.session.company?.code,
		// roles: authData?.roles,
	});

	const qForms = data || [];

	if (!isLoadingFindQuestionForm && _.isEmpty(qForms)) {
		return <EmptyQFormsList />;
	}

	return (
		<div>
			{qForms.map((qForm: any, index: number) => {
				return <CampaignFormItem key={index} questionFormObject={qForm} />;
			})}
		</div>
	);
};

export default QuestionFormsSynthesis;

const styles2 = {
	container: css({
		// display: 'flex',
		// flexDirection: 'column',
		// alignItems: 'flex-start',
		gap: '1px',
		borderRadius: '16px',
		border: '1px solid #E2DDEB',
		// background: '#E2DDEB',

		padding: 24,
		marginBottom: 16,
	}),

	flexGrow: css({
		flexGrow: 1,
	}),

	title: css({
		color: '#140C27)',
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',
	}),
};

export type QuestionFormValues = {
	objectId?: string;
	translation: {
		en: {
			typeFormId: string;
			name: string;
			url: string;
		};
		fr: {
			typeFormId: string;
			name: string;
			url: string;
		};
		it: {
			typeFormId: string;
			name: string;
			url: string;
		};
		es: {
			typeFormId: string;
			name: string;
			url: string;
		};
		de: {
			typeFormId: string;
			name: string;
			url: string;
		};
	};
	lang: string;
	published?: boolean;
	type: string;
};

const CampaignFormItem = ({ questionFormObject }: { questionFormObject: QuestionFormValues }) => {
	const { t } = useTranslation();
	const [isDialogOpen, toggleDialog] = useToggle(false);
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	return (
		<div className={cx('flexRow', styles2.container)}>
			<div className={cx('', styles2.flexGrow)}>
				<div className={cx('', styles2.title)}>
					{questionFormObject?.translation?.fr?.name ||
						questionFormObject?.translation?.en?.name ||
						questionFormObject?.translation?.it?.name ||
						questionFormObject?.translation?.es?.name}
				</div>
				<div>
					{t('x-envois', {
						x: _.toNumber(_.get(questionFormObject, `campaignCounts.${authData?.session.company?.code}`, 0)),
					})}
				</div>
			</div>

			<div>
				<BButton
					iconLeft={<Eye color="#9587AE" size={20} />}
					label={<div className={cx('flexRow black')}>{t('Aperçu')}</div>}
					variant="tertiary"
					sx={{ marginRight: '16px' }}
					onClick={toggleDialog}
				/>
				<Link to={`${FRONT_PATH_NAMES.questionForms}/prepare/${questionFormObject.objectId}`}>
					<BButton label={<div className={cx('flexRow black')}>{t('Préparer un envoi')}</div>} variant="secondary" />
				</Link>
			</div>

			<Dialog
				title={''}
				isLoading={false}
				description={''}
				open={isDialogOpen}
				toggle={toggleDialog}
				closeButton={true}
				withCancelButton={false}
				paperProps={{
					maxWidth: '100vw',
				}}
			>
				<QuestionPreview
					title={
						questionFormObject?.translation?.fr?.name ||
						questionFormObject?.translation?.en?.name ||
						questionFormObject?.translation?.it?.name ||
						questionFormObject?.translation?.es?.name ||
						questionFormObject?.translation?.de?.name
					}
					translation={questionFormObject?.translation}
				/>
			</Dialog>
		</div>
	);
};

const styles3 = {
	text1: css({
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',

		marginBottom: 12,
	}),
	box: css({
		padding: '32px 24px',
		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),
		gap: 24,
	}),
};

const EmptyQFormsList = () => {
	const { t } = useTranslation();
	// return (
	// 	<div className={cx('flexCenter', styles3.box)}>
	// 		<CircleDashed color={siteColors.grey700} size={40} /*  css={{ marginBottom: 20 }} */ />
	// 		<div css={{ textAlign: 'center' }}>
	// 			<div className={cx('', styles3.text1)}>{t('empty-qforms-list-text-1')}</div>
	// 			<div>{t('empty-qforms-list-text-2')}</div>
	// 		</div>
	// 		<a href="mailto:support@carbonmaps.io" css={{ textDecoration: 'none' }}>
	// 			<BButton label={t('contact-verb')} variant="secondary" />
	// 		</a>
	// 	</div>
	// );

	return <GenericEmptyBlock text1={t('empty-qforms-list-text-1')} text2={t('empty-qforms-list-text-2')} />;
};
