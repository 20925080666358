import { type RouteObject } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import AuthGuard from '../components/AuthGuard';
import SuperAdminSettings from '../containers/superAdmin/SuperAdminSettings';
import QuestionForms from '../containers/superAdmin/questionForms/QuestionForms';
import QuestionFormsSettings from '../containers/superAdmin/questionForms/settings/QuestionFormsSettings';
// import SectorSheet from '../containers/superAdmin/sectorSheet/SectorSheet';
// import SectorSheetList from '../containers/superAdmin/sectorSheet/SectorSheetList';
import BoLayout from '../layouts/bo/BoLayout';

const boRoutes: RouteObject[] = [
	{
		element: (
			<AuthGuard allowedRolesLabels={['super_admin']}>
				<BoLayout />
			</AuthGuard>
		),
		children: [
			{
				path: FRONT_PATH_NAMES.superAdminSettings,
				element: <SuperAdminSettings />,
				children: [
					{
						path: ':tab',
						element: null,
					},
				],
			},
			{
				path: `${FRONT_PATH_NAMES.superAdminSettings}-a`,
				children: [
					{
						path: 'question-forms',
						children: [
							{
								index: true,
								element: <QuestionForms />,
							},
							{
								path: 'settings/:questionFormId',
								element: <QuestionFormsSettings />,
							},
						],
					},
				],
			},
		],
	},
];

export default boRoutes;
