import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { ReactComponent as SupplierIcon } from '@carbonmaps/media/icons/supplier.svg';
import { useGetOneSupplier, useGetSupplierSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetSupplierSheetTimePeriod';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import BlueCheck from '../../components/BlueCheck';
import SheetPageHeader from '../../components/layout/page/SheetPageHeader';
import { useTranslation } from '../../hooks/useTranslation';

const SupplierHeader = () => {
	const { t } = useTranslation();
	const params = useParams();

	// use to get simple data
	const { data: supplierInfo, error, isLoading: isLoadingSupplier, isSuccess } = useGetOneSupplier({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.supplierId!,
	});


	// use to get the bluecheck
	const { data, isLoading } = useGetSupplierSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.supplierId!,
	});

	const theme = useTheme();


	if (isLoadingSupplier) {
		return <Skeleton variant="rectangular" height="120px" sx={{ mb: '16px', borderRadius: '16px' }} />;
	}

	return (
		<SheetPageHeader
			icon={<SupplierIcon css={{ '& path': { fill: theme.palette.common.black } }} width={32} height={32} />}
			sx={_.merge({
				background: 'linear-gradient(90deg, #EEF2FB 0.53%, #E9EEF9 99.99%)',
			})}
			leftElement={
				<Box ml="24px">
					<Box className="flexRow alignCenter">
						<Typography variant="h3" fontWeight={500}>
							{t('Supplier')}
						</Typography>
					</Box>
					<Box className="flexRow alignCenter" mb={pxToRem(8)}>
						<BlueCheck
							title={(supplierInfo as any)?.name}
							variant="h2"
							withIcon={_.get(data, 'supplier.isBlueCheck', false)}
						/>
					</Box>
				</Box>
			}
			variant="flexible"
		/>
	);
};

export default SupplierHeader;
