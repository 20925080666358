import {
	CarbonFootprintFormInput,
	CarbonFootprintFormValidationSchema,
} from '@carbonmaps/shared/validations/carbonFootprint.validations';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import FormSelect from '../../../components/form/fields/FormSelect';
import Typography from '../../../components/Typography';
import { useTranslation } from '../../../hooks/useTranslation';

const styles = {
	container: css({
		paddingTop: 24,
	}),
	select: css({
		'.MuiInputBase-root': {
			minWidth: 160,
			minHeight: '40px !important',
		},
	}),
	dropdown: css({
		minHeight: '24px !important',
	}),
};

const emission_method_options = [
	{
		label: 'GHG protocol',
		value: 'GHG protocol',
	},
	{
		label: 'BEGESr V5',
		value: 'BEGESr V5',
	},
	{
		label: 'ISO 14069',
		value: 'ISO 14069',
	},
	{
		label: 'Non précisée',
		value: 'other',
	},
	{
		label: ' ',
		value: '',
	},
];

export type CarbonFootprint = {
	year?: number;
	emission_source?: string;
	emission_method?: string;
	scope_1?: number;
	scope_2?: number;
	scope_3?: number;
	intrants?: number;
	packaging?: number;
	fret?: number;
	immobilisations?: number;
	energy?: number;
	transport?: number;
	direct_waste_emissions?: number;
	product_lifecycle?: number;
};

type CarbonFootprintFormProps = {
	item: any;
	onSubmit: (data: any) => void;
	onClose: () => void;
	isReset?: boolean;
};

const CarbonFootprintForm = ({ item, onSubmit, onClose, isReset }: CarbonFootprintFormProps) => {
	const { t } = useTranslation();

	const {
		register,
		control,
		handleSubmit,
		setValue,
		getValues,
		reset,
		watch,
		formState: { errors },
	} = useForm<CarbonFootprintFormInput>({
		resolver: zodResolver(CarbonFootprintFormValidationSchema),
		defaultValues: async () => {
			return {
				year: item?.year,
				emission_source: item?.emission_source,
				emission_method: item?.emission_method,
				scope_1: item?.scope_1,
				scope_2: item?.scope_2,
				scope_3: item?.scope_3,
				intrants: item?.intrants,
				packaging: item?.packaging,
				fret: item?.fret,
				immobilisations: item?.immobilisations,
				energy: item?.energy,
				transport: item?.transport,
				direct_waste_emissions: item?.direct_waste_emissions,
				product_lifecycle: item?.product_lifecycle,
			};
		},
		mode: 'all',
	});

	useEffect(() => {
		if (!item || Object.keys(item).length === 0) {
			reset({
				year: -1,
				emission_source: '',
				emission_method: '',
				scope_1: undefined,
				scope_2: undefined,
				scope_3: undefined,
				intrants: undefined,
				packaging: undefined,
				fret: undefined,
				immobilisations: undefined,
				energy: undefined,
				transport: undefined,
				direct_waste_emissions: undefined,
				product_lifecycle: undefined,
			});
		} else {
			reset(item);
		}
	}, [reset, item]);

	// ---- year options ---- //
	const yearOptions = useMemo(() => {
		const currentYear = new Date().getFullYear();

		const startYear = currentYear - 3;

		const years = Array.from({ length: 30 }, (_, i) => {
			const year = startYear + i;
			return {
				value: year,
				label: year,
			};
		});

		return [{ value: -1, label: ' ' }, ...years];
	}, []);

	return (
		<FormContainer
			title={''}
			buttonTitle={t('save-button')}
			onSubmit={handleSubmit(onSubmit)}
			withSave
			withCancel
			onCancel={() => {
				onClose();
			}}
			addStyles={{
				maxWidth: 528,
				margin: 'auto',
			}}
			t={t}
		>
			<div>
				<FormSelect
					control={control}
					{...register('year')}
					label={t('Année')}
					defaultValue={item?.year}
					options={yearOptions}
					renderValue={(v: number) => <Typography>{v !== -1 ? v : ''}</Typography>}
					className={styles.select}
					dropdownClassName={styles.dropdown}
					isOptions
				/>
				<BInput
					required
					id="emission_source"
					label={<div>{t('Source du bilan carbone')}</div>}
					fullWidth
					margin="dense"
					{...register('emission_source')}
					name="emission_source"
					error={errors.emission_source ? true : false}
					errorMessage={errors?.emission_source?.message}
					autoComplete="off"
				/>
				<FormSelect
					control={control}
					{...register('emission_method')}
					label={t('Méthodologie utilisée')}
					defaultValue={item?.emission_method}
					options={emission_method_options}
					renderValue={(v: string) => <Typography>{v}</Typography>}
					className={styles.select}
					dropdownClassName={styles.dropdown}
					isOptions
				/>

				<BInput
					required
					id="scope_1"
					label={<div>{t('Scope 1')}</div>}
					fullWidth
					margin="dense"
					{...register('scope_1')}
					name="scope_1"
					error={errors.scope_1 ? true : false}
					errorMessage={errors?.scope_1?.message}
					autoComplete="off"
					type="number"
				/>
				<BInput
					required
					id="scope_2"
					label={<div>{t('Scope 2')}</div>}
					fullWidth
					margin="dense"
					{...register('scope_2')}
					name="scope_2"
					error={errors.scope_2 ? true : false}
					errorMessage={errors?.scope_2?.message}
					autoComplete="off"
					type="number"
				/>
				<BInput
					required
					id="scope_3"
					label={<div>{t('Scope 3')}</div>}
					fullWidth
					margin="dense"
					{...register('scope_3')}
					name="scope_3"
					error={errors.scope_3 ? true : false}
					errorMessage={errors?.scope_3?.message}
					autoComplete="off"
					type="number"
				/>
				<BInput
					required
					id="intrants"
					label={<div>{t('Intrants')}</div>}
					fullWidth
					margin="dense"
					{...register('intrants')}
					name="intrants"
					error={errors.intrants ? true : false}
					errorMessage={errors?.intrants?.message}
					autoComplete="off"
					type="number"
				/>
				<BInput
					required
					id="packaging"
					label={<div>{t('Emballage')}</div>}
					fullWidth
					margin="dense"
					{...register('packaging')}
					name="packaging"
					error={errors.packaging ? true : false}
					errorMessage={errors?.packaging?.message}
					autoComplete="off"
					type="number"
				/>
				<BInput
					required
					id="fret"
					label={<div>{t('Fret')}</div>}
					fullWidth
					margin="dense"
					{...register('fret')}
					name="fret"
					error={errors.fret ? true : false}
					errorMessage={errors?.fret?.message}
					autoComplete="off"
					type="number"
				/>
				<BInput
					required
					id="immobilisations"
					label={<div>{t('Immobilisations')}</div>}
					fullWidth
					margin="dense"
					{...register('immobilisations')}
					name="immobilisations"
					error={errors.immobilisations ? true : false}
					errorMessage={errors?.immobilisations?.message}
					autoComplete="off"
					type="number"
				/>
				<BInput
					required
					id="energy"
					label={<div>{t('Energie & hors énergie')}</div>}
					fullWidth
					margin="dense"
					{...register('energy')}
					name="energy"
					error={errors.energy ? true : false}
					errorMessage={errors?.energy?.message}
					autoComplete="off"
					type="number"
				/>
				<BInput
					required
					id="transport"
					label={<div>{t('Déplacements ')}</div>}
					fullWidth
					margin="dense"
					{...register('transport')}
					name="transport"
					error={errors.transport ? true : false}
					errorMessage={errors?.transport?.message}
					autoComplete="off"
					type="number"
				/>

				<BInput
					required
					id="direct_waste_emissions"
					label={<div>{t('Déchets directs')}</div>}
					fullWidth
					margin="dense"
					{...register('direct_waste_emissions')}
					name="direct_waste_emissions"
					error={errors.direct_waste_emissions ? true : false}
					errorMessage={errors?.direct_waste_emissions?.message}
					autoComplete="off"
					type="number"
				/>

				<BInput
					required
					id="product_lifecycle"
					label={<div>{t('Utilisation & fin de vie')}</div>}
					fullWidth
					margin="dense"
					{...register('product_lifecycle')}
					name="product_lifecycle"
					error={errors.product_lifecycle ? true : false}
					errorMessage={errors?.product_lifecycle?.message}
					autoComplete="off"
					type="number"
				/>
			</div>
		</FormContainer>
	);
};

export default CarbonFootprintForm;
