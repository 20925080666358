import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Divider, Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import _ from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';

import { useTranslation } from '../../../hooks/useTranslation';

import Popover from '../../../components/Popover';

import { isWhitespace } from '@carbonmaps/shared/utils/utils';
import { siteColors } from '../../../lib/colors';
import Ingredient from './ingredient/Ingredient';
import Packaging from './packaging/Packaging';

const styles = {
	main: css({
		maxWidth: '100%',
	}),
	block: css({
		display: 'block',
	}),
	hidden: css({
		display: 'none',
	}),
};

const myTabStyles = {
	common: css({
		whiteSpace: 'nowrap!important',
		height: '58px',
		minHeight: '58px!important',
	} as any),
	quote: css({
		maxWidth: '120px!important',
	}),
	quoteActive: css({
		maxWidth: 'unset!important',
	}),

	label: {
		quote: css({
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}),
		quoteActive: css({
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}),
		common: css({
			textTransform: 'none',
		}),
	},

	disabled: css({
		cursor: 'not-allowed',
	}),
};

export const MyTab = ({
	group,
	variant = 'quote',
	className,
	isPopover,
	renderPopoverContent,
	onClose,
	...tabProps
}: any) => {
	const tabContent = (
		<Tab
			disableRipple
			label={
				<>
					<div className={cx('width100 capitalize', myTabStyles.label.common, (myTabStyles.label as any)[variant])}>
						{!_.isNil(group?.name) && !isWhitespace(group?.name) ? group?.name : 'Sans nom'}
					</div>
				</>
			}
			className={cx('', className, myTabStyles.common, (myTabStyles as any)[variant])}
			{...tabProps}
		/>
	);

	if (isPopover) {
		return (
			<div css={{ display: 'flex' }} className={cx('')}>
				<Popover
					trigger={tabContent}
					renderContent={({ close }) => {
						return renderPopoverContent ? renderPopoverContent(close) : (null as any);
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					onClose={onClose}
				/>
				<Divider
					orientation="vertical"
					color={siteColors.grey500}
					flexItem
					sx={{ height: '32px', placeSelf: 'center' }}
				/>
			</div>
		);
	}

	return (
		<div css={{ display: 'flex' }} className={cx(tabProps.disabled ? myTabStyles.disabled : '')}>
			{tabContent}
			<Divider
				orientation="vertical"
				color={siteColors.grey500}
				flexItem
				sx={{ height: '32px', placeSelf: 'center' }}
			/>
		</div>
	);
};

const ScenarioContent = () => {
	const { t } = useTranslation();

	const iTabs = useMemo(() => {
		return {
			ingredients: {
				tabLabel: t('ingredients'),
				element: <Ingredient />,
			},
			packagings: {
				tabLabel: t('emballage'),
				element: <Packaging />,
			},
			transformation: {
				tabLabel: t('transformation'),
				element: null,
				disabled: true,
			},
			transport: {
				tabLabel: t('transport'),
				element: null,
				disabled: true,
			},
		};
	}, [t]);

	const [scenarioSheetTab, setScenarioSheetTab] = useQueryParam('scenarioSheetTab', StringParam);

	useEffect(() => {
		if (!scenarioSheetTab) {
			setScenarioSheetTab(_.keys(iTabs)[0]);
		}
	}, [iTabs, scenarioSheetTab, setScenarioSheetTab]);

	const [tabIndex, setTabIndex] = useState(_.keys(iTabs).indexOf(scenarioSheetTab ?? ''));

	const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
		setTabIndex(tabIndex);
		setScenarioSheetTab(_.keys(iTabs)[tabIndex]);
	};

	return (
		<div className={cx('width100', styles.main)}>
			<div
				className={cx('width100')}
				css={{
					background: 'white',
					position: 'sticky',
					top: 0,
					zIndex: 2,
				}}
			>
				<Tabs
					value={tabIndex}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
					sx={{
						maxWidth: '80%',
						'.MuiTabs-scrollButtons.Mui-disabled': {
							opacity: '0.3',
						},
					}}
				>
					{_.entries(iTabs).map(([key, tabGroup]) => {
						return (
							<MyTab
								variant="scenario"
								group={{ name: (iTabs as any)[key]?.tabLabel }}
								key={key}
								disabled={(iTabs as any)[key]?.disabled}
							/>
						);
					})}
				</Tabs>
			</div>

			{_.entries(iTabs).map(([key, tabGroup]) => {
				return <div className={cx(scenarioSheetTab === key ? styles.block : styles.hidden)}>{tabGroup.element}</div>;
			})}
		</div>
	);
};

export default ScenarioContent;
