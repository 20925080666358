import { getCarbonFootprintByScopeAction } from '@carbonmaps/ui/actions/carbonFootprint.actions';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useMemo } from 'react';
import Card from '../../../../components/v3/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import DonutChart from '../../../packagings/components/DonutChart';
import CarbonFootprintLegend from './CarbonFootprintLegend';
import CarbonFootprintTitle from './CarbonFootprintTitle';
import CarbonFootprintTooltip from './CarbonFootprintTooltip';
import EmptyCarbonFootprint from './EmptyCarbonFootprint';

const CarbonFootprintScope = () => {
	// ---- hooks ---- //
	const { t } = useTranslation();

	// ---- fetch data ---- //
	const { data, isLoading } = useQuery({
		queryKey: ['getCarbonFootprintByScope'],
		queryFn: getCarbonFootprintByScopeAction,
	});

	const items = useMemo(() => {
		if (!data?.items?.length)
			return [
				{
					color: siteColors.grey3,
					value: 99.9,
					tonnage: 0,
				},
			];

		return data.items;
	}, [data]);

	return (
		<Card title={<CarbonFootprintTitle title={t('Par scope', { period: '' })} item={data} />} loading={isLoading}>
			{!data ? (
				<EmptyCarbonFootprint />
			) : (
				<DonutChart
					width={200}
					height={200}
					items={items}
					innerRadius={85}
					outerRadius={70}
					legend={<CarbonFootprintLegend items={items as any} />}
					withTooltip
					renderTooltip={(item, indicator) => {
						return <CarbonFootprintTooltip label={item?.label} value={item?.tonnage} />;
					}}
					data={items}
					className="flex1 justifyCenter width100 nowrap"
				/>
			)}
		</Card>
	);
};

export default CarbonFootprintScope;
