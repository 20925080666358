import Typography from '../../../../components/Typography';

import { Info } from 'lucide-react';
import LightTooltipGlossary from '../../../../components/LightTooltipGlossary';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import Help from './Help';

type CarbonFootprintTitleProps = {
	title: React.ReactNode;
	year?: number;
	item?: {
		year: number;
		emission_source?: string;
		emission_method?: string;
		tooltip?: string;
	};
	tooltip?: React.ReactNode;
	withYear?: boolean;
};

const CarbonFootprintTitle = ({ title, year, item, tooltip, withYear = true }: CarbonFootprintTitleProps) => {
	const { t } = useTranslation();
	return (
		<div className="flexRow alignCenter spaceBetween width100">
			<div className="flexRow gap8 alignCenter">
				<Typography className="grey9 fontWeight500">{title}</Typography>
				{withYear ? <Typography className="grey7 fontWeight500">{year || item?.year}</Typography> : null}
			</div>

			{item ? (
				<LightTooltipGlossary title={<Help item={item} tooltip={tooltip} />}>
					<Info size={20} color={siteColors.grey6} />
				</LightTooltipGlossary>
			) : null}
		</div>
	);
};

export default CarbonFootprintTitle;
