import { useEffect, useState } from 'react';
import CarbonFootprintForm, { type CarbonFootprint } from './CarbonFootprintForm';

import {
	getCurrentCarbonFootPrintAction,
	saveBilanFootprintAction,
} from '@carbonmaps/ui/actions/carbonFootprint.actions';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { css } from '@emotion/css';

import Skeleton from '../../../components/Skeleton';
import { useQueryParams } from '../../../hooks/useSearchParams';
import CarbonFootprintTable from './CarbonFootprintTable';

const styles = {
	container: css({
		paddingTop: 16,
	}),
	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		minHeight: '136px',
		flex: 1,
	} as any),
};

const CarbonFootPrint = () => {
	// ---- company selected ---- //
	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';

	// ---- get current carbon footprint ---- //

	const { data, isLoading } = useQuery({
		queryKey: [
			'getCurrentCarbonFootPrint',
			{
				companyId,
			},
		],
		queryFn: getCurrentCarbonFootPrintAction,
	});

	const queryClient = useQueryClient();
	// ---- mutation to save footPrint ---- //
	const { mutate: saveCarbonFootPrint, isLoading: isLoadingUpdate } = useMutation({
		mutationKey: ['saveCarbonFootPrintsaveCarbonFootPrint'],
		mutationFn: saveBilanFootprintAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getCurrentCarbonFootPrint'] });
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getCurrentCarbonFootPrint'] });
		},
	});

	const onSaveData = (data: CarbonFootprint) => {
		saveCarbonFootPrint({ ...data, companyId });
	};

	return (
		<div className={styles.container}>
			{/* {isLoading ? (
				<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
			) : (
				<CarbonFootprintForm item={data} onSubmit={onSaveData} onClose={() => {}} />
			)} */}

			<CarbonFootprintForm item={data} onSubmit={onSaveData} onClose={() => {}} />
		</div>
	);
};

export default CarbonFootPrint;
