import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import {
	getProductTableDataActionPackagingUses,
} from '@carbonmaps/ui/actions/product.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import BlockTitle from '../../../components/BlockTitle';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';
import { css, cx } from '@emotion/css';
import PackagingUsesFilter from './PackagingUsesFilter';
import PackagingUsesTable from './PackagingUsesTable';

export interface PackagingItem {
	label: string;
	countUse: number;
	carbonIntensity?: number;
	water?: number;
	carbonImpact?: number;
	percentage?: number;
	labelOrigine?: string;
	uid?: string;
}


const styles = {
	main: css({
		marginBottom: '1rem!important'
	}),
};

const PackagingUses = () => {
	const [loading] = useState(false);
	const params = useParams();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();
	const { indicator } = useApp();
	// translation
	const { t } = useTranslation();
	const packagingId = params.packagingId;

	const {
		data: products,
	} = useQuery({
		queryKey: [
			'getProductTableDataPackagingUses',
			{
				input: '',
				page: 1,
				size: 10_000,
				facetFilters: [],
				supplierIds: [],
				viewMode: indicator,
				selectedYear,
				packagingId,
			},
		],
		queryFn: getProductTableDataActionPackagingUses,
	});

	useEffect(() => {
		if (!searchQueryParams) return;
		setSearchQueryParams({
			...searchQueryParams,
			packagingId: params.packagingId,
		});
	}, [searchQueryParams, params.packagingId, setSearchQueryParams]);

	return (
		<div>
			<BlockTitle className={cx(styles.main)}>
				{t('packaging-product-uses', { products: toLowerCase(t('products')) })} (
				{formatNumber(products?.meta.count, undefined, 0)})
			</BlockTitle>
			<div className="flexColumn alignCenter" css={{}}>
				<PackagingUsesFilter packagingId={params.packagingId} />
				<PackagingUsesTable filterOptions={searchQueryParams} />
			</div>
			<LoadingDialog open={loading} content={`${t('export-loading-message')}...`} />
		</div>
	);
};

export default PackagingUses;
