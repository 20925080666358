import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { BarChart2 } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import BlockCard from '../../components/BlockCard';
import BlockTitle from '../../components/BlockTitle';
import Skeleton from '../../components/Skeleton';
import Unit from '../../components/units/Unit';
import { useTranslation } from '../../hooks/useTranslation';
import { GLOBAL_INDICATOR_CONFIG } from '../../utils/graph';
import { getGlobalIndicator } from '../../utils/simulation.utils';

import IndicatorItem from './IndicatorItem';
import { useScenario } from './scenario.hooks';
import { useDataSimulation } from './useDataSimulation.hooks';

const styles = {
	block: css({
		background: 'white',
		border: 'none!important',
	}),
	flexSpacing: css({
		gap: '10%',
		// margin: '10%',
	}),

	skeleton: css({
		height: '165px !important',
		width: '100% !important',
	} as any),
};

const IndicatorsBlock = () => {
	const params = useParams();
	const { isLoading } = useScenario({
		simulationId: params.simulationId || '',
	});

	// translation
	const { t } = useTranslation();
	const { simulation } = useDataSimulation();

	const [dataIndicator, setDataIndicator] = useState<any>(null);

	const { indicator } = useApp();
	const content = useMemo(() => {
		return (
			<>
				<div>
					<IndicatorItem
						text={t('per_kilo')}
						unit={
							<Unit
								className="grey700"
								measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
							/>
						}
						value={dataIndicator?.gesPerKilo}
						// difference={parseFloat(formatNumber(dataIndicator?.gesPerKiloDiff || 0, undefined, 2)?.replace(',', '.'))}
						difference={dataIndicator?.gesPerPortionDiff || 0}
						icon={GLOBAL_INDICATOR_CONFIG.get(indicator)?.intensityIcon}
						showZero={true}
						showLte={true}
					/>
				</div>
				<div>
					<IndicatorItem
						icon={GLOBAL_INDICATOR_CONFIG.get(indicator)?.impactPortionIcon}
						text={t('per_unit')}
						value={dataIndicator?.gesPerPortion}
						unit={
							<Unit
								className="grey700"
								measure={indicator === CARBON_INDICATOR ? 'carbonImpactPortion' : 'waterImpactPortion'}
							/>
						}
						// difference={parseFloat(
						// 	formatNumber(dataIndicator?.gesPerPortionDiff || 0, undefined, 2)?.replace(',', '.'),
						// )}
						difference={dataIndicator?.gesPerPortionDiff || 0}
						showZero={true}
						showLte={true}
					/>
				</div>
			</>
		);
	}, [dataIndicator, indicator, t]);

	// ---- trigger render when change ingredients or packaging ---- //
	useEffect(() => {
		if (!simulation) return;
		const globalIndicator = getGlobalIndicator(simulation, indicator);

		setDataIndicator(globalIndicator);
	}, [simulation, indicator]);

	return (
		<>
			<BlockTitle icon={<BarChart2 className="grey700" width={20} height={20} />}>
				{indicator === CARBON_INDICATOR ? t('Indicateurs carbone') : t('Indicateurs eau')}
			</BlockTitle>
			<BlockCard className={cx(styles.block, styles.flexSpacing, 'flexRow width100 nowrap')}>
				{isLoading ? (
					<Skeleton width="100%!important" className={styles.skeleton}>
						{content}
					</Skeleton>
				) : (
					content
				)}
			</BlockCard>
		</>
	);
};

export default IndicatorsBlock;
