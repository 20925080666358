import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getConfigFacets } from '@carbonmaps/shared/utils/utils';
import { getDataImpactByCategoryAction } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/v3/Button';
import Card from '../../../components/v3/Card';
import CategoryTable from '../../../components/v3/CategoryTable';

import DashboardSelect from '../../../components/v3/DashboardSelect';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';

const styles = {
	content: css({
		minHeight: 164,
	}),

	emptyText: css({
		color: cn(siteColors.grey700, '!important'),
		textAlign: 'center',
		width: '100%',
		height: '164px',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
	}),
};

const ImpactPerCategory = () => {
	// ---- hooks ---- //
	const { indicator } = useApp();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();
	const { t } = useTranslation();

	const navigate = useNavigate();
	// ------------------------------------ //
	// --------- state -------------------- //
	// ------------------------------------ //
	const [currentCategory, setCurrentCategory] = useState<string>();

	// ------------------------------------ //
	// ---- fetch data impact category ---- //
	// ------------------------------------ //
	// period selected
	const { selectedYear } = useYearSelection();

	const options = useMemo(() => {
		const items = getConfigFacets(authData?.session.company, false, 'Product');

		return items?.map((item: { field: string; label: string }) => {
			return {
				value: item.field,
				label: t('filter-by-category', { category: item.label }),
			};
		});
	}, [authData?.session.company, t]);

	// ---- fetch segmentation data ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getImpactProductByCategory',
			{
				period: selectedYear,
				category: currentCategory || authData?.session?.company?.category || options[0]?.value,
				viewMode: indicator,
				limit: 6,
			},
		],
		queryFn: getDataImpactByCategoryAction,
	});

	// ---- event june ---- //
	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Dashboard' },
			});
		},
		[analytics],
	);

	// ---- on goTo analyze page ---- //
	const goToAnalyzePage = useCallback((item: { category: string; label: string; objectId: string }) => {
		navigate(`${FRONT_PATH_NAMES.analyse}/${item.category}/${item.label}`);
	}, []);

	const goToAnalyzeList = useCallback(() => {
		const category = currentCategory || (authData?.session?.company?.category as string) || options[0]?.value;
		trackEvent('Dashboard Impact per ' + category + ' See All');
		navigate(`${FRONT_PATH_NAMES.analyse}/${category}`);
	}, [currentCategory]);

	// ---- current category ---- //
	useEffect(() => {
		if (!currentCategory) {
			setCurrentCategory(authData?.session?.company?.category);
		}
	}, [options, authData]);

	return (
		<Card
			title={
				<DashboardSelect
					options={options}
					onChange={(v: any) => {
						setCurrentCategory(v);
					}}
					value={currentCategory || (authData?.session?.company?.category as string) || options[0]?.value}
					className="lineHeight1"
				/>
			}
			footer={
				<Button
					label={t('analyze-category-dashboard-button', {
						category: currentCategory || (authData?.session?.company?.category as string) || options[0]?.value,
					})}
					handleClick={() => {
						goToAnalyzeList();
					}}
				/>
			}
			loading={isLoading}
		>
			<div className={cx(styles.content, 'width100')}>
				{data?.length ? <CategoryTable data={data || []} /> : null}
				{!isLoading && !data?.length ? <div className={styles.emptyText}>{t('dashboard-empty-category')}</div> : null}
			</div>
		</Card>
	);
};

export default ImpactPerCategory;
