import {
	CustomMessageFormInput,
	CustomMessageValidationSchema,
} from '@carbonmaps/shared/validations/questions.validations';
import { css, cx } from '@emotion/css';
import { IconButton } from '@mui/material';
import { ArrowLeft } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import { useTranslation } from '../../../hooks/useTranslation';

import BInput from '@carbonmaps/ui/components/saas/BInput';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { SubmitHandler, useForm, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { cn, siteColors } from '../../../lib/colors';
import { DEFAULT_CUSTOM_MESSAGE_OPTIONS } from '../../../utils/constants';
import { useQuestionFormStore } from './hooks';
import { stylesPrepareQuestion, topStyles } from './QuestionFormSuppliers';

const languagesData = _.values(DEFAULT_CUSTOM_MESSAGE_OPTIONS);


const styles = {
	main: css({
		alignItems: 'center!important',
	}),
};

type Props = {
	setScreen: (screen: number) => void;
};

const CustomMessageForm = ({ setScreen }: Props) => {
	const { customMessages, setCustomMessages } = useQuestionFormStore();


	const {
		register,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm<CustomMessageFormInput>({
		resolver: zodResolver(CustomMessageValidationSchema),
		defaultValues: async () => {
			const languageCodes = _.keys(DEFAULT_CUSTOM_MESSAGE_OPTIONS);
			let defaultMessages = languageCodes.reduce((acc, lang) => {
				acc[lang] = DEFAULT_CUSTOM_MESSAGE_OPTIONS[lang].value ?? '';
				return acc;
				// }, {} as Record<any, string | undefined>); // error
			}, {} as any);

			if (!_.isEmpty(customMessages)) {
				defaultMessages = languageCodes.reduce((acc, lang) => {
					acc[lang] = (customMessages as any)?.[lang] ?? '';
					return acc;
					// }, {} as Record<any, string | undefined>); // error
				}, {} as any);
			}
			return {
				messages: defaultMessages,
			};
		},
		mode: 'onChange',
	});

	const onSubmit: SubmitHandler<CustomMessageFormInput> = async (values: any) => {
		setCustomMessages(values?.messages);
		setScreen(0);
	};

	return (
		<div>
			<TopBar setScreen={setScreen} handleSubmit={handleSubmit} onSubmit={onSubmit} />
			<div className={cx('flexColumn', stylesPrepareQuestion.main, styles.main)}>
				<MessageForm register={register} errors={errors} />
			</div>
		</div>
	);
};

export default CustomMessageForm;

const TopBar = ({
	setScreen,
	handleSubmit,
	onSubmit,
}: {
	setScreen: (screen: number) => void;
	handleSubmit: UseFormHandleSubmit<CustomMessageFormInput>;
	onSubmit: SubmitHandler<CustomMessageFormInput>;
}) => {
	const { t } = useTranslation();
	return (
		<div className={cx('flexRow alignCenter', topStyles.main)}>
			<div className={cx('flexRow alignCenter', topStyles.leftGroup)}>
				<div
					css={{
						marginRight: 21,
					}}
				>
					<IconButton
						disableRipple
						onClick={() => {
							setScreen(0);
						}}
					>
						<ArrowLeft color={siteColors.grey700} size={20} />
					</IconButton>
				</div>
				<div className={cx('', topStyles.text1)}>{t('Personnaliser le message')}</div>
			</div>

			<div className={cx('flexRow alignCenter')}>
				<div className="flexRow gap16">
					<BButton label={t('Enregistrer')} variant="primary" onClick={handleSubmit(onSubmit)} />
				</div>
			</div>
		</div>
	);
};

const noteStyles = {
	main: css({
		display: 'flex',
		width: '701px',
		padding: '48px 32px',
		alignItems: 'center !important',
		gap: '32px',
		flexDirection: 'column',
	}),
	item: css({
		gap: '16px',
		'.MuiInputBase-root': {
			width: '605px',
			minHeight: '165px',
			padding: '24px 16px',
			borderRadius: '8px',
		},

		'.MuiOutlinedInput-notchedOutline': {
			borderColor: cn(siteColors.grey500, '!important'),
			borderRadius: '8px',
		},
	}),
};

const MessageForm = ({ register, errors }: { register: UseFormRegister<CustomMessageFormInput>; errors: any }) => {
	return (
		<div className={cx(noteStyles.main)}>
			{languagesData.map(({ label, lang }) => (
				<Item key={lang} label={label} lang={lang} register={register} errors={errors} />
			))}
		</div>
	);
};

const Item = ({
	label,
	lang,
	register,
	errors,
}: {
	label: string;
	lang: string;
	register: UseFormRegister<CustomMessageFormInput>;
	errors: any;
}) => {
	return (
		<div className={cx('flexColumn', noteStyles.item)}>
			<BInput
				id={lang}
				label={label}
				fullWidth
				margin="dense"
				{...register(`messages.${lang}` as never)}
				error={errors?.messages?.[lang]?.message ? true : false}
				errorMessage={errors?.messages?.[lang]?.message}
				autoComplete="off"
				isTextarea={true}
				addStyles={{
					width: '605px',
				}}
				rows={9}
			/>
		</div>
	);
};
