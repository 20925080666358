import { Suspense } from 'react';

import { HelmetProvider } from 'react-helmet-async';

import { AppProvider } from '@carbonmaps/ui/contexts/AppProvider';

import { version } from '../../../package.json';

import Version from './components/Version';
import QueryClientProvider from './providers/QueryClientProvider';
import ThemeProvider from './providers/ThemeProvider';
import Routes from './routes/Routes';

const App = () => {
	return (
		<QueryClientProvider>
			<ThemeProvider>
				<HelmetProvider>
					<Version version={version} />
					<Suspense fallback={<div></div>}>
						<AppProvider>
							<Routes />
						</AppProvider>
					</Suspense>
				</HelmetProvider>
			</ThemeProvider>
		</QueryClientProvider>
	);
};

export default App;
