import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';
import { HelpCircle } from 'lucide-react';

import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import { siteColors } from '../../../lib/colors';

type SoldItemsPopoverProps = {
	value?: number;
	color?: string;
	sx?: SxProps;
};

const styles = {
	content: css({
		padding: 24,
		gap: 12,
	}),
};

const SoldItemsPopover = ({ value, color, sx = {} }: SoldItemsPopoverProps) => {
	const theme = useTheme();
	return (
		<div>
			<BPopover
				anchorOriginHorizontal={'right'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'right'}
				anchorOriginVertical={'bottom'}
				modeHover
				content={(onClose) => {
					return (
						<Box className={cx('flexColumn', styles.content)}>
							<Typography className="fontWeight600">
								{' '}
								{value && value < 0 ? 'Volume incorrect' : 'Volume non renseigné'}
							</Typography>
						</Box>
					);
				}}
				addStyles={
					{
						minWidth: '30px',
						...sx,
					} as any
				}
			>
				<HelpCircle color={color || siteColors.carbon500} />
			</BPopover>
		</div>
	);
};

export default SoldItemsPopover;
