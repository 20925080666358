import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { classNames } from '@carbonmaps/shared/utils/constants';

import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';

import { cn, siteColors } from '../../../lib/colors';
import CreateButton from './CreateButton';
import DevisTable from './DevisTable';
import QuoteGraph from './QuoteGraph';
import QuoteSearch from './QuoteSearch';

const styles = {
	btn1: css({
		borderRadius: '8px!important',
		padding: '8px 16px!important',
		'&:hover': {
			backgroundColor: cn(siteColors.primary, '!important'),
		},
	}),
	a: css({
		marginBottom: '24px',
	}),

	graphContainer: css({
		marginBottom: '16px',
	}),
};

const DevisList = () => {
	const { t } = useTranslation();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();

	const params = useParams();
	const reportClientId = params.reportClientId!;
	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);

	const { data: clientData, refetch } = useQuery({
		queryKey: ['getClientSheetById', { reportClientId }] as const,
		queryFn: async (ctx) => {
			const {
				queryKey: {
					1: { reportClientId },
				},
			} = ctx;

			const reportClient = await new Parse.Query(classNames.CACHE_REPORT_CLIENT).equalTo('objectId', reportClientId).select('uidClient').first();
			const client = await new Parse.Query(classNames.CLIENT).equalTo('name', reportClient?.get('uidClient')).first();
			return client?.toJSON();
		},
	});

	return (
		<div>
			<div className={cx('flexRow justifyBetween w100 alignCenter', styles.a)}>
				<QuoteSearch />
				<div className="flexRow gap16">
					<CreateButton setTableParams={setTableParams} paramsTable={paramsTable} initialSelectedOption={clientData} />
				</div>
			</div>

			<div className={cx('', styles.graphContainer)}>
				<QuoteGraph />
			</div>

			<div>
				<DevisTable />
			</div>
		</div>
	);
};

export default DevisList;

