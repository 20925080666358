import { css } from '@emotion/css';
import { useTheme } from '@mui/material';
import { Compass } from 'lucide-react';

import IconContainer from '../../../../components/layout/list/IconContainer';
import ListHeader from '../../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../../hooks/useTranslation';

type AuditHeaderProps = {
	subtitle?: string;
};

const FieldHeader = ({ subtitle }: AuditHeaderProps) => {
	const theme = useTheme();
	// translation
	const { t } = useTranslation();
	return (
		<ListHeader
			title={t('facet-add-field-title')}
			subtitle={subtitle}
			icon={
				<IconContainer
					element={<Compass size={40} color={theme.palette.common.white} />}
					color={theme.palette.grey[900]}
				/>
			}
		// className="bgGrey500"
		/>
	);
};

export default FieldHeader;
