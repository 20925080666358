import { z } from 'zod';

const emailFieldSchema = z
	.string({ required_error: 'Adresse email obligatoire' })
	.email({ message: 'Entrez une adresse e-mail valide' })
	.max(120, 'Email doit avoir 120 caractères au maximum');
// .refine(value => value.toLowerCase());

const passwordFieldSchema = z
	.string({ required_error: 'Mot de passe obligatoire' })
	.min(8, 'Mot de passe doit comprendre au moins 8 caractères')
	.max(64, 'Mot de passe doit comprendre au plus 64 caractères')
	.regex(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, 'Minimum 8 caractères et un caractère spécial');

export const logInSchema = z.object({
	email: emailFieldSchema,
	password: passwordFieldSchema,
});

export const resetPasswordSchema = z
	.object({
		password: passwordFieldSchema,
		confirmPassword: z.string(),
		languageCode: z.string().default('fr'),
	})
	.refine(
		(data) => {
			return data.confirmPassword === data.password;
		},
		{
			message: "Le mot de passe n'est pas identique",
			path: ['confirmPassword'],
		},
	);

export const sendEmailUpdateEmailSchema = z.object({
	newEmail: emailFieldSchema,
});

export type LogInInput = z.infer<typeof logInSchema>;
export type ResetPasswordInput = z.infer<typeof resetPasswordSchema>;
export type SendUpdateEmailFormInput = z.infer<typeof sendEmailUpdateEmailSchema>;
