import { slugify } from "@carbonmaps/ui/utils/utils";
import { siteColors } from "../../../apps/front/src/lib/colors";
import { ICompany } from "../types/company.types";
import { FRONT_PATH_NAMES } from "./constants";

// ---- STATUS CODE SIMULATION & QUOTE ---- //
export const STATUS_ARCHIVE = 'ARCHIVE';
export const STATUS_DENIED = 'DENIED';
export const STATUS_VALID = 'VALID';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const TO_ECO_DESIGN = 'TO_ECO_DESIGN'; // SIMULATION ONLY

// STATUS CODE QUOTE ONLY
export const TO_COMPLETE = 'TO_COMPLETE';

export const STATUS_SIMULATION = {
	[STATUS_ARCHIVE]: {
		code: STATUS_ARCHIVE,
		label: 'Archivé',
		color: '#F4FAFF',
	},
	[STATUS_DENIED]: {
		code: STATUS_DENIED,
		label: 'Refusé',
		color: '#FEF7F1',
	},
	[STATUS_VALID]: {
		code: STATUS_VALID,
		label: 'Validé',
		color: '#F5F9EC',
	},
	[STATUS_IN_PROGRESS]: {
		code: STATUS_IN_PROGRESS,
		label: 'En cours',
		color: siteColors.grey200,
	},
	[TO_ECO_DESIGN]: {
		code: TO_ECO_DESIGN,
		label: 'eco-design-label',
		color: siteColors.grey300,
	},
} as any;

export const STATUS_QUOTE = {
	// [STATUS_ARCHIVE]: {
	// 	code: STATUS_ARCHIVE,
	// 	label: 'Archivé',
	// 	color: '#F4FAFF',
	// },
	[STATUS_DENIED]: {
		code: STATUS_DENIED,
		label: 'Refusé',
		color: '#FEF7F1',
	},
	[STATUS_VALID]: {
		code: STATUS_VALID,
		label: 'Validé',
		color: '#F5F9EC',
	},
	[STATUS_IN_PROGRESS]: {
		code: STATUS_IN_PROGRESS,
		label: 'En cours',
		color: siteColors.grey200,
	},
	[TO_COMPLETE]: {
		code: TO_COMPLETE,
		label: 'to-complete',
		color: siteColors.grey300,
	},
};

export const formatSlugUrlSalesQuote = ({ company, client, json }: { json?: boolean, company?: any, client?: any }) => {
	if (json) {
		return (company?.id ?? company?.objectId) + '$' + slugify(client?.name);
	}
	if (client && company) {
		return company?.id + '$' + slugify(client?.get('name'));
	}
	return '';
}
