import { useCallback, useEffect, useState } from 'react';

import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { FileUp, MoreVertical, Trash, Undo2 } from 'lucide-react';
import { useToggle } from 'react-use';

import { ReactComponent as BlueCheckOK } from '@carbonmaps/media/icons/bluecheckok.svg';
import { EXPORT_TYPE, ITEM_TYPE_INGREDIENT } from '@carbonmaps/shared/utils/constants';
import { getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getCategoriesIngredient, getIngredientCount } from '@carbonmaps/ui/actions/ingredient.actions';
import { getCompanySuppliers } from '@carbonmaps/ui/actions/supplier.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BDropdown from '@carbonmaps/ui/components/saas/BDropdown';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';
import { generateUniqUID, toLowerCase } from '@carbonmaps/ui/utils/utils';

import ActionsBar from '../../components/ActionsBar';
import Filter, { IFilterFolder } from '../../components/Filter';
import SearchAutocomplete from '../../components/SearchAutocompleteV2';
import ExportDialog, { ExportDialogProps } from '../../components/dialogs/ExportDialog';
import LoadingDialog from '../../components/dialogs/LoadingDialog';
import { Option } from '../../components/form/fields/MultiSelectCheckboxField';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useSkeleton } from '../../hooks/useSkeleton';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { exportIngredientsAction } from '../../lib/react-query/features/export/ingredient.actions';
import { useFindIngredientFamilyTradeoff } from '../../lib/react-query/features/ingredient/ingredient.hooks';
import { getLabelNodeFacet, SUPPLIER_LABEL_FOLDER } from '../../utils/utils';

const suppliersFolderLabel = SUPPLIER_LABEL_FOLDER;

const styles = {
	width300: css({
		width: 300,
	}),
};

const IngredientFilter = ({ withMore = true }: { withMore?: boolean }) => {
	const theme = useTheme();

	// translation
	const { t } = useTranslation();

	//--------------------------------------------------------------------------------------//
	//                                        State                                         //
	//--------------------------------------------------------------------------------------//
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);

	const [isClearInputValue, setIsClearInputValue] = useState(false);
	const [isExportDialogOpen, toggleExportDialog] = useToggle(false);

	const [isExportLoading, setExportLoading] = useState(false);
	const [exportKey, setExportKey] = useState<string | undefined>(undefined);

	const analytics = useJuneTrack();
	const { selectedYear } = useYearSelection();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// ---- fetch count product ---- //
	// get count product for a company. this count will appear in header list
	// const { data: exportCount } = useQuery({
	// 	queryKey: ['getIngredientCompanyCount'],
	// 	queryFn: getIngredientCount,
	// });

	// const { data: exportCount } = useQuery({
	// 	queryKey: ['getIngredientCount', { period: selectedYear }],
	// 	queryFn: getIngredientCount,
	// });
	const { indicator } = useApp();
	const [filterOptions] = useSearchQueryParams();

	const {
		result: { data: dataTradeoff, isLoading },
	} = useFindIngredientFamilyTradeoff({
		params: {
			input: filterOptions.input,
			facetFilters: filterOptions.facetFilters,
			categoryFilters: filterOptions.categoryFilters,
			supplierIds: filterOptions.supplierIds,
			viewMode: indicator,
		},
	});

	const exportCount = dataTradeoff?.length ?? 0;

	const {
		data: facets,
		isSuccess: isSuccessFetchFacets,
		isLoading: isFetchFacetsLoading,
	} = useQuery({
		queryKey: [
			'getCategoryIngredient',
			{
				input: searchQueryParams?.input || '',
				categoryFilters: searchQueryParams?.categoryFilters || null,

				facetFilters: searchQueryParams?.facetFilters || [],
			} as any,
		],
		queryFn: getCategoriesIngredient,
	});

	const { data: suppliersData, isLoading: isSuppliersLoading } = useQuery({
		queryKey: ['getAllSuppliersForCompany', { itemType: ITEM_TYPE_INGREDIENT, isFilter: true }],
		queryFn: getCompanySuppliers,
	});

	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string) => {
			if (!analytics) return;
			analytics.track(
				eventName,
				{},
				{
					context: { category: 'Explore' },
				},
			);
		},
		[analytics],
	);

	const trackFilterEvent = useCallback(() => {
		trackEvent('Explore Ingredients Filter');
	}, [trackEvent]);

	useEffect(() => {
		if (!suppliersData) return;

		const suppliersFolder: IFilterFolder = {
			label: suppliersFolderLabel,
			isFacet: false,
			showCount: false,
			options: suppliersData.map((sup) => {
				return {
					label: sup.name,
					value: sup.id,
					folderLabel: suppliersFolderLabel,
				};
			}),
		};

		setFilterFolders((folders) => {
			const supplierFolderIndex = folders.findIndex((e) => {
				return e.label === suppliersFolderLabel;
			});

			if (supplierFolderIndex === -1) {
				const n2FolderIndex = folders.findIndex((e) => {
					if (e.isFacet) {
						return e.path === 'tagAdvancedModelization';
					}

					return false;
				});

				const n2Folder = folders[n2FolderIndex];
				return [...(n2Folder ? [n2Folder] : []), suppliersFolder, ...folders];
			}

			folders[supplierFolderIndex] = suppliersFolder;
			return folders;
		});
	}, [suppliersData]);

	useEffect(() => {
		// don't change current filter
		if (!facets) return;

		let N2FilterFolder: IFilterFolder;
		const facetFilterFolders: IFilterFolder[] = [];

		Object.entries(facets.facet ?? {}).forEach(([key, value]: [any, any]) => {
			const { label, type, global } = getFacetConfig(key, authData?.session.company, 'Ingredient') ?? {};
			const facetLabel = label || getFacetLabel(key, authData?.session.company);

			const options: Option[] = value?.buckets?.map((bucket: any) => {
				return {
					value: bucket._id,
					label: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
						? getLabelValue(key, String(bucket._id))
						: String(bucket._id),
					labelNode: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
						? getLabelNodeFacet(key, bucket._id, t)
						: undefined,
					count: bucket.count,
					folderLabel: facetLabel,
					isFacetBucket: true,
					facetPath: key,
					type: type ?? 'hardcoded-undefined-facet-type',
					global,
					itemType: ITEM_TYPE_INGREDIENT,
				};
			});

			const formedFacetFolder = {
				label: facetLabel,
				options,
				isFacet: true,
				path: key,
				global,
				customIcon: key === 'tagAdvanced' ? <BlueCheckOK css={{ 'path:first-of-type': { fill: 'grey' } }} /> : null,
				showCount: false,
			};

			// return ;
			if (key === 'tagAdvanced') {
				N2FilterFolder = formedFacetFolder;
			} else {
				facetFilterFolders.push(formedFacetFolder);
			}
		});

		setFilterFolders((folders) => {
			const notFacetFolders = folders.filter((e) => {
				return !e.isFacet;
			});

			return [...(N2FilterFolder ? [N2FilterFolder] : []), ...notFacetFolders, ...facetFilterFolders];
		});
	}, [isSuccessFetchFacets, facets]);

	useEffect(() => {
		const searchParams = searchQueryParams?.facetFilters ?? [];
		const supplierIdsParams = searchQueryParams?.supplierIds ?? [];
		const currentSelectedValues = [] as any;
		const currentAppliedValuesFolders = [] as any;

		//	if there are filters in the url
		if ((searchParams && searchParams.length) || (supplierIdsParams && supplierIdsParams.length)) {
			if (searchParams && searchParams.length) {
				searchParams
					.filter((e: any) => {
						return e.itemType === ITEM_TYPE_INGREDIENT;
					})
					.forEach((item: any) => {
						const arrayValues = item.values.map((v: any) => {
							const value = {
								facetPath: item.path,
								value: v,
								folderLabel: item.folderLabel ?? item.path,
								label: getLabelValue(item.path, v),
								isFacetBucket: true,
								type: typeof v,
								global: item.global === true ? true : false,
								itemType: ITEM_TYPE_INGREDIENT,
							};

							currentSelectedValues.push(value);
							return value;
						});

						currentAppliedValuesFolders.push({
							isFacet: true,
							label: item.folderLabel ?? item.path,
							options: arrayValues,
						});
					});
			}

			//for supplier
			if (supplierIdsParams && supplierIdsParams.length) {
				if (!suppliersData) return;
				const values = suppliersData
					.filter((item: any) => {
						return supplierIdsParams.includes(item.id);
					})
					.map((sup) => {
						return {
							label: sup.name,
							value: sup.id,
							folderLabel: suppliersFolderLabel,
						};
					});
				const suppliersFolder: IFilterFolder = {
					label: suppliersFolderLabel,
					isFacet: false,
					showCount: false,
					options: values,
				};
				currentAppliedValuesFolders.push(suppliersFolder); // each one filter
				currentSelectedValues.push(...values); // in the base filter
			}

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchQueryParams, suppliersData]);

	// ---- define loading skeleton page ---- //
	const { skeleton } = useSkeleton({
		condition: isFetchFacetsLoading || isSuppliersLoading,
	});

	// ---- select rows selected in store ---- //
	const { selectedRows } = useRowTableSelection();

	const {
		result: { data },
	} = useGetClientAuthQuery();

	const handleExport: ExportDialogProps['handleExport'] = useCallback(
		async ({ exportFormat }) => {
			trackEvent('Explore Ingredients Export');
			setExportLoading(true);
			const exportKey = generateUniqUID();
			setExportKey(exportKey);
			await exportIngredientsAction({
				queryKey: [
					'exportIngredients',
					{
						format: exportFormat,
						facetFilters: searchQueryParams.facetFilters,
						input: searchQueryParams.input,
						supplierIds: searchQueryParams?.supplierIds ?? [],
						ids: selectedRows.map((r: any) => {
							// return r.original.codeIngCmaps;
							return r.original.codeCmaps;
						}),
						t,
						selectedYear,
						companyName: data?.session?.company?.name || '___',
						exportKey,
					},
				],
				meta: undefined,
			});
			setExportLoading(false);
		},
		[searchQueryParams, selectedRows, trackEvent, selectedYear, data?.session],
	);

	return (
		<ActionsBar
			onLeft={
				<div className="flexRow alignCenter" css={{ gap: '.5rem' }} /* bgcolor="red" */>
					<SearchAutocomplete
						skeleton={skeleton}
						isLoading={false}
						size={20}
						setParamsAutocomplete={setSearchQueryParams && _.debounce(setSearchQueryParams, 500)}
						placeholder={t('search_for_ingredient_placeholder', { ingredient: toLowerCase(t('ingredient')) })}
						options={[]}
						isClearInputValue={isClearInputValue}
						setIsClearInputValue={setIsClearInputValue}
						trackName="Explore Ingredients Search"
						trackCategory="Explore"
					/>
					<Filter
						skeleton={skeleton}
						isLoading={false}
						appliedValuesByFolders={appliedValuesByFolders}
						setAppliedValuesByFolders={setAppliedValuesByFolders}
						selectedValues={selectedValues}
						setSelectedValues={setSelectedValues}
						folders={filterFolders}
						onTriggerFilter={({ allFoldersSelectedValues }) => {
							const facetBuckets = allFoldersSelectedValues.filter((e) => {
								return e.isFacetBucket;
							});
							// * for supplier filters
							const supplierIds = allFoldersSelectedValues
								.filter((e) => {
									return !e.isFacetBucket && e.folderLabel === suppliersFolderLabel;
								})
								.map((e) => {
									return e.value;
								}) as string[];

							setSearchQueryParams((oldValue: any) => {
								const newFacetFiltersMap = new Map<string, /* IFacetFilter */ any>();
								facetBuckets.forEach((e) => {
									if (!e.isFacetBucket) return;
									const foundFacetFilter = newFacetFiltersMap.get(e.facetPath);

									if (!foundFacetFilter) {
										newFacetFiltersMap.set(e.facetPath, {
											path: e.facetPath,
											type: e.type,
											values: [e.value],
											global: e.global,
											itemType: e.itemType,
											folderLabel: e.folderLabel,
										});
										return;
									}

									foundFacetFilter.values.push(e.value);
								});
								return {
									...oldValue,
									supplierIds,
									facetFilters: [...newFacetFiltersMap.values()],
								};
							});
						}}
						onClick={trackFilterEvent}
					/>
				</div>
			}
			onRight={
				<>
					{((appliedValuesByFolders.length > 0 && selectedValues.length > 0) || searchQueryParams.input !== '') && (
						<BButton
							onClick={() => {
								setSelectedValues([]);
								setAppliedValuesByFolders([]);
								setSearchQueryParams({
									...searchQueryParams,
									supplierIds: [],
									facetFilters: [],
									input: '',
								});
								setIsClearInputValue(true);
							}}
							iconLeft={<Undo2 color={theme.palette.warning.main} />}
							label={t('reset-filter-button')}
							variant="tertiary"
							addStyles={{
								color: theme.palette.warning.main,
								background: 'none',
								fontSize: '14px',
								fontWeight: 600,
							}}
						/>
					)}
					{withMore ? (
						<BDropdown
							menus={[
								{
									title: t('delete-button'),
									value: '',
									icon: <Trash color={theme.palette.grey[700]} />,
									customComponent: 'Filtrer',
								},
							]}
							renderItem={(menu) => {
								return (
									<div className={styles.width300}>
										<BButton
											onClick={toggleExportDialog}
											iconLeft={<FileUp color={theme.palette.grey[700]} />}
											label={t('export-row-title')}
											variant="tertiary"
											addStyles={{
												gap: 8,
												fontSize: '14px',
												fontWeight: 600,
												cursor: 'pointer',
												padding: '8px 16px 8px 16px',
												background: 'transparent!important',
												color: siteColors.primary,
												paddingInline: 0,
											}}
										/>

										{/* <Divider />
									<DeleteConfirm
										key={String(menu)}
										onClick={() => {
											console.log('====================================');
											console.log('delete stuff');
											console.log('====================================');
										}}
										title={`Suppression de(s) ${selectedRows?.length ? selectedRows?.length : ''} produit(s)`}
									>
										<MenuItem sx={{ width: 300, paddingLeft: '9px' }} key={menu.title}>
											{menu.icon}{' '}
											<Typography sx={{ paddingLeft: '9px', textTransform: 'none' }}>{menu.title}</Typography>
										</MenuItem>
									</DeleteConfirm> */}

										<ExportDialog
											title={`${t('export-button')} ${selectedRows?.length ? selectedRows?.length : exportCount}  ${t(
												'export-ingredient-description',
												{ ingredient: toLowerCase(t('ingredient')) },
											)}`}
											open={isExportDialogOpen}
											toggle={toggleExportDialog}
											isLoading={false}
											handleExport={handleExport}
										/>
										<LoadingDialog
											open={isExportLoading}
											content={`${t('Export en cours')}...`}
											more={t('Veuillez patienter, le chargement peut prendre quelques minutes')}
											exportKey={exportKey}
											type={EXPORT_TYPE.ingredient}
										/>
									</div>
								);
							}}
						>
							<BButton
								label={<MoreVertical />}
								variant="tertiary"
								addStyles={{
									borderRadius: '8px',
									background: 'none',
									color: siteColors.text,
									padding: '8px 16px 8px 16px',
								}}
							/>
						</BDropdown>
					) : null}
				</>
			}
		/>
	);
};

export default IngredientFilter;
