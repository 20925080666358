import useTheme from '@mui/material/styles/useTheme';
import { FileEdit } from 'lucide-react';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
type EcoConceptionProps = {
	title?: string;
	subtitle?: string;
};

const default_title = 'Éco-conception';

const EcoConception = ({ title = default_title, subtitle }: EcoConceptionProps) => {
	const theme = useTheme();
	// translation
	const { t } = useTranslation();
	return (
		<ListHeader
			title={t(title)}
			isBeta={false}
			subtitle={subtitle}
			icon={
				<IconContainer element={<FileEdit size={32} color={theme.palette.common.white} />} color={siteColors.teal500} />
			}
			// className="bgSimulation"
		/>
	);
};

export default EcoConception;
