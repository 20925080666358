import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import _ from 'lodash';

import { COLOR_SCORING } from '@carbonmaps/shared/utils/scoring';

import CardContent from '../../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../../components/dashboard/v2/CardTitle';
import Skeleton from '../../../../../components/Skeleton';
import BarItem from '../../../../../components/supplier/BarItem';
import CardSupplier from '../../../../../components/supplier/CardSupplier';
import LabelAxis from '../../../../../components/supplier/LabelAxis';
import { useTranslation } from '../../../../../hooks/useTranslation';
import {
	useFindGeneralRSESuppliersRepartitionChart,
	useFindQuestionFormsTabs,
	useRseQFormTabQueryParam,
} from '../hooks/rse.hooks';

const styles = {
	supplierIcon: css({
		path: {
			fill: '#5A719D',
		},
	}),
	content: css({
		minHeight: 321,
	}),
	container: css({
		height: 'var(--height)',
		gap: 'var(--gap)',
	}),

	highLightColor: css({
		background: 'var(--backgroundColor) !important',
	}),
};

const Supplier = ({
	isLoading: _isLoadingProp = false,
	fullWidth = false,
	highlightNote,
}: {
	isLoading?: boolean;
	fullWidth?: boolean;
	highlightNote?: string;
}) => {
	const { t } = useTranslation();
	const [questionFormId] = useRseQFormTabQueryParam();

	// ---- fetch repartition data ---- //
	const {
		result: { data, isLoading },
	} = useFindGeneralRSESuppliersRepartitionChart({
		questionFormId: _.toString(questionFormId),
	});

	// const { data: tabsData, isLoading: isLoadingTabsData } = useFindQuestionFormsTabs();

	const barNumber = data?.length || 1;
	const gap = 12;

	// return null;
	// const customOrder = ['A+', 'A', 'B', 'C', 'D', 'E'];
	// const orderedData = _.sortBy(data, [(item) => customOrder.indexOf(item.label)], ['asc']);

	const orderedData = data;
	const maxPercent = useMemo(() => {
		if (!data) return 0;
		// eslint-disable-next-line no-unsafe-optional-chaining
		const maxValuePercent = Math.max(
			// eslint-disable-next-line no-unsafe-optional-chaining
			...data?.map((item: any) => {
				return item?.percentValue || 0;
			}),
			0,
		);
		return maxValuePercent;
	}, [data]);

	// console.log('***************', orderedData);

	const content = (
		<CardSupplier
			title={<CardTitle title={t('repartition-supplier-card-title')} />}
			className={cx('flex1', fullWidth ? 'width100' : '')}
		>
			<CardContent className={cx(styles.content, 'flex1')} loading={isLoading || _isLoadingProp}>
				<div className="width100 flexColumn gap16 height100">
					<div
						className={cx('width100 flexRow flex1 nowrap flexEnd', styles.container)}
						style={{ ['--gap' as any]: `${gap}px` }}
					>
						{orderedData?.map((item: any, index: number) => {
							return (
								<BarItem
									key={index}
									item={item}
									width={`calc(${100 / barNumber}% - ${gap}px)`}
									color={(COLOR_SCORING as any)[item?.label]}
									withLabel={true}
									maxPercent={maxPercent}
									carbonBarClassName={_.toLower(item?.label) === _.toLower(highlightNote) ? styles.highLightColor : ''}
									// highLighted={item?.label === highlightNote}
								/>
							);
						})}
					</div>
					{/* <LabelAxis data={orderedData} gap={gap} /> */}
				</div>
			</CardContent>
		</CardSupplier>
	);

	// if (isLoading) {
	// 	return <Skeleton sx={{ flex: 1 }}>{content}</Skeleton>;
	// }

	return content;
};

export default Supplier;
