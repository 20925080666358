import React from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

const styles = {
	container: css({ gap: 6, paddingTop: '16px', paddingBottom: '16px' }),
	label: css({
		textTransform: 'uppercase',
		fontWeight: '400 !important',
		fontSize: '14px !important',
		lineHeight: '1.2 !important',
		overflow: 'hidden !important',
		textOverflow: 'ellipsis !important',
		whiteSpace: 'nowrap !important',
		maxWidth: 195,
	} as any),

	subtitle: css({
		overflow: 'hidden !important',
		textOverflow: 'ellipsis !important',
		maxWidth: 195,
	} as any),
	link: css({
		textDecoration: 'none',
	}),

	icon: css({
		borderRadius: '4px',
		padding: '4px',
	}),
};

const TextAuditDisplay = (props: any) => {
	const {
		row,
		column: { props: addProps },
	} = props;

	const labelKey = addProps?.labelKey || 'labelProduct';
	const uidKey = addProps?.uidKey || 'uidProduct';
	const label = row?.original[labelKey];
	const uid = row?.original[uidKey];

	const ComponentLink = addProps?.withLink ? Link : React.Fragment;
	return (
		<ComponentLink
			to={
				addProps?.withLink ? `${FRONT_PATH_NAMES.products.details}/${row.original.productId || row.original._id}` : '#'
			}
			className={styles.link}
			target={'_blank'}
		>
			<div className={cx('flexRow nowrap justifyStart alignCenter', styles.container)}>
				{addProps.icon ? <div className={cx('flexCenter bgGrey300', styles.icon)}>{addProps.icon}</div> : null}
				<div>
					<Typography className={styles.label} variant="h2">
						{label || '???'}
					</Typography>
					<Typography variant="h5" className={cx('grey700', styles.subtitle)}>
						{uid || '???'}
					</Typography>
				</div>
			</div>
		</ComponentLink>
	);
};

export default TextAuditDisplay;
