import { Fragment, ReactNode, useEffect } from 'react';

import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Company from './company/Company';
import Facets from './facets/Facets';
import Fields from './facets/fields/Fields';
import Glossary from './glossary/Glossary';
import Module from './module/Module';
import PreferredSurvey from './preferredSurvey/PreferredSurvey';
import Translations from './translations/Translations';
import Users from './users/Users';
import Version from './version/Version';

type ITab = {
	title: string;
	element: ReactNode;
};

const tabs: Record<string, ITab> = {
	companies: {
		title: 'Entreprises',
		element: <Company />,
	},
	users: {
		title: 'Utilisateurs',
		element: <Users />,
	},
	facets: {
		title: 'Facets',
		element: <Facets />,
	},

	version: {
		title: 'Version',
		element: <Version />,
	},

	'facets-fields': {
		title: 'Fields',
		element: <Fields />,
	},

	module: {
		title: 'Module',
		element: <Module />,
	},

	translation: {
		title: 'Translation',
		element: <Translations />,
	},

	glossary: {
		title: 'Glossaire',
		element: <Glossary />,
	},

	// 'preferred-survey': {
	// 	title: 'Questionnaire préféré',
	// 	element: <PreferredSurvey />,
	// },
};

const SuperAdminSettings = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		if (!params.tab || !_.has(tabs, params.tab)) {
			navigate(`${FRONT_PATH_NAMES.superAdminSettings}/${Object.keys(tabs)[0]}`, { replace: true });
			return;
		}
	}, [navigate, params.tab, setBreadcrumbs]);

	return (
		<div>
			{Object.entries(tabs).map(([tabName, value], index) => {
				return tabName === params.tab && <Fragment key={index}>{value.element}</Fragment>;
			})}
		</div>
	);
};

export default SuperAdminSettings;
