import { css, cx } from '@emotion/css';
import DashboardLifeCycleGraph from '../DashboardLifeCycleGraph';
import Temporality from '../v2/Temporality';
import ImpactPerCategory from './ImpactPerCategory';
import SupplierImpact from './SupplierImpact';

const styles = {
	container: css({
		maxWidth: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridColumnGap: 32,
		gridRowGap: 32,
		paddingBottom: 20,
	}),
};
const AnalyzeTab = () => {
	return (
		<div className={cx(styles.container, 'width100')}>
			<DashboardLifeCycleGraph iconSize={24} />
			<Temporality />
			<ImpactPerCategory />
			<SupplierImpact />
		</div>
	);
};

export default AnalyzeTab;
