import { useTranslation } from '../../../../../hooks/useTranslation';
import ImpactKpi from '../../../components/ImpactKpi';
import TonnageKpi from '../../../components/TonnageKpi';
import { useIndicatorGlobal } from '../../../packagings.hooks';
import PackagingCountCard from './PackagingCountCard';

const KpiWidget = () => {
	const { data, isLoading } = useIndicatorGlobal({});
	const { t } = useTranslation();

	return (
		<div className="flexRow  width100 stretch gap16">
			<ImpactKpi data={data} isLoading={isLoading} tooltip={t('tooltip-impact-kpi')} />
			<TonnageKpi tonnage={data?.tonnage} isLoading={isLoading} />
			<PackagingCountCard />
		</div>
	);
};

export default KpiWidget;
