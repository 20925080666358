import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';

import { getObjScoring } from '@carbonmaps/shared/utils/scoring';
import _ from 'lodash';
import SupplierScore from '../../../../../apps/front/src/components/supplier/SupplierScore';
const styles = {
	container: css({
		padding: '8px 10px 8px 10px',
		borderRadius: '24px',
		width: '42px',
	}),
	text: css({
		color: '#FFF !important',
		fontSize: '15px !important',
		fontWeight: 600,
		position: 'relative',
		left: -4,
	}),
};

const Scoring = (props: any) => {
	const { sx = {}, row } = props;

	const label = _.get(row, 'original.iNote') || '-';

	return (
		<div className="width100 height100 flexCenter">
			<SupplierScore score={label} withInfoIcon iconSize={16} />
		</div>
	);
};

export default Scoring;
