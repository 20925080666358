import { useMemo, useState } from 'react';

import useTheme from '@mui/material/styles/useTheme';
import ReactECharts from 'echarts-for-react';
import { PieChart, type PieSeriesOption } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { siteColors } from '../../../lib/colors';

echarts.use([PieChart, CanvasRenderer, LabelLayout]);

type EChartsOption = echarts.ComposeOption</* TooltipComponentOption | */ PieSeriesOption>;
type Data = NonNullable<PieSeriesOption['data']>;

type Props = {
	data: {
		value: number;
		name: string;
		isBlueCheck?: boolean;
	}[];
	data2: {
		value: number;
		name: string;
		isBlueCheck?: boolean;
		noCenterValue?: boolean;
	};
	indicator: string;
};

const DonutProductChart = ({ data, data2, indicator }: Props) => {
	// const myRef = useRef<HTMLElement>(null);

	const theme = useTheme();
	const _data: Data = data
		.sort((a, b) => {
			return b.value - a.value;
		})
		.map((e, index) => {
			if (index === 0) {
				return {
					...e,
					itemStyle: { color: indicator === 'water' ? siteColors.water500 : theme.palette.primary.main },
				};
			}

			return e;
		});

	const _data2 = useMemo(() => {
		const result = [];
		result.push(data2);

		return result;
	}, [data2]);

	// eslint-disable-next-line
	const [option, _setOption] = useState<EChartsOption>({
		// tooltip: {
		// 	trigger: 'item',
		// },
		series: [
			{
				silent: true,
				name: 'Access From',
				type: 'pie',
				animation: false,
				radius: [0, '40%'],
				label: {
					position: 'center',
					// fontSize: 30,
					// formatter: '{value|{c}}\n{text|{b}}',
					// formatter: '{value|{c}}\n{text1|KG} {text2|EQ}{text1|CO}{text2|2}{text1|/KG}',
					formatter: (params) => {
						const data2displayValue = (params.data as any).noCenterValue
							? '-'
							: formatNumber(params.value, undefined, 2);

						if (indicator === 'carbon') {
							return `{value|${data2displayValue}}\n{text1|KG} {text2|EQ}{text1|CO}{text2|2}{text1|/KG}`;
						}

						if (indicator === 'water') {
							return `{value|${data2displayValue}}\n{text1|m}{text3|3}{text1|/KG}`;
						}

						return `${params.value}`;
					},
					rich: {
						value: {
							fontSize: 40,
							color: indicator === 'water' ? siteColors.water500 : theme.palette.primary.main,
							// fontVariantNumeric: 'lining-nums tabular-nums',
						},
						text1: {
							fontSize: 16,
							color: indicator === 'water' ? siteColors.water500 : theme.palette.primary.main,
							// verticalAlign: 'bottom',
						},
						text2: {
							fontSize: 10,
							color: indicator === 'water' ? siteColors.water500 : theme.palette.primary.main,
							verticalAlign: 'bottom',
							padding: [12, 0, 0, 0],
						},
						text3: {
							fontSize: 10,
							color: indicator === 'water' ? siteColors.water500 : theme.palette.primary.main,
							verticalAlign: 'bottom',
							padding: [0, 0, 4, 0],
							// align: 'center'
							// baseline: 'top',
						},
					},
				},
				// data: [{ value: 5.1, name: '5.10 KG / ...' }],
				data: _data2,
				emphasis: {
					disabled: true,
				},
				itemStyle: {
					color: siteColors.common.white,
				},
			},
			{
				silent: true,
				name: 'Access From',
				type: 'pie',
				animation: false,
				radius: ['40%', '70%'],
				// width: '100%',
				// left: '50%',
				// data: [
				// 	{ value: 93.24, name: 'Viande De Boeuf', itemStyle: { color: theme.palette.primary.main } },
				// 	{ value: 2.01, name: 'Triple Concentré De Tomates' },
				// 	{ value: 1.42, name: 'Semoule De Blé Dur' },
				// 	{ value: 0.04, name: 'Oeuf' },
				// 	{ value: 0.03, name: 'Crème' },
				// 	{ value: 0.02, name: 'Tomate' },
				// 	{ value: 0.01, name: 'Oignon. Séché' },
				// 	{ value: 0.04, name: 'Amidon De Maïs' },
				// 	{ value: 4.22, name: 'Autre' },
				// ].sort((a, b) => {
				// 	return b.value - a.value;
				// }),
				data: _data,
				label: {
					position: 'outer',
					// alignTo: 'labelLine',
					alignTo: 'edge',
					edgeDistance: 100,
					// distance: 100,
					// bleedMargin: 100,
					minMargin: 20,
					// formatter: '{value|{c}%}{spacer|}{text|{b}}',
					formatter: (params: any) => {
						// add blueCheck
						if (params.data?.isBlueCheck) {
							return `{blueCheck|}{blueCheckSpace|}{value|${
								/* formatNumber(Number(params.value)) */ formatNumber(params.value, undefined, 1)
							} %}{spacer|}{text|${params.name}}`;
						}

						return `{value|${
							/* formatNumber(Number(params.value)) */ formatNumber(params.value, undefined, 1)
						} %}{spacer|}{text|${params.name}}`;
					},
					rich: {
						value: {
							fontSize: '14px',
							fontWeight: 600,
						},
						spacer: {
							width: 8,
						},
						text: {
							fontSize: '14px',
							fontWeight: 400,
						},

						blueCheck: {
							height: 16,
							width: 16,
							backgroundColor: {
								image: '/blueCheck.svg',
							},
						},
						blueCheckSpace: {
							width: 6,
						},
					},
				},
				labelLine: {
					lineStyle: {
						color: theme.palette.grey[700],
					},
					// smooth: 2,
					// length: 100,
					// length2: 150,
					// maxSurfaceAngle: 0,
					// minTurnAngle: 0,
					// show: false,
					// showAbove: true,
				},
				emphasis: {
					disabled: true,
				},
				// itemStyle: {
				// 	color: '#c23531',
				// 	shadowBlur: 200,
				// 	shadowColor: 'rgba(0, 0, 0, 0.5)',
				// },
			},
		],
	});
	console.log(option, 'optionoptionoptionoptionoption');
	return <ReactECharts option={option} opts={{ renderer: 'svg' }} />;
};

export default DonutProductChart;
