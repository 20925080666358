
import _ from 'lodash';

import SearchAutocomplete from '../../../components/SearchAutocompleteV2';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';

const QuoteSearch = () => {
	const { t } = useTranslation();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();

	return (
		<SearchAutocomplete
			size={20}
			setParamsAutocomplete={setSearchQueryParams && _.debounce(setSearchQueryParams, 500)}
			isLoading={false}
			placeholder={t('search')}
			options={[]}
		/>
	);
};

export default QuoteSearch;

