import _ from 'lodash';
import { z } from 'zod';
import { DEFAULT_CUSTOM_MESSAGE_OPTIONS } from '../../../apps/front/src/utils/constants';

//todo add later if need
const noForbiddenWordsValidator = (value: string, lang: string) => {
	// Vérifie si la chaîne contient des mots interdits
	for (const word of DEFAULT_CUSTOM_MESSAGE_OPTIONS[lang].forbiddenWords) {
		if (value.includes(word)) {
			return `Remplacer '${word}' par la valeur correspondante`;
		}
	}
	return true; // Indique que la validation est réussie
};

const languages = _.keys(DEFAULT_CUSTOM_MESSAGE_OPTIONS);

const MessageSchema = languages.reduce((schema, lang) => {
	schema[lang] = z.string().min(1, 'Message cannot be empty');
	// .refine(value => noForbiddenWordsValidator(value, 'fr') === true, {
	// 	message: 'Remplacer les mots entre [...] par une valeur correspondante',
	// }),
	return schema;
}, {} as Record<string, z.ZodString>);


export const CustomMessageValidationSchema = z.object({
	messages: z.object(
		languages.reduce((schema, lang) => {
			schema[lang] = MessageSchema[lang];
			return schema;
		}, {} as Record<string, z.ZodString>)
	),
});

export type CustomMessageFormInput = z.infer<typeof CustomMessageValidationSchema>;
