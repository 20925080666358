import { ReactComponent as CarboneImpactIcon } from '@carbonmaps/media/icons/carbon-impact.svg';
import { ReactComponent as IntensityIcon } from '@carbonmaps/media/icons/carbon-intensity.svg';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import _ from "lodash";

export const ACCUMULATOR_NUMBER_FACET = 25;


export const TOP_BAR_HEIGHT = pxToRem(58);
export const SIDEBAR_WIDTH = pxToRem(280);

// ----------------------------------------------------------- //
// ------------------- view mode options --------------------- //
// ----------------------------------------------------------- //

export const CARBONE_IMPACT_VIEW = 'carbonImpact';
export const INTENSITY_VIEW = 'intensity';
export const WATER_IMPACT_VIEW = 'waterImpact';

export const MODE_VIEW_OPTIONS = [
	{
		label: 'Impact total',
		extraLabel: 'Données ramenées au % du portefeuille',
		value: CARBONE_IMPACT_VIEW,
		icon: CarboneImpactIcon,
	},
	{
		label: 'Intensité',
		extraLabel: 'Données ramenées au kilo de produit',
		value: INTENSITY_VIEW,
		icon: IntensityIcon,
	},
];

export const CARBON_INDICATOR = 'carbon';
export const WATER_INDICATOR = 'water';

export const MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY = 10_000;
export const MAX_NUMBER_OF_INGREDIENTS_TO_DISPLAY = 10_000;
export const MAX_NUMBER_OF_PACKAGING_TO_DISPLAY = 10_000;
export const MAX_NUMBER_OF_SUPPLIERS_TO_DISPLAY = 10_000;


// let m_fr = 'Cher(e) directeur / directrice RSE \n';
// m_fr += 'Nous espérons que ce message vous trouve bien. Je me permets de vous contacter car vous êtes fournisseur de notre organisation, et que nous lançons une photographie de l’état d’engagement RSE de nos fournisseurs.\n';
// m_fr += 'Merci de compléter les informations requises d’ici au [VOTRE DATE].\n';
// m_fr += 'Cordialement, [VOTRE NOM],\n';
// m_fr += '[VOTRE POSTE]';

const m_fr = `
Cher partenaire,
Vous recevez cet email en tant que [fournisseur / prestataire] de [NOM DE VOTRE ORGANISATION]. Dans le cadre de sa démarche RSE, [NOM DE VOTRE ORGANISATION] lance une évaluation de la maturité et de l’engagement RSE de ses [fournisseurs / prestataires logistiques]. L’objectif est double :
 - Permettre à [NOM DE VOTRE ORGANISATION] d’initier un travail collaboratif avec vous pour vous aider renforcer vos bonnes pratiques RSE
 - Vous permettre d’accéder à un espace avec vos résultats, vos leviers d’action et votre comparaison par rapport à d’autres [fournisseurs/prestataires logistiques] de [NOM DE VOTRE ORGANISATION]
Pour cela, [NOM DE VOTRE ORGANISATION] s'appuie sur l'expertise de Carbon Maps et utilise ce tiers de confiance pour collecter la donnée via un questionnaire (disponible ci-dessous). Celui-ci vous prendra environ 30 minutes à remplir. Nous vous serions reconnaissants si vous pouviez le compléter avant le [SAISIR UNE DATE]. Pour toute question, n’hésitez pas à envoyer un email à l’adresse support@carbonmaps.io.
Cordialement, [NOM DE VOTRE ORGANISATION] et Carbon Maps
`;

let m_en = 'Dear CSR Director, \n';
m_en += 'We hope this message finds you well. I am reaching out to you as a supplier to our organization because we are conducting an assessment of the CSR engagement status of our suppliers. Please complete the required information by [YOUR DATE].\n';
m_en += 'Best regards,  \n';
m_en += '[YOUR NAME]  \n';
m_en += '[YOUR POSITION]';

let m_it = 'Gentile Direttore/Direttrice CSR, \n';
m_it += 'Speriamo che questo messaggio vi trovi bene. Mi permetto di contattarvi in quanto fornitore della nostra organizzazione, poiché stiamo conducendo una valutazione dello stato di impegno CSR dei nostri fornitori. Vi preghiamo di completare le informazioni richieste entro il [LA VOSTRA DATA].\n';
m_it += 'Cordiali saluti,  \n';
m_it += '[IL VOSTRO NOME]  \n';
m_it += '[LA VOSTRA POSIZIONE]';

let m_es = 'Estimado/a Director/a de RSE: \n';
m_es += 'Esperamos que este mensaje le encuentre bien. Me pongo en contacto con usted en calidad de proveedor de nuestra organización, ya que estamos realizando una evaluación del estado de compromiso de RSE de nuestros proveedores. Le agradeceríamos que completara la información requerida antes del [SU FECHA].\n';
m_es += 'Atentamente,  \n';
m_es += '[SU NOMBRE]  \n';
m_es += '[SU CARGO]';

let m_de = 'Sehr geehrte/r CSR-Direktor/in, \n';
m_de += 'Wir hoffen, dass Sie diese Nachricht wohlbehalten erreicht. Ich setze mich als Lieferant unserer Organisation mit Ihnen in Verbindung, da wir eine Bewertung des CSR-Engagements unserer Lieferanten durchführen. Wir wären Ihnen dankbar, wenn Sie die erforderlichen Informationen vor dem [IHREM DATUM] ausfüllen könnten.\n';
m_de += 'Mit freundlichen Grüßen,  \n';
m_de += '[IHR NAME]  \n';
m_de += '[IHRE POSITION]';

interface LangOption {
	lang: string;
	value: string;
	label: string;
	forbiddenWords: Array<string>;
}

export const DEFAULT_CUSTOM_MESSAGE_OPTIONS: Record<string, LangOption> = {
	fr: {
		lang: 'fr',
		value: m_fr,
		label: 'Français',
		forbiddenWords: ['[VOTRE DATE]', '[VOTRE NOM]', '[VOTRE POSTE]']
	},
	en:
	{
		lang: 'en',
		value: m_en,
		label: 'Anglais',
		forbiddenWords: ['[YOUR DATE]', '[YOUR NAME]', '[YOUR POSITION]']
	},
	it:
	{
		lang: 'it',
		value: m_it,
		label: 'Italien',
		forbiddenWords: ['[SU FECHA]', '[SU NOMBRE]', '[SU CARGO]']
	},
	es:
	{
		lang: 'es',
		value: m_es,
		label: 'Espagnol',
		forbiddenWords: ['[LA VOSTRA DATA]', '[IL VOSTRO NOME]', '[LA VOSTRA POSIZIONE]']
	},
	de:
	{
		lang: 'de',
		value: m_de,
		label: 'Allemand',
		forbiddenWords: ['[IHREM DATUM]', '[IHR NAME]', '[IHRE POSITION]']
	},
}

export const EMAIL_LANGUAGES_SUPPLIER = _.keys(DEFAULT_CUSTOM_MESSAGE_OPTIONS);


