import BButton from '@carbonmaps/ui/components/saas/BButton';
import { css, cx } from '@emotion/css';
import { siteColors } from '../../lib/colors';

const styles = {
	button: css({
		color: `${siteColors.blackButton} !important`,
		padding: '8px 16px !important',
		fontWeight: '500 !important',
	}),
};

type ButtonProps = {
	label: string;
	handleClick: () => void;
	isDisabled?: boolean;
	className?: string;
};

const Button = ({ label, handleClick, className }: ButtonProps) => {
	return (
		<BButton
			variant="secondary"
			label={label}
			onClick={handleClick}
			className={cx('width100', styles.button, className)}
		/>
	);
};

export default Button;
