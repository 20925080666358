import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { css, cx } from '@emotion/css';

import { Button } from '@mui/material';
import Difference from './Difference';

type Props = {
	value: any;
	data: any;
	withPercent?: boolean;
	valuePercent?: number;
	indicator: 'carbon' | 'water';
	noPopover?: true;
	showZero?: boolean;
	withEquals?: boolean;
};
const styles = {
	fitContent: css({
		width: 'fit-content',
	}),

	modelizationBloc: css({
		height: pxToRem(24),
		border: '1px solid transparent!important',
	}),
	pointer: css({
		cursor: 'auto',
		margin: '0!important',
		padding: '0!important'
	}),
};

const Diff = ({ withEquals = false, showZero = false, noPopover, value, valuePercent, data, withPercent = true, indicator = 'carbon' }: Props) => {


	if (noPopover) {
		return (
			<Button className={cx(styles.pointer)}>
				<div className={cx('flexRow justifyEnd nowrap', styles.modelizationBloc)}>
					<Difference
						withPercent={withPercent}
						value={value}
						decimal={2}
						diffMainClass={cx(styles.fitContent)}
						showZero={showZero}
						withEquals={withEquals}
					// showLte={true}
					/>
				</div>
			</Button>
		);
	}
	return (
		<div className={cx('flexRow justifyEnd nowrap', styles.modelizationBloc)}>
			<Difference
				withPercent={withPercent}
				value={value}
				decimal={2}
				diffMainClass={cx(styles.fitContent)}
				withEquals={withEquals}
			// showZero={true}
			// showLte={true}
			/>
		</div>
	);
};

export default Diff;
