import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import Typography from '../../Typography';
import Unit from '../../units/Unit';

type TooltipProps = {
	item: any;
};

const styles = {
	container: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey600),
		background: siteColors.common.white,
		padding: 24,
		boxShadow:
			'0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 16px 0px rgba(0, 0, 0, 0.40), 0px 1px 2px 0px rgba(0, 0, 0, 0.45)',
		minWidth: 236,
	}),
	value: css({
		color: 'var(--color) !important',
	}),
};

const DisplayValue = ({ value, label }: any) => {
	const { indicator } = useApp();
	const theme = useTheme();
	return (
		<div className="flexColumn gap4">
			<Typography className="grey900 fontWeight600 lineHeight1">{label}</Typography>
			<Typography className="number lineHeight1">
				<span
					className={styles.value}
					style={{ ['--color' as any]: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500 }}
				>
					{formatNumber(value, undefined, 0)}{' '}
				</span>
				<Unit measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'} className={'grey800'} />
			</Typography>
		</div>
	);
};

const Tooltip = ({ item }: TooltipProps) => {
	const { t } = useTranslation();
	const { indicator } = useApp();
	return (
		<div className={cx('flexColumn gap24', styles.container)}>
			<Typography variant="h3" className="grey900 lineHeight1">
				{item?.label}
			</Typography>
			<DisplayValue value={item[`${indicator}Impact`]} label={t('Impact total')} />
			<DisplayValue value={item[`${indicator}ImpactEstimated`]} label={t('Objectif pour la période')} />
		</div>
	);
};

export default Tooltip;
