import React from 'react';

import { css, cx } from '@emotion/css';
import { type CSSProperties } from '@emotion/serialize';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { siteColors } from '../../../../apps/front/src/lib/colors';

const classes = (theme: Theme) => {
	return {
		container: {
			display: 'flex',
			flexDirection: 'column',
			'.MuiInputBase-root': {
				borderRadius: '8px',
			},
			'& .MuiInputBase-input': {
				padding: '10.5px 10px',
				background: theme.palette.common.white,
			},
			'& label.Mui-focused': {
				color: 'green',
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: 'green',
			},
			'& .MuiOutlinedInput-root': {
				// background: theme.palette.grey[400],
				'& .MuiTypography-body1': {
					color: theme.palette.common.black,
				},
				'& fieldset': {
					borderColor: theme.palette.grey[500],
					transition: '100ms',
				},
				'&:hover fieldset': {
					borderColor: theme.palette.grey[600],
				},
				'&.Mui-focused fieldset': {
					border: `1px solid ${theme.palette.primary.light}`,
					boxShadow: `0px 0px 0px 2px ${siteColors.carbon100}`,
				},
				'& .Mui-error': {
					borderColor: 'red',
				},
				'& .MuiInputBase-inputMultiline': {
					padding: '0 10px!important',
				},
			},
			label: {
				fontWeight: 600,
				fontSize: 16,
			},
		},
	};
};

interface BInputProps {
	errorMessage?: any;
	unity?: string;
	customUnity?: string;
	startIcon?: string | React.ReactNode;
	endIcon?: string | React.ReactNode;
	addStyles?: CSSProperties;
	readOnly?: boolean;
	className?: string;
	isTextarea?: boolean;
}

const Input = (
	{
		label,
		isTextarea = false,
		errorMessage,
		startIcon,
		readOnly,
		endIcon,
		unity,
		customUnity,
		addStyles,
		className,
		rows,
		...rest
	}: TextFieldProps & BInputProps,
	ref: any,
) => {
	const theme = useTheme();
	const styles = classes(theme);
	return (
		<div className={cx('input-container', className)} css={{ ...styles.container, ...addStyles } as any}>
			{label ?? (
				<label>
					{label} {rest.required && '*'}
				</label>
			)}
			{!!errorMessage && (
				<Typography variant="inherit" color={'red'}>
					{errorMessage}
				</Typography>
			)}
			<TextField
				{...rest}
				ref={ref}
				css={{ ...(styles as any) }}
				InputProps={{
					readOnly: readOnly,
					endAdornment: unity ? (
						<InputAdornment position="end">{customUnity || 'kg'}</InputAdornment>
					) : (
						endIcon && endIcon
					),
					startAdornment: startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>,
				}}
				multiline={isTextarea ? true : undefined}
				rows={isTextarea ? rows || 4 : 1}
			/>
		</div>
	);
};

const BInput = React.forwardRef(Input);

export default BInput;
