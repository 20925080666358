import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { Navigate, createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as CarrotsIcon } from '@carbonmaps/media/icons/carrots3.svg';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetIngredientSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetIngredientSheet';

import SEO from '../../components/SEO';
import SheetPageLayout from '../../components/layout/page/SheetPageLayout';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useTranslation } from '../../hooks/useTranslation';

import { siteColors } from '../../lib/colors';
import Family from './family/Family';
import IngredientSynthesis from './synthesis/IngredientSynthesis';
import IngredientUses from './uses/IngredientUses';

type ITab = {
	title: string;
	element: ReactNode;
	disabled?: boolean;
	tabName?: string;
};

const tabs: Record<string, ITab> = {
	synthesis: {
		title: 'synthesis-tab',
		element: <IngredientSynthesis />,
		tabName: 'Summary',
	},
	family: {
		title: 'ingredient_family',
		element: <Family />,
		tabName: 'Ingredients cluster',
	},
	usage: {
		title: 'uses-tab',
		element: <IngredientUses />,
		tabName: 'Usages',
	},
};

const IngredientSheet = () => {
	const { setBreadcrumbs } = useApp();
	const params = useParams(); // * { ingredientId: string; tab: string }
	const [notFound, setNotFound] = useState<boolean>(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();

	const analytics = useJuneTrack();

	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	useEffect(() => {
		window.scrollTo({ behavior: 'smooth', top: 0 });
	}, []);

	const { data, isLoading, isError, error } = useGetIngredientSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.ingredientId!,
		// period: 2023,
	});

	const trackEvent = useCallback(
		(name: string, options: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		if (isError && error instanceof Error) {
			if (error.message && error.message === 'Object not found.') {
				setNotFound(true);
			}
		}
	}, [error, isError]);

	useEffect(() => {
		if (!data) {
			setBreadcrumbs([
				//
				// { text: t('reference_ingredients', { ingredients: t('ingredients') }) },
				{ text: t('ingredients') },
			]);
			return;
		}

		if (!isLoading && !data) {
			setNotFound(true);
		}

		if ((data as any).notFound) {
			setNotFound(true);
		}

		setBreadcrumbs([
			{
				// text: t('reference_ingredient', { ingredient: t('ingredient') }),
				text: t('ingredients'),
				link: `${FRONT_PATH_NAMES.ingredients}?${searchParams}`,
			},
			{ text: _.capitalize(data.ingredient?.labelCmaps || '') },
		]);

		// track event

		trackEvent('Explore Land Ingredient', { IngredientLabel: data?.ingredient?.labelCmaps });
	}, [data, setBreadcrumbs, searchParams, isLoading, t /*  trackEvent */]);

	const handleTabChange = (_: SyntheticEvent, value: string) => {
		trackEvent('Explore Ingredient Switch Tab', { IngredientTabTarget: tabs[value]?.tabName });
		navigate(
			`${FRONT_PATH_NAMES.ingredients}/${params.ingredientId}/${value}?${
				value !== 'usage' ? searchParams.toString() : ''
			}`,
		);
	};

	// if (!params.tab) {
	// 	return (
	// 		<Navigate
	// 			replace
	// 			state={{ from: location }}
	// 			to={`${FRONT_PATH_NAMES.ingredients}/${params.ingredientId}/${Object.keys(tabs)[0]}`}
	// 		/>
	// 	);
	// }

	if (notFound) {
		// TODO: Create a notFound Component
		return <Typography variant="h1">l'ingrédient avec l'identifiant: {params.ingredientId} n'existe pas.</Typography>;
	}

	return (
		<Box>
			<SEO
				title={data?.ingredient?.labelCmaps ? `${_.capitalize(data.ingredient.labelCmaps)} - CarbonMaps` : 'CarbonMaps'}
			/>
			<SheetPageLayout
				loading={isLoading}
				currentTab={params.tab || Object.keys(tabs)[0]}
				headerProps={{
					variant: 'fixed',
					// icon: <Carrot width={40} height={40} />,
					icon: <CarrotsIcon width={40} height={40} />,
					isBlueCheck: (data?.ingredient as any)?.tagAdvanced,
					title: data?.ingredient?.labelCmaps || '',
					overTitle: t('reference_ingredient', { ingredient: t('ingredient') }) || '',
					sx: {
						background: siteColors.green10,
						// background:
						// 	'linear-gradient(90deg, #E4F3E3 0%, #E0F0F3 100%), linear-gradient(90deg, #E5EAFF 0%, #EFE0FF 100%)',
					},
				}}
				onTabChange={handleTabChange}
				tabs={tabs}
			/>
		</Box>
	);
};

export default IngredientSheet;
