import { getProductCountByPeriod } from '@carbonmaps/ui/actions/dashboard.actions';
import { css, cx } from '@emotion/css';

import { useQuery } from '@tanstack/react-query';

import { useYearSelection } from '../../../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../../hooks/useTranslation';

import KPICard from '../../../../../packagings/components/KPICard';
import KpiValue from '../../../../../packagings/components/KpiValue';

const styles = {
	container: css({
		minWidth: 201,
	}),
};

const ProductCountKPI = () => {
	const { t } = useTranslation();

	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	// ---- fetch count product by period ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getProductCountByPeriod',
			{ period: selectedYear, facetFilters: searchQueryParams?.facetFilters || [] },
		],
		queryFn: getProductCountByPeriod,
	});

	return (
		<KPICard
			className={cx('flex1', styles.container)}
			isLoading={isLoading}
			value={<KpiValue value={data ?? 0} unit={null} />}
			label={t('products')}
		/>
	);
};

export default ProductCountKPI;
