import _ from 'lodash';

import { CARBON_INDICATOR, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import { randomColor } from '../utils/color.utils';

import { formatSlugUrlSalesQuote } from '@carbonmaps/shared/utils/simulation';
import { isWhitespace } from "@carbonmaps/shared/utils/utils";
import { retry } from "async";
import { TFunction } from "i18next";
import { WATER_INDICATOR } from "../utils/constants";
import ProductModel from './Product.model';

export default class QuoteModel {
	quote: any;

	constructor(quote: any) {
		this.quote = quote || {};
	}

	getName() {
		return this.quote?.name;
	}

	getClient() {
		return this?.quote?.client;
	}

	getGroup() {
		return this?.quote?.groups ?? []; // don't order because it is already order in database
	}

	getEstimatedImpact(indicator: any, product?: any) {
		let total = 0;
		const groups = this.getGroup();

		groups.map((item: any) => {
			const products = item?.products || [];
			products.map((p: any) => {
				const fieldKey = indicator === CARBON_INDICATOR ? 'gesTotalImpact' : 'waterUseTotalImpact';

				// check when product is change
				const isChange = product ? product?.productId === p?.objectId && product?.groupId === item?.objectId : false;
				total = total + Number(isChange ? product[fieldKey]?.toFixed(0) : p[fieldKey]?.toFixed(0));
			});
		});

		return total;
	}

	getAverageIntensity(indicator: any, product?: any) {
		let productsCount = 0;
		let total = 0;
		const groups = this.getGroup();

		groups.map((item: any) => {
			const products = item?.products || [];
			products.map((p: any) => {
				productsCount = productsCount + 1;
				const fieldKey = indicator === CARBON_INDICATOR ? 'gesTotal' : 'waterUseTotal';

				// check when product is change
				const isChange = product ? product?.productId === p?.objectId && product?.groupId === item?.objectId : false;
				total = total + Number(isChange ? product[fieldKey]/* ?.toFixed(0) */ : p[fieldKey]/* ?.toFixed(0) */);
			});
		});

		return total / (productsCount || 1);
	}

	getTotalImpactPerGroup(indicator: any) {
		const impactPerGroup = [] as any;

		const groups = this.getGroup();
		groups.forEach((item: any) => {
			let total = 0;
			const products = item?.products || [];

			products.forEach((p: any) => {
				const fieldKey = indicator === CARBON_INDICATOR ? 'gesTotalImpact' : 'waterUseTotalImpact';
				total = total + p[fieldKey];
			});

			if (products?.length) {
				impactPerGroup.push(total);
			}
		});

		const total = _.mean(impactPerGroup);

		return total;
	}

	getGesTransport() {
		let total = 0;
		const groups = this.getGroup();
		groups.map((item: any) => {
			const products = item?.products || [];

			products.map((p: any) => {
				total = total + p.gesTransport;
			});
		});
		return total;

	}

	getQuantityTotal() {
		let total = 0;
		const groups = this.getGroup();
		groups.map((item: any) => {
			const products = item?.products || [];

			products.map((p: any) => {
				total = total + p.quantity;
			});
		});
		return total;
	}

	getImpactTotal(indicator = 'carbon') {
		let total = 0;
		const groups = this.getGroup();
		groups.map((item: any) => {
			const products = item?.products || [];

			products.map((p: any) => {
				total = total + p.quantity * (indicator === 'carbon' ? (p?.carbonImpactPortion || p?.gesImpactPortion) : (p?.waterImpactPortion || p?.waterUseImpactPortion));
			});
		});
		return total;
	}

	getDataPieChart(withColor = false, indicator = 'carbon') {
		const quantityTotal = this.getQuantityTotal();
		const impactTotal = this.getImpactTotal(indicator);

		const data = [] as any;
		const groups = this.getGroup();
		let index = 0;
		groups.map((item: any) => {
			const products = item?.products || [];

			products.map((p: any) => {

				data.push({
					// value: ((p?.quantity || 0) * 100) / quantityTotal,
					value:
						((p?.quantity * (indicator === 'carbon' ? (p?.carbonImpactPortion || p?.gesImpactPortion) : (p?.waterImpactPortion || p?.waterUseImpactPortion)) || 0) * 100) /
						impactTotal,
					name: p?.label,
					//	y: (p?.quantity || 0 * 100) / quantityTotal,
					y:
						((p?.quantity * (indicator === 'carbon' ? (p?.carbonImpactPortion || p?.gesImpactPortion) : p?.waterImpactPortion) || 0) * 100) /
						impactTotal,
					isBlueCheck: p.product?.tagAdvancedModelization === 'yes',
					color: withColor ? randomColor(index) : undefined,
				});
				index += 1;
			});
		});
		return data;
	}

	getIntensityValue(field: string) {
		let totalIntensity = 0;
		const groups = this.getGroup();
		groups.map((item: any) => {
			const products = item?.products || [];
			products.map((p: any) => {
				const productObject = new ProductModel(p);
				const value = p?.[field] ?? (productObject?.getIntensity(field) || 0);
				//ATTENTION : Impact à la portion = intensité * poids du produit
				//ATTENTION : netWeight en g => convert to kg
				totalIntensity = (totalIntensity || 0) + value * (parseFloat(p?.netWeight || 0) / 1000) * parseFloat(p?.quantity || 0);

			});
		});
		return totalIntensity;
	}

	getDataLifeCycle(indicator: any) {
		const dataMap = new Map();

		//const gesTotal = this.getIntensityValue(indicator === CARBON_INDICATOR ? 'gesTotal' : 'waterUseTotal');

		const gesAgriculture = this.getIntensityValue(
			indicator === CARBON_INDICATOR ? 'gesAgricultures' : 'waterUseAgricultures',
		);
		const gesTransformation = this.getIntensityValue(
			indicator === CARBON_INDICATOR ? 'gesTransformation' : 'waterUseTransformation',
		);
		const gesPackaging = this.getIntensityValue(indicator === CARBON_INDICATOR ? 'gesPackaging' : 'waterUsePackaging');
		const gesTransport = this.getIntensityValue(indicator === CARBON_INDICATOR ? 'gesTransport' : 'waterUseTransport');
		const gesDistribution = this.getIntensityValue(
			indicator === CARBON_INDICATOR ? 'gesDistribution' : 'waterUseDistribution',
		);
		const gesConsumption = this.getIntensityValue(
			indicator === CARBON_INDICATOR ? 'gesConsumption' : 'waterUseConsumption',
		);

		const gesTotal = parseFloat(gesAgriculture as any) +
			parseFloat(gesTransformation as any)
			+ parseFloat(gesPackaging as any)
			+ parseFloat(gesTransport as any)
			+ parseFloat(gesDistribution as any)
			+ parseFloat(gesConsumption as any);

		dataMap.set('gesAgriculture', {
			value: gesAgriculture,
			percentValue: (gesAgriculture * 100) / gesTotal,
		});


		dataMap.set('gesTransformation', {
			value: gesTransformation,
			percentValue: (gesTransformation * 100) / gesTotal,
		});


		dataMap.set('gesPackaging', {
			value: gesPackaging,
			percentValue: (gesPackaging * 100) / gesTotal,
		});

		dataMap.set('gesTransport', {
			value: gesTransport,
			percentValue: (gesTransport * 100) / gesTotal,
		});


		dataMap.set('gesDistribution', {
			value: gesDistribution,
			percentValue: (gesDistribution * 100) / gesTotal,
		});


		dataMap.set('gesConsumption', {
			value: gesConsumption,
			percentValue: (gesConsumption * 100) / gesTotal,
		});

		return dataMap;
	}

	getGroupTotalImpact(group: Record<string, any>, indicator: string) {
		if (!group || !indicator) {
			return 0;
		}

		let totalImpact = 0

		_.get(group, 'products', []).forEach((product: Record<string, any>) => {
			if (indicator === WATER_INDICATOR) {
				totalImpact += _.get(product, 'waterUseTotalImpact', 0)
			} else {
				totalImpact += _.get(product, 'gesTotalImpact', 0)
			}
		});

		return totalImpact;
	}

	getAllGroupsImpactData(indicator: string | undefined = 'carbon') {
		const groups = this.getGroup();

		return groups.map((group: any) => {
			return {
				name: _.get(group, 'name'),
				value: this.getGroupTotalImpact(group, indicator)
			};
		})
	}

	getFacetsCounts() {
		const counts: any[] = [];
		const facetsQuote = this.quote?.facetsQuote || {}
		_.entries(facetsQuote).forEach(([field, value]) => {
			counts.push({
				name: _.get(value, 'label'),
				count: _.get(value, 'count')
			});
		})
		return counts;
	}

	getLinkPageParent() {
		const slugUrl: string = formatSlugUrlSalesQuote({ company: this?.quote?.company, client: this?.quote?.client, json: true });
		return `${FRONT_PATH_NAMES.sales}/${slugUrl}/quote`;
	}

	getCompany() {
		return this?.quote?.company;
	}

	getOrderedFacetsArray(preferredFacet: string | undefined, facetsConfig: Record<string, any>[] | undefined, companyCode: string | undefined) {
		if (!facetsConfig || !companyCode) {
			return new Map();
		}

		const myMap = new Map();

		const foundConfig = facetsConfig.find((item) => {
			return _.get(item, 'collection') === 'Product_' + companyCode
		});

		if (!foundConfig) {
			return myMap;
		}

		const facetsArray: any[] = _.get(foundConfig, 'fields');
		let orderedFacetsArray = [...facetsArray];

		if (preferredFacet) {
			const preferredIndex = facetsArray.findIndex((e: any) => {
				return _.get(e, 'field') === preferredFacet;
			})

			if (preferredIndex > -1) {
				const left = _.slice(facetsArray, 0, preferredIndex);
				const right = _.slice(facetsArray, preferredIndex + 1, facetsArray.length);

				orderedFacetsArray = [facetsArray[preferredIndex], ...left, ...right];
			}
		}

		return orderedFacetsArray;
	}

	prepareGroupCategories(companyCode: string | undefined, preferredFacetKey: string | undefined, group: Record<string, any>, indicator: string | undefined = CARBON_INDICATOR) {
		if (!companyCode || !preferredFacetKey || !indicator) {
			return [];
		}

		const groupCategoriesMap = new Map<string, { products: Record<string, any>[] }>();
		_.get(group, 'products', []).forEach((product: Record<string, any>) => {
			const categories = _.get(product, `${companyCode}Categories`, {})
			const category = _.get(categories, preferredFacetKey);

			const iCat = groupCategoriesMap.get(category) || {
				products: []
			};

			const units = _.get(product, 'quantity');

			const iProduct = {
				name: _.get(product, 'label'),
				units: _.isString(units) ? parseFloat(units) : units,
				intensity: indicator === WATER_INDICATOR ? _.divide(_.get(product, 'waterImpactPortion', 0), 1_000) : _.get(product, 'carbonImpactPortion', 0),
				impact: indicator === WATER_INDICATOR ? _.get(product, 'waterUseTotalImpact', 0) : _.get(product, 'gesTotalImpact', 0),
			}

			groupCategoriesMap.set(category, {
				products: [...iCat.products, iProduct],
			})
		});

		let groupCategories: Record<string, any>[] = [];
		(groupCategoriesMap.entries() as any).forEach((entry: any) => {
			const [field, value] = entry;
			groupCategories.push({
				...value,
				name: field,
				totalImpact: _.sumBy(value.products, 'impact'),
			});
		})

		const totalImpact = _.sumBy(groupCategories, 'totalImpact');

		groupCategories = groupCategories.map((categoryItem) => {
			return {
				...categoryItem,
				percent: _.multiply(_.divide(_.get(categoryItem, 'totalImpact', 0), totalImpact), 100),
			}
		})

		return groupCategories;
	}

	getGroupsDataPDF({ preferredFacet, facetsConfig, indicator = 'carbon', companyCode }: { companyCode: string | undefined; indicator: string | undefined; preferredFacet: string | undefined; facetsConfig: Record<string, any>[] | undefined }): {
		name: string;
		totalImpact: number;
		preferredFacet: {
			name: string;
			categories: {
				name: string;
				totalImpact: number;
				percent: number;
				products: {
					name: string;
					units: number;
					intensity: number;
					impact: number;
				}[];
			}[];
		};
	}[] {
		if (!preferredFacet || !facetsConfig || !companyCode) {
			return [];
		}

		const orderedFacetsArray = this.getOrderedFacetsArray(preferredFacet, facetsConfig, companyCode);

		const groups = this.getGroup()
		const _groups: any[] = [];

		groups.forEach((group: any) => {
			const groupName = _.get(group, 'name');
			const groupTotalImpact = this.getGroupTotalImpact(group, indicator);

			const preferredFacetName = _.toString(_.get(orderedFacetsArray, ('0.label')));
			const preferredFacetKey = _.toString(_.get(orderedFacetsArray, '0.field'));

			// prepare categories
			const groupCategories = this.prepareGroupCategories(companyCode, preferredFacetKey, group, indicator);

			const _group = {
				name: groupName,
				totalImpact: groupTotalImpact,
				preferredFacet: {
					name: preferredFacetName,
					categories: groupCategories,
				},
			}

			_groups.push(_group);
		})

		return _groups;
	}

	getPreferredFacetSummaryData({ preferredFacet, facetsConfig, indicator = 'carbon', companyCode, t }: { t: TFunction; companyCode: string | undefined; indicator: string | undefined; preferredFacet: string | undefined; facetsConfig: Record<string, any>[] | undefined }): {
		name: string;
		categories: {
			name: string;
			totalImpact: number;
			percent: number;
		}[]
	} {
		const groupsData = this.getGroupsDataPDF({ preferredFacet, companyCode, facetsConfig, indicator }) || [];

		const orderedFacetsArray = this.getOrderedFacetsArray(preferredFacet, facetsConfig, companyCode) || [];
		const preferredFacetName = _.toString(_.get(orderedFacetsArray, '0.label'));

		const categoriesMap = new Map<string, { totalImpact: number }>();

		groupsData.forEach((groupData) => {
			groupData.preferredFacet.categories.forEach((category) => {
				const iCat = categoriesMap.get(category.name) || { totalImpact: 0 };

				categoriesMap.set(category.name, {
					totalImpact: iCat.totalImpact + category.totalImpact,
				})
			})
		})

		let preferredFacetSummaryCategories: Record<string, any>[] = [];
		(categoriesMap.entries() as any).forEach((entry: any) => {
			const [categoryName, value] = entry;

			preferredFacetSummaryCategories.push({
				name: categoryName,
				totalImpact: value.totalImpact,
			})
		});

		const sumImpact = _.sumBy(preferredFacetSummaryCategories, 'totalImpact')

		preferredFacetSummaryCategories = preferredFacetSummaryCategories.map((category) => {
			return {
				...category,
				percent: _.multiply(_.divide(category.totalImpact || 0, sumImpact || 1), 100)
			}
		})

		preferredFacetSummaryCategories = _.orderBy(preferredFacetSummaryCategories, ['totalImpact'], ['desc'])
		const topFiveCategories = _.slice(preferredFacetSummaryCategories, 0, 5);
		const otherCategoriesArray = _.slice(preferredFacetSummaryCategories, 5);

		const otherCategoriesObject = {
			name: t('Others'),
			totalImpact: _.sumBy(otherCategoriesArray, 'totalImpact'),
			percent: _.sumBy(otherCategoriesArray, 'percent')
		};

		const preferredFacetSummary = {
			name: preferredFacetName,
			categories: [...topFiveCategories, ...(otherCategoriesArray.length > 0 ? [otherCategoriesObject] : [])],
		};

		return preferredFacetSummary as never;
	}

	getOtherFacetsSummaryData({ preferredFacet, facetsConfig, indicator = 'carbon', companyCode, t }: { t: TFunction; companyCode: string | undefined; indicator: string | undefined; preferredFacet: string | undefined; facetsConfig: Record<string, any>[] | undefined }): {
		name: string;
		subCategories: {
			name: string;
			intensity: number;
			totalImpact: number;
			percent: number;
		}[]
	}[] {
		if (!preferredFacet || !facetsConfig || !companyCode) {
			return [];
		}

		const groups = this.getGroup();

		const categoriesMap = new Map<string, Map<string, { totalImpact: number; intensities: number[]; }>>();

		groups.forEach((group: any) => {
			_.get(group, 'products', []).forEach((product: any) => {
				const intensity = indicator === WATER_INDICATOR ? _.divide(_.get(product, 'waterImpactPortion', 0), 1_000) : _.get(product, 'carbonImpactPortion', 0);
				const impact = indicator === WATER_INDICATOR ? _.get(product, 'waterUseTotalImpact', 0) : _.get(product, 'gesTotalImpact', 0);

				const categories = _.get(product, `${companyCode}Categories`, {}) as Record<string, string>

				_.entries(categories).forEach((entry) => {
					const [categoryName, subCategoryName] = entry;

					const subCategoriesMap = categoriesMap.get(categoryName) || new Map<string, { totalImpact: number; intensities: number[]; }>();

					const subCategory = subCategoriesMap.get(subCategoryName) || { intensities: [], totalImpact: 0 };

					const iSubCategory = {
						intensities: [...subCategory.intensities, intensity],
						totalImpact: subCategory.totalImpact + impact,
					}

					subCategoriesMap.set(subCategoryName, iSubCategory);
					categoriesMap.set(categoryName, subCategoriesMap);
				})
			})
		})

		const result: any[] = [];

		const othersFacetItem = {
			name: t('Others'),
			subCategories: [] as any[],
		};

		const orderedFacetsArray = this.getOrderedFacetsArray(preferredFacet, facetsConfig, companyCode);
		const facetsConfigMap = new Map();
		orderedFacetsArray.forEach((facetConfigItem: any) => {
			if (_.get(facetConfigItem, 'isVisibleInFront') !== true) {
				return;
			}
			facetsConfigMap.set(_.get(facetConfigItem, 'field'), facetConfigItem);
		});

		(categoriesMap.entries() as any).forEach((entry1: any) => {
			const [categoryField, subCategoriesMap] = entry1;

			if (_.isEqual(categoryField, preferredFacet)) {
				return;
			}

			const config = facetsConfigMap.get(categoryField);

			if (!config) {
				(subCategoriesMap.entries() as any).forEach((entry2: any) => {
					const [subCategoryName, value] = entry2;

					if (!subCategoryName) {
						return;
					}

					othersFacetItem.subCategories.push({
						name: subCategoryName,
						intensity: _.mean(value.intensities),
						totalImpact: value.totalImpact,
					})
				})
				return;
			}

			const facetItem = {
				name: _.get(config, 'label'),
				subCategories: [] as any[],
			};

			(subCategoriesMap.entries() as any).forEach((entry2: any) => {
				const [subCategoryName, value] = entry2;

				if (!subCategoryName) {
					return;
				}

				facetItem.subCategories.push({
					name: subCategoryName,
					intensity: _.mean(value.intensities),
					totalImpact: value.totalImpact,
				})
			})

			const dedupedItems = dedupeSubCategories(facetItem.subCategories)

			// const sumImpacts = _.sumBy(dedupedItems, 'totalImpact');
			const base = _.maxBy(dedupedItems, 'totalImpact');

			const _subCategories = dedupedItems.map((e) => {
				return {
					...e,
					percent: e.totalImpact / (_.get(base, 'totalImpact') || 1) * 100,
				}
			})

			facetItem.subCategories = _subCategories;

			result.push(facetItem);
		})

		return result;
	}
}

const dedupeSubCategories = (items: any[]) => {
	return _(items)
		// Step 1: Group by name
		.groupBy('name')
		// Step 2: Map over the grouped items
		.map((group, name) => ({
			name,
			// Step 3: Sum totalImpact
			totalImpact: _.sumBy(group, 'totalImpact'),
			// Step 4: Calculate the mean of intensity
			intensity: _.meanBy(group, 'intensity'),
		}))
		// Step 5: Convert the Lodash wrapper back to a plain array
		.value();
}
