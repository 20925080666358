import { useEffect } from 'react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import EmbedNotion from '../../components/EmbedNotion';
import SEO from '../../components/SEO';

const SectorSheet = () => {
	const { setBreadcrumbs } = useApp();

	// useEffect(() => {
	// 	const bodyElement = document.getElementsByTagName('body')[0];
	// 	// bodyOverFlowY.current = bodyElement.style.overflowY;
	// 	bodyElement.style.overflowY = 'unset';

	// 	return () => {
	// 		bodyElement.style.overflowY = 'auto';
	// 	};
	// });

	useEffect(() => {
		setBreadcrumbs([{ text: 'Fiche filières' }]);
	}, [setBreadcrumbs]);

	return (
		// <iframe
		// 	title="sector-sheet-notion-page"
		// 	src="https://v2-embednotion.com/Fiches-fili-res-4f09bd1d0c014330b08b00f316a20d1f?pvs=4"
		// 	css={{ width: '100%', height: '100%', border: 'none', borderRadius: 'none', padding: 'none' }}
		// ></iframe>
		<>
			<SEO title="Fiche Filière - Carbon Maps" />
			<EmbedNotion
				title="sector-sheet-carbonmaps"
				// url="https://v2-embednotion.com/Fiches-fili-res-4f09bd1d0c014330b08b00f316a20d1f?pvs=4"
				url="https://v2-embednotion.com/4f09bd1d0c014330b08b00f316a20d1f"
			/>
		</>
	);
};

export default SectorSheet;
