import { useMemo, useRef } from 'react';

import { css, cx } from '@emotion/css';
import type { Theme } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Cloud, Cloudy, Droplet, Droplets, ShoppingCart } from 'lucide-react';
import ReactDOMServer from 'react-dom/server';

import { IProduct } from '@carbonmaps/shared/types/product.types';
import {
	ALL_YEAR_OPTION_VALUE,
	classNames,
	ITypeModelizationIngredientKey,
	typeNames,
	WORDING_TERMS,
} from '@carbonmaps/shared/utils/constants';
import { getProductTradeoffDataAction, productCompanyCount } from '@carbonmaps/ui/actions/product.actions';
import { Indicators, StaticContent, StaticContentLoading } from '@carbonmaps/ui/components/saas/charts/ScatterChart';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import TradeOffChart from '../../../../../components/TradeOffChart';
import Unit from '../../../../../components/units/Unit';
import { useYearSelection } from '../../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../../lib/react-query/features/auth/auth.hooks';
import ProductModel from '../../../../../models/Product.model';
import { CARBON_INDICATOR } from '../../../../../utils/constants';

interface ProductTradeoffProps {
	filterOptions?: any;
}

const lighterColors = {
	carbon: siteColors.carbon300,
	water: siteColors.water300,
};

const styles = {
	legendDotCommon: css({
		width: '8px',
		height: '8px',
		borderRadius: '8px',
		marginRight: '8px',
	}),

	ml1: css({
		marginLeft: '26px',
	}),
	ml2: css({
		marginLeft: '16px',
	}),

	colorLightCarbon: css({
		background: lighterColors.carbon,
	}),
	colorLightWater: css({
		background: lighterColors.water,
	}),
	colorDarkCarbon: css({
		background: siteColors.primary,
	}),
	colorDarkWater: css({
		background: siteColors.water500,
	}),
};

const classes = {
	text: {
		color: siteColors.primary,
		fontWeight: 600,
	},
};

const formatDataGraph = (data: any, useValue: any, switchValue: any, theme: any, t: any) => {
	const finalArray = (data || [])
		?.map((product: IProduct) => {
			const objectProduct = new ProductModel(product);
			return {
				...product,
				y: (product as any)[useValue.y.value] || 0,
				x: (product as any)[useValue.x.value] || 0,
				name: product.label,
				value: (product as any)[useValue.x.value],
				[useValue.x.value]: (product as any)[useValue.x.value] || 0,
				[useValue.y.value]: (product as any)[useValue.y.value] || 0,
				key: product.objectId,
				uid: product.uid,
				path: 'products/details',
				isChecked: objectProduct.isN2ModelizationType(),
				labelTypeModelizationIngredient: t(
					(product.typeModelizationIngredient?.toLowerCase() || '') as ITypeModelizationIngredientKey,
					{ recipe: t('recipe'), recipe_lowercase: toLowerCase(t('recipe')) },
				),
				typeModelizationIngredient: product.typeModelizationIngredient,

				// https://carbonmaps.atlassian.net/browse/SCM-1080?focusedCommentId=12412
				color:
					switchValue === CARBON_INDICATOR
						? ['n1_standard_ingredient', 'n1_standard', 'custom_ingredient', 'custom_ingredients'].includes(
								product.typeModelizationIngredient?.toLocaleLowerCase() || '',
						  )
							? siteColors.primary
							: lighterColors.carbon
						: ['n1_standard_ingredient', 'n1_standard', 'custom_ingredient', 'custom_ingredients'].includes(
								product.typeModelizationIngredient?.toLocaleLowerCase() || '',
						  )
						? siteColors.water500
						: lighterColors.water,
			};
		})
		.sort((a: any, b: any) => {
			const is_a_darkColor = a.color === siteColors.primary || a.color === siteColors.water500;
			const is_b_darkColor = b.color === siteColors.primary || b.color === siteColors.water500;

			if (is_a_darkColor && !is_b_darkColor) {
				return 1;
			}

			if (!is_a_darkColor && is_b_darkColor) {
				return -1;
			}

			// for non-'water' types, maintain the original order
			return 0;
		});

	return finalArray;
};

const getIndicators = (theme: Theme, t: any, period: number): Indicators[] => {
	return [
		{
			label: `${t(WORDING_TERMS.INTENSITY)}`,
			value: 'carbonIntensity',
			unity: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
			precision: 2,
			popoverKeys: [
				{
					key: 'carbonImpact',
					label:
						period === ALL_YEAR_OPTION_VALUE ? t(WORDING_TERMS.IMPACT_ALL_YEAR) : t(WORDING_TERMS.IMPACT, { period }),
					icon: <Cloudy color={theme.palette.primary.main} />,
					unit: <Unit measure="carbonImpactTon" color={theme.palette.grey[700]} fontWeight={500} />,
					style: classes.text,
					precision: 0,
				},
				{
					key: 'carbonIntensity',
					label: t(WORDING_TERMS.INTENSITY),
					icon: <Cloud color={theme.palette.primary.main} />,
					unit: (
						<Unit measure="carbonIntensityKg" textTransform={'none'} color={theme.palette.grey[700]} fontWeight={500} />
					),
					style: classes.text,
					precision: 2,
				},
			],
		},
		{
			label: t('water'),
			value: 'waterIntensity',
			unity: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
			precision: 2,
			popoverKeys: [
				{
					key: 'waterImpact',
					label:
						period === ALL_YEAR_OPTION_VALUE ? t(WORDING_TERMS.IMPACT_ALL_YEAR) : t(WORDING_TERMS.IMPACT, { period }),
					icon: <Droplets color={siteColors.water500} />,
					unit: <Unit measure="waterImpact" color={theme.palette.grey[700]} fontWeight={500} />,
					style: { ...classes.text, color: siteColors.water500 },
					precision: 0,
				},
				{
					key: 'waterIntensity',
					label: t(WORDING_TERMS.INTENSITY),
					icon: <Droplet color={siteColors.water500} />,
					unit: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
					style: { ...classes.text, color: siteColors.water500 },
					precision: 2,
				},
			],
		},
		{
			label: t('soldItems'),
			value: 'soldItems',
			precision: 0,
			unity: <Unit measure="uvc" color={theme.palette.grey[700]} fontWeight={500} />,
			popoverKeys: [
				{
					key: 'soldItems',
					label: t('soldItems'),
					icon: <ShoppingCart />,
					unit: <Unit measure="uvc" color={theme.palette.grey[700]} fontWeight={500} />,
					precision: 0,
				},
			],
		},
	];
};

const useFindProductTradeoff = ({ params }: { params: any }) => {
	const { facetFilters, input, supplierIds, indicator } = params;
	const { selectedYear } = useYearSelection();

	return useQuery({
		queryKey: [
			'getProductTradeoffData',
			{
				facetFilters: /* filterOptions?. */ facetFilters || [],
				input: /* filterOptions?. */ input,
				supplierIds: /* filterOptions?. */ supplierIds || [],
				selectedYear,
				viewMode: indicator,
			},
		],
		queryFn: getProductTradeoffDataAction,
	});
};

const ProductTradeoff = ({ filterOptions }: ProductTradeoffProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();
	// ---- switch mode value ---- //
	const { indicator: switchValue } = useApp();

	// ---- current indicator graph ---- //
	const indicators = useMemo(() => {
		return getIndicators(theme, t, selectedYear);
	}, [theme, t]);

	// ---- value graph by indicator ---- //
	const useValue = useMemo(() => {
		if (switchValue === CARBON_INDICATOR) {
			return {
				x: indicators[2],
				y: indicators[0],
			};
		} else {
			return {
				x: indicators[2],
				y: indicators[1],
			};
		}
	}, [indicators, switchValue]);

	// ---- fetch data graph ---- //
	const { data, isLoading } = useFindProductTradeoff({
		params: {
			facetFilters: filterOptions?.facetFilters,
			input: filterOptions?.input,
			supplierIds: filterOptions?.supplierIds,
			indicator: switchValue,
		},
	});

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// ---- fetch all count product for a company without filter ---- //
	// use this count will appear in graph legend
	const { data: productCount } = useQuery({
		queryKey: ['getProductCompanyCount', { period: selectedYear }],
		queryFn: productCompanyCount,
	});

	const countRef = useRef(0);

	const tooltipFormatter: any = _.debounce(function (this: any, anotherTooltip: any) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const tooltip = this;
		// eslint-disable-next-line prefer-const, no-var
		var ret = ReactDOMServer.renderToString(<StaticContentLoading t={t} xAxis={useValue.x} yAxis={useValue.y} />);
		countRef.current = countRef.current + 1;

		findOneScatterPointData({
			uid: tooltip.point.uid,
			period: selectedYear,
			count: countRef.current,
			update: authData?.currentUpdate,
			company: authData?.session?.company,
		}).then((joinedProduct: Record<string, any> | undefined) => {
			if (countRef.current === joinedProduct?.count) {
				tooltip.point.labelTypeModelizationIngredient = t(
					(joinedProduct?.typeModelizationIngredient?.toLowerCase() || '') as ITypeModelizationIngredientKey,
					{ recipe: t('recipe'), recipe_lowercase: toLowerCase(t('recipe')) },
				);
				tooltip.point.name = joinedProduct?.label;
				tooltip.point.carbonImpact = joinedProduct?.carbonImpact;
				tooltip.point.waterImpact = joinedProduct?.waterImpact;
				tooltip.point.isChecked = joinedProduct?.tagAdvancedModelization === 'yes';
				anotherTooltip.label?.attr({
					text: ReactDOMServer.renderToString(
						<StaticContent t={t} tooltip={tooltip} xAxis={useValue.x} yAxis={useValue.y} />,
					),
				});
			}
		});

		return ret;
	}, 500);

	return (
		<TradeOffChart
			datas={(data ? { data: formatDataGraph(data, useValue, switchValue, theme, t) } : {}) as any}
			displayLine={`${data?.length || 0} ${t('sur')} ${productCount || 0} ${t('products')}`}
			indicators={indicators}
			useValue={useValue}
			loading={isLoading}
			skeleton={isLoading}
			toggleImpact={false}
			legend={
				<div
					className={cx('flexRow alignCenter')}
					// css={{
					// 	alignContent: 'flex-start',
					// }}
				>
					<div
						className={cx(
							styles.legendDotCommon,
							styles.ml1,
							switchValue === CARBON_INDICATOR ? styles.colorDarkCarbon : styles.colorDarkWater,
						)}
					/>{' '}
					{t('actual-recipe', { recipe: t('recipe'), recipe_lowercase: toLowerCase(t('recipe')) })}
					<div
						className={cx(
							styles.legendDotCommon,
							styles.ml2,
							switchValue === CARBON_INDICATOR ? styles.colorLightCarbon : styles.colorLightWater,
						)}
					/>{' '}
					{t('recipe-types', { recipe: t('recipe'), recipe_lowercase: toLowerCase(t('recipe')) })}
				</div>
			}
			hcOptions={{
				tooltip: {
					formatter: tooltipFormatter,
				},
			}}
		/>
	);
};

export default ProductTradeoff;

// =============================

const findOneScatterPointData = async ({
	uid,
	period,
	update,
	count,
	company,
}: {
	uid?: string;
	period?: number;
	update?: Parse.Object;
	count?: number;
	company?: any;
}) => {
	const productCollectionName = `${classNames.PRODUCT}_${company?.code}`;
	const productQuery = new Parse.Query(productCollectionName)
		.equalTo('uid', uid)
		.containedIn('impactYear', [period])
		.equalTo('update', update)
		.exclude(['objectId', 'createdAt', 'updatedAt'] as never)
		.select(['label', 'typeModelizationIngredient', 'tagAdvancedModelization']);

	const impactQuery = new Parse.Query(classNames.IMPACT_YEAR)
		.equalTo('uid', uid)
		.equalTo('year', period)
		.equalTo('type', typeNames.PRODUCT)
		.equalTo('update', update)
		.equalTo('isDisabled', false)
		.exclude(['objectId', 'createdAt', 'updatedAt'] as never)
		.select(['carbonImpact', 'waterImpact']);

	const promise1 = productQuery.first({ json: true });
	const promise2 = impactQuery.first({ json: true });

	const [product, impact]: [any, any] = await Promise.all([promise1, promise2]);

	return {
		...impact,
		...product,
		count,
		// carbonImpact: impact?.carbonImpact,
	};
};
