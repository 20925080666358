import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';

import { getProductTableDataAction, productCompanyCount } from '@carbonmaps/ui/actions/product.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetIngredientSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetIngredientSheet';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockTitle from '../../../components/BlockTitle';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';

import IngredientUsesFilter from './IngredientUsesFilter';
import IngredientUsesTable from './IngredientUsesTable';

export interface IngredientItem {
	label: string;
	countUse: number;
	carbonIntensity?: number;
	water?: number;
	carbonImpact?: number;
	percentage?: number;
	labelOrigine?: string;
	uid?: string;
}

const IngredientUses = () => {
	const [loading] = useState(false);
	const params = useParams();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const { t } = useTranslation();

	const [searchParams] = useSearchParams();

	const { data } = useGetIngredientSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.ingredientId!,
	});

	useEffect(() => {
		if (!searchQueryParams) return;
		setSearchQueryParams({
			...searchQueryParams,
			ingredientId: params.ingredientId,
		});
	}, [searchQueryParams, params.ingredientId]);

	return (
		<div>
			<BlockTitle>
				{t('uses_in_finished_products', { products: toLowerCase(t('products')) })} (
				{formatNumber(data?.ingredient.usageCount, undefined, 0)})
			</BlockTitle>
			<div className="flexColumn alignCenter" css={{}}>
				<IngredientUsesFilter />
				<IngredientUsesTable filterOptions={searchQueryParams} />
			</div>
			<LoadingDialog open={loading} content={`${t('export-loading-message')}...`} />
		</div>
	);
};

export default IngredientUses;
