import { END_OF_LIFE_COLORS, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { Trash2 } from 'lucide-react';
import { ReactNode, useMemo } from 'react';
import Skeleton from '../../../../components/Skeleton';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/widget/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../lib/colors';
import DefaultLegend from '../../components/DefaultLegend';
import DonutChart from '../../components/DonutChart';
import DonutChartLegend from '../../components/DonutChartLegend';
import { useEndOfLifeMaterialPercent } from '../../packagings.hooks';

const styles = {
	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		minHeight: 160,
		flex: 1,
	} as any),
	textNoWrap: css({
		whiteSpace: 'nowrap',
	}),
	fontBold: css({
		fontWeight: 'bold',
	}),
};
const MaterialEndOfLifeBlock = () => {
	const { indicator } = useApp();
	const { t } = useTranslation();

	const { data, isLoading } = useEndOfLifeMaterialPercent({ viewMode: indicator });

	const items = useMemo(() => {
		if (!data || !data?.length)
			return [
				{
					color: siteColors.grey3,
					value: 99.9,
					tonnage: 0,
				},
			];

		const tonnage = _.sumBy(data, 'tonnage');

		return data?.map((item: any) => {
			return {
				...item,
				label: item?.uid,
				color: (END_OF_LIFE_COLORS as any)[item?.uid] || siteColors.grey7,
				value: (item?.tonnage / tonnage) * 100, //  item?.tonnagePercent,
				tonnage: item?.tonnage,
				unit: <Unit measure={'massTon'} />,
			};
		});
	}, [data]);

	const orderedItems = _.orderBy(items, 'order');

	return (
		<Card
			title={t('Fin de vie des matériaux')}
			icon={<Trash2 size={20} color={siteColors.recycledIcon} />}
			sx={{ padding: '32px 24px !important', flexWrap: 'nowrap', width: '100%', justifyContent: 'center' }}
		>
			{isLoading ? (
				<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
			) : (
				<DonutChart
					width={200}
					height={171}
					// items={items}
					items={orderedItems}
					innerRadius={83}
					outerRadius={66}
					legend={!data || !data?.length ? <DefaultLegend /> : <DonutChartLegend items={orderedItems} />}
					withTooltip
					renderTooltip={(item, indicator) => {
						return (
							<TooltipCard>
								<div>
									<div className={cx('', styles.textNoWrap, styles.fontBold)}>{_.get(item, 'label', '-')}</div>
									<div className={cx('', styles.textNoWrap)}>
										{displayValue(_.get(item, 'tonnage', '-'))} {_.get(item, 'unit', '-')}
									</div>
								</div>
							</TooltipCard>
						);
					}}
					data={orderedItems}
				/>
			)}
		</Card>
	);
};

export default MaterialEndOfLifeBlock;

const TooltipCard = ({ children }: { children: ReactNode }) => {
	return (
		<div
			className="flexColumn"
			css={{
				borderRadius: '8px',
				border: cn('1px solid', siteColors.grey500),
				background: siteColors.common.white,
				boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
				padding: '24px',
				gap: '24px',
				minWidth: '150	px',
			}}
		>
			{/* OOOOOOOOOOOOOOOOO */}
			{children}
		</div>
	);
};
