import { ReactNode, useEffect, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import CircularProgress from '@mui/material/CircularProgress';
import type { Theme } from '@mui/material/styles/createTheme';
import useTheme from '@mui/material/styles/useTheme';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import { Maximize } from 'lucide-react';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as RefreshIcon } from '@carbonmaps/media/icons/reset.svg';
import { classNames } from '@carbonmaps/shared/utils/constants';
import { displayValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Skeleton from '../../../../../apps/front/src/components/Skeleton';
import { siteColors } from '../../../../../apps/front/src/lib/colors';
import { toLowerCase } from '../../../utils/utils';
import BButton from '../BButton';

export interface Indicators {
	label: string;
	value: string;
	unity: string | JSX.Element;
	precision: number;
	popoverKeys?: any;
}

export interface ScatterDataPoint {
	name: string;
	parent?: string;
	options?: {
		name: string;
		parent?: string;
	};
	data: {
		totalPercentage: string;
		impact: string;
		quantity: string;
		y: number;
		x: number;
		name: string;
		key: string;
		parent?: string;
	}[];
	type?: string;
}

export type redirectOptions = 'gammeProduit' | 'produit' | 'gammeIngredient';

const styles = {
	container: css({
		padding: '16px 24px',
		background: siteColors.common.white,
	}),
	tooltipContainer: css({
		gap: 4,
	}),

	titlePoint: css({
		margin: 0,
		color: siteColors.text,
		fontSize: 16,
		fontWeight: 600,
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		width: '350px',
	}),
	uidRef: css({
		margin: '5px 0',
		marginBottom: 24,
		color: siteColors.grey700,
		fontSize: 14,
		fontWeight: 400,
		textTransform: 'capitalize',
	}),

	popoverContent: css({
		gap: 8,
		marginBlock: 10,
		alignItems: 'stretch',
	}),
	box: css({
		borderRadius: 8,
		background: siteColors.grey300,
		padding: 16,
		gap: 8,
	}),
	boxContainer: css({
		gap: 5,
		background: '#F0F9FC',
	}),
	h2: css({
		color: siteColors.grey900,
		fontSize: 16,
		fontWeight: 600,
		margin: 0,
	}),
	gap5: css({
		gap: 5,
	}),
	title: {
		color: siteColors.grey700,
		fontSize: 12,
		fontWeight: 600,
		margin: 0,
		textTransform: 'uppercase',
	},
	unity: {
		color: siteColors.grey700,
		fontSize: 12,
		fontWeight: 500,
		// textTransform: 'uppercase', //don't use uppercase for unity
		margin: 0,
	},
	href: {
		color: siteColors.primary,
		fontSize: 14,
		fontWeight: 500,
		textDecoration: 'none',
	},
	scoring: {
		color: siteColors.grey900,
		fontSize: 12,
		fontWeight: 600,
		textDecoration: 'none',
	},
};

interface DefautOptions {
	xAxis: Indicators;
	yAxis: Indicators;
	navigate: any;
	handleZoom: (e: any) => void;
	mainColor?: string;
	theme: Theme;
	fullScreen: boolean;
	size?: { width: number; height: number };
	minYAxis?: number;
	t?: any;
}

const defaultScatterOptions = ({
	navigate,
	xAxis,
	yAxis,
	mainColor,
	handleZoom,
	theme,
	fullScreen,
	size,
	minYAxis,
	t,
}: // getTooltipFormatter,
DefautOptions) => {
	// Custom tooltip formatter function
	const tooltipFormatter: any = /* getTooltipFormatter ? getTooltipFormatter(t, xAxis, yAxist) : */ function (
		this: any,
		anotherTooltip: any,
	) {
		console.log('XXXXXXXXXXXXXXXXXXXX', this, anotherTooltip);
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const tooltip = this;
		// // eslint-disable-next-line prefer-const, no-var
		// var ret = 'waiting..';

		// new Parse.Query(classNames.IMPACT_YEAR)
		// 	.equalTo('objectId', tooltip.point.objectId)
		// 	.first()
		// 	.then((impact) => {
		// 		console.log('lol');
		// 		tooltip.point.name = impact?.get('uid');

		// 		anotherTooltip.label.attr({
		// 			text: ReactDOMServer.renderToString(<StaticContent t={t} tooltip={tooltip} xAxis={xAxis} yAxis={yAxis} />),
		// 		});
		// 	});

		// return ret;

		return ReactDOMServer.renderToString(<StaticContent t={t} tooltip={tooltip} xAxis={xAxis} yAxis={yAxis} />);
	};

	const handleClick = function (event: any) {
		// console.log('AAAAAAAAAAAAAA', this);
		if (event.point.path) {
			navigate(`/${event.point.path}/${event.point.key}`);
		}
	};

	return {
		chart: {
			zoomType: 'xy',
			type: 'scatter',
			alignThresholds: true,
			animation: false,
			plotBackgroundColor: theme.palette.grey[200],
			style: {
				fontFamily: 'Inter',
				fontWeight: 'light',
			},
			resetZoomButton: {
				theme: {
					// Customize the appearance of the reset zoom button
					style: {
						padding: '10px',
						fontSize: '16px',
						fontWeight: 'bold',
						// Add other CSS properties as needed
					},
				},
				position: {
					align: 'right', // Adjust the position as needed
					verticalAlign: 'top', // Adjust the position as needed
					x: 10, // Horizontal offset
					y: -10, // Vertical offset
				},
				relativeTo: 'chart',
			},
			events: {
				selection: handleZoom,
			},
		},
		credits: {
			enabled: false,
		},
		legend: {
			enabled: false,
		},
		plotOptions: {
			series: {
				stickyTracking: false,
				dataLabels: {
					enabled: false,
					align: 'right',
					y: 11,
					x: -10,
					format: '{point.name}',
				},
				turboThreshold: 0,
				animation: false,
			},
			scatter: {
				// custom: {
				// 	itemUid: this?.uid,
				// },
				marker: {
					symbol: 'circle',
					radius: 3,
					fillColor: mainColor,
					states: {
						hover: {
							enabled: true,
							lineWidth: 2,
							lineWidthPlus: 0,
							radiusPlus: 1,
							lineColor: mainColor,
							fillColor: 'white',
						},
					},
				},
				cursor: 'pointer',
				events: {
					click: handleClick,
					// mouseOver: (event: any) => {
					// 	console.log('BBBBBBBBBBBBBB', event);
					// },
				},
			},
		},
		title: {
			useHTML: true,
			text: '',
		},
		tooltip: {
			useHTML: true,
			formatter: tooltipFormatter,
			backgroundColor: siteColors.common.white,
			borderWidth: 0,
			borderRadius: 16,
			boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
			style: {
				boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
				zIndex: 100000,
			},
			outside: true,
		},
		yAxis: {
			gridLineDashStyle: 'Dash',
			gridLineColor: '#C2D6D1',
			title: {
				x: 260,
				y: -25,
				align: 'high',
				useHtml: true,
				text: '',
				left: 0,
				style: {
					transform: 'rotate(0deg)',
					color: mainColor,
					fontWeight: 'bold',
					fontSize: '16px',
					lineHeight: '12px',
					textTransform: 'uppercase',
				},
			},
			gridLineWidth: 1,
			lineWidth: 1,
			// min: minYAxis ?? 0,
			min: 0,
		},
		xAxis: {
			labels: {
				formatter: (data: any) => {
					return formatNumber(data.value, '', 0);
				},
			},

			startOnTick: true,
			endOnTick: true,
			min: 0,
		},
	};
};

interface ScatterProps {
	ScatterData: any;
	hcOptions?: Highcharts.Options;
	color?: string;
	xAxis: Indicators;
	yAxis: Indicators;
	loading?: boolean;
	toggleFullScreen: () => void;
	fullScreen: boolean;
	size?: { width: number; height: number };
	height?: number;
	enableFullscreen?: boolean;
	viewAllLink?: ReactNode;
	t?: any;
}

const scatterChartStyles = {
	container: css({
		position: 'relative',
		width: '100%',

		// background: 'red',

		'& .highcharts-plot-background': {
			fill: 'transparent!important',
			cursor: 'crosshair',
		},
		'& .highcharts-background': {
			fill: 'transparent!important',
		},
		'& .highcharts-grid-line': {
			stroke: 'transparent',
		},
		'& .highcharts-reset-zoom': {
			display: 'none',
		},

		'& .highcharts-container': {
			width: '100%!important',

			'& > svg': {
				width: '100%!important',
			},
			// height: '100%',
		},
	}),
	content: css({
		width: '50%',
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		top: '70px',
		zIndex: 2000,
	}),

	paddingContainer: css({
		padding: '12px !important',
	}),
	zoomContainer: css({
		position: 'absolute',
		right: 0,
		top: -35,
		gap: '12px',
	}),
};

const ScatterChart = (props: ScatterProps): JSX.Element => {
	const {
		ScatterData,
		hcOptions,
		color,
		xAxis,
		yAxis,
		loading,
		fullScreen,
		enableFullscreen = false,
		viewAllLink,
		size,
		height,
		toggleFullScreen,
		t,
	} = props;
	const [isZoom, setisZoom] = useState(false);
	const navigate = useNavigate();
	const theme = useTheme();
	// const [fullScreen, setFullScreen] = useState<boolean>(true);

	const handleZoom = () => {
		setisZoom(true);
		return null;
	};

	//configure the origin of the y axes
	let minYAxis = 0;

	if (!enableFullscreen && ScatterData.data && ScatterData.data.length > 1) {
		const minObj: any = _.minBy(ScatterData.data, yAxis.value);
		const meanValue = _.meanBy(ScatterData.data, yAxis.value);
		const minValue = minObj[yAxis.value];
		if (meanValue !== minValue) minYAxis = meanValue;
	}

	const options = Highcharts.merge(
		defaultScatterOptions({
			navigate,
			xAxis,
			yAxis,
			mainColor: color,
			handleZoom,
			theme,
			fullScreen,
			size,
			minYAxis,
			t,
		}),
		hcOptions,
		{
			series: ScatterData,
		},
	);
	const chartRef: any = useRef(null);

	const handleResetZoom = () => {
		const chart = chartRef.current?.chart;

		if (chart) {
			chart.zoomOut();
		}

		setTimeout(() => {
			setisZoom(false);
		}, 500);
	};

	useEffect(() => {
		if (!size) return;

		chartRef.current?.chart.setSize(
			fullScreen ? size?.width : size?.width,
			fullScreen ? size?.height - 100 : height || 180,
		);
	}, [size]);

	return (
		<div className={scatterChartStyles.container}>
			{loading ? (
				<div className={cx('flexCenter', scatterChartStyles.content)}>
					<div className={scatterChartStyles.paddingContainer}>
						<CircularProgress color="inherit" size={20} />
					</div>
				</div>
			) : null}
			<div className={cx('flexRow alignCenter', scatterChartStyles.zoomContainer)}>
				{!fullScreen && enableFullscreen ? <Maximize onClick={toggleFullScreen} style={{ cursor: 'pointer' }} /> : null}

				{!enableFullscreen && viewAllLink ? viewAllLink : ''}

				{isZoom && (
					<BButton
						addStyles={{
							color: siteColors.text,
							fontSize: 14,
							fontWeight: 600,
						}}
						iconLeft={<RefreshIcon />}
						onClick={handleResetZoom}
						label={t('tradeoff-reset-zoom')}
						variant="secondary"
					></BButton>
				)}
			</div>
			<HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
		</div>
	);
};

export default ScatterChart;

// =============================
// !warning you should not use useState or useEffect another hook in the StaticContent component
export const StaticContent = ({
	tooltip,
	t,
	xAxis,
	yAxis,
}: {
	tooltip: Record<string, any> | undefined;
	t: any;
	xAxis: any;
	yAxis: any;
}) => {
	return (
		<div className={cx('flexColumn', styles.container)}>
			<div className={cx('flexRow alignCenter nowrap', styles.tooltipContainer)}>
				{tooltip?.point.isChecked ? (
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.46263 1.85265C7.26148 0.889966 8.73852 0.889967 9.53737 1.85265C9.95854 2.36019 10.6031 2.62715 11.2597 2.56608C12.5053 2.45024 13.5498 3.49466 13.4339 4.74026C13.3728 5.39694 13.6398 6.04146 14.1473 6.46263C15.11 7.26148 15.11 8.73852 14.1473 9.53737C13.6398 9.95854 13.3728 10.6031 13.4339 11.2597C13.5498 12.5053 12.5053 13.5498 11.2597 13.4339C10.6031 13.3728 9.95854 13.6398 9.53737 14.1473C8.73852 15.11 7.26148 15.11 6.46263 14.1473C6.04146 13.6398 5.39694 13.3728 4.74026 13.4339C3.49466 13.5498 2.45024 12.5053 2.56608 11.2597C2.62715 10.6031 2.36019 9.95854 1.85265 9.53737C0.889966 8.73852 0.889967 7.26148 1.85265 6.46263C2.36019 6.04146 2.62715 5.39694 2.56608 4.74026C2.45024 3.49466 3.49466 2.45024 4.74026 2.56608C5.39694 2.62715 6.04146 2.36019 6.46263 1.85265Z"
							fill={siteColors.water500}
						/>
						<path
							d="M9.89808 5.16132C10.0375 4.92901 10.3388 4.85371 10.5711 4.99306C10.7868 5.12251 10.8671 5.39152 10.7659 5.61529L10.7393 5.66603L7.79625 10.5711C7.65657 10.8039 7.35754 10.8762 7.12908 10.7429L7.08129 10.7112L5.11931 9.23965C4.90256 9.0771 4.85867 8.76966 5.02118 8.55291C5.17125 8.35288 5.44474 8.30008 5.6564 8.42095L5.70793 8.45482L7.23444 9.59966L9.89808 5.16132Z"
							fill="white"
						/>
					</svg>
				) : (
					<></>
				)}
				<h2 className={styles.titlePoint}>{tooltip?.point.name}</h2>
			</div>

			{tooltip?.point.uid && <p className={styles.uidRef}>{tooltip?.point.uid}</p>}
			<div className={cx('flexRow nowrap', styles.popoverContent)}>
				{yAxis?.popoverKeys ? (
					yAxis.popoverKeys.map((item: any, index: number) => {
						return (
							<div className={cx('flexColumn', styles.box)} key={index}>
								{item.icon}
								<h2 className={cx(styles.h2)} style={{ ...item?.style }}>
									{tooltip?.point[item.key] ? displayValue(tooltip?.point[item.key], undefined, item.precision) : '- '}
								</h2>
								<div style={{ flex: 1 }}>
									<p style={styles.title as any}>{item.label}</p>
									<p style={styles?.unity as any}>{item.unit}</p>
								</div>
							</div>
						);
					})
				) : (
					<div className={cx('flexColumn', styles.box, styles.boxContainer)}>
						<h2 className={styles.h2}>
							{tooltip?.point[yAxis?.value]
								? displayValue(tooltip?.point[yAxis?.value], undefined, yAxis.precision)
								: '- '}
						</h2>
						<p style={styles.title as any}>{t(yAxis?.label)}</p>
						<p style={styles?.unity as any}>{yAxis?.unity}</p>
					</div>
				)}
				{xAxis?.popoverKeys ? (
					xAxis.popoverKeys.map((item: any, index: number) => {
						return (
							<div className={cx('flexColumn', styles.box)} key={index}>
								{item.icon}
								<h2 className={styles.h2} style={item?.style}>
									{tooltip?.point[item.key] ? displayValue(tooltip?.point[item.key], undefined, item.precision) : '- '}
								</h2>
								<div style={{ flex: 1 }}>
									<p style={styles.title as any}>{t(item.label)}</p>
									<p style={styles?.unity as any}>{item.unit}</p>
								</div>
							</div>
						);
					})
				) : (
					<div className={cx('flexColumn', styles.box, styles.gap5)}>
						<h2 className={styles.h2}>
							{tooltip?.point[xAxis?.value]
								? displayValue(tooltip?.point[xAxis?.value], undefined, xAxis.precision)
								: '- '}
						</h2>
						<p style={styles.title as any}>{t(xAxis?.label)}</p>
						<p style={styles?.unity as any}>{xAxis?.unity}</p>
					</div>
				)}
			</div>
			{tooltip?.point.labelTypeModelizationIngredient && (
				<div className="flexRow nowrap alignCenter justifyCenter" style={{ marginBlock: 10 }}>
					<span style={styles.scoring}>
						{t('Modélisation recette', {
							recipe: t('recipe'),
							recipe_lowercase: toLowerCase(t('recipe')),
						}) || 'Modélisation recette'}
						&nbsp;:
					</span>
					<span>&nbsp;{tooltip?.point.labelTypeModelizationIngredient}</span>
				</div>
			)}
		</div>
	);
};

export const StaticContentLoading = ({ t, xAxis, yAxis, withTypeModelization = true }: any) => {
	return (
		<Skeleton>
			<StaticContent
				t={t}
				xAxis={xAxis}
				yAxis={yAxis}
				tooltip={{
					point: {
						name: 'loading....',
						uid: 'loading....',
						...(withTypeModelization && { labelTypeModelizationIngredient: 'loading....' }),
					},
				}}
			/>
		</Skeleton>
	);
};
