import { getConfigFacets } from '@carbonmaps/shared/utils/utils';
import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import FacetCard from './FacetCard';

const styles = {
	container: css({
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: 32,
	}),
};

const moveItemToFront = (arr: any[], id: string | number) => {
	// Separate the item with the given id from the rest of the array
	const itemToMove = arr.filter((item) => item.field === id);
	const otherItems = arr.filter((item) => item.field !== id);

	// Combine the item with the rest of the array, placing it at the front
	return itemToMove.concat(otherItems);
};

const Facets = () => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const items = getConfigFacets(authData?.session.company, false, 'Product').slice(0, 6);
	const sortedItems = moveItemToFront(
		items,
		_.get(authData, 'session.company.category', _.get(authData, '0.field')) as never,
	);

	return (
		<div className={cx('width100', styles.container)}>
			{sortedItems.map((item, key: number) => {
				return <FacetCard key={key} category={_.get(item, 'field', '---')} label={_.get(item, 'label', '---')} />;
			})}
		</div>
	);
};

export default Facets;
