import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useParams } from 'react-router-dom';
import MetaDataCard from '../../components/card/MetaDataCard';
import { useQuoteSheet } from './quote.hooks';

const QuoteMetaData = () => {
	const { indicator } = useApp();

	const params = useParams();
	// ---- fecth data for quote ---- //
	const { data, isLoading } = useQuoteSheet({
		quoteId: params.quoteId || '',
		viewMode: indicator,
	});
	return (
		<MetaDataCard
			createdBy={data?.user ? `${data?.user?.get('firstName')} ${data?.user?.get('lastName')}` : null}
			createdAt={data?.createdAt}
			updatedAt={data?.updatedAt}
		/>
	);
};

export default QuoteMetaData;
