import { css, cx } from '@emotion/css';
import GlobalIndicatorBlock from './GlobalIndicatorBlock';
import MaterialTable from './MaterialTable';
import RecycledMaterialsBlock from './RecycledMaterialsBlock';

const CONTENT_WIDTH = 'calc(100% - 4rem)';
const CONTENT_MAX_WIDTH = '1200px';

const styles = {
	container: css({
		paddingBottom: 85,
		width: CONTENT_WIDTH,
		maxWidth: CONTENT_MAX_WIDTH,
		margin: 'auto',
	}),
};

const PackagingsRecycled = () => {
	return (
		<div className={cx('flexColumn width100 gap24', styles.container)}>
			<GlobalIndicatorBlock />
			<RecycledMaterialsBlock />
			<MaterialTable />
		</div>
	);
};

export default PackagingsRecycled;
