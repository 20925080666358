import { cx } from '@emotion/css';

import IngredientAcvFilter from '../../../../audit/acv/ingredient/IngredientAcvFilter';
import IngredientAcvTable from '../../../../audit/acv/ingredient/IngredientAcvTable';

const AuditIngredientLCAs = () => {
	// const { setBreadcrumbs } = useApp();

	// // translation
	// const { t } = useTranslation();

	// // ---- breadcrumbs ---- //
	// useEffect(() => {
	// 	setBreadcrumbs([{ text: t('audit-title') }, { text: t('acv-breadcrumbs') }]);
	// }, [t, setBreadcrumbs]);

	return (
		<div className={cx('flexColumn')}>
			<IngredientAcvFilter />
			<IngredientAcvTable />
		</div>
	);
};

export default AuditIngredientLCAs;
