import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Carrot } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { GetPackagingParams } from '@carbonmaps/ui/actions/packaging.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useIngredientsProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockTitle from '../../../components/BlockTitle';
import ExportDialog, { ExportDialogProps } from '../../../components/dialogs/ExportDialog';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { exportIngredientsAction } from '../../../lib/react-query/features/export/ingredient.actions';
import { CARBON_INDICATOR } from '../../../utils/constants';
import { supplierCheckedCount } from '../../../utils/supplier';

const styles = {
	mb40: css({
		marginBottom: '40px',
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},
		'.bordered td, .bordered th': {

			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered': {
			border: `solid 1px ${theme.palette.grey[500]}`,
			borderRadius: '0px 0px 6px 6px',
		},

		'.bordered tr th:nth-of-type(2) div': {
			justifyContent: 'flex-start!important',
		},
		'.bordered tr th:nth-of-type(3) div,.bordered tr td:nth-of-type(3) div,.bordered tr th:nth-of-type(4) div,.bordered tr td:nth-of-type(4) div,.bordered tr th:nth-of-type(5) div,.bordered tr td:nth-of-type(5) div,.bordered tr th:nth-of-type(6) div,.bordered tr td:nth-of-type(6) div':
		{
			justifyContent: 'flex-end!important',
			paddingRight: '0!important',
		},
		'.bordered tr td:nth-of-type(3) p,.bordered tr td:nth-of-type(4) p': {
			paddingRight: '0!important',
			textAlign: 'end',
		},

	};
};

const IngredientsList = () => {
	const theme = useTheme();
	const { indicator, setIndicator } = useApp();
	const params = useParams();
	const fetchIdRef = useRef(0);
	const [, setSelectedRow] = useState<string[]>([]);
	const [, setQueryParams] = useState<GetPackagingParams>({ page: 1, size: 10, productId: '' });
	const [sortingBy, setSortingBy] = useState({
		id: 'carbonWeightedIntensity',
		desc: true,
	});
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);
	const { t } = useTranslation();

	const { data, isLoading } = useIngredientsProduct({
		productId: params.productId!,
	});

	const ingredientsTableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('ingredient')} />;
				},
				accessor: 'name',
				editable: true,
				sortDescFirst: true,
				component: 'FinalProduct',
				icon: <Carrot color={theme.palette.grey[700]} />,
				props: {
					onClick: (item: any, e?: any) => {
						e.preventDefault();
						e.stopPropagation();
						window.open(`${FRONT_PATH_NAMES.ingredients}/${item?.objectId}/family?ids=${item?.uid}`);
					},
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('suppliers')} />;
				},
				accessor: 'suppliers',
				component: 'Supplier',
				editable: true,
				sortDescFirst: true,
				type: 'number',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('presence')} />;
				},
				accessor: 'presence',
				editable: true,
				sortDescFirst: true,
				component: 'Percentage',
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY_WEIGHTED_PRODUCT, {
								per_kilo: t('per_kilo'),
								product: toLowerCase(t('product')),
								of_product: t('of_product'),
							})}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonWeightedIntensity' : 'waterWeightedIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				props: {
					isWeighted: true,
					...(indicator !== CARBON_INDICATOR
						? {
							color: {
								primary: siteColors.water500,
								secondary: siteColors.grey500,
							},
						}
						: null),
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY_WEIGHTED_ING, {
								per_kilo: t('per_kilo'),
								ingredient: toLowerCase(t('ingredient')),
								an_ingredient: t('an_ingredient'),
							})}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				props: {
					...(indicator !== CARBON_INDICATOR
						? {
							color: {
								primary: siteColors.water500,
								secondary: siteColors.grey500,
							},
						}
						: null),
				},
			},
		];
	}, [theme.palette.common.black, indicator, sortingBy, t]);

	const updateQueryParams: ComponentProps<typeof TableComponent>['fetchData'] = useCallback(
		({ pageIndex, pageSize }: any) => {
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setQueryParams({
					page: pageIndex + 1,
					size: pageSize,
				});
			}
		},
		[],
	);

	const onSelectRows = (rows: unknown[]) => {
		const rowsIds = rows.map((r: any) => {
			return r.original.objectId;
		});
		setSelectedRow(rowsIds);
	};

	const { skeleton: tableSkeleton } = useSkeleton({ condition: isLoading });

	const [isExportLoading, setExportLoading] = useState(false);

	// ---- select rows selected in store ---- //
	const { selectedRows } = useRowTableSelection();

	const { selectedYear } = useYearSelection();

	const {
		result: { data: dataAuth },
	} = useGetClientAuthQuery();

	const handleExport: ExportDialogProps['handleExport'] = useCallback(
		async ({ exportFormat }) => {
			setExportLoading(true);
			await exportIngredientsAction({
				queryKey: [
					'exportIngredients',
					{
						format: exportFormat,
						supplierIds: [],
						ids: selectedRows.length
							? selectedRows.map((e: any) => {
								return e.original.objectId;
							})
							: data?.ingredients.map((e: any) => {
								return e.objectId;
							}),
						matchField: '_id', // objectId
						productId: params.productId,
						productUid: data?.uidProduct,
						t,
						selectedYear,
						companyName: dataAuth?.session?.company?.name || '___',
						view: 'product-ingredient-table',
					},
				],
				meta: undefined,
			});
			setExportLoading(false);
		},
		[data?.ingredients, params.productId, selectedRows, selectedYear, dataAuth?.session?.company?.name],
	);

	const [isExportDialogOpen, toggleExportDialog] = useToggle(false);

	const dataTable = useMemo(() => {
		if (!data?.ingredients) return [];

		const res = data.ingredients;

		const maxCarboneIntensity: any = _.maxBy(res, 'carbonIntensity');
		const maxWaterIntensity: any = _.maxBy(res, 'waterIntensity');
		const maxWeightedCarboneIntensity: any = _.maxBy(res, 'carbonWeightedIntensity');
		const maxWeightedWaterIntensity: any = _.maxBy(res, 'waterWeightedIntensity');

		const ingredients = res.map((item: any) => {
			const groupCount = supplierCheckedCount(item?.suppliers);

			return {
				...item,
				isChecked: item.tagAdvanced === 'yes',
				origin: item.uid,
				numberSupplierFullfilled: groupCount?.yes ?? 0,
				numberSupplierNotFullfilled: groupCount?.no ?? 0,
				// in percentage
				percentage:
					(indicator === CARBON_INDICATOR
						? (item.carbonIntensity * 100) / maxCarboneIntensity.carbonIntensity
						: (item.waterIntensity * 100) / maxWaterIntensity.waterIntensity) || 0,
				// in percentage Weighted
				percentageWeighted:
					(indicator === CARBON_INDICATOR
						? (item.carbonWeightedIntensity * 100) / maxWeightedCarboneIntensity.carbonWeightedIntensity
						: (item.waterWeightedIntensity * 100) / maxWeightedWaterIntensity.waterWeightedIntensity) || 0,
			};
		});

		// for manual sorting

		const { id, desc } = sortingBy;
		const sort = desc === false ? 'asc' : 'desc';

		return _.orderBy(ingredients, id, sort);
	}, [data, indicator, sortingBy]);

	useEffect(() => {
		setSortingBy({
			id: indicator === CARBON_INDICATOR ? 'carbonWeightedIntensity' : 'waterWeightedIntensity',
			desc: true,
		});
	}, [indicator]);

	return (
		<div className={styles.mb40}>
			<BlockTitle>
				{t('original_recipe', { recipe: t('recipe'), recipe_lowercase: toLowerCase(t('recipe')) })}
			</BlockTitle>

			<BButton variant="secondary" label={t('export-button')} onClick={toggleExportDialog} />

			<TableComponent
				skeleton={tableSkeleton}
				pagination={false}
				noDataMessage={t('product-ingredient-empty-data', {
					ingredients: toLowerCase(t('ingredients')),
					ingredient: toLowerCase(t('ingredient')),
				})}
				tableName={'ingredients'}
				loading={false}
				fetchData={updateQueryParams}
				pageCount={1}
				isSelectable
				columns={ingredientsTableColumns}
				data={dataTable || []}
				pageSize={10}
				transFunc={t}
				setSelectedRow={(rows) => {
					return onSelectRows(rows);
				}}
				onSortingColumn={(value: any) => {
					return setSortingBy(value);
				}}
				addStyles={stylesTable}
				manualSortBy
			/>

			<ExportDialog
				title={`${t('export-button')} ${selectedRows?.length ? selectedRows?.length : data?.ingredients?.length}  ${t(
					'ingrédient(s)',
					{
						ingredient: toLowerCase(t('ingredient')),
						ingredients: toLowerCase(t('ingredients')),
						an_ingredient: t('an_ingredient'),
					},
				)} ?`}
				open={isExportDialogOpen}
				toggle={toggleExportDialog}
				isLoading={false}
				handleExport={handleExport}
			/>
			<LoadingDialog
				open={isExportLoading}
				content={`${t('Export en cours')}...`}
				more={t('Veuillez patienter, le chargement peut prendre quelques minutes')}
			/>
		</div>
	);
};

export default IngredientsList;
