import { useMemo } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { Box as BoxIcon, Carrot, HelpCircle } from 'lucide-react';

import {
	ALL_YEAR_OPTION_VALUE,
	CARBON_INDICATOR,
	ITEM_TYPE_INGREDIENT,
	WORDING_TERMS,
} from '@carbonmaps/shared/utils/constants';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';

import { useYearSelection } from '../../hooks/useImpactYear';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import ProductModel from '../../models/Product.model';
import { orderBy } from '../../utils/array.utils';
import TableHeaderCell from '../table/TableHeaderCell';
import ImpactCarbonePopover from '../table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../table/popover/WaterImpactPopover';

type ApprovisionnementListProps = {
	data?: any;
	indicator?: string;
	pagination?: boolean;
	withPercent?: boolean;
	withImpactPortion?: boolean;
};

const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
};

const SupplyTable = ({
	data = [],
	indicator = CARBON_INDICATOR,
	pagination = true,
	withPercent = false,
	withImpactPortion = false,
}: ApprovisionnementListProps) => {
	const theme = useTheme();

	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();

	const columns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} valueType="string" label={t('approvisionnement-tab')} />;
				},
				accessor: 'label',
				component: 'TagLabel',
				editable: true,
				sortDescFirst: true,
				type: 'string',
				props: {
					gap: 8,
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
						maxWidth: 250,
					},
				},
				icon: <BoxIcon color={theme.palette.grey[700]} />,
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label="Volume" variant="measure" measure="massTon" />;
				},
				accessor: 'tonnage',
				component: 'SimpleCell',
				editable: true,
				sortDescFirst: true,
				type: 'number',
			},
			...(indicator === CARBON_INDICATOR
				? [
					{
						Header: (props: any) => {
							return (
								<TableHeaderCell
									column={props.column}
									label={
										selectedYear === ALL_YEAR_OPTION_VALUE
											? t(WORDING_TERMS.IMPACT_ALL_YEAR)
											: t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })
									}
									variant="measure"
									measure="carbonImpactTon"
								/>
							);
						},
						accessor: 'carbonImpactPercent',
						editable: true,
						sortDescFirst: true,
						component: 'PercentField',
						type: 'percent',
						props: {
							popoverComponent: ImpactCarbonePopover,
							// style: { justifyContent: 'flex-end' },
							popoverClassName: styles.popoverImpactStyle,
							className: styles.percent,

							emptyValue: (
								<HelpCircle color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500} />
							),
							color: { primary: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500 },
						},
					},
					{
						Header: (props: any) => {
							return (
								<TableHeaderCell
									column={props.column}
									label={t(WORDING_TERMS.INTENSITY)}
									variant="measure"
									measure="carbonIntensityKg"
								/>
							);
						},
						accessor: 'carbonIntensity',
						editable: true,
						sortDescFirst: true,
						component: 'IntensityField',
						type: 'number',
						props: {
							color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
						},
					},
					...(withImpactPortion
						? [
							{
								Header: (props: any) => {
									return (
										<TableHeaderCell
											column={props.column}
											label={t(WORDING_TERMS.IMPACT_PORTION)}
											variant="measure"
											measure="carbonImpactPortion"
										/>
									);
								},
								accessor: 'carbonImpactPortion',
								editable: true,
								sortDescFirst: true,
								component: 'ImpactPortion',
								type: 'number',
							},
						]
						: []),
				]
				: [
					{
						Header: (props: any) => {
							return (
								<TableHeaderCell
									column={props.column}
									label={
										selectedYear === ALL_YEAR_OPTION_VALUE
											? t(WORDING_TERMS.IMPACT_ALL_YEAR)
											: t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })
									}
									variant="measure"
									measure="waterImpact"
								/>
							);
						},
						accessor: 'waterImpactPercent',
						editable: true,
						sortDescFirst: true,
						component: 'PercentField',
						type: 'percent',
						props: {
							popoverComponent: WaterImpactPopover,
							popoverClassName: styles.popoverImpactStyle,
							className: styles.percent,
							emptyValue: (
								<HelpCircle color={indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500} />
							),
							color: { primary: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500 },
						},
					},
					{
						Header: (props: any) => {
							return (
								<TableHeaderCell
									column={props.column}
									label={t(WORDING_TERMS.INTENSITY)}
									variant="measure"
									measure="waterIntensity"
								/>
							);
						},
						accessor: 'waterIntensity',
						editable: true,
						sortDescFirst: true,
						component: 'IntensityField',
						type: 'number',
						props: {
							color: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
						},
					},
					...(withImpactPortion
						? [
							{
								Header: (props: any) => {
									return (
										<TableHeaderCell
											column={props.column}
											label={t(WORDING_TERMS.IMPACT_PORTION)}
											variant="measure"
											measure="waterImpactPortion"
										/>
									);
								},
								accessor: 'waterImpactPortion',
								editable: true,
								sortDescFirst: true,
								component: 'ImpactPortion',
								type: 'number',
							},
						]
						: []),
				]),

			...(withPercent
				? [
					{
						// Header: 'PRESENCE',
						Header: (props: any) => {
							return <TableHeaderCell column={props.column} label={t('percent-fourni')} />;
						},
						accessor: 'proportion',
						editable: true,
						sortDescFirst: true,
						type: 'number',
						component: 'Percentage',
						disableSortBy: true,
						props: {
							precision: 0,
							sx: { fonSize: '16px', fontWeight: 600, color: theme.palette.common.black },
						},
					},
				]
				: []),
		];
	}, [indicator, withPercent, theme, withImpactPortion, selectedYear, t]);

	return (
		<TableComponent
			pageCount={10}
			noDataMessage="Aucun produit ne correspond à la sélection."
			pageSize={100}
			columns={columns}
			data={orderBy(
				data.map((item: any) => {
					const object = new ProductModel(item);

					return {
						...item,
						isChecked: object.isN2ModelizationType(),
						icon:
							item.itemType === ITEM_TYPE_INGREDIENT ? (
								<Carrot color={theme.palette.grey[700]} />
							) : (
								<BoxIcon color={theme.palette.grey[700]} />
							),
					};
				}),
				indicator === 'carbon' ? 'carbonImpact' : 'waterImpact',
			)}
			manualPagination={false}
			addStyles={{
				table: {
					border: 'none!important',
				},
				'.bordered td, .bordered th': {
					borderLeft: 'none!important',
					background: `${theme.palette.common.white} !important`,
					borderBottom: `1px solid ${theme.palette.grey[400]}`,
				},
				'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div ,,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button':
				{
					justifyContent: 'flex-end!important',
					paddingRight: '0!important',
				},
			}}
			fetchData={(q: any) => {
				//
			}}
			loading={false}
			pagination={pagination}
			transFunc={t}
		/>
	);
};

export default SupplyTable;
