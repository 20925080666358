import BInput from '@carbonmaps/ui/components/saas/BInput';
import { css, cx } from '@emotion/css';
import { Tab, Tabs, TextField } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import z from 'zod';
import { cn, siteColors } from '../../../../lib/colors';
import { unionSchema } from './QuestionsTab';


const styles = {
	displayNone: css({
		display: 'none',
	}),
	marginTop4: css({
		marginTop: 24,
	}),
	gap3: css({
		gap: 18,
	}),

	optionLabel: css({
		padding: 12,
		cursor: 'pointer',
	}),
	optionHover: css({
		'&:hover': {
			backgroundColor: siteColors.grey200,
		},
	}),
	optionSelected: css({
		backgroundColor: siteColors.carbon100,
	}),

	multipleChoiceItem: css({
		padding: 6,
		marginBottom: 12,
	}),

	allPoints: css({

		// background: 'red'
	}),

	contentInput: css({
		maxWidth: '125px',
		gap: '0px',

	})
};


type Prettify<T> = {
	[K in keyof T]: T[K];
} & {};


type UnionType = Prettify<z.infer<typeof unionSchema>>;

const TYPE_SCORE_MASTER = ['A+', 'A', 'B', 'C', 'D', 'E'];

type PointChildComponentProps = {
	form: UseFormReturn<UnionType>;
	index: number;
	code: 'default' | 'A+' | 'A' | 'B' | 'C' | 'D' | 'E';
	typeResponse: 'multiple_choice_answers';
};


const PointTab: React.FC<PointChildComponentProps> = ({ form, index, code, typeResponse }) => {
	return <BInput
		label={code === 'default' ? 'Points' : `${code}`}
		type="number"
		{...form.register(`${typeResponse}.${index}.points.${code}` as const, { valueAsNumber: true })}

		error={_.get(form.formState.errors, `${typeResponse}.${index}.points.${code}`)}
		errorMessage={_.get(form.formState.errors, `${typeResponse}.${index}.points.${code}`)}
		className={cx(styles.contentInput, 'hiddenArrows')}
	/>

}


const PointTabYesNo: any = ({ form, code, type }: { form: any, code: any, type: 'yes | no' }) => {
	const typeResponse = 'yes_no_answers';
	return (
		<BInput
			label={code === 'default' ? 'Points' : `${code}`}
			type="number"
			{...form.register(`${typeResponse}.${type}.points.${code}` as const, { valueAsNumber: true })}

			error={_.get(form.formState.errors, `${typeResponse}.${type}.points.${code}`)}
			errorMessage={_.get(form.formState.errors, `${typeResponse}.${type}.points.${code}`)}
			className={cx(styles.contentInput, 'hiddenArrows')}
		/>


	);
}


const PointStatement: any = ({ form, code }: { form: any, code: any }) => {
	const typeResponse = 'statement_answer';
	return (
		<BInput
			label={code === 'default' ? 'Points' : `Points ${code}`}
			type="number"
			{...form.register(`${typeResponse}.points.${code}` as const, { valueAsNumber: true })}

			error={_.get(form.formState.errors, `${typeResponse}.points.${code}`)}
			errorMessage={_.get(form.formState.errors, `${typeResponse}.points.${code}`)}
			className={cx('hiddenArrows')}
		/>
	);
}

const PointNumber: any = ({ form, code }: { form: any, code: any }) => {
	const typeResponse = 'number_answer';
	return (
		<BInput
			label={code === 'default' ? 'Points' : `${code}`}
			type="number"
			{...form.register(`${typeResponse}.points.${code}` as const, { valueAsNumber: true })}

			error={_.get(form.formState.errors, `${typeResponse}.points.${code}`)}
			errorMessage={_.get(form.formState.errors, `${typeResponse}.points.${code}`)}
			className={cx('hiddenArrows')}
		/>
	);
}

type ChildComponentProps = {
	form: UseFormReturn<UnionType>;
	index: number;
	typeResponse: 'multiple_choice_answers' | 'yes_no_answers' | 'statement_answer' | 'number_answer';
	masterQuestion?: boolean;
	type: 'yes' | 'no' | 'statement' | 'number';
};

const QuestionMasterPoints: React.FC<ChildComponentProps> = ({ form, index, typeResponse, masterQuestion, type }) => {
	// const [codePoint, setCodePoint] = useState(TYPE_SCORE_MASTER[0]);


	// const handleChange = (event: React.SyntheticEvent, newValue: string) => {
	// 	setCodePoint(newValue);
	// };

	if (!masterQuestion) {
		return (
			<div>
				{typeResponse === "multiple_choice_answers" && <PointTab form={form} index={index} code={'default'} typeResponse={'multiple_choice_answers'} />}

				{typeResponse === "yes_no_answers" && <PointTabYesNo form={form} type={type} code={'default'} />}

				{typeResponse === "statement_answer" && <PointStatement form={form} code={'default'} />}

				{typeResponse === "number_answer" && <PointNumber form={form} code={'default'} />}


			</div>

		);

	}

	return (
		<div>
			{/* <Tabs value={codePoint} onChange={handleChange} aria-label="wrapped label tabs example"> */}
			{/* {TYPE_SCORE_MASTER?.map((item: string) => {
					return <PointTab form={form} index={index} code={item as any} typeResponse={typeResponse} />;
				})} */}

			{/* </Tabs> */}


			<div className={cx('flexRow gap16', styles.allPoints)}>
				{typeResponse === 'multiple_choice_answers' &&
					// <PointTab form={form} index={index} code={codePoint as any} typeResponse={typeResponse} />

					TYPE_SCORE_MASTER?.map((item: string) => {
						return <PointTab form={form} index={index} code={item as any} typeResponse={typeResponse} />;
					})
				}


				{typeResponse === 'yes_no_answers'
					&&

					TYPE_SCORE_MASTER?.map((item: string) => {
						return <PointTabYesNo form={form} index={index} code={item as any} type={'yes_no'} />;
					})

				}

			</div>


			{/* {typeResponse === 'statement_answer'
					&& TYPE_SCORE_MASTER.includes(codePoint) &&
					<PointStatement form={form} code={codePoint as any} />}


				{typeResponse === 'number_answer'
					&& TYPE_SCORE_MASTER.includes(codePoint) &&
					<PointNumber form={form} code={codePoint as any} />} */}
		</div>


	);
};

export default QuestionMasterPoints;
