import { useMemo, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { Atom, Factory, PackageOpen, Trash2, Truck } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LifeCycleChartTooltip from '../../../../components/charts/LifeCycleChartTooltip';

import Typography from '../../../../components/Typography';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/v3/Card';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useSize } from '../../../../hooks/useSize';
import { siteColors } from '../../../../lib/colors';
import { getDataLifeCyclePackagingAction } from '../../../../lib/react-query/features/packaging/packaging.actions';
import PLifeCycleChart from '../../../packagingSheet/synthesis/PLifecycleChart';

const styles = {
	axisContainer: css({
		paddingTop: 12,
		'.lifecycle-x-label-text': {
			color: `${siteColors.grey7} !important`,
			lineHeight: '1 !important',
			paddingTop: 2,
		},
	}),
	container: css({
		minHeight: 150,
	}),
};

const getConfigMap = ({
	t,
	indicator,
	iconSize = 16,
	iconColor = siteColors.grey700,
}: {
	t: TFunction;
	indicator: string;
	iconSize?: number;
	iconColor?: string;
}) => {
	const config = new Map([
		[
			'materials',
			{
				icon: <Atom width={iconSize} height={iconSize} color={iconColor} />,
				getToolTip: ({ value }: { value: number }) => (
					<LifeCycleChartTooltip
						value={value}
						label={t('materials')}
						unit={<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpactTon'} />}
						precision={2}
					/>
				),
				xLabel: t('materials'),
			},
		],
		[
			'transformation',
			{
				icon: <Factory width={iconSize} height={iconSize} color={iconColor} />,
				getToolTip: ({ value }: { value: number }) => (
					<LifeCycleChartTooltip
						value={value}
						label={t('transformation')}
						unit={<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpactTon'} />}
						precision={2}
					/>
				),
				xLabel: t('transformation'),
			},
		],
		[
			'transport',
			{
				icon: <Truck width={iconSize} height={iconSize} color={iconColor} />,
				getToolTip: ({ value }: { value: number }) => (
					<LifeCycleChartTooltip
						value={value}
						label={t('transport')}
						unit={<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpactTon'} />}
						precision={2}
					/>
				),
				xLabel: t('transport'),
			},
		],
		[
			'endOfLife',
			{
				icon: <Trash2 width={iconSize} height={iconSize} color={iconColor} />,
				getToolTip: ({ value }: { value: number }) => (
					<LifeCycleChartTooltip
						value={value}
						label={t('end-of-life')}
						unit={<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpactTon'} />}
						precision={2}
					/>
				),
				xLabel: t('end-of-life'),
			},
		],
	]);

	return config;
};

const formatData = ({ data, config }: { data?: any[]; config: Map<string, any> }) => {
	if (!data || !_.isArray(data)) {
		return [];
	}

	return data.map((d) => {
		const key = _.get(d, 'key');
		const configItem = config.get(key);

		return {
			...d,
			...configItem,
			value: _.get(d, 'percentage'),
			actualValue: _.get(d, 'value'),
			tooltip: configItem?.getToolTip({ value: _.get(d, 'value') }),
		};
	});
};

const PackagingLifeChart = ({
	heightGraph = 180,
	bottomPosition = 50,
	isModelizationN2,
}: {
	heightGraph?: number;
	bottomPosition?: number;
	iconSize?: number;
	isModelizationN2?: boolean;
}) => {
	const theme = useTheme();
	const { indicator: viewMode } = useApp();
	const params = useParams();

	const { selectedYear } = useYearSelection();

	// translation
	const { t } = useTranslation();

	// size
	const containerRef = useRef(null);
	const size = useSize(containerRef);

	const { indicator } = useApp();

	// ---- fetch data graph for this period selected ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getDataLifeCyclePackaging',
			{
				selectedYear,

				indicator,
			},
		] as const,
		queryFn: getDataLifeCyclePackagingAction,
	});

	const svgParentSize = useMemo(() => {
		if (!size) {
			return {
				width: 1008,
				height: 135,
			};
		}

		const height = heightGraph || 220;

		return {
			width: (size as any)?.width || 1008,
			height: 135,
		};
	}, [size, heightGraph]);

	const config = getConfigMap({ t: t as never, indicator });

	const finalData = formatData({ data: _.get(data, 'data'), config });

	const noDataContent = (
		<div className={cx('flexCenter textCenter')} css={{ color: siteColors.grey700 }}>
			<div>{t('life-chart-error-modelization-version')}</div>
		</div>
	);

	const hasNoData = _.get(data, 'status') !== 'SUCCESS';
	// const hasNoData = true;

	return (
		<Card
			title={
				<Typography variant="h3" className="grey8 fontWeight600">
					{t('Cycle de vie des emballages')}
				</Typography>
			}
			loading={isLoading}
			containerClassName={styles.container}
		>
			<div css={{ width: '100%' }} ref={containerRef}>
				{isModelizationN2 ? (
					<PLifeCycleChart
						datas={finalData}
						viewBox={`0 0 ${svgParentSize.width} ${svgParentSize.height}`}
						withLabel
						colors={
							viewMode === WATER_INDICATOR
								? {
										main: siteColors.water500,
										line: siteColors.water100,
										polygonGradient: [siteColors.water100, siteColors.water100],
								  }
								: {
										main: siteColors.carbon500,
										line: siteColors.carbon100,
										polygonGradient: [siteColors.carbon100, siteColors.carbon100],
								  }
						}
						areaHeight={svgParentSize.height}
						areaWidth={svgParentSize.width}
						labelColor={viewMode === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500}
						bottomPosition={bottomPosition}
						strokeWidth={1.5}
						lineWidth={2}
						fontStyles={{
							title: 12,
							subtitle: 12,
						}}
						topValueContainer={{
							width: 70,
							height: 30,
						}}
						axisClassName={styles.axisContainer}
					/>
				) : (
					noDataContent
				)}
			</div>
		</Card>
	);
};

export default PackagingLifeChart;
