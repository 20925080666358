import { toLowerCase } from '@carbonmaps/ui/utils/utils';
import { css } from '@emotion/css';
import LightTooltipGlossary from '../../../../../components/LightTooltipGlossary';
import { useGlossary } from '../../../../../hooks/useGlossary';
import { useTranslation } from '../../../../../hooks/useTranslation';

const styles = {
	intro: css({
		padding: '32px 16px 8px 16px',
	}),
};

const AuditModelizationsIntro = () => {
	const { t } = useTranslation();
	const { g } = useGlossary();

	return (
		<>
			<div className={styles.intro}>
				{t('Cet écran vous permet de connaître la')}{' '}
				<LightTooltipGlossary title={g('methode-de-modelisation-produit-')}>
					<span className="underline">{toLowerCase(t('Modélisation'))}</span>
				</LightTooltipGlossary>{' '}
				{t('utilisée pour un produit dans les autres modules et sur votre dashboard', {
					product_lowercase: toLowerCase(t('product')),
				})}
				.<br />
				{t('En cas de')}{' '}
				<LightTooltipGlossary title={g('modelization-custom')}>
					<span className="underline">{toLowerCase(t('Modélisation personnalisée'))}</span>
				</LightTooltipGlossary>{' '}
				{t('il vous permet également de comparer l’impact de la personnalisation sur le scoring de votre produit', {
					product_lowercase: toLowerCase(t('product')),
				})}
				.
			</div>
		</>
	);
};

export default AuditModelizationsIntro;
