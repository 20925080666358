import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Typography, useTheme } from '@mui/material';
import MUILink from '@mui/material/Link';
import { darken, emphasize, lighten } from '@mui/system/colorManipulator';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';

import { CARBON_INDICATOR, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import {
	getProductTableDataAction,
	getProductTableDataActionPackagingUses,
	getProductTradeoffDataAction,
} from '@carbonmaps/ui/actions/product.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import BlueCheck from '../../../components/BlueCheck';
import Skeleton from '../../../components/Skeleton';
import PackagingLineBar from '../../../components/packaging/PackagingLineBar';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetPackagingSheetTimePeriod } from '../../../lib/react-query/features/packaging/packaging.hooks';
import PackagingModel from '../../../models/Packaging.model';
import { useFindPackagingDetailRecycled } from '../packaging-sheet.hooks';

const styles = {
	block: css({
		background: 'none',
		padding: '0!important',
	}),
	donutContainer: css({
		width: '30%',
		// height: '268px',
	}),

	mainClassName: css({
		marginTop: '-2.5px',
	}),
	textClassName: css({
		fontSize: '90% !important',
	}),

	diffMainClass: css({
		fontSize: '10px!important',
		padding: '1px!important',
		'& svg': {
			width: '10px',
		},
	}),

	skeleton: css({
		height: '300px !important',
		width: '100% !important',
	} as any),

	icon: css({
		width: 24,
		'& svg': {
			width: '100% !important',
		},
	}),

	graphContainer: css({
		// gap: '44px',
		// padding: '0!important',
		gap: '32px',
		padding: '24px',
		color: cn(siteColors.grey700, '!important'),
	}),
	gap8: css({
		gap: 8,
	}),

	gap16: css({
		gap: 16,
	}),
	miniFont: css({
		fontSize: 12,
	}),

	circle: css({
		width: 8,
		height: 8,
		borderRadius: '50%',
		background: 'var(--backgroundColor)',
		marginTop: 4,
	}),

	label: css({
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		maxWidth: 300,
		//textWrap: 'nowrap',
		lineHeight: '1 !important',
		color: cn(siteColors.grey900, '!important'),
	}),
	value: css({
		color: cn(siteColors.grey800, '!important'),
		lineHeight: '1 !important',
	}),
	noUnderline: css({
		textDecoration: 'none !important',
	}),
};
type LegendProps = {
	color: string;
	label: string;
	value?: number;
	objectId: string;
};

const Legend = ({ color, label, value = 0, objectId }: LegendProps) => {
	return (
		<MUILink
			component={Link}
			// target='_blank'
			key={objectId}
			className={styles.noUnderline}
			to={`${FRONT_PATH_NAMES.products.details}/${objectId}/synthesis`}
		>
			<div className="flexRow gap8 nowrap">
				<div className={styles.circle} style={{ ['--backgroundColor' as any]: color }} />
				<div className="flexColumn flex1 gap8">
					<Typography className={styles.label}>{label}</Typography>
					<Typography variant="caption" className={cx(styles.value, 'number')}>
						{formatNumber(value, undefined, 1)} %
					</Typography>
				</div>
			</div>
		</MUILink>
	);
};

type ListItemProps = {
	part: any;
};

const PackagingRecycledMaterials = () => {
	const params = useParams();
	const { t } = useTranslation();

	const { data: _data, isLoading } = useFindPackagingDetailRecycled({ packagingId: _.toString(params.packagingId) });

	const data = useMemo(() => {
		if (!_data || _.get(_data, 'status') !== 'ok') {
			return [];
		}

		const recycled = _.get(_data, 'recycled', 0);

		return [
			{
				percentValue: recycled,
				label: t('recycled'),
				color: siteColors.green500,
			},
			{
				percentValue: 100 - recycled,
				label: t('not-recycled'),
				color: siteColors.grey3,
			},
		];
	}, [_data, t]);

	const content = (
		<div className={cx('flexCenter gap16 textCenter ', styles.graphContainer)}>
			{_.isEmpty(data) || _.isNil(_.get(_data, 'recycled')) || _.get(_data, 'recycled') <= 0 ? (
				t(
					// /* _.get(_data, 'recycled') <= 0 ? 'packaging-lifecycle-no-data-message' :  */ 'empty-recycled-packaging-label',
					_.get(_data, 'recycled') <= 0 ? 'empty-recycled-packaging-label' : 'packaging-lifecycle-no-data-message',
				)
			) : (
				<PackagingLineBar data={data} noPaddingTop />
			)}
		</div>
	);

	return (
		<>
			<BlockTitle className={cx('flexRow alignCenter')}>{t('packaging-detail-recycled-material-title')}</BlockTitle>
			<BlockCard className={cx(styles.block)}>
				{isLoading ? (
					<Skeleton width="100%!important" className={styles.skeleton}>
						{content}
					</Skeleton>
				) : (
					content
				)}
			</BlockCard>
		</>
	);
};

const tooltipStyles = {
	container: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
		padding: '16px',
		gap: 4,
		minWidth: '200px',
	}),
};

export const ToolTip = ({ item }: any) => {
	return (
		<div className={cx('flexRow alignCenter nowrap', tooltipStyles.container)}>
			<BlueCheck fontSize={12} title={''} iconSize={14} withIcon={item?.isBlueCheck} />
			<MUILink
				component={Link}
				// target='_blank'
				key={item?.objectId}
				className={styles.noUnderline}
				to={`${FRONT_PATH_NAMES.products.details}/${item?.objectId}/synthesis`}
			>
				<Typography className="flex1">
					<span className="fontWeight600">{formatNumber(item?.value, undefined, 1)}</span> % {item?.name}
				</Typography>
			</MUILink>
		</div>
	);
};

export default PackagingRecycledMaterials;
