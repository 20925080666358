import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Carrot, HelpCircle } from 'lucide-react';
import { createSearchParams, useParams, useSearchParams } from 'react-router-dom';

import { ALL_YEAR_OPTION_VALUE, FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { GetParams, getIngredients } from '@carbonmaps/ui/actions/ingredient.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import TableHeaderCell from '../../../components/table/TableHeaderCell';
import ImpactCarbonePopover from '../../../components/table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../../../components/table/popover/WaterImpactPopover';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useFindIngredientFamily } from '../../../lib/react-query/features/ingredient/ingredient.hooks';
import { CARBON_INDICATOR } from '../../../utils/constants';
import { supplierCheckedCount } from '../../../utils/supplier';

const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},
		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered tr td:nth-of-type(4) div,.bordered tr td:nth-of-type(6) div': {
			justifyContent: 'flex-end',
		},
		'.bordered tr th:not(:nth-of-type(2),:nth-of-type(2),:nth-of-type(7)) div': {
			justifyContent: 'right',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div ,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) p':
		{
			justifyContent: 'flex-end!important',
			paddingRight: '0!important',
		},
	};
};

const formatThenSetData = (res: any, indicator: string) => {
	if (!res?.length) return [];

	const maxCarboneIntensity: any = _.maxBy(res, 'carbonIntensity');
	const maxWaterIntensity: any = _.maxBy(res, 'waterIntensity');
	return res.map((item: any) => {
		const showAlert =
			item.carbonImpact === 0 && item.carbonIntensity !== null && (item.sumUvc === 0 || item.volume === 0) ? 1 : 0;
		const showDataviz = item.carbonIntensity && item.carbonIntensity !== 0 ? 1 : 0;
		const groupCount = supplierCheckedCount(item?.suppliers);
		return {
			...item,
			numberSupplierFullfilled: groupCount.yes,
			numberSupplierNotFullfilled: groupCount.no, //		origin: item.uid,
			water: item.waterIntensity ? formatNumber(item.waterIntensity, undefined, 2) : '-', //to remove in refonte
			percentage:
				(indicator === CARBON_INDICATOR
					? (item.carbonIntensity * 100) / maxCarboneIntensity?.carbonIntensity
					: (item.waterIntensity * 100) / maxWaterIntensity?.waterIntensity) || 0,
			carboneImpactRaw: item.carbonImpact / 1000,
			ref: item.uid,
			origin: item.uid,
			showDataviz,
			showAlert,
			linkSupplier: item?.allSuppliers?.[0]
				? `${FRONT_PATH_NAMES.suppliers.general}?supplierIds=${JSON.stringify(_.uniq(item.allSuppliers[0]))}`
				: null,
			uid: item.uid,
		};
	});
};

type IngredientTableProps = {
	filterOptions: any;
};

const FamilyTable = ({ filterOptions }: IngredientTableProps) => {
	// query params
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	// translation
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();
	// theme
	const theme = useTheme();
	// switch value ( carbon or water )
	const { indicator } = useApp();
	// custom style tab
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<GetParams>({
		input: '',
		page: 1,
		size: 100,
		facetFilters: [],
		supplierIds: [], // supplier filters
		direction: 0,
		sortingBy: null,
		...filterOptions,
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);
	const params = useParams();
	// ---- table columns
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('ingredient')} />;
				},
				accessor: 'label',
				component: 'TagLabel',
				editable: true,
				sortDescFirst: true,
				type: 'string',
				icon: <Carrot width={20} height={20} color={siteColors.grey700} />,
				props: {
					gap: 8,
					style: {
						color: theme.palette.common.black,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
						textTransform: 'uppercase !important',
					},
					sx: {
						cursor: 'initial',
					},
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('suppliers')} />;
				},
				accessor: 'suppliers',
				component: 'Supplier',
				editable: true,
				sortDescFirst: true,
				type: 'number',
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={
								selectedYear === ALL_YEAR_OPTION_VALUE
									? t(WORDING_TERMS.IMPACT_ALL_YEAR)
									: t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })
							}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonImpactPercent' : 'waterImpactPercent',
				editable: true,
				sortDescFirst: true,
				component: 'PercentField',
				type: 'percent',
				props: {
					...(indicator === CARBON_INDICATOR
						? {
							popoverComponent: ImpactCarbonePopover,

							popoverClassName: styles.popoverImpactStyle,
							className: styles.percent,
							emptyValue: <HelpCircle color={siteColors.carbon500} />,
						}
						: {
							color: {
								primary: siteColors.water500,
								secondary: siteColors.grey500,
							},
							popoverComponent: WaterImpactPopover,
							popoverClassName: styles.popoverImpactStyle,
							className: styles.percent,
							emptyValue: <HelpCircle color={siteColors.water500} />,
						}),
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							//label={indicator === CARBON_INDICATOR ? WORDING_TERMS.INTENSITY : 'Intensité eau'}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					...(indicator !== CARBON_INDICATOR
						? {
							color: {
								primary: siteColors.water500,
								secondary: siteColors.grey500,
							},
						}
						: null),
				},
			},
			// {
			// 	Header: (props: any) => {
			// 		return (
			// 			<TableHeaderCell
			// 				column={props.column}
			// 				label="Volume"
			// 				variant="unit"
			// 				unit={<Typography color={siteColors.grey700}>Tonnes</Typography>}
			// 			/>
			// 		);
			// 	},
			// 	accessor: 'volume',
			// 	editable: true,
			// 	sortDescFirst: true,
			// 	component: 'SoldItemsField',
			// 	type: 'number',
			// },
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('uses')} /* measure="carbonIntensity" */ />;
				},
				accessor: 'countUse',
				component: 'SimpleCell',
				type: 'number',
				editable: true,
				sortDescFirst: true,
				props: {
					style: {
						fontSize: '14px',
					},
				},
			},
		];
	}, [indicator, t, selectedYear]);


	const [_searchParams] = useSearchParams();
	const paramType = _searchParams.get('paramType');

	const {
		result: { data: dataResult, isLoading },
	} = useFindIngredientFamily({
		params: {
			...paramsTable,
			viewMode: indicator,
			ingredientId: params.ingredientId,
			paramType,
		},
	});

	const { skeleton } = useSkeleton({ condition: isLoading });

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...filterOptions };
		});
		// redirect to first page
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions]);

	return (
		<TableComponent
			skeleton={skeleton}
			loading={false}
			fetchData={updateTableParams}
			pageCount={dataResult?.meta?.last_page || 0}
			noDataMessage={t('ingredient-family-table-empty-data', {
				ingredients: toLowerCase(t('ingredients')),
				ingredient: toLowerCase(t('ingredient')),
				an_ingredient: t('an_ingredient'),
			})}
			pageSize={paramsTable.size}
			columns={tableColumns}
			resetPage={resetPage}
			data={formatThenSetData(dataResult?.ingredients, indicator) || []}
			onSortingColumn={handleSortingTable}
			addStyles={stylesTable}
			transFunc={t}
			manualSortBy
			setSelectedRow={() => {
				return 1;
			}}
		/>
	);
};

export default FamilyTable;
