import { Fragment, SyntheticEvent, useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { ITab } from '../../../components/layout/page/SheetPageLayout';
import TabsBar, { TabItem } from '../../../components/TabsBar';
import { useTranslation } from '../../../hooks/useTranslation';

import _ from 'lodash';
import QuestionFormsHeader from './QuestionFormsHeader';
import QuestionFormsSynthesis from './tabs/QuestionFormsSynthesis';
import QuestionListHistory from './tabs/QuestionListHistory';

const tabs: Record<string, ITab> = {
	synthesis: {
		title: 'synthesis-tab',
		element: <QuestionFormsSynthesis />,
	},
	history: {
		title: 'historique',
		element: <QuestionListHistory />,
	},
};

const styles = {
	tabContainer: css({
		top: 58,
		margin: '0 auto',
		marginTop: '17px !important',
		position: 'sticky',
		// width: 'calc(100% - 64px)',
		'&.sticky': { width: '100%', borderRadius: 0 },
		marginBottom: 32,
	}),

	contentContainerClassName: css({
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	}),
};

const QuestionFormsGeneral = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { setBreadcrumbs } = useApp();

	const currentTab = params.tab || _.keys(tabs)[0];

	useEffect(() => {
		const title2 = _.get(tabs[currentTab], 'title');
		setBreadcrumbs([{ text: t('Collecter') }, ...(title2 ? [{ text: t(title2) }] : [])]);
	}, [t, params.tab]);

	const tabEntries = Object.entries(tabs);

	const defaultTab = tabEntries[0];

	const onTabChange = (_: SyntheticEvent, value: string) => {
		navigate(`${FRONT_PATH_NAMES.questionForms}/general/${value}`);
	};

	return (
		<Container
			header={<QuestionFormsHeader />}
			containedContent={false}
			contentContainerClassName={styles.contentContainerClassName}
		>
			<TabsBar
				value={params.tab || defaultTab[0]}
				onChange={onTabChange}
				className={cx('widthContainer', styles.tabContainer)}
			>
				{tabEntries.map(([tabName, { title, disabled }]) => {
					return <TabItem key={tabName} label={t(title)} value={tabName} disabled={disabled} />;
				})}
			</TabsBar>
			<div className={cx('widthContainer')}>
				{tabEntries.map(([tabName, { element }]) => {
					return tabName === (params.tab || defaultTab[0]) && <Fragment key={tabName}>{element}</Fragment>;
				})}
			</div>
		</Container>
	);
};

export default QuestionFormsGeneral;
