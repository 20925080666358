import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { PackageOpen, PieChart } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { usePackagingProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import { useTranslation } from '../../../hooks/useTranslation';
import ProductModel from '../../../models/Product.model';

import { IPackaging } from '@carbonmaps/shared/types/packaging.types';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { CARBON_INDICATOR } from '../../../utils/constants';
import DonutProductChart from './DonutProductChart';

const ProductDonutBlock = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const params = useParams();

	const { t } = useTranslation();

	const handleViewDetailsClick = () => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		navigate(`${FRONT_PATH_NAMES.products.details}/${params.productId!}/packaging`);
	};

	const { indicator, setIndicator } = useApp();

	const { data: modelizationData, isLoading } = usePackagingProduct({
		productId: params.productId!,
	});

	const _modelizationData = _.isString(modelizationData) ? {} : modelizationData;

	const data = useMemo(() => {
		return _.get(_modelizationData, 'packaging', []).map((item: IPackaging) => {
			const productModel = new ProductModel(_modelizationData);

			let value = 0;

			switch (indicator) {
				case CARBON_INDICATOR:
					value = productModel.getIntensity('gesPackaging')
						? ((item?.gesWeighted || 0) / productModel.getIntensity('gesPackaging')) * 100
						: 0;
					break;
				case WATER_INDICATOR:
					value = productModel.getIntensity('waterUsePackaging')
						? ((item?.waterUseWeighted || 0) / productModel.getIntensity('waterUsePackaging')) * 100
						: 0;
					break;
				default:
					value = productModel.getIntensity('gesPackaging')
						? ((item?.gesWeighted || 0) / productModel.getIntensity('gesPackaging')) * 100
						: 0;
					break;
			}

			return {
				value,
				name: item?.packaging?.get('label'),
			};
		});
	}, [_modelizationData, indicator]);

	const data2 = useMemo(() => {
		let value = 0;
		let res;

		const productModel = new ProductModel(_modelizationData as any);

		switch (indicator) {
			case CARBON_INDICATOR:
				res = productModel.getIntensity('gesPackaging')?.toFixed(2);
				if (res) value = parseFloat(res);
				break;

			case WATER_INDICATOR:
				res = productModel.getIntensity('waterUsePackaging')?.toFixed(2);
				if (res) value = parseFloat(res);
				break;

			default:
				res = productModel.getIntensity('gesPackaging')?.toFixed(2);
				if (res) value = parseFloat(res);
				break;
		}

		return value;
	}, [data, indicator, theme]);

	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBON_INDICATOR);
	}, [setIndicator]);

	return (
		<>
			<BlockTitle icon={<PackageOpen size={20} color={theme.palette.grey[700]} />}>
				{/* {t('breakdown_by_packaging_graph_title')} */}
				{t('emballage')}
			</BlockTitle>
			<BlockCard>
				<Box display="flex" justifyContent="flex-end">
					<BButton variant="secondary" label={t('see_more_packaging_details')} onClick={handleViewDetailsClick} />
				</Box>
				{!isLoading && (
					<DonutProductChart
						key={indicator}
						data={data || []}
						data2={{
							value: data2,
							name: 'KG',
						}}
						indicator={indicator}
					/>
				)}
			</BlockCard>
		</>
	);
};

export default ProductDonutBlock;
