import { useEffect, useState } from 'react';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useTranslation } from '../../../hooks/useTranslation';

import { css, cx } from '@emotion/css';
import { Tab, Tabs } from '@mui/material';
import { Database, Leaf } from 'lucide-react';
import { siteColors } from '../../../lib/colors';

import CarbonFootPrint from './CarbonFootprint';
import VersionHeader from './VersionHeader';
import VersionTable from './VersionTable';

const items = [
	{
		label: 'Import',
		value: 'version-tab',
		icon: Database,
		element: <VersionTable />,
		tabName: 'version-tab',
	},
	{
		label: 'Bilan carbone',
		value: 'carbon-footprint',
		icon: Leaf,
		element: <CarbonFootPrint />,
		tabName: 'Ma performance extra-financière',
	},
];

const styles = {
	hide: css({
		display: 'none',
	}),
	show: css({
		display: 'block',
	}),
	container: css({
		marginTop: 16,
		width: '100%',
		background: siteColors.grey300,
		borderRadius: '16px',
		padding: '8px',
		'& .MuiTabs-flexContainer': {
			gap: 8,
			justifyContent: 'center !important',
		},
		'& .MuiButtonBase-root': {
			textTransform: 'none',
			display: 'flex',
			flex: '1 0 0 ',
			alignItems: 'center',
			minHeight: '52px !important',
			gap: 8,
			fontSize: '16px !important',
		},
		'& .MuiTabs-scroller': {
			overflow: 'initial !important',
		},
		'& .Mui-selected': {
			borderRadius: '8px',
			background: siteColors.common.white,
			boxShadow: '0px 2px 1px 0px rgba(93, 17, 230, 0.18), 0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
		},
		'& .MuiTabs-indicator': {
			background: 'none !important',
			//height: '2px !important',
		},
	} as any),
};

const Version = () => {
	const { setBreadcrumbs } = useApp();

	const { t } = useTranslation();
	const [currentTab, setCurrentTab] = useState('version-tab');

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setCurrentTab(newValue);
	};

	// ---- breadcrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([
			{ text: t('company-breadcrumbs'), link: `${FRONT_PATH_NAMES.superAdminSettings}/companies` },
			{ text: t('version-title') },
		]);
	}, [setBreadcrumbs, t]);

	return (
		<Container header={<VersionHeader subtitle={'test'} />} seoText={`${t('version-title')} - Carbon Maps`}>
			<Tabs
				onChange={handleTabChange}
				aria-label="lab API tabs example"
				value={currentTab}
				className={cx(styles.container)}
			>
				{items.map((item: any, index: number) => {
					const IconComponent = item?.icon;
					const iconColor = currentTab === item.value ? siteColors.primary : siteColors.grey700;
					return (
						<Tab
							label={
								<div className="flexRow alignCenter gap8 nowrap textAlignLeft">
									<IconComponent color={iconColor} size={20} />
									{item.label}
								</div>
							}
							value={item.value}
							key={index}
							disabled={item?.disabled}
						/>
					);
				})}
			</Tabs>
			{items?.map((item: any, index) => {
				return (
					<div key={index} className={item.value === currentTab ? styles.show : styles.hide}>
						{item.element}
					</div>
				);
			})}
		</Container>
	);
};

export default Version;
