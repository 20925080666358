import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import { Box } from 'lucide-react';

import { productCompanyCount } from '@carbonmaps/ui/actions/product.actions';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';

const ProductsHeader = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	const { selectedYear } = useYearSelection();

	// ---- fetch count product ---- //
	// get count product for a company. this count will appear in header list
	const { data: productCount } = useQuery({
		queryKey: ['getProductCompanyCount', { period: selectedYear }],
		queryFn: productCompanyCount,
	});

	return (
		<ListHeader
			title={t('products')}
			icon={<IconContainer element={<Box color={theme.palette.common.white} size={32} />} />}
		/>
	);
};

export default ProductsHeader;
