import { css, cx } from '@emotion/css';

import { ReactComponent as ProductIcon } from '@carbonmaps/media/icons/product-chart-icon.svg';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DonutChart from '../../../../components/charts/DonutChart';
import Skeleton from '../../../../components/Skeleton';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { orderBy } from '../../../../utils/array.utils';
import { DEFAULT_GRAPH_INDICATOR_OPTIONS, getDefaultGraphIndicator } from '../../../../utils/graph';
import { useBreakDownProduct } from '../../analyse.hooks';
import AnalysisDonutContent from '../component/graph/AnalysisDonutContent';
import TooltipProductChart from '../component/graph/TooltipProductChart';
const styles = {
	container: css({ gap: '24px' }),
	skeleton: css({
		width: '100% !important',
		height: '40px !important',
	}),
};

const toDataIndicatorGraph = (
	data: Record<string, string | number>[],
	category: string,
	indicatorField: string,
	isDisabled?: boolean,
	viewMode?: string,
) => {
	if (!data) {
		return [
			{
				y: 1,
			},
		];
	}

	const result = data.map((item /* , index */) => {
		// let color = siteColors.primary;

		// if (isEven(index)) {}

		return {
			...item,
			category,
			categoryValue: item?.label,
			y: isDisabled ? 1 : Math.abs((item[`${viewMode}Impact`] || item[indicatorField]) as any),
			volume: item?.soldItems,
			// color,
			// color: isEven(index) ? siteColors.primary : siteColors.carbon300,
		};
	});

	return result;
};
/*
function isEven(n: number) {
	return n % 2 == 0;
} */

const ProductDonut = () => {
	const { t } = useTranslation();
	const { indicator: viewMode } = useApp();
	// ---- fetch data ---- //
	const { data, isLoading } = useBreakDownProduct({ viewMode });

	const defaultIndicator = useMemo(() => {
		return getDefaultGraphIndicator(t);
	}, [t]);

	const [indicatorOptions, setIndicatorOptions] = useState(defaultIndicator);

	const [indicatorGraphValue, setIndicatorGraphValue] = useState(DEFAULT_GRAPH_INDICATOR_OPTIONS[viewMode][0]);

	useEffect(() => {
		setIndicatorGraphValue((indicatorOptions as any)[viewMode][0]);
	}, [viewMode, indicatorOptions]);

	const navigate = useNavigate();
	const params = useParams();
	const handleClick = (value: string) => {
		navigate(`${FRONT_PATH_NAMES.analyse}/${params.segment}/${params.value}/${value}`);
	};

	// return null;

	const _data = toDataIndicatorGraph(data?.products || [], t('products'), `${viewMode}Impact`, false, viewMode) as any;

	return (
		<div className={cx('flexColumn alignCenter', styles.container)}>
			<DonutChart
				data={_data}
				color={isLoading ? siteColors.grey400 : siteColors.primary}
				content={
					isLoading ? (
						<Skeleton className={styles.skeleton} />
					) : (
						<AnalysisDonutContent icon={<ProductIcon />} label={t('products')} value={data?.count || 0} />
					)
				}
				width={250}
				innerRadius={32}
				renderTooltip={(item: any, indicator: any) => {
					return <TooltipProductChart item={item} indicator={indicator} viewMode={viewMode} />;
				}}
				indicator={indicatorGraphValue}
				onClick={(item: any) => {
					if (item.objectId === 'other') return;
					window.open(`${FRONT_PATH_NAMES.products.details}/${item?.originalId}/synthesis`);
				}}

			// onClick={(item) => {
			// 	if (!path || item?.isOther) return;
			// 	window.open(`${path}/${item?.id}/synthesis`);
			// }}
			// disabled={!path}
			// renderTooltip={renderTooltip}
			// renderContent={renderContent}
			// tooltipPosition={tooltipPosition}
			// sx={{ ...(isDisabled && { pointerEvents: 'none', opacity: 0.8 }), ...sx }}
			// radiusDonnut={radiusDonnut}
			/>

			<BButton
				variant="secondary"
				label={`${t('see-all')} ${t('products')}`}
				onClick={() => {
					handleClick('product');
					return;
				}}
				addStyles={{
					height: 'auto',
					minHeight: 42,
					// ...(!button?.link && {
					// 	pointerEvents: 'none',
					// 	color: 'rgba(0, 0, 0, 0.38)',
					// }),
				}}
			/>
		</div>
	);
};

export default ProductDonut;
