import { css, cx } from '@emotion/css';
import { useNavigate } from 'react-router-dom';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';

type DashboardButtonProps = {
	label: string;
	path: string;
	withFilter?: boolean;
	orderBy?: string;
	direction?: string;
	addEcoDesignFilter?: boolean;
	eventName?: string;
	isDisabled?: boolean;
};

const styles = {
	container: css({
		padding: '8px 16px',
	}),
	disabled: css({
		cursor: 'default !important',
	}),
};

const DashboardButton = ({
	label,
	path,
	withFilter,
	orderBy,
	direction,
	addEcoDesignFilter,
	eventName,
	isDisabled,
}: DashboardButtonProps) => {
	const analytics = useJuneTrack();

	const [searchQueryParams] = useSearchQueryParams();
	const navigate = useNavigate();
	const stringFilters = JSON.stringify([
		...(searchQueryParams?.facetFilters || []),
		...(addEcoDesignFilter
			? [
					{
						path: 'ecoConcevable',
						type: 'hardcoded-undefined-facet-type',
						values: ['eco_concevable'],
						global: false,
						folderLabel: 'Éco-concevable',
					},
			  ]
			: []),
	]);

	const handleClick = () => {
		if (isDisabled) {
			return;
		}

		analytics.track(
			eventName,
			{},
			{
				// Add the GROUP_ID here to track this event on behalf of a workspace
				context: { category: 'Dashboard' },
			},
		);

		const link = withFilter
			? `${path}?facetFilters=${stringFilters || '[]'}&orderBy=${orderBy}&direction=${direction}`
			: path;
		navigate(link);
	};

	return (
		<BButton
			variant="secondary"
			label={label}
			onClick={handleClick}
			className={cx('width100', styles.container, isDisabled ? styles.disabled : '')}
		/>
	);
};

export default DashboardButton;
