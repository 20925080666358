import { useCallback, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';

import { roleLabels } from '@carbonmaps/shared/utils/constants';
import { MOD_ECO_CONCEPTION } from '@carbonmaps/shared/utils/module';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetProductSheetTimePeriod, useProductInfo } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { getPluginCompany } from '@carbonmaps/ui/utils/utils';

import useHasRoles from '../../../hooks/useHasRoles';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';

import { useReportClientInfo } from '@carbonmaps/ui/hooks/useGetReportClientSheet';
import { css, cx } from '@emotion/css';
import BlockCard from '../../../components/BlockCard';
import ContentCard from '../../../components/sales/ContentCard';
import Diff from '../../../components/sales/Diff';
import Percent from '../../../components/sales/Percent';
import SalesCard from '../../../components/sales/SalesCard';
import Skeleton from '../../../components/Skeleton';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ProductsClientReportsTable from './ProductsClientReportsTable';

const styles = {
	description: css({
		fontSize: '12px',
		fontWeight: 400,
		color: siteColors.grey8,
	}),
	skeleton: css({
		height: '300px !important',
		width: '100% !important',
	} as any),
};

const ReportClientSynthesis = () => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const params = useParams();
	const [searchQueryParams] = useSearchQueryParams();
	const { t } = useTranslation();
	const { indicator } = useApp();
	const { selectedYear } = useYearSelection();

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { data: reportClientInfo, isLoading } = useReportClientInfo({
		objectId: params.reportClientId!,
		viewMode: indicator,
	});

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Land Report Client', options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- track event product page ---- //
	useEffect(() => {
		if (!reportClientInfo) return;

		trackEvent({ Client: reportClientInfo?.name });
	}, [reportClientInfo, trackEvent]);

	return (
		<>
			<div className={cx('flexRow gap16 nowrap')}>
				<ContentCard
					title={t('better-quality-product', {
						product: t('product'),
					})}
					overTitle={
						<Percent
							value={
								indicator === 'carbon'
									? reportClientInfo?.betterQualityProductCarbon
									: reportClientInfo?.betterQualityProductWater
							}
						/>
					}
				>
					<div className={cx(styles.description)}>
						{t('Produits vendus à ce client sont moins émissifs que la moyenne du marché')}
					</div>
				</ContentCard>
				<ContentCard
					title={t('vs-Market-Average')}
					overTitle={
						<Diff
							noPopover={true}
							data={reportClientInfo}
							value={
								indicator === 'carbon'
									? reportClientInfo?.vsMarketAverageCarbonPercentage
									: reportClientInfo?.vsMarketAverageWaterPercentage
							}
							indicator={indicator}
						/>
					}
				>
					<div className={cx(styles.description)}>
						{t('Impact par rapport à la moyenne du marché pour le même catalogue')}
					</div>
				</ContentCard>
			</div>
			<ProductsClientReportsTable filterOptions={searchQueryParams} manualSortBy />
		</>
	);
};

export default ReportClientSynthesis;
