import { css, cx } from '@emotion/css';
import { Chip, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

import { STATUS_SIMULATION } from '@carbonmaps/shared/utils/simulation';

import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';

// type FormSelectProps = {
// 	name: string;
// 	label?: React.ReactNode;
// };

const styles = {
	chipStyles: css({
		textTransform: 'capitalize',
		background: 'var(--color-background)!important',
		uppercase: 'none',
		borderRadius: '8px !important',
		border: '1px solid rgba(0, 0, 0, 0.10)!important',
		height: '28px !important',
		span: {
			color: cn(siteColors.grey900, '!important'),
			fontSize: '12px !important',

			height: 'auto',
		},
	}),
	select: css({
		height: '42px !important',
		padding: '8px 16px !important',
		borderRadius: '8px !important',
		':hover': {
			borderColor: siteColors.primary,
		},
		'& .MuiSelect-select': {
			paddingLeft: '0px !important',
			paddingTop: '0px !important',
			paddingBottom: '0px !important',
			paddingRight: '24px !important',
		},
		'& fieldset': {
			borderWidth: '1px',
			borderColor: siteColors.grey700,
			borderRadius: '8px',
		},

		'& fieldset:hover': {
			borderWidth: '1px',
			borderColor: siteColors.primary,
			borderRadius: '8px',
		},
	}),
	label: css({
		fontSize: '14px!important',
		fontWeight: '600!important',
		color: siteColors.grey900,
	}),
	gap8: css({
		gap: 8,
	}),
};

const FormSelect = ({
	name,
	label,
	control,
	defaultValue,
	children,
	renderValue,
	options,
	className,
	dropdownClassName,
	isOptions = false,
	...props
}: any) => {
	const { t } = useTranslation();
	return (
		<div className={cx('flexColumn', className, styles.gap8)}>
			<Typography className={styles.label}>{label}</Typography>
			<FormControl {...props}>
				<Controller
					render={({ field: { onChange, onBlur, value } }) => {
						return (
							<Select
								defaultValue={value}
								onChange={onChange}
								onBlur={onBlur}
								renderValue={(v: any) => {
									if (renderValue) {
										return renderValue(v);
									}
									return (
										<Chip
											label={t(STATUS_SIMULATION[v].label)}
											className={cx(styles.chipStyles)}
											style={{ ['--color-background' as any]: STATUS_SIMULATION[v].color }}
										/>
									);
								}}
								className={styles.select}
							>
								{isOptions
									? options.map((item: { value: string | number; label: string }, index: number) => {
											return (
												<MenuItem value={item.value} className={dropdownClassName}>
													{item.label}
												</MenuItem>
											);
									  })
									: Object.keys(STATUS_SIMULATION).map((key: string, index: number) => {
											return <MenuItem value={key}>{t(STATUS_SIMULATION[key].label)}</MenuItem>;
									  })}
							</Select>
						);
					}}
					name={name}
					control={control}
					defaultValue={defaultValue}
				/>
			</FormControl>
		</div>
	);
};

export default FormSelect;
