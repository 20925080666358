import React, { useState } from 'react';

import { css } from '@emotion/css';
import { Typography } from '@mui/material';

import Dialog from '../../../components/dialogs/Dialog';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../../lib/react-query/features/img/img.hooks';
import { useQuoteStore } from '../quote.hooks';

import { siteColors } from '../../../lib/colors';
import QuoteForm from './QuoteForm';

type Props = {
	openModal: boolean;
	setOpenModal: (v: boolean) => void;
};

const ExportDialog = ({ openModal, setOpenModal }: Props) => {
	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: authData?.session.company?.logo || '' },
	});

	return (
		<Dialog
			open={openModal}
			toggle={() => {
				setOpenModal(false);
			}}
			withCancelButton={false}
			title={
				(
					<Typography variant="h2" color={siteColors.primary}>
						{t('quote-detail-title')}
					</Typography>
				) as any
			}
		>
			<QuoteForm
				onCloseDialog={() => {
					setOpenModal(false);
				}}
			/>
		</Dialog>
	);
};

export default ExportDialog;
